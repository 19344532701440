import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	IGetPromoCodesResponse,
	IGetPromoCodeByIdResponse,
	ICreatePromoCodeDto,
	ICreatePromoCodeResponse,
	IUpdatePromoCodeDto,
	IUpdatePromoCodeResponse
} from '@lomonosov/types';

export default class {
	static async getPromoCodes (): Promise<AxiosResponse<IGetPromoCodesResponse>> {
		return $api.get<IGetPromoCodesResponse>('/promo-codes');
	}

	static async getPromoCodesStatistic (): Promise<AxiosResponse<any>> {
		return $api.get<any>('/promo-codes/statistic');
	}

	static async getPromoCodeStatisticById (promoCodeId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/promo-codes/${promoCodeId}/statistic`);
	}

	static async getCurrentPromoCode (promoCodeId: string): Promise<AxiosResponse<IGetPromoCodeByIdResponse>> {
		return $api.get<IGetPromoCodeByIdResponse>(`/promo-codes/${promoCodeId}`);
	}

	static async addPromoCode (promoCode: ICreatePromoCodeDto): Promise<AxiosResponse<ICreatePromoCodeResponse>> {
		return $api.post<ICreatePromoCodeResponse>('/promo-codes', promoCode);
	}

	static async updatePromoCode (
		promoCodeId: string,
		newInfoRole: IUpdatePromoCodeDto
	): Promise<AxiosResponse<IUpdatePromoCodeResponse>> {
		return $api.put<IUpdatePromoCodeResponse>(`/promo-codes/${promoCodeId}`, newInfoRole);
	}

	static async deletePromoCode (promoCodeId: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/promo-codes/${promoCodeId}`);
	}
}
