import React from 'react';
import classNames from 'classnames';
import './style.css';

export type QuoteColorType = 'bright_mint' | 'green' | 'light_violet' | 'light_lemon' |
  'blue' | 'orange' | 'violet' | 'deep_violet' | 'ruby' | 'red' | 'dark_gray' |
  'light_gray' | 'text_white' | 'black_600' | 'light-black' | 'black';

interface TeacherCardQuoteProps {
	className?: string;
	children: React.ReactNode;
	color: QuoteColorType;
}

export const TeacherCardQuote = (props: TeacherCardQuoteProps) => {
	const { className, children, color } = props;

	return (
		<div className={classNames('teacherCardQuote', className, color)}>
			{children}
		</div>
	);
};
