import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { adminAnalyticsStore } from '@stores';
import { Button, Table } from '@components';
import { IColumn } from '@components/table';
import SaveIcon from '@mui/icons-material/Save';
import { TableCurrentTaskAnalysis } from '../index';

export default observer(() => {
	const [search, setSearch] = useState('');
	const [timer, setTimer] = useState<any>();

	const onSearchChange = (newSearch: string) => {
		setSearch(newSearch);
		clearTimeout(timer);
		setTimer(setTimeout(async () => {
			adminAnalyticsStore.changeFilterFieldStudentActivity(newSearch, 'search');
			await adminAnalyticsStore.getFiltersStudentActivity('tasks');
		},
		1000
		));
	};

	const getCurrentTask = async (id: string) => {
		adminAnalyticsStore.resetCurrentTaskPagination();
		await adminAnalyticsStore.getCurrentTaskActivity(8, 0, id);
	};

	const changePage = async (event, newPage) => {
		await adminAnalyticsStore.changePageStudentActivity(
			newPage,
			adminAnalyticsStore.paginationStudentActivity.tasks.rows,
			'tasks'
		);
	};

	const changeTableRows = async (e: React.ChangeEvent<HTMLInputElement>) => {
		await adminAnalyticsStore.changePageStudentActivity(
			adminAnalyticsStore.paginationStudentActivity.tasks.currentPage,
			+e.target.value,
			'tasks'
		);
	};

	return (
		<div className='student-analysis__table'>
			<Table
				columns={columnsTable}
				data={adminAnalyticsStore.taskActivity}
				detailPanel={{
					Component: TableCurrentTaskAnalysis,
					action: (id) => getCurrentTask(id),
					autoClose: true
				}}
				search={{
					prop: search,
					action: (str) => { onSearchChange(str); }
				}}
				pagination={{
					count: adminAnalyticsStore.paginationStudentActivity.tasks.totalCount,
					page: adminAnalyticsStore.paginationStudentActivity.tasks.currentPage,
					rowsPerPage: adminAnalyticsStore.paginationStudentActivity.tasks.rows,
					onPageChange: changePage,
					onRowsPerPageChange: changeTableRows,
					rowsPerPageOptions: [8, 10, 25, 50]
				}}
			/>
			{adminAnalyticsStore.filtersStudentActivity.discipline && adminAnalyticsStore.filtersStudentActivity.end && adminAnalyticsStore.filtersStudentActivity.start
				? <a href={adminAnalyticsStore.downloadFileUserAnswers()} download className='student-analysis__button'>
					<Button icon={<SaveIcon/>} type='main_green'>
						Скачать файл xlsx
					</Button>
				</a>
				: <div className='student-analysis__button'>
					<Button icon={<SaveIcon/>} type='main_green' disable>
						Скачать файл xlsx
					</Button>
				</div>
			}
		</div>
	);
});

const columnsTable: IColumn[] = [
	{ field: 'name', name: 'ДЗ', width: 400 },
	{ field: 'type', name: 'Тип ДЗ' },
	{ field: 'date', name: 'Дата', tooltip: 'Дата начало выполнения работ' },
	{ field: 'count', name: 'Кол-во выполнений', align: 'center' },
	{ field: 'avg', name: 'Средний балл', align: 'center' }
];
