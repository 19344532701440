import React, { useMemo } from 'react';
import { BookIcon, DateIcon, GraduateCap } from 'lomonosov-icons/index';
import Tooltip from '@mui/material/Tooltip';
import { LevelIcon } from 'src/helpers/icons';

import './style.css';

export default (props: IPortalCardInfoProps) => {
	const { menu, marginTop } = props;

	const icons = useMemo(() => ({
		date: <DateIcon/>,
		book: <BookIcon/>,
		'graduate-cap': <GraduateCap/>,
		level: <LevelIcon/>
	}), []);

	return (
		<div className='portal-card-info' style={{ marginTop: marginTop }}>
			{menu.map(item =>
				item.name &&
				<Tooltip key={item.typeIcon} title={item.tooltip ?? ''} arrow>
					<div className={`portal-card-info__item ${item.typeIcon} ${item.tooltip && 'pointer'}`}>
						{icons[item.typeIcon]}
						<span
							className={`
								portal-card-info__text ${item.onClick && 'portal-card-info__text_click'} theme-text 
							`}
							onClick={item.onClick}
						>
							{item.name}
						</span>
					</div>
				</Tooltip>
			)}
		</div>
	);
};

interface IPortalCardInfoProps {
	menu: {
		typeIcon: 'date' | 'book' | 'graduate-cap' | 'level',
		name: string,
		onClick?: () => void,
		tooltip?: string | any
	}[],
	marginTop?: string
}
