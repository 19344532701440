import { makeAutoObservable, runInAction } from 'mobx';
import { ShopAdminService } from '@services';
import { generalStore } from '@stores';
import {
	IGetAllTariffPlansResponse,
	ICreateTariffPlanDto,
	ICreateTariffDto,
	IUpdateTariffDto,
	ICreateTariffResponse,
	IUpdateTariffResponse
} from '@lomonosov/types';
import { GetTariffPlanByIdResponse, UpdateTariffPlanDto } from '@interfaces';

class AdminTariffPlansStore {
	plans: IGetAllTariffPlansResponse = [];
	tariff: ICreateTariffResponse | IUpdateTariffResponse | null = null;

	currentTariff: any = null;

	setPlans (data: IGetAllTariffPlansResponse) {
		this.plans = data;
	}

	constructor () {
		makeAutoObservable(this);
	}

	async getPlans (): Promise<IGetAllTariffPlansResponse | null> {
		try {
			const { data } = await ShopAdminService.getTariffPlans();
			this.setPlans(data);
			return data;
		} catch (e) {
			console.log(e);
			return null;
		}
	}

	async getPlanById (id: string): Promise<GetTariffPlanByIdResponse | null> {
		try {
			const { data } = await ShopAdminService.getCurrentTariffPlan(id);
			data.tariffs.forEach(t => {
				t.periods.forEach(p => {
					// @ts-ignore
					p.begins = p.begins.split('T')[0]; /// ??
					// @ts-ignore
					p.ends = p.ends.split('T')[0]; /// ??
				});
			});
			return data;
		} catch (e) {
			console.log(e);
			return null;
		}
	}

	async addTariffPlan (state: ICreateTariffPlanDto) {
		try {
			const { data } = await ShopAdminService.addTariffPlan(state);
			this.setPlans([...this.plans, data]);
		} catch (e) {
			console.log(e);
			return null;
		}
	}

	async updateTariffPlan (id: string, state: UpdateTariffPlanDto) {
		const { data } = await ShopAdminService.updateTariffPlan(id, state);
		const indexPlan = this.plans.findIndex((p) => p.id === data.id);
		this.plans[indexPlan] = data;
		this.setPlans([...this.plans]);
	}

	async deleteTariffPlan (id: string) {
		try {
			await ShopAdminService.deleteTariffPlan(id);
			runInAction(() => {
				const indexPlan = this.plans.findIndex((p) => p.id === id);
				this.plans.splice(indexPlan, 1);
				this.setPlans([...this.plans]);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async addTariff (state: ICreateTariffDto) {
		try {
			const { data } = await ShopAdminService.addTariff(state);
			runInAction(() => {
				this.tariff = data;
			});
			generalStore.changeAlertState('success', 'Тариф создан');
		} catch (e) {
			console.log(e);
			return null;
		}
	}

	async updateTariff (id: string, state: IUpdateTariffDto) {
		try {
			const { data } = await ShopAdminService.updateTariff(id, state);
			runInAction(() => {
				this.tariff = data;
			});
			generalStore.changeAlertState('success', 'Тариф обновлен');
		} catch (e) {
			console.log(e);
		}
	}

	async deleteTariff (id: string) {
		try {
			await ShopAdminService.deleteTariff(id);
			runInAction(() => {
				this.tariff = null;
				generalStore.changeAlertState('success', 'Тариф удалён');
			});
		} catch (e) {
			console.log(e);
		}
	}

	async updateTariffsOrder (tariffs: { tariffs: { id: string, order: number }[]}) {
		try {
			await ShopAdminService.updateTariffsOrder(tariffs);
		} catch (e) {
			console.log(e);
		}
	}

	changeCurrentTariff (newTariff) {
		this.currentTariff = newTariff;
	}
}

export const adminTariffPlansStore = new AdminTariffPlansStore();
