import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, FormControlLabel, IconButton, Switch } from '@mui/material';
import { ArrowBackButton, Hearts, Select } from '@components';
import { constants, images, getNoun } from '@helpers';
import { IGetUserCoursesItemResponse, Difficult } from '@lomonosov/types';
import HelpIcon from '@mui/icons-material/Help';
import './style.css';

export type SettingCourseModalType = 'setting' | 'delete';

interface ISettingCourse {
	id: string;
	name: string;
	setting: IGetUserCoursesItemResponse['setting'];
	preview: { id: string }[]
}

interface SettingCourseModalProps {
  course?: ISettingCourse | null;
  action: (settings?: IGetUserCoursesItemResponse['setting']) => void;
	type: SettingCourseModalType;
	changeType?: (type: SettingCourseModalType) => void;
	handleClose?: () => void;
}

export default (props: SettingCourseModalProps) => {
	const { course, action, type, handleClose, changeType } = props;
	const [isShowTimer, setIsShowTimer] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [difficult, setDifficult] = useState<{id: Difficult, name: string} | null>(levels[0]);
	const [helperText, setHelperText] = useState<string>('');
	const [isChanged, setIsChanged] = useState(false);
	const [isShowMotivationInfo, setIsShowMotivationInfo] = useState(false);

	if (!course) {
		return null;
	}

	useEffect(() => {
		setDifficult(levels.find(level => level.id === course?.setting?.difficult) || null);
		if (difficult?.id !== Difficult.easy) {
			setIsDisabled(true);
		}
	}, [course?.setting?.difficult]);

	useEffect(() => {
		const { setting } = course || null;
		const { isShowTimer: showTimer, difficultUpdated } = setting || null;

		setIsShowTimer(showTimer);

		if (!difficultUpdated) return;

		const diff = dayjs().diff(difficultUpdated, 'day');
		const daysLeft = 30 - diff;
		const helperText = `Можно поменять через ${daysLeft} ${getNoun(daysLeft, 'день', 'дня', 'дней')}`;

		if (diff >= 30) {
			setIsDisabled(false);
			setHelperText('');
		} else {
			setIsDisabled(true);
			setHelperText(helperText);
		}
	}, [course?.id, course?.setting?.difficultUpdated]);

	const handleChangeDifficult = (e, newValue) => {
		setDifficult(newValue);
		setIsChanged(true);
	};

	const changeShowTimer = (e) => {
		setIsChanged(true);
		setIsShowTimer(e.target.checked);
	};

	const handleSubmit = () => {
		const now = new Date();
	  action({
			...course.setting,
			isShowTimer: isShowTimer,
			difficult: difficult?.id || Difficult.easy,
			difficultUpdated: now
		});
	};

	const onDeleteCourse = () => {
		changeType?.('delete');
	};

	if (isShowMotivationInfo) {
		return (
			<MotivationInfo handleClose={() => setIsShowMotivationInfo(false)}/>
		);
	}

	if (type === 'delete') {
		return (
			<div className='settings-course-modal'>
				<p className='settings-course-modal__title'>Это действие необратимо</p>
				<div className='settings-course-modal__buttons'>
					<Button className='settings-course-modal__button' variant='contained' color='error' onClick={handleSubmit}>
						Точно
					</Button>
					<Button className='settings-course-modal__button' variant='contained' color='primary' onClick={handleClose}>
						Отмена
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div className='settings-course-modal'>
			<div className='settings-course-modal__header'>
				<img
					className='settings-course-modal__image' alt='course'
					src={course?.preview?.length !== 0
						? `${constants.URL}/files/${course?.preview?.[0]?.id || ''}`
						: images.defaultCourse
					}
				/>
				<h2 className='settings-course-modal__title'>
					{course?.name}
				</h2>
			</div>
			<div className='settings-course-modal__block'>
				<p className='settings-course-modal__text'>
					Мотивационная система
					<IconButton onClick={() => setIsShowMotivationInfo(true)}>
						<HelpIcon sx={{ color: '#8417FF' }}/>
					</IconButton>
				</p>
				<Select
					input={{
						label: 'Уровень сложности',
						helperText: helperText
					}}
					className='settings-course-modal__select'
					options={levels}
					disabled={isDisabled}
					value={difficult}
					onChange={handleChangeDifficult}
					disableClearable
				/>
			</div>
			<div className='settings-course-modal__block'>
				<p className='settings-course-modal__text'>
					Мои жизни: <Hearts count={course?.setting?.healthCount}/>
				</p>
			</div>
			<div className='settings-course-modal__block'>
				<p className='settings-course-modal__text'>Таймер до даты экзамена</p>
				<FormControlLabel
					className='settings-course-modal__checkbox'
					control={
						<Switch checked={isShowTimer} onChange={changeShowTimer} name='Показать' />
					}
					label={isShowTimer ? 'Скрыть' : 'Показывать'}
				/>
			</div>
			<Button variant='contained' color='primary' className='settings-course-modal__button' onClick={handleSubmit} disabled={!isChanged}>
				Применить
			</Button>
			<Button className='settings-course-modal__button-delete' onClick={onDeleteCourse}>
				Удалить курс навсегда
			</Button>
		</div>
	);
};

const levels: { id: Difficult; name: string }[] = [
	{ id: Difficult.easy, name: 'Легкий' },
	{ id: Difficult.medium, name: 'Средний' },
	{ id: Difficult.hard, name: 'Сложный' }
];

const MotivationInfo = ({ handleClose }: { handleClose: () => void }) => {
	return (
		<div className='motivation-info'>
			<ArrowBackButton onClick={handleClose}/>
			<h2 className='motivation-info__title'>Как работает мотивационная система?</h2>
			<p className='motivation-info__subTitle'>Существует 3 уровня сложности:</p>
			<ul>
				<li>
					<strong>Легкий</strong> — данная система предусматривает отключение дедлайнов: домашние задания и пробные экзамены можно выполнять в любое время.
				</li>
				<li>
					<strong>Средний</strong> — данная система предусматривает наличие дедлайнов: домашние задания и пробники можно выполнять только в установленные сроки.
				</li>
				<li>
					<strong>Сложный</strong> — данная система предусматривает наличие дедлайнов: домашние задания и пробные экзамены можно выполнять только в установленные сроки и системы жизней (за каждое невыполненное домашнее задание или пробный экзамен сгорает одна жизнь и в случае, если будут потрачены все жизни, то доступ к курсу будет закрыт и возобновится только в случае ликвидации долгов).
				</li>
			</ul>
		</div>
	);
};
