import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, DeleteModal } from '@components';
import { adminDisciplineStore } from '@stores';

export default observer(() => {
	const [deletableDisciplineId, setDeletableDisciplineId] = useState<string>('');

	useEffect(() => {
		if (!adminDisciplineStore.disciplines?.length) {
			adminDisciplineStore.getDisciplines().then();
		}
	}, []);

	const deleteDiscipline = () => {
		adminDisciplineStore.deleteDiscipline(deletableDisciplineId).finally(() => setDeletableDisciplineId(''));
	};

	// TODO проставить интерфейсы, как только их обновят
	return (
		<>
			<Table
				columns={[
					{ field: 'id', name: 'id', copy: true, editable: false },
					{ field: 'name', name: 'Название' },
					{ field: 'groupVkID', name: 'groupId' },
					{ field: 'groupAccessToken', name: 'access_token', maxCharacters: 30 }
				]}
				data={adminDisciplineStore.disciplines}
				search pagination
				actionRow={{
					add: newDiscipline => adminDisciplineStore.createDiscipline(newDiscipline),
					delete: id => setDeletableDisciplineId(id),
					edit: (id, newState) => adminDisciplineStore.updateDiscipline(id, newState)
				}}
			/>
			<DeleteModal
				deleteName={adminDisciplineStore.disciplines.find(discipline => discipline.id === deletableDisciplineId)?.name}
				open={!!deletableDisciplineId}
				handleClose={() => setDeletableDisciplineId('')}
				action={deleteDiscipline}
			/>
		</>
	);
});
