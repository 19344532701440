import React from 'react';

interface TabPanelProps {
	children?: React.ReactNode;
	name: string | number;
	value: string | number;
	overflow?: boolean;
	className?: string
}

export default (props: TabPanelProps) => {
	const {
		children,
		value,
		name,
		overflow = false,
		className = ''
	} = props;

	if (value !== name) {
		return null;
	}

	return (
		<div className={className} role='tabpanel' hidden={value !== name} style={{ overflow: overflow ? 'hidden' : 'visible' }}>
			{children}
		</div>
	);
};
