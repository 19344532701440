import { makeAutoObservable, runInAction } from 'mobx';

import { convertDateFormat } from '@helpers';
import { AdminShortlink } from '@services';

class AdminShortLinkStore {
	links: any[] = []

	getLinks = async () => {
		try {
			const { data } = await AdminShortlink.getLinks();

			runInAction(() => {
				this.links = data.map((e) => ({
					...e,
					id: e.hash,
					date: convertDateFormat(e.createdAt)
				}));
			});
		} catch (e) {
			console.error('getLinks', e);
		}
	}

	addLink = async (link: {
		link: string;
		description: string;
	}) => {
		try {
			const { data } = await AdminShortlink.createLink(link);

			runInAction(() => {
				this.links = [...this.links, { ...data, id: data.hash }];
			});
		} catch (e) {
			console.error('addLink', e);
		}
	}

	editLink = async (id: string, newData: { description: string }) => {
		try {
			const { data } = await AdminShortlink.updateLink(id, newData);

			runInAction(() => {
				this.links = this.links.map((el) => (el.hash === data.hash ? { ...el, description: data.description } : el));
			});
		} catch (e) {
			console.error('editLink', e);
		}
	}

	deleteLink = async (id: string) => {
		try {
			const { data } = await AdminShortlink.deleteLink(id);

			if (data) {
				runInAction(() => {
					this.links = this.links.filter(el => (el.hash !== id));
				});
			}
		} catch (e) {
			console.error('deleteLink', e);
		}
	}

	constructor () { makeAutoObservable(this); }
}

export const adminShortLinkStore = new AdminShortLinkStore();
