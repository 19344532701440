import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { adminUserStore, userStore, adminMentorsStore } from '@stores';
import { Table, Button, CustomModal } from '@components';

import './style.css';

export default observer(() => {
	const [timer, setTimer] = useState<any>();
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState('');
	const [deletableUserId, setDeletableUserId] = useState<string>('');

	const onSearchChange = (newSearch: string) => {
		setSearch(newSearch);
		clearTimeout(timer);
		setTimer(setTimeout(() =>
			adminMentorsStore.getMentorStudents(page, rowsPerPage, userStore.user?.mentor?.id || '', newSearch),
		1000
		));
		setPage(0);
	};

	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
		adminMentorsStore.getMentorStudents(newPage, rowsPerPage, userStore.user?.mentor?.id || '', search);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	useEffect(() => {
		handleChangePage(undefined, 0);
	}, [rowsPerPage]);

	const deleteUser = () => {
		const updatedMentors = adminUserStore.mentors.filter(mentor => mentor.id !== userStore.user?.mentor?.id);
		adminUserStore.updateUserMentors(deletableUserId, updatedMentors).then(() => setDeletableUserId(''));
		adminMentorsStore.setStudentMentors(adminMentorsStore.students.filter(student => student.id !== deletableUserId));
	};

	const columnsAdmin: any = [
		{ name: 'id', field: 'id', copy: true },
		{ name: 'vkId', field: 'vkId' },
		{ name: 'Имя', field: 'firstName' },
		{ name: 'Фамилия', field: 'secondName' },
		{ name: 'Email', field: 'email' },
		{ name: 'Телефон', field: 'phone' }
	];

	return (
		<>
			<Table
				columns={columnsAdmin}
				data={adminMentorsStore.students}
				pagination={{
					count: adminMentorsStore.pageCount,
					page: page,
					rowsPerPage: rowsPerPage,
					onPageChange: handleChangePage,
					onRowsPerPageChange: handleChangeRowsPerPage
				}}
				search={{
					prop: search,
					action: (str) => { onSearchChange(str); }
				}}
				actionRow={{
					delete: (id) => {
						setDeletableUserId(id);
						adminUserStore.getUserMentors(id);
					}
				}}
			/>
			<CustomModal
				viewClose='cross'
				open={!!deletableUserId}
				textTitle='Отказаться от менторства?'
				handleClose={() => setDeletableUserId('')}
				heightModal='auto'
			>
				<div className='my-students__buttons'>
					<div className='my-students__button'>
						<Button type='main_orange' onClick={() => setDeletableUserId('')}>
              Назад
						</Button>
					</div>
					<div className='my-students__button'>
						<Button type='main_green' onClick={deleteUser}>
              Отказаться
						</Button>
					</div>
				</div>
			</CustomModal>
		</>
	);
});
