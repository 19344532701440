import React, { memo } from 'react';
import { IconButton } from '@material-ui/core';
import { VKLogoIcon, YouTubeIcon, TelegramIcon } from 'lomonosov-icons';

import { images } from '@helpers';

import './style.css';

export default memo(() => (
	<footer className='footer'>
		<div className='footer__main-block'>
			<div className='footer__main-block__column'>
				<img className='column_logo' src={images.darkLogo} alt=''/>
			</div>
			<div className='footer__main-block__column'>
				<div className='column_title'>
          Lomonosov
				</div>
				<a href='https://lomonosov.school' className='column_text'>
          О нас
				</a>
				<a href={'https://lomonosov.school/#teachers'} className='column_text'>
          Преподаватели
				</a>
				<a href={'https://lomonosov.school/#feedbacks'} className='column_text'>
          Отзывы
				</a>
				<a
					href={'https://lomonosov.school/legal/privacy-policy'}
					className='column_text' target='_blank' rel='noreferrer noopener'
				>
          Политика конфиденциальности
				</a>
				<a
					href={'https://lomonosov.school/legal/organisation'}
					className='column_text' target='_blank' rel='noreferrer noopener'
				>
          Юридическая информация
				</a>
				<a
					href={'https://lomonosov.school/investors'}
					className='column_text' target='_blank' rel='noreferrer noopener'
				>
					Инвесторам
				</a>
				<a
					href={'https://lomonosov.school/partners-ege'}
					className='column_text' target='_blank' rel='noreferrer noopener'
				>
					Партнерская программа
				</a>
			</div>
			<div className='footer__main-block__column'>
				<div className='column_title'>
          Обучение
				</div>
				<a
					href={'/#personal'} target='_blank'
					rel='noreferrer noopener' className='column_text'
				>
          Личный кабинет
				</a>
				<a href={'https://lomonosov.school/ege'} className='column_text'>
          ЕГЭ
				</a>
				<a href={'https://lomonosov.school/oge'} className='column_text'>
          ОГЭ
				</a>
				<a href={'https://lomonosov.school/dvi'} className='column_text'>
          ДВИ
				</a>
				<a href={'https://lomonosov.school/summer'} className='column_text'>
          Летняя школа
				</a>
				<a
					href='https://vk.com/app7039615_-150364082#30060'
					className='column_text' target='_blank' rel='noreferrer noopener'
				>
          Полезные материалы
				</a>
			</div>
			<div className='footer__main-block__column'>
				<div className='column_title'>
          Приложения
				</div>
				<div className='column_text'>
          IOS (Скоро)
				</div>
				<a
					href='https://play.google.com/store/apps/details?id=school.lomonosov.portal.twa'
					className='column_text' target='_blank' rel='noreferrer noopener'
				>
          Android
				</a>
			</div>
			<div className='footer__main-block__column'>
				<div className='column_title'>
          Контакты
				</div>
				<div className='column_text'>
					<a href='mailto:info@lomonosov.school'>info@lomonosov.school</a>
				</div>
				<div className='column_text'>
					<a href='tel:+79104353535'>+7 910 435 35 35</a>
				</div>
			</div>
		</div>
		<div className='footer__ur-info-block'>
			<div className='footer__ur-info-block__container'>
				<div className='container_text'>
          © Lomonosov School, 2017&ndash;2023, ИП Ключников Андрей Сергеевич, ОГРНИП 319774600575270, г. Москва, Россия
				</div>
				<div className='container_buttons'>
					<IconButton href='https://vk.com/lomonosov_school' target='_blank' rel='noreferrer noopener'>
						<VKLogoIcon/>
					</IconButton>
					<IconButton
						href='https://www.youtube.com/@LomonosovSchool'
						target='_blank' rel='noreferrer noopener'
					>
						<YouTubeIcon/>
					</IconButton>
					<IconButton href='https://t.me/lmnsvbot' target='_blank' rel='noreferrer noopener' style={{ color: '#fff' }}>
						<TelegramIcon/>
					</IconButton>
				</div>
			</div>
		</div>
	</footer>
));
