import { makeAutoObservable } from 'mobx';
import { AdminInvoiceService } from '@services';
import { convertDateFormat } from '@helpers';
import { IGetInvoiceById, InvoicesQueryParamsDto, InvoiceStatusType } from '@interfaces';

interface IFilteringMenuProps {
	end?: Date | string | null,
	start?: Date | string | null,
	search?: string,
	isClose: {
		id: 'open' | 'close',
		name: string
	},
	status: {
		id: InvoiceStatusType,
		name: string
	} | null
}

class AdminInvoiceStore {
	invoices: { invoices: IInvoiceForTable[], count: number } = {
		invoices: [],
		count: 0
	};

	currentInvoice: IGetInvoiceById | null = null;
	isClose: boolean = false
	statistic: Record<InvoiceStatusType, number> & { total: number } | null = null

	filtering: IFilteringMenuProps = {
		end: null,
		start: null,
		isClose: {
			id: 'open',
			name: 'Открытые'
		},
		status: null,
		search: ''
	}

	changeFieldCurrentInvoice (value, field) {
		if (this.currentInvoice) {
			this.currentInvoice[field] = value;
		}
	}

	changeFieldFiltering (value, field: 'isClose' | 'end' | 'start' | 'search' | 'status') {
		this.filtering[field] = value;
	}

	async getInvoiceById (id: string) {
		try {
			const res = await AdminInvoiceService.getInvoiceById(id);
			this.currentInvoice = res.data;
			return null;
		} catch (error) {
			console.log(error);
		}
	}

	async getInvoices (take: number = 5, skip: number = 0, search?: string, userId?: string) {
		try {
			const isCloseStatus = {
				close: true,
				open: false
			};

			const params: Partial<InvoicesQueryParamsDto> = {
				take,
				skip,
				search: search || this.filtering?.search,
				userId: userId || undefined,
				isClose: isCloseStatus[this.filtering.isClose.id],
				status: this.filtering.status ? this.filtering.status.id : undefined,
				start: this.filtering?.start,
				end: this.filtering?.end
			};

			const { data } = await AdminInvoiceService.getInvoices(params);

			this.invoices.invoices = data?.invoices
				.map((invoice, index) => {
					return ({
						...invoice,
						managerName: `${invoice.manager?.firstName} ${invoice.manager?.secondName}`,
						userName: invoice.user ? `${invoice.user?.firstName} ${invoice.user?.secondName}` : 'Отсутствует',
						dateCreate: convertDateFormat(invoice.createdAt),
						total: invoice?.items.reduce((sum, item) => sum + item.price, 0),
						invoice: 'Курсы: ' + invoice.items.map(item => item.tariff?.course?.discipline?.name).join(', ')
					});
				});

			this.invoices.count = data.count;
			if (!userId) {
				const statistic = await AdminInvoiceService.getInvoiceStatistic();
				this.statistic = statistic.data;
			}
		} catch (e) {
			console.log(e);
		}
	}

	async updateInvoiceStatus (id: string, invoice: any) {
		try {
			const { data } = await AdminInvoiceService.updateInvoice(id, invoice);
			this.currentInvoice = data;
		} catch (e) {
			console.log(e);
		}
	}

	async createInvoice (invoice: any) {
		try {
			await AdminInvoiceService.createInvoice(invoice);
			await this.getInvoices(5, 0);
		} catch (e) {
			console.log(e);
		}
	}

	async deleteInvoice (id: string) {
		try {
			await AdminInvoiceService.deleteInvoice(id);
			this.invoices.invoices = this.invoices.invoices.filter(invoice => invoice?.id !== id);
		} catch (e) {
			console.log(e);
		}
	}

	constructor () { makeAutoObservable(this); }
}

export const adminInvoiceStore = new AdminInvoiceStore();

interface IInvoiceForTable {
	id: string;
	isClose: boolean;
	shortId: string;
	managerName: string;
	userName: string;
	dateCreate: string;
	total: number;
	invoice: string
}
