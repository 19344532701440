import { makeAutoObservable } from 'mobx';
import { AdminAbandonedCarts, IGetAllAbandonedCartResponse } from '@services';
import { convertDateFormat, getArrMonthsName } from '@helpers';

interface ICurrentCartProps {
	id: string,
	courseName: string,
	duration: string,
	tariffName: string,
	price: number
}

class AdminAbandonedCartsStore {
  abandonedCarts: IGetAllAbandonedCartResponse = {
  	items: [],
  	count: 0
  };

  currentAbandonedCarts: ICurrentCartProps[] | null = [];
  isClose: boolean = false

  async getAbandonedCartsById (id: string) {
  	try {
  		const { data } = await AdminAbandonedCarts.getAbandonedCartById(id);
  		this.currentAbandonedCarts = data.items.map(course => ({
  			id: course.id,
  			courseName: course.tariff.course.name,
  			duration: getArrMonthsName([{ begins: course.startPeriod, ends: course.endPeriod }]).monthsName,
  			tariffName: course.tariff.name,
  			price: course.price
  		}));
  		return null;
  	} catch (error) {
  		console.log(error);
  	}
  }

  async getAbandonedCarts (take: number = 5, skip: number = 0) {
  	try {
  	  const params = {
  	    take, skip
  		};
  		const { data } = await AdminAbandonedCarts.getAbandonedCarts(params);
  		this.abandonedCarts.items = data.items.map(cart =>
  			({
  				...cart,
  				userName: `${cart?.user?.firstName} ${cart?.user?.secondName}`,
  				vkId: cart?.user?.vkId,
  				phone: cart?.user?.phone,
  				email: cart?.user?.email,
  				dateCreate: convertDateFormat(cart?.createdAt)
  			})
  		);
  		this.abandonedCarts.count = data.count;
  	} catch (e) {
  		console.log(e);
  	}
  }

  constructor () { makeAutoObservable(this); }
}

export const adminAbandonedCartsStore = new AdminAbandonedCartsStore();
