import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import { EssayType } from '../admin-services';

export default class {
	/* Баннеры  */
	static async getListEssay (take: number, skip: number, type: EssayType): Promise<AxiosResponse<IAllUserEssayResponse>> {
		return $api.get<IAllUserEssayResponse>('/essays/literary-texts', { params: { type, take, skip } });
	}

	static async getByIdEssay (id: string): Promise<AxiosResponse<IGetByIdUserEssay>> {
		return $api.get<IGetByIdUserEssay>(`/essays/literary-texts/${id}`);
	}

	static async createEssay (essay: ISendEssayDto): Promise<AxiosResponse<any>> {
		return $api.post<any>('/essays', essay);
	}

	static async buyEssay (buyEssayDto: IBuyEssayDto): Promise<AxiosResponse<IBuyEssayResponse>> {
		return $api.post<IBuyEssayResponse>('purchases/essays/buy', buyEssayDto);
	}
}

export interface ISendEssayDto {
	userFile: {
		id: string
	},
	literaryText: {
		id: string
	}
}

export interface IResponseGetEssay {
	id: string;
	begins: Date | string;
	ends: Date | string;
	text: string | null;
	file: { id: string } | null;
	userFile?: any;
}

export interface IAllUserEssayResponse {
	count: number;
	items: IResponseGetEssay[]
}

export interface IUserEssay {
	id: string;
	mentorFile: {
		id: string
	} | null;
	userFile: {
		id: string;
	}
}

export interface IGetByIdUserEssay {
	essay: IUserEssay,
	id: string;
}

export type BuyEssayType = 'standard' | 'extended';
export interface IBuyEssayDto {
	email: string;
	phone?: string;
	type: BuyEssayType;
	literaryText: {
		id: string;
	}
}

export interface IBuyEssayResponse {
	OrderId: string;
	PaymentURL: string;
	Status: string;
	Success: boolean;
}
