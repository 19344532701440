import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { Button, CustomModal, Input } from '@components';

import './style.css';

interface IPersonalDiscountModal {
  openPersonalDiscountModal: boolean
  setOpenPersonalDiscountModal: () => void
  checkPromoDiscountModal: (text: string) => Promise<any>
  title: string
  reload?: boolean
}

export default observer((props: IPersonalDiscountModal) => {
	const { openPersonalDiscountModal, setOpenPersonalDiscountModal, checkPromoDiscountModal, title, reload } = props;

	const [isActive, setIsActive] = useState(false);
	const [snackbar, setSnackbar] = useState(false);
	const [snackbarText, setSnackbarText] = useState('');

	const [promoCode, setPromoCode] = useState({
		title: title,
		titleButton: 'Активировать',
		message: false
	});
	const [promoCodeText, setPromoCodeText] = useState('');

	const handleClick = async (text) => {
		if (isActive) {
			setOpenPersonalDiscountModal();
			reload &&
        window.location.reload();
		} else {
			const check = await checkPromoDiscountModal(text);
			if (check) {
				setIsActive(true);
				setPromoCode({
					title: 'Промокод активирован!',
					titleButton: 'Продолжить',
					message: false
				});
			} else {
				setSnackbarText(check.message);
				setSnackbar(true);
				if (check.code === 3) {
					return setPromoCode({
						title: 'Вы уже активировали этот промокод',
						titleButton: 'Активировать',
						message: false
					});
				}
				setPromoCode({
					title: 'Промокод не действителен :(',
					titleButton: 'Активировать',
					message: true
				});
			}
		}
	};

	const Alarm = (props: AlertProps) => {
		return <MuiAlert className='alert' elevation={6} variant='filled' {...props} />;
	};

	return (
		<CustomModal
			open={openPersonalDiscountModal} viewClose='cross' handleClose={setOpenPersonalDiscountModal}
			heightModal='266px' widthModal='700px'
		>
			<div className='personal-discount-modal__wrapper'>
				<div className='personal-discount-modal'>
					<div className='personal-discount-modal__title'>
						<h4>{promoCode.title}</h4>
					</div>
					<div className='personal-discount-modal__content'>
						<div className='personal-discount-modal__field'>
							<Input
								label='Введите промокод' value={promoCodeText}
								onChange={(e) => setPromoCodeText(e.target.value)}
							/>
						</div>
						<div className='personal-discount-modal__button'>
							<Button type='main_green' onClick={() => handleClick(promoCodeText)}>
								{promoCode.titleButton}
							</Button>
						</div>
					</div>
					{promoCode.message &&
            <div className='personal-discount-modal__manager'>
            	<p>
                Проверьте все еще раз или
            		<a href='https://vk.me/lomonosov_school' target='_blank' rel='noreferrer noopener'>
                  напишите менеджеру
            		</a>
            	</p>
            </div>
					}
				</div>
			</div>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				open={snackbar}
				autoHideDuration={5000}
				onClose={() => { setSnackbar(!snackbar); }}
			>
				<Alarm
					onClose={() => { setSnackbar(!snackbar); }}
					severity='warning'
				>
					{snackbarText}
				</Alarm>
			</Snackbar>
		</CustomModal>
	);
});
