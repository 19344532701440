import React from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import Switch from '@mui/material/Switch';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import { generalStore } from '@stores';

interface IProps {
	tooltip?: string
}

export default observer((props: IProps) => {
	const {
		tooltip
	} = props;

	const StyledSwitch = styled(Switch)(() => ({
		padding: 8,
		'& .MuiSwitch-track': {
			borderRadius: 22 / 2,
			backgroundColor: `${generalStore.theme === 'light' ? 'white' : 'black'} !important`,
			border: `1px solid ${generalStore.theme === 'light' ? '#191919' : '#FFFFFF'}`,
			opacity: '1 !important'
		},
		'& .MuiSwitch-switchBase': {
			'&.Mui-checked': {
				color: `${generalStore.theme === 'light' ? '#191919' : '#FFFFFF'}`
			}
		},
		'& .MuiSwitch-thumb': {
			boxShadow: 'none',
			width: 15,
			height: 15,
			margin: '3px'
		}
	}));

	return (
		<Tooltip
			enterDelay={500}
			leaveDelay={300}
			placement='right'
			title={tooltip}
		>
			<ListItem
				button
				className='DrawerMenuListItem'
				onClick={() => generalStore.changeTheme()}
			>
				<ListItemIcon className='DrawerMenuListItem__icon theme-text'>
					<Icon>{`${generalStore.theme}_mode`}</Icon>
				</ListItemIcon>
				<ListItemText className='theme-text' primary='тема' />
				<StyledSwitch checked={generalStore.theme === 'light'} />
			</ListItem>
		</Tooltip>
	);
});
