import { makeAutoObservable } from 'mobx';
import { UserNewslettersService } from '@services';
import { INewsletterInterface } from '@interfaces';

class UserNewslettersStore {
	newsletters: INewsletterInterface[] = [];
	currentNewsLetter: INewsletterInterface | undefined = undefined;
	loader: boolean = false;
	count: number = Number(localStorage.newslettersCount) ?? 0

	constructor () {
		makeAutoObservable(this);
	}

	async getAllNewsletters (userId: string) {
		this.loader = true;
		try {
			const { data } = await UserNewslettersService.getAllNewsletters(userId);
			this.newsletters = data;
			this.count = data.filter(newsletter => !newsletter.isViewed).length;
			localStorage.newslettersCount = this.count;
		} catch (e) {
			console.log(e);
		}
		this.loader = false;
	}

	async readNewsLetter (userId: string, newsLetter: INewsletterInterface) {
		try {
			if (!newsLetter.isViewed && newsLetter.id) {
				await UserNewslettersService.readNewsLetter(userId, newsLetter.id);
				this.newsletters = this.newsletters.map(letter => letter.id === newsLetter.id ? { ...newsLetter, isViewed: true } : newsLetter);
				this.count -= 1;
				localStorage.newslettersCount = this.count;
			}
			this.currentNewsLetter = newsLetter;
		} catch (e) {
			console.log(e);
		}
	}

	changeCount (value) {
		this.count = value;
	}
}

export const userNewslettersStore = new UserNewslettersStore();
