import React from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from '@components';
import { adminAbandonedCartsStore } from '@stores';
import { IColumn } from '@components/table';

export default observer(({ dataL }: any) => {
	return (
		<div className='detail-panel-cart'>
			<Table
				columns={columns}
				data={adminAbandonedCartsStore.currentAbandonedCarts || []}
				title='Курсы'
			/>
		</div>
	);
});

const columns: IColumn[] = [
	{
		field: 'courseName',
		name: 'Курс'
	},
	{
		field: 'tariffName',
		name: 'Тариф'
	},
	{
		field: 'duration',
		name: 'Продолжительность'
	},
	{
		field: 'price',
		name: 'Цена'
	}
];
