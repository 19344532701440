import { makeAutoObservable, runInAction } from 'mobx';
import { Difficult } from '@lomonosov/types/dist';
import { IUser, IUserRankings } from '@interfaces';

import { AdminDirectionService, AuthService, PasswordChangeDto, UserRankingService, UserService } from '@services';
import { generalStore, authStore } from '@stores';
import { QueryParams } from '@helpers';

class UserStore {
	user: IUser = defaultUser;
	userRankings: IUserRankings = {
		rate: [],
		userRate: {
			id: '',
			firstName: '',
			secondName: '',
			avg: 0,
			count: 0,
			order: 0
		}
	}

	directions: {
		id: string;
		name: string;
	}[] = []

	isAdmin: boolean = false;
	isEmployee: boolean = false;
	isManager: boolean = false;
	isLoadingRating: boolean = false;

	purchases: any = [];
	userBannersPortal: any = [];
	userBannersCourse: any = [];

	coursesLifeModalState: boolean = false;
	motivationHelpState: boolean = false;
	saveDifficultModalState: boolean = false;

	professionPromo: string = '';
	showCareerGuidance: boolean = false;

	constructor () {
		makeAutoObservable(this);
	}

	async confirmTelegram (telegramUser: {
		first_name: string;
		last_name: string;
		photo_url: string;
		auth_date: string;
		username: string;
		hash: string;
		id: string;
	}) {
		try {
			const { data: { telegramId } } = await UserService.confirmTelegram(telegramUser, this.user.id);
			runInAction(() => {
				this.user.telegramId = telegramId;
			});
		} catch (e) {
			console.log(e);
		}
	}

	// Получить покупки пользователя
	async getUserPurchases () {
		try {
			const { data } = await UserService.getPurchases();
			runInAction(() => {
				this.purchases = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	// Получить информацию пользователя
	async getUserInfo () {
		try {
			const { data } = await UserService.getUserInfo();
			runInAction(() => {
				this.user = data;
				if (data.roles?.some(role => role.name === 'Сотрудник')) this.isEmployee = true;
				if (data.roles?.some(role => role.name === 'Администратор')) this.isAdmin = true;
				if (data.roles?.some(role => role.name === 'Менеджер')) this.isManager = true;
			});
			authStore.changeIsAuth(true);
		} catch (e) {
			console.log(e);
		}
	}

	async updateUserInfo (user?: Partial<IUser>) {
		try {
			const { data } = await UserService.updateUserInfo(user || this.user);
			runInAction(() => {
				this.user = data;
				generalStore.changeAlertState('success');
			});
		} catch (e) {
			console.log(e);
		}
	}

	async subscribeNotification (courseId) {
		try {
			await UserService.subscribeNotification(this.user.id, courseId);
			runInAction(() => {
				this.user.notifications.push({ course: { id: courseId, name: '', disciplineId: '' }, courseId: courseId, id: '', userId: this.user.id });
				generalStore.changeAlertState('success', 'Ты успешно подписан');
			});
		} catch (e) {
			console.log(e);
		}
	}

	async unsubscribeNotification (courseId) {
		try {
			await UserService.unsubscribeNotification(this.user.id, courseId);
			runInAction(() => {
				generalStore.changeAlertState('success', 'Ты успешно отписан');
				this.user.notifications = this.user.notifications.filter(item => item.courseId !== courseId);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async getUserRankings (courseId) {
		try {
			this.isLoadingRating = true;
			const { data } = await UserRankingService.getRankings(courseId);
			this.userRankings = data;
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoadingRating = false;
		}
	}

	async getDirections () {
		try {
			const { data } = await AdminDirectionService.getDirections();
			this.directions = data;
		} catch (error) {
			console.log(error);
		}
	}

	async getUserBanners (userId: string, type: 'portal' | 'course') {
		try {
			const { data } = await UserService.getBanners(userId, type);
			runInAction(() => {
				if (type === 'portal') {
					this.userBannersPortal = data;
				} else {
					this.userBannersCourse = data;
				}
			});
		} catch (e) {
			console.log(e);
		}
	}

	async getProfessionPromo (userId: string) {
		try {
			const { data } = await UserService.getProfessionPromo(userId);
			this.professionPromo = data.code;
		} catch (e) {
			console.log(e);
		}
	}

	async checkCareerGuidance (userId: string) {
		try {
			const { data } = await UserService.checkCareerGuidance(userId);
			this.showCareerGuidance = data;
		} catch (e) {
			console.log(e);
		}
	}

	async changePassword (newData: PasswordChangeDto): Promise<boolean> {
		try {
			const { data } = await AuthService.changePassword(newData);
			generalStore.changeAlertState(data.status ? 'success' : 'warning', data.msg);
			return data.status;
		} catch (e) {
			console.log(e);
			return false;
		}
	}

	async confirmVK (code: string, userId) {
		try {
			const { data } = await AuthService.confirmVK(code, userId);
			this.user = data;
		} catch (error) {
			console.log(error);
		} finally {
			localStorage.removeItem(QueryParams.CODE);
		}
	}

	async subscribeNotificationsByDisciplines (disciplineIds: string[]) {
		try {
			const { data } = await UserService.subscribeNotificationsByDisciplines(this.user.id, disciplineIds);
			this.user.notifications = data;
			generalStore.changeAlertState('success');
		} catch (error) {
			console.log(error);
		}
	}

	handleChangeUserInfo (field: string, value) {
		this.user[field] = value;
	}

	changeCoursesLifeModalState () {
		this.coursesLifeModalState = !this.coursesLifeModalState;
	}

	changeMotivationHelpState () {
		this.motivationHelpState = !this.motivationHelpState;
	}

	changeSaveDifficultModalState () {
		this.saveDifficultModalState = !this.saveDifficultModalState;
	}

	clearUserData () {
		this.user = defaultUser;
	}
}

const defaultUser: IUser = {
	id: '',
	classroom: null,
	createdAt: new Date(),
	isBanned: false,
	shortId: '',
	vkId: '',
	telegramId: '',
	discordId: '',
	appleId: '',
	googleId: '',
	photo: '',
	firstName: '',
	secondName: '',
	dateOfBirth: new Date(),
	email: '',
	phone: '',
	goal: 0,
	roles: [],
	direction: null,
	difficult: Difficult.easy,
	difficultUpdated: new Date(),
	isVerifiedEmail: false,
	hasPassword: false,
	parents: null,
	notifications: []
};

export const userStore = new UserStore();
