import axios from 'axios';
import { generalStore } from '@stores';

let isRetry = false;

export const $apiSSO = axios.create({
	withCredentials: true, // для подтягивания cooks к каждому запросу
	baseURL: process.env.SSO_AUTH_URI
}); // создаем новый экземпляр(instance) axios с пользовательской конфигурацией.

// interceptor для перехватывания запросов и ответов
$apiSSO.interceptors.request.use((config) => {
	config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`; // на каждый запрос цеплять токен
	return config;
});

$apiSSO.interceptors.response.use((config) => {
	return config; // если запрос прошел успешно
}, async (error) => {
	const originalRequest = error.config; // запрос который упал
	// eslint-disable-next-line
	if (error.response.status == 401 && error.config && !error.config._isRetry) {
		originalRequest._isRetry = true; // для того чтобы не происходил бесконечный рефреш
		try {
			// error, делаем рефреш
			if (!isRetry) {
				isRetry = true;
				const { data } = await axios.get<any>(`${process.env.SSO_AUTH_URI}/auth/refresh-tokens`, { withCredentials: true });
				localStorage.setItem('token', data.accessToken);
				localStorage.removeItem('ssoToken');
			}
			return $apiSSO.request(originalRequest); // заново отправляем запрос
		} catch (e) {
			localStorage.removeItem('token');
			localStorage.removeItem('userId');
			// window.location.pathname = '/login';
		}
	}
	console.log(error.response?.data?.message);
	generalStore.changeAlertState('warning', error.response?.data?.message);
	throw error; // если ошибка не 401, перебрасываем ошибку на верхний уровень
});
