import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Chip, Stack } from '@mui/material';
import { Table, Button } from '@components';
import { adminAnalyticsStore } from '@stores';
import SaveIcon from '@mui/icons-material/Save';

export default observer((props: any) => {
	const { dataL } = props;
	const [typeAnswers, setTypeAnswers] = React.useState<'rate' | 'text'>('rate');

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTypeAnswers((event.target as HTMLInputElement).value as 'text' | 'rate');
	};

	const changePage = async (_event, newPage) => {
		await adminAnalyticsStore.changePageStudentActivity(
			newPage,
			adminAnalyticsStore.paginationStudentActivity.currentTask.rows,
			'currentTask',
			dataL.id);
	};

	const changeTableRows = async (e: React.ChangeEvent<HTMLInputElement>) => {
		await adminAnalyticsStore.changePageStudentActivity(
			adminAnalyticsStore.paginationStudentActivity.currentTask.currentPage,
			+e.target.value,
			'currentTask',
			dataL.id
		);
	};

	const columnsTableAnswers: any = adminAnalyticsStore.currentTaskActivity?.[0]?.answers
		.map((item, index) => ({ field: item.id, name: `Вопрос ${++index}`, align: 'center', tooltip: item.name, width: 100, className: item.type })) || [];

	const dataTable = adminAnalyticsStore.currentTaskActivity.map(item => ({
		name: item.name,
		scores: adminAnalyticsStore.checkVerifiedAnswer(item.status, item.scores).scores,
		...Object.fromEntries(new Map(item.answers.map(answer => ([answer.id, answer.scores]))))
	}));

	return (
		<div className='student-analysis__detail-table'>
			<Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ marginBottom: '10px' }} spacing={2}>
				<Stack direction='row' spacing={1}>
					<Chip label='Ручная проверка' className='manual' />
					<Chip label='Автомат. проверка' className='automatic'/>
				</Stack>
				<Stack direction='row' spacing={1} alignItems='center'>
					<FormControl>
						<FormLabel id='demo-row-radio-buttons-group-label'>Тип ответов для файла</FormLabel>
						<RadioGroup
							row
							value={typeAnswers}
							onChange={handleChange}
							aria-labelledby='demo-row-radio-buttons-group-label'
							name='row-radio-buttons-group'
						>
							<FormControlLabel value='text' control={<Radio />} label='Ответы' />
							<FormControlLabel value='rate' control={<Radio />} label='Баллы' />
						</RadioGroup>
					</FormControl>
					{dataTable.length > 0 &&
					<a href={adminAnalyticsStore.downloadFileCurrentTaskAnswers(dataL.id, typeAnswers)} download className='student-analysis__button_task'>
						<Button icon={<SaveIcon/>} type='main_green'>
							Скачать файл xlsx
						</Button>
					</a>
					}
				</Stack>
			</Stack>
			<Table
				columns={[{ field: 'name', name: 'Студент' }, { field: 'scores', name: 'Баллы' }, ...columnsTableAnswers]}
				data={dataTable}
				pagination={{
					count: adminAnalyticsStore.paginationStudentActivity.currentTask.totalCount,
					page: adminAnalyticsStore.paginationStudentActivity.currentTask.currentPage,
					rowsPerPage: adminAnalyticsStore.paginationStudentActivity.currentTask.rows,
					onPageChange: changePage,
					onRowsPerPageChange: changeTableRows,
					rowsPerPageOptions: [8, 10, 25, 50]
				}}
			/>
		</div>
	);
});
