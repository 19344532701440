import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';

import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { generalStore } from '@stores';

export default ({ count }: IHearts) => {
	return (
		<ListItemIcon>
			{[...Array(3 - count)].map((_, index) =>
				<HeartBrokenIcon
					key={index + Math.random()}
					sx={{ color: '#000' }}
				/>
			)}
			{[...Array(count)].map((_, index) =>
				<FavoriteIcon
					key={index + Math.random()}
					sx={{ color: '#70EBE7' }}
					stroke={generalStore.theme === 'light' ? '#191919' : '#fff'}
					strokeWidth={1.5}
				/>
			)}
		</ListItemIcon>
	);
};

interface IHearts {
	count: number;
}
