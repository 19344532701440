import React from 'react';
import { observer } from 'mobx-react-lite';
import { images } from '@helpers';
import './style.css';

export default observer(() => {
	return (
		<div className='prize'>
			<p className='prize__title'>
        Награда за<br/>
        выполнение:
			</p>
			<img className='prize__image ' src={images.head3} alt='error'/>
			<p className='prize__text'>
        +10&nbsp;<span className='prize__text-XP'>XP</span><br/>
        +10&nbsp;<span className='prize__text-dollar'>$</span>
			</p>
		</div>
	);
});
