import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';

import { adminReferralStore, adminUserStore } from '@stores';
import { Select, Table, CustomModal, Button } from '@components';
import { ReferralDetailPanel, PurchasesModal } from './referral-blocks';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDown';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export default observer(() => {
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [currentUser, setCurrentUser] = useState<any>(null);
	const [referralUser, setReferralUser] = useState<{user: any, status: 'rejected' | 'completed'}>({
		user: {
			id: '',
			fio: '',
			bank: '',
			requisite: ''
		},
		status: 'rejected'
	});
	const [timer, setTimer] = useState<any>();
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState('');

	const openModal = (user, status: 'rejected' | 'completed') => {
		setReferralUser({ user, status });
		setIsOpenModal(true);
	};

	const openPurchasesModal = (user) => {
		adminUserStore.getUserPurchases(user.id).then(() => setCurrentUser(user));
	};

	const sendWithdraw = () => {
		adminReferralStore.editWithdrawalStatus(referralUser.user.id, referralUser.status);
		setIsOpenModal(false);
	};

	useEffect(() => {
		handleChangePage(undefined, 0);
	}, [rowsPerPage]);

	const columns = [
		{ name: 'ФИО', field: 'name' },
		{ name: 'Сумма', field: 'withdrawalFunds' },
		{ name: 'Реквизиты', field: 'requisite' },
		{ name: 'Банк', field: 'bank' },
		{ name: 'Обновлено', field: 'updatedAt' }
	];

	const onSearchChange = (newSearch: string) => {
		setSearch(newSearch);
		clearTimeout(timer);
		setTimer(setTimeout(() =>
			adminReferralStore.getWithdrawal(page, rowsPerPage, newSearch),
		1000
		));
		setPage(0);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		adminReferralStore.getWithdrawal(newPage, rowsPerPage, search);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const handleChangeStatus = (e, value) => {
		adminReferralStore.changeStatus(value);
		handleChangePage(undefined, 0);
	};

	return (
		<div className='settings-page'>
			<div className='settings-page__field'>
				<Select
					input = {{ label: 'Статус заявки' }}
					value={adminReferralStore.status}
					options={statusOptions}
					onChange={handleChangeStatus}
					disableClearable
				/>
			</div>
			<Table
				columns={columns}
				data={adminReferralStore.withdrawalReferrals}
				detailPanel={{ Component: ReferralDetailPanel }}
				pagination={{
					count: adminReferralStore.withdrawalCount,
					page: page,
					rowsPerPage: rowsPerPage,
					onPageChange: handleChangePage,
					onRowsPerPageChange: handleChangeRowsPerPage
				}}
				search={{
					prop: search,
					action: (str) => { onSearchChange(str); }
				}}
				actionRow={{
					custom: {
						icon: <ShoppingCartIcon sx={{ color: '#FFC800' }}/>,
						action: (id, data) => openPurchasesModal(data.referral.user),
						tooltip: 'Покупки'
					}
				}}
				customCellsRender={
					adminReferralStore.status.id === 'wait'
						? [{
							name: 'Одобрить',
							render: (state) => <IconButton sx={{ color: '#8417FF' }} onClick={() => openModal(state, 'completed')}>
								<ThumbUpIcon/>
							</IconButton>
						},
						{
							name: 'Отклонить',
							render: (state) => <IconButton sx={{ color: '#FF3364' }} onClick={() => openModal(state, 'rejected')}>
								<ThumbDownAltIcon />
							</IconButton>
						}]
						: [{
							name: 'Сменить статус',
							render: (state) => adminReferralStore.status.id === 'rejected'
								? <IconButton sx={{ color: '#8417FF' }} onClick={() => openModal(state, 'completed')}>
									<ThumbUpIcon/>
								</IconButton>
								: <IconButton sx={{ color: '#FF3364' }} onClick={() => openModal(state, 'rejected')}>
									<ThumbDownAltIcon />
								</IconButton>
						}]
				}
			/>
			<CustomModal
				open={isOpenModal} handleClose={() => setIsOpenModal(false)}
				viewClose='cross' heightModal='auto'
			>
				<div className='settings-page__modal'>
					{referralUser.status === 'completed'
						? <div className='settings-page__text'>
							<h1>Отправь денег</h1>
							<p>{referralUser.user.fio}</p>
							<p>{referralUser.user.bank}</p>
							<p>{referralUser.user.requisite}</p>
						</div>
						: <div className='settings-page__text'>
							<h1>Этот пользователь говноед💩 и денег мы ему не отправим?</h1>
						</div>
					}
					<div className='settings-page__buttons'>
						<div className='settings-page__button'>
							<Button type='main_red' onClick={() => setIsOpenModal(false)}>Отмена</Button>
						</div>
						<div className='settings-page__button'>
							<Button type='main_green' onClick={() => sendWithdraw()}>
								{referralUser.status === 'completed' ? 'Заявка одобрена' : 'Да, он говноед'}
							</Button>
						</div>
					</div>
				</div>
			</CustomModal>
			<CustomModal
				open={!!currentUser}
				handleClose={() => setCurrentUser(null)}
				widthModal='auto'
			>
				<PurchasesModal user={currentUser}/>
			</CustomModal>
		</div>
	);
});

const statusOptions = [
	{
		id: 'wait',
		name: 'Ожидает подтверждения'
	},
	{
		id: 'rejected',
		name: 'Отклонено'
	},
	{
		id: 'completed',
		name: 'Одобрено'
	}
];
