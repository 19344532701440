import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, CustomModal, DeleteModal } from '@components';
import { CurrentNewsletter } from './admin-newsletters-blocks';
import { adminNewslettersStore } from '@stores';
import './style.css';

export default observer(() => {
	useEffect(() => {
		(async () => {
			await adminNewslettersStore.getAllNewsletters();
		})();
	}, []);

	const openModal = () => {
		adminNewslettersStore.resetCurrentNewsletter();
		adminNewslettersStore.changeIsOpenModal(true);
	};

	const getOwnerName = newsletter =>
		newsletter.owner
			? `${newsletter.owner.secondName} ${newsletter.owner.firstName}`
			: 'Пользователь удалён';

	return (
		<div className='theme-background'>
			<Table
				title='Рассылки'
				data={adminNewslettersStore.newsletters.map(newsletter => ({
					...newsletter,
					type: newsletterTypes[newsletter.type],
					ownerName: getOwnerName(newsletter)
				}))}
				columns={newslettersColumns}
				actionHeader={{
					add: () => openModal()
				}}
				actionRow={{
					delete: (id) => adminNewslettersStore.changeDeletableNewsletterId(id)
				}}
				detailPanel={{
					Component: CurrentNewsletter,
					autoClose: true,
					action: (id) => adminNewslettersStore.getNewsletterById(id)
				}}
			/>
			<CustomModal
				widthModal='60vw'
				heightModal='60vh'
				textTitle=''
				open={adminNewslettersStore.isOpenModal}
				handleClose={() => adminNewslettersStore.changeIsOpenModal(false)}
				viewClose='cross'
			>
				<CurrentNewsletter/>
			</CustomModal>
			<DeleteModal
				open={!!adminNewslettersStore.deletableNewsletterId}
				handleClose={() => adminNewslettersStore.changeDeletableNewsletterId('')}
				deleteName={adminNewslettersStore.newsletters.find(newsletter => newsletter.id === adminNewslettersStore.deletableNewsletterId)?.topic}
				action={() => adminNewslettersStore.deleteNewsletter()}
			/>
		</div>
	);
});

const newslettersColumns = [
	{ field: 'id', name: 'ID', copy: true },
	{ field: 'topic', name: 'Тема' },
	{ field: 'sender', name: 'Отправитель' },
	{ field: 'type', name: 'Тип' },
	{ field: 'ownerName', name: 'Создатель' }
];

const newsletterTypes = {
	All: 'Все',
	Courses: 'Курсы'
};
