import React, { useState, useEffect } from 'react';
import { Table } from '@components';
import { adminPromoCodeStore } from '@stores';

const currentPromoCodeStatisticsColumns = [
	{ name: 'Название курса', field: 'courseName' },
	{ name: 'Дата покупки', field: 'createdAt' },
	{ name: 'Начало периода', field: 'startPeriod' },
	{ name: 'Конец периода', field: 'endPeriod' },
	{ name: 'Название тарифа', field: 'tariffName' },
	{ name: 'Цена', field: 'price' }
];

export default () => {
	const [data, setData] = useState([]);

	useEffect(() => {
		setData(adminPromoCodeStore.currentUser.purchases);
	}, []);

	return (
		<Table
			title='Покупки'
			columns={currentPromoCodeStatisticsColumns}
			data={data}
		/>
	);
};
