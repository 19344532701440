import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { debounce } from '@mui/material/utils';

import { Select, Input } from '@components';
import { adminDisciplineStore, adminHomeworkStore } from '@stores';
import { IGetTemplatesHomeworkQueryParams } from '@services';
import { Button } from '@mui/material';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import './style.css';

export default observer(() => {
	const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		adminHomeworkStore.changeFilterField(e.target.value, 'search');
		debouncedSearch();
	}, []);

	const changeField = async (value, field: keyof IGetTemplatesHomeworkQueryParams) => {
		adminHomeworkStore.changeFilterField(value, field);
		await adminHomeworkStore.getTemplates();
	};

	const debouncedSearch = debounce(async () => {
		await adminHomeworkStore.getTemplates();
	}, 1000);

	return (
		<div className='homework-template-filtering'>
			<Select
				input={{ label: 'Выберите тип ДЗ' }}
				options={typesHomeworkTemplate}
				value={adminHomeworkStore.filterFields.type}
				onChange={(_e, value) => changeField(value, 'type')}
				className='homework-template-filtering__field'
			/>
			<Select
				input={{ label: 'Выберите предмет' }}
				options={adminDisciplineStore.disciplines}
				value={adminHomeworkStore.filterFields.discipline}
				onChange={(_e, value) => changeField(value, 'discipline')}
				className='homework-template-filtering__field'
			/>
			<Input
				label='Поиск'
				value={adminHomeworkStore.filterFields.search}
				className='homework-template-filtering__field'
				onChange={onSearchChange}
			/>
			<Link to='/admin/homework' className='homework-template-filtering__button'>
				<Button
					startIcon={<AddOutlinedIcon color='success'/>}
					variant='outlined' color='success'
					onClick={() => adminHomeworkStore.clearCurrentTemplate()}
					className='homework-template-filtering__button'
				>
					Добавить ДЗ
				</Button>
			</Link>
		</div>
	);
});

const typesHomeworkTemplate = [
	{
		id: 'automatic',
		name: 'Автоматическая проверка'
	},
	{
		id: 'manual',
		name: 'Ручная проверка'
	},
	{
		id: 'probe',
		name: 'Пробник'
	}
];
