import React from 'react';
import { SkeletonComponent } from '@components';
import './style.css';

export default () => {
	return (
		<div className='homework-card-loader homework-card theme-block-background'>
			<div className='homework-card__preview homework-card-loader__preview'>
				<SkeletonComponent/>
			</div>
			<div className='homework-card__title-block'>
				<div className='portal-courses-card-advantages'>
					<div className='portal-courses-card-advantages__item homework-card-loader__advantages-item'>
						<SkeletonComponent/>
					</div>
					<div className='portal-courses-card-advantages__item homework-card-loader__advantages-item'>
						<SkeletonComponent/>
					</div>
					<div className='portal-courses-card-advantages__item homework-card-loader__advantages-item'>
						<SkeletonComponent/>
					</div>
				</div>
				<div className='homework-card__title homework-card-loader__title'>
					<SkeletonComponent/>
				</div>
			</div>
			<div className='homework-card-deadline homework-card-loader__deadline'>
				<SkeletonComponent/>
			</div>
			<div className='homework-card__button homework-card-loader__button'>
				<SkeletonComponent/>
			</div>
		</div>
	);
};
