import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

import { Button } from '@components';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { BookIcon } from 'lomonosov-icons';

import './style.css';

interface IHomeworkBankList {
  homework: any,
  openDeleteHomeworkModal: (id: string) => void
}

export default observer((props: IHomeworkBankList) => {
	const { homework, openDeleteHomeworkModal } = props;

	const [menuOpen, setMenuOpen] = useState(null);

	const handleOpen = (e) => setMenuOpen(e.currentTarget);
	const handleClose = () => setMenuOpen(null);

	const openModal = () => {
		handleClose();
		openDeleteHomeworkModal(homework);
	};

	return (
		<div className='homework-template-card theme-block-background'>
			<div className='homework-template-card__title'>
				{homework.name}
			</div>
			<div className='homework-template-card__description'>
				{homework.description.slice(0, 147)}
				{homework.description.length > 147 ? '...' : ''}
			</div>
			<div className='homework-template-card__block'>
				<div className='homework-template-card__info'>
					<div className='homework-template-card__discipline'>
						<div className='portal-book'><BookIcon/></div><span>{homework.discipline?.name}</span>
					</div>
					<div className='homework-template-card__teacher'>
            Создатель: {`${homework.owner?.firstName} ${homework.owner?.secondName}`}
					</div>
				</div>
				<Link to={`/admin/homework/${homework.id}`} className='homework-template-card__button'>
					<Button type='main_blue'>
            Редактировать
					</Button>
				</Link>
			</div>
			<IconButton className='homework-template-card__menu-button theme-text' onClick={handleOpen}>
				<MoreHorizIcon/>
			</IconButton>
			<Menu
				id='simple-menu'
				// keepMounted
				anchorEl={menuOpen}
				open={Boolean(menuOpen)}
				onClose={handleClose}
			>
				<MenuItem onClick={() => openModal()}>Удалить</MenuItem>
			</Menu>
		</div>
	);
});
