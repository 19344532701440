import axios from 'axios';

export const $strapiApi = axios.create({
	withCredentials: true,
	baseURL: process.env.STRAPI_API_URL
});

// interceptor для перехватывания запросов и ответов
$strapiApi.interceptors.request.use((config) => {
	config.headers.Authorization = `Bearer ${process.env.STRAPI_TOKEN}`;
	return config;
});

$strapiApi.interceptors.response.use((config) => {
	return config;
}, async (error) => console.log(error));
