import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import { Link } from 'react-router-dom';
import { checkLink, getNoun } from '@helpers';
import { Button } from '@components';
import { IGetAllBannersItemResponse } from '@lomonosov/types';
import 'swiper/css';
import './style.css';

interface IPortalBannerProps {
	banners: IGetAllBannersItemResponse[],
	userName: string
}

export default (props: IPortalBannerProps) => {
	const { banners, userName } = props;

	const replaceKeyWords = (str: string, deadline?) => {
		const bannerDay = Number(new Date(deadline)) - Number(new Date());
		let text = str.replace(/name/i, userName);
		if (deadline) {
			text = text.replace(/day/i, `${String(new Date(bannerDay).getDate())} ${getNoun(new Date(bannerDay).getDate(), 'день', 'дня', 'дней')}`);
		}
		return text;
	};

	return (
		<div className='portal-banner-slider'>
			<Swiper
				slidesPerView={1}
				autoplay={{ delay: 7000, disableOnInteraction: false }}
				spaceBetween={0}
				speed={1500}
				modules={[Pagination, Autoplay]}
			>
				{banners.map((banner: IGetAllBannersItemResponse, index: number) =>
					<SwiperSlide key={index}>
						<div className='portal-banner-slider__item'>
							<div className='item__support-banner'>
								<span className='support-banner__text'>
									{replaceKeyWords(banner.name, banner?.deadline)}
								</span>
								{banner.link
									? checkLink(banner.link)
										? <a
											className='support-banner__button' href={banner.link}
											target='_blank' rel='noreferrer'
										>
											<Button type='main'>
												<span>{banner.textButton ? banner.textButton : 'Написать' }</span>
											</Button>
										</a>
										: <Link className='support-banner__button' to={banner.link}>
											<Button type='main'>
												<span>{banner.textButton ? banner.textButton : 'Перейти' }</span>
											</Button>
										</Link>
									: null
								}
							</div>
						</div>
					</SwiperSlide>
				)}
			</Swiper>
		</div>
	);
};
