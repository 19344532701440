import React from 'react';
import { Avatar, Chip } from '@mui/material';
import { IGetAnswersStatisticResponse } from '@lomonosov/types/dist';
import { getNoun } from '@helpers';

export default (props: IStatisticsHomeworkProps) => {
	const { statistics } = props;

	const getLabelText = (type: 'max' | 'min' | 'avg') => {
		return `
						${statistics[type]?.days || 0} ${getNoun(statistics[type]?.days || 0, 'день', 'дня', 'дней')}
						${statistics[type]?.hours || 0} ${getNoun(statistics[type]?.hours || 0, 'час', 'часа', 'часов')}
						${statistics[type]?.minutes || 0} ${getNoun(statistics[type]?.hours || 0, 'минута', 'минуты', 'минут')}
        	`;
	};

	return (
		<>
			{statistics?.min && statistics?.max && statistics?.avg &&
      <p className='control-mentors__title'>
          Время проверки (минимальное, максимальное, среднее)
      </p>
			}
			<div className='control-mentors__statistic'>
				{statistics?.min &&
        <Chip
        	avatar={<Avatar>Min</Avatar>}
        	label={getLabelText('min')}
        	variant='outlined' color='success'
        />
				}
				{statistics?.max &&
        <Chip
        	avatar={<Avatar>Max</Avatar>}
        	label={getLabelText('max')}
        	variant='outlined' color='error'
        />
				}
				{statistics?.avg &&
        <Chip
        	avatar={<Avatar className='control-mentors__avatar'>Avg</Avatar>}
        	label={getLabelText('avg')}
        	variant='outlined' color='primary'
        />
				}
			</div>
		</>
	);
};

interface IStatisticsHomeworkProps {
	statistics: IGetAnswersStatisticResponse | { max: null, min: null, avg: null }
}
