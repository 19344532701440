import React from 'react';
import { observer } from 'mobx-react-lite';
import { images } from '@helpers';
import classNames from 'classnames';
import './style.css';
import { Button } from '@mui/material';

export default observer((props: IPortalPlugProps) => {
	const { children, buttons = [], className } = props;

	return (
		<div className={classNames('plug', className)}>
			<img
				src={images.newPlug}
				alt='plug'
				className='plug__image'
			/>
			<div className='plug__title theme-block-background'>
				{children}
			</div>
			<div className='plug__buttons'>
				{buttons.map((button, index) =>
					<Button key={index} onClick={button.onClick} href={button.href} variant='contained' color='primary' className='plug__button'>
						{button.title}
					</Button>
				)}
			</div>
		</div>
	);
});

interface IPortalPlugProps {
	/* Текст заглушки */
	children?: React.ReactNode,
	className?: string;
	/* Кнопки */
	buttons?: {
		title: string,
		color?: string,
		onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
		href?: string
	}[]
}
