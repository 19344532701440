import React from 'react';
import { observer } from 'mobx-react-lite';
import Pagination from '@mui/material/Pagination';
import { adminAnalyticsStore } from '@stores';
import { PortalPlug } from '@components';
import { LessonCard, CourseCardLoader } from '../../components';
import '../../style.css';

export default observer(() => {
	return (
		<div className='courses-analysis'>
			<div className='courses-analysis__cards'>
				{adminAnalyticsStore.isLoading
					? <>
						<CourseCardLoader/>
						<CourseCardLoader/>
						<CourseCardLoader/>
						<CourseCardLoader/>
						<CourseCardLoader/>
					</>
					: adminAnalyticsStore.lessons.length !== 0
						? adminAnalyticsStore.lessons.map((lesson: LessonsCardProps) =>
							<LessonCard key={lesson.id} lesson={lesson}/>
						)
						: <PortalPlug>Уроки не найдены</PortalPlug>
				}
			</div>
			<Pagination
				count={adminAnalyticsStore.pagination.lessons.pages}
				page={adminAnalyticsStore.pagination.lessons.currentPage}
				onChange={(e, page) => adminAnalyticsStore.changePageCourses(page, 5, 'lessons')}
				defaultPage={1} size='large' showFirstButton showLastButton
				classes={{ ul: 'courses-analysis__paginator' }}
			/>
		</div>
	);
});

interface LessonsCardProps {
	id: string,
	name: string,
	section: {
		id: string,
		name: string
		course: {
			name: string,
			id: string
		}
	},
	viewers: number,
	homeworks: number,
	manualHomeworks: number,
	probes: number,
	tasks: number
}
