import { makeAutoObservable, runInAction } from 'mobx';
import { ICreateRoleDto, IGetRolesResponse, IUpdateRoleDto } from '@lomonosov/types';
import { AdminRoleService } from '@services';
import { userStore } from '@stores';

class AdminRoleStore {
	roles: IGetRolesResponse = [];

	constructor () {
		makeAutoObservable(this);
	}

	// Получить все роли
	async getRoles () {
		try {
			const { data } = await AdminRoleService.getRoles();
			runInAction(() => {
				this.roles = data.filter(role => role.name === 'Менеджер' && !userStore.isManager ? null : role);
			});
		} catch (e) {
			console.log(e);
		}
	}

	// Получить конкретную роль
	async getCurrentRole (roleId: string) {
		const { data } = await AdminRoleService.getCurrentRole(roleId);
		console.log(data);
	}

	// Добавить новую роль
	async addRole (role: ICreateRoleDto) {
		const { data } = await AdminRoleService.addRole(role);
		this.roles = [...this.roles, data];
	}

	// Изменить конкретную роль
	async updateRole (roleId: string, newInfoRole: IUpdateRoleDto) {
		const { data } = await AdminRoleService.updateRole(roleId, newInfoRole);
		this.roles = this.roles.map(role => role.id === roleId ? data : role);
	}

	// Удалить конкретную роль
	async deleteRole (roleId: string) {
		await AdminRoleService.deleteRole(roleId);
		this.roles = this.roles.filter(role => role.id !== roleId);
	}
}

export const adminRoleStore = new AdminRoleStore();
