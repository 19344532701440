import { AxiosResponse } from 'axios';
import { $api } from '@helpers';

interface AbandonedCatsQueryParams {
  take: number,
  skip: number,
  search?: string,
}

export default class {
	static async getAbandonedCarts (query: AbandonedCatsQueryParams): Promise<AxiosResponse<IGetAllAbandonedCartResponse>> {
		return $api.get<IGetAllAbandonedCartResponse>('/abandoned-carts', { params: { ...query } });
	}

	static async getAbandonedCartById (id): Promise<AxiosResponse<IGetOneAbandonedCartResponse>> {
		return $api.get<IGetOneAbandonedCartResponse>(`/abandoned-carts/${id}`);
	}
}

export interface ICartItem {
  id: string;
  startPeriod: Date;
  endPeriod: Date;
  price: number;

  /** Внешние ключи */
  tariffId: string;

  /** Отношения */
  tariff: {
    id: string;
    name: string;
    course: {
      id: string;
      name: string;
    }
  };
}

export interface IGetOneAbandonedCartResponse {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  items: ICartItem[];
  userId: string;
  user: {
    shortId: string;
    firstName: string;
    secondName: string;
    phone: string;
    email: string;
    vkId: string;
  }
}

export interface IGetAllAbandonedCartResponse {
  items: Array<{
    id: string;
    createdAt: Date;
    updatedAt: Date;
    userId: string;
    user: {
      shortId: string;
      firstName: string;
      secondName: string;
      phone: string;
      email: string;
      vkId: string;
    }
  }>
  count: number;
}
