import React from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import classNames from 'classnames';
import './style.css';

interface IPlayerIconProps {
  className?: string;
  variant?: 'small' | 'large';
  timeLine?: string;
}

export const PlayerIcon = (props: IPlayerIconProps) => {
	const { className, timeLine, variant = 'small' } = props;

	return (
		<span className={classNames('playerIcon', `playerIcon_${variant}`, className)}>
			<PlayArrowIcon />
			<span>{timeLine}</span>
		</span>
	);
};
