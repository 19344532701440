import React, { useEffect, useState } from 'react';
import {
	ListItemButton,
	TableCell,
	TableRow
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TableRowItem from '../tableRow';
import {
	IColumn,
	IActionRowAdd,
	IDetailPanel,
	styleOption,
	ISwapRow
} from '../../type';

interface IProps {
	colSpan: number
	activeDetailPanel: [string | null, (s: string | null) => void]
	columns: IColumn[]
	action: IActionRowAdd
	detailPanel?: IDetailPanel,
	styleOption: styleOption
	swap: ISwapRow | boolean
}

export default (props: IProps) => {
	const {
		columns,
		colSpan,
		action,
		detailPanel,
		styleOption,
		swap,
		activeDetailPanel
	} = props;

	const [isAdd, setAdd] = useState(false);
	const [defaultValue, setDefaultValue] = useState({});

	useEffect(() => {
		const temp = {};
		columns.forEach((col) => {
			if (col.defaultValue !== undefined) {
				temp[col.field] = col.defaultValue;
			} else {
				temp[col.field] = null;
			}
		});
		setDefaultValue(temp);
	}, []);

	const saveAdd = (newState: any) => {
		action(newState);
		setAdd(false);
	};

	return (
		<>
			{
				isAdd
					? <TableRowItem
						detailPanel={detailPanel}
						columns={columns}
						colSpan={colSpan}
						actionRow={{
							delete: () => { setAdd(false); },
							edit: (id, newState) => { saveAdd(newState); }
						}}
						isCreate
						styleOption={styleOption}
						data={defaultValue}
						swap={swap}
						index={-1}
						order={0}
						activeDetailPanel={activeDetailPanel}
					/>
					: <TableRow>
						<TableCell className='table__row-action' colSpan={colSpan}>
							<ListItemButton className={`table__row-action-button table__row-action-${styleOption.size}`} onClick={() => setAdd(true)}>
								<AddIcon className='table__row-action-button-icon' />
							</ListItemButton>
						</TableCell>
					</TableRow>
			}
		</>
	);
};
