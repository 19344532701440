import { makeAutoObservable, runInAction } from 'mobx';
import { IGetAllBannersResponse } from '@lomonosov/types';
import { UserBannerService } from '@services';

class UserBannerStore {
	bannersPortal: IGetAllBannersResponse = [];
	bannersCourse: IGetAllBannersResponse = [];
	isLoading: boolean = true;

	constructor () {
		makeAutoObservable(this);
	}

	// Получить все баннеры пользователя
	async getBanners (userId: string) {
		try {
			const { data } = await UserBannerService.getBanners(userId);
			runInAction(() => {
				this.bannersPortal = data.filter(banner => banner.type === 'portal');
				this.bannersCourse = data.filter(banner => banner.type === 'course');
				this.isLoading = false;
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const userBannerStore = new UserBannerStore();
