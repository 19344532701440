export const checkLink = (link: string): boolean => {
	// eslint-disable-next-line no-useless-escape,max-len
	const RegExp = /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9\-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-\/])*)?/;
	return RegExp.test(link);
};

export const convertPhone = (login: string) => {
	const temp = login.replace(/\D/g, '');
	if (regPhone.test(temp)) {
		return temp.replace(regPhone, '8$2$3$4$5');
	} else {
		return login;
	}
};

export const getVideoId = (url: string, type: 'youtube' | 'rutube') => {
	if (type === 'youtube') {
		let id = '';
		url.includes('v=')
			? id = url.split('v=')[1].split('&')[0] || url.split('youtu.be/')[1]
			: id = url.split('/').pop()?.split('?')?.[0] || '';
		return id;
	} else if (type === 'rutube') {
		const regex = /https?:\/\/(?:www\.)?rutube\.ru\/video\/([a-zA-Z0-9]+)/;
		const match = url.match(regex);
		return match ? match[1] : null;
	} else {
		return url;
	}
};

// eslint-disable-next-line no-useless-escape
export const regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
// eslint-disable-next-line no-useless-escape
export const regPhone = /(\+?7|8)\s?\(?(\d{3})\)?\s?\-?\s?(\d{3})\s?\-?\s?(\d{2})\s?\-?\s?(\d{2})/;
