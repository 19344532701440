import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

import { adminUserStore } from '@stores';
import { Select, Hearts } from '@components';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import './style.css';

export default observer(() => {
	useEffect(() => {
		adminUserStore.getMotivationCourses(adminUserStore.currentUser.id);
	}, []);

	return (
		<div className='motivation-panel'>
			<div className='motivation-panel__courses'>
				{adminUserStore.coursesForMotivation.map(course =>
					<CourseHP key={course?.id} course={course}/>
				)}
			</div>
		</div>
	);
});

const CourseHP = observer(({ course }: any) => {
	const [count, setCount] = useState<number>(course?.healthCount);
	const [difficult, setDifficult] = useState(levels.find(level => level.id === course?.difficult));
	const [helperText, setHelperText] = useState<string>('Не менял');
	const id = course?.id;

	useEffect(() => {
		if (course?.difficultUpdated) {
			setHelperText(`Последнее изменение: ${dayjs(course?.difficultUpdated).format('DD-MM-YYYY')}`);
		}
	}, [course?.difficultUpdated]);

	const handleClick = (num: number) => {
		adminUserStore.changeUserHP(id, count + num);
		setCount(count + num);
	};

	const handleChangeDifficult = (e, newValue) => {
		setDifficult(newValue);
		adminUserStore.updateSettingCourse(course?.courseId, id, { difficult: newValue?.id });
	};

	return (
		<ListItem className='motivation-panel__course'>
			<ListItemText
				primary={course?.course?.name || ''}
				primaryTypographyProps={{
					fontSize: 16,
					fontWeight: 'medium',
					letterSpacing: 0
				}}
			/>
			<div className='motivation-panel__field'>
				<Select
					input={{ label: 'Уровень сложности', helperText: helperText }}
					options={levels}
					value={difficult}
					onChange={handleChangeDifficult}
				/>
			</div>
			<IconButton
				disabled={count <= 0}
				className='motivation-panel__course_button'
				onClick={() => handleClick(-1)}
			>
				<RemoveIcon/>
			</IconButton>
			<Hearts count={count}/>
			<IconButton
				disabled={count >= 3}
				className='motivation-panel__course_button'
				onClick={() => handleClick(1)}
			>
				<AddIcon/>
			</IconButton>
		</ListItem>
	);
});

const levels = [
	{ id: 'easy', name: 'Легкий' },
	{ id: 'medium', name: 'Средний' },
	{ id: 'hard', name: 'Сложный' }
];
