import React from 'react';
import { SkeletonComponent } from '@components';
import './style.css';

export default () => {
	return (
		<div className='gamification-tasks-loader'>
			<div className='gamification-tasks-loader__title'>
				<SkeletonComponent/>
			</div>
			<div className='gamification-tasks-loader__card'>
				<SkeletonComponent/>
				<div className='gamification-tasks-loader__card-title'>
					<SkeletonComponent/>
				</div>
				<div className='gamification-tasks-loader__card-description'>
					<SkeletonComponent/>
				</div>
				<div className='gamification-tasks-loader__card-reward'>
					<span className='gamification-tasks-loader__card-reward-item'><SkeletonComponent/></span>
					<span className='gamification-tasks-loader__card-reward-item'><SkeletonComponent/></span>
					<div className='gamification-tasks-loader__button'><SkeletonComponent/></div>
				</div>
			</div>
			<div className='gamification-tasks-loader__card'>
				<SkeletonComponent/>
				<div className='gamification-tasks-loader__card-title'>
					<SkeletonComponent/>
				</div>
				<div className='gamification-tasks-loader__card-description'>
					<SkeletonComponent/>
				</div>
				<div className='gamification-tasks-loader__card-reward'>
					<span className='gamification-tasks-loader__card-reward-item'><SkeletonComponent/></span>
					<span className='gamification-tasks-loader__card-reward-item'><SkeletonComponent/></span>
					<div className='gamification-tasks-loader__button'><SkeletonComponent/></div>
				</div>
			</div>
		</div>
	);
};
