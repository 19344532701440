import React from 'react';
import { observer } from 'mobx-react-lite';
import { DateInput, DropdownList, Select } from '@components';
import { adminHomeworkAnswersStore, adminMentorsStore } from '@stores';
import { HomeworkAnswerStatus, HomeworkTemplateType } from '@lomonosov/types/dist';
import './style.css';
import { IGetHomeworkAnswersQueryParams } from '@services';
import { debounce } from '@mui/material';

export default observer(() => {
	const changeMentor = async (e, value: OptionSelect) => {
		adminHomeworkAnswersStore.changeFilterField(value, 'mentor');
		await adminHomeworkAnswersStore.getHomeworkAnswerAnalysis();
	};

	const changeDiscipline = async (e, value: OptionSelect) => {
		adminHomeworkAnswersStore.changeFilterField(value, 'discipline');
		await adminHomeworkAnswersStore.getHomeworkAnswerAnalysis();
	};

	const debouncedSearchMentors = debounce(async (e) => {
		await adminMentorsStore.getMentors(0, 10, e.target.value);
	}, 1000);

	const handleFilterChange = async (e, _value: string, option: OptionSelect) => {
		adminHomeworkAnswersStore.changeFilterField(option, e.target.name);
		await adminHomeworkAnswersStore.getHomeworkAnswerAnalysis();
	};

	const handleChangeDate = async (e) => {
		adminHomeworkAnswersStore.changeFilterField(e.target.value || null, e.target.name);
		await adminHomeworkAnswersStore.getHomeworkAnswerAnalysis();
	};

	const onDeleteFilter = async (field: keyof IGetHomeworkAnswersQueryParams) => {
		adminHomeworkAnswersStore.changeFilterField(null, field);
		await adminHomeworkAnswersStore.getHomeworkAnswerAnalysis();
	};

	if (adminHomeworkAnswersStore.isLoadingFilters) {
		return null;
	}

	return (
		<div className='filtering-menu-control-mentors'>
			<Select
				className='filtering-menu-control-mentors__item'
				input={{ label: 'Выберите ментора', onChange: debouncedSearchMentors }}
				options={adminMentorsStore.mentors}
				value={adminHomeworkAnswersStore.filterFields.mentor}
				onChange={changeMentor}
			/>
			<Select
				className='filtering-menu-control-mentors__item'
				input={{ label: 'Предмет' }}
				value={adminHomeworkAnswersStore.filterFields?.discipline}
				onChange={changeDiscipline}
				disabled={adminHomeworkAnswersStore.loader}
				options={adminHomeworkAnswersStore.disciplines}
			/>
			<DropdownList
				className='filtering-menu-control-mentors__item'
				label='Статус работы' name='status'
				options={statusTasks}
				value={adminHomeworkAnswersStore.filterFields?.status}
				onChange={handleFilterChange}
				fieldOptionKey='name'
				readonly={adminHomeworkAnswersStore.loader}
			/>
			<DropdownList
				className='filtering-menu-control-mentors__item'
				label='Тип задания' name='type'
				options={taskTypes}
				value={adminHomeworkAnswersStore.filterFields?.type}
				onChange={handleFilterChange}
				fieldOptionKey='name'
				onDelete={onDeleteFilter}
				readonly={adminHomeworkAnswersStore.loader}
			/>
			<div className='filtering-menu-control-mentors__item'>
				<DateInput
					name='start'
					onChange={handleChangeDate}
					value={adminHomeworkAnswersStore.filterFields.start}
					label='Период от' type='date'
				/>
			</div>
			<div className='filtering-menu-control-mentors__item'>
				<DateInput
					name='end'
					onChange={handleChangeDate}
					value={adminHomeworkAnswersStore.filterFields.end}
					label='Период до' type='date'
				/>
			</div>
		</div>
	);
});

const statusTasks: { id: HomeworkAnswerStatus, name: string }[] = [
	{
		id: HomeworkAnswerStatus.VERIFICATION,
		name: 'Не провереные'
	},
	{
		id: HomeworkAnswerStatus.VERIFIED,
		name: 'Провереные'
	}
];

const taskTypes = [
	{
		id: HomeworkTemplateType.MANUAL,
		name: 'С ручной проверкой'
	},
	{
		id: HomeworkTemplateType.PROBE,
		name: 'Пробник'
	}
];
