import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { PortalPlug } from '@components';
import { PlayerIcon } from './ui/PlayerIcon';
import classNames from 'classnames';
import './style.css';

interface IVideoProps {
  className?: string;
  posterUrl?: string;
  videoUrl?: string;
  height?: number;
  width?: number;
  alt?: string;
  timeLine?: string;
  variant?: 'small' | 'large';
  autoPlay?: boolean;
  showControls?: boolean;
}

export const Media = (props: IVideoProps) => {
	const {
		className, posterUrl = '', videoUrl, alt, variant = 'large', timeLine, autoPlay = true, showControls = false
	} = props;
	const [isShowControls, setIsShowControls] = useState(showControls);

	const onPlayVideo = () => {
		setIsShowControls(true);
	};

	if (!videoUrl && !posterUrl) {
		return (
			<div className={'media'}>
				<PortalPlug>{'Не удалось \n загрузить видео'}</PortalPlug>
			</div>
		);
	}

	if (!isShowControls && posterUrl) {
		return (
			<div className={classNames('media', className)}>
				<img
					alt={alt || 'image'}
					src={posterUrl || ''}
					className={'videoPoster'}
				/>
				{posterUrl && videoUrl && (
					<IconButton
						className={'playIcon'} onClick={onPlayVideo}
						aria-label='Button play video'
					>
						<PlayerIcon
							variant={variant}
							timeLine={timeLine}
						/>
					</IconButton>
				)}
			</div>
		);
	}

	return (
		<div className={classNames('media', className)}>
			<video
				controls={isShowControls}
				className={'videoPlayer'}
				muted={!isShowControls}
				loop={!isShowControls}
				autoPlay={autoPlay}
				autoFocus={false}
				playsInline
				preload='none'
			>
				<source
					src={videoUrl}
					type='video/mp4'
				/>
			</video>
		</div>
	);
};
