import { AxiosResponse } from 'axios';
import { IGetUserLessonsResponse } from '@lomonosov/types';
import { $api } from '@helpers';
import { IGetCalendarHomeworksItem } from '@interfaces';

export default class {
	static async getCalendarHomeworks (startDate: string, endDate: string): Promise<AxiosResponse<IGetCalendarHomeworksItem[]>> {
		return $api.get<IGetCalendarHomeworksItem[]>(`users/calendar-homeworks?startDate=${startDate}&endDate=${endDate}`);
	}

	static async getCalendarLessons (startDate: string, endDate: string): Promise<AxiosResponse<IGetUserLessonsResponse>> {
		return $api.get<IGetUserLessonsResponse>(`users/lessons?startDate=${startDate}&endDate=${endDate}`);
	}
}
