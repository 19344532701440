import React from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import Footer from '@blocks/footer';
import Header from '@blocks/header';
import { Loader } from '@components';
import { authStore } from '@stores';
import { images } from '@helpers';

import './style.css';

export default observer(() => {
	if (authStore.isLoading) {
		return (
			<AuthWrapper>
				<Loader/>
			</AuthWrapper>
		);
	}

	return (
		<AuthWrapper>
			<Outlet/>
		</AuthWrapper>
	);
});

const AuthWrapper = ({ children }: { children: React.ReactNode}) => {
	return (
		<div className='login-logup theme-background'>
			<Header />
			<div className='login-logup__content'>
				{backgroundColumns.slice(0, 3).map((column) => (
					<div
						className='login-logup__column'
						key={column.disciplineImage}
						style={{ backgroundImage: `url(${column.image})` }}
					>
						<img src={column.disciplineImage} alt={column.disciplineImage} />
					</div>
				))}
				<div className='login-logup__column large'>
					{children}
				</div>
				{backgroundColumns.slice(3).map((column) => (
					<div
						className='login-logup__column'
						key={column.disciplineImage}
						style={{ backgroundImage: `url(${column.image})` }}
					>
						<img src={column.disciplineImage} alt={column.disciplineImage} />
					</div>
				))}
			</div>
			<Footer />
		</div>
	);
};

const backgroundColumns: { disciplineImage: string, image: string }[] = [
	{
		disciplineImage: images.uMathIcon,
		image: images.uTeacherMath
	},
	{
		disciplineImage: images.uRussianIcon,
		image: images.uTeacherRussian
	},
	{
		disciplineImage: images.uInformaticsIcon,
		image: images.uTeacherInformatics
	},
	{
		disciplineImage: images.uHistoryIcon,
		image: images.uTeacherHistory
	},
	{
		disciplineImage: images.uChemistryIcon,
		image: images.uTeacherChemistry
	},
	{
		disciplineImage: images.uEnglishIcon,
		image: images.uTeacherEnglish
	}
];
