export const constants = {
	NODE_ENV: process.env.NODE_ENV,
	URL: `${process.env.API_ROOT}/${process.env.API_VERSION}`,
	FILE_URL: `${process.env.API_ROOT}/${process.env.API_VERSION}/files`,
	VK_AUTH_URI: 'https://oauth.vk.com/authorize',
	VK_CLIENT_ID: '7191713',
	VK_REDIRECT_URI: {
		AUTH: process.env.VK_REDIRECT_URI_AUTH,
		CONFIRM: process.env.VK_REDIRECT_URI_CONFIRM
	},
	TABlE_OPTIONS: {
		draggable: false,
		actionsColumnIndex: -1,
		rowStyle: {
			color: '#342B48',
			fontFamily: 'Gotham Pro, sans-serif',
			backgroundColor: '#E8EFFE'
		},
		headerStyle: {
			backgroundColor: '#342B48',
			color: '#FFF',
			zIndex: 2
		}
	},
	TABLE_LOCALIZATION: {
		pagination: {
			labelDisplayedRows: '{from}-{to} из {count}',
			labelRowsSelect: 'записей'
		},
		toolbar: {
			searchPlaceholder: 'Поиск'
		},
		header: {
			actions: ''
		},
		body: {
			emptyDataSourceMessage: 'Нет записей',
			addTooltip: 'Добавить запись',
			deleteTooltip: 'Удалить запись',
			editTooltip: 'Изменить запись',
			filterRow: {
				filterTooltip: 'Фильтр'
			},
			editRow: {
				deleteText: 'Удалить?',
				saveTooltip: 'Принять',
				cancelTooltip: 'Отмена'
			}
		}
	},
	SLIDER_SETTINGS: {
		dots: false,
		infinite: true,
		autoplay: true,
		speed: 2000,
		autoplaySpeed: 10000,
		pauseOnHover: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
		adaptiveHeight: false,
		lazyLoad: true
	}
};
