import React, { memo, useMemo } from 'react';
import dayjs from 'dayjs';
import { IUserHomework } from '@interfaces';
import { HomeworkAnswerStatus } from '@lomonosov/types';
import { convertMoscowToLocal } from '@helpers';

import './style.css';

enum ResultMarkType {
	EXCELLENT = 5,
	GOOD = 4,
	BADLY = 2
}

export default memo((props: IUserHomework['answers']) => {
	const { maxScores, scores, status, createdAt } = props;
	const grade = useMemo(() => getGrade(scores, maxScores), [maxScores, scores]);
	const timePassed = createdAt && useMemo(() => dayjs(convertMoscowToLocal(createdAt)).fromNow(true), [createdAt]);

	if (status === HomeworkAnswerStatus.VERIFICATION && timePassed) {
		return (
			<div className='journal-card-grade-block' style={{ backgroundColor: gradeColors[ResultMarkType.BADLY] }}>
				<span className='journal-card-grade-block__title'>На&nbsp;проверке</span>
				<span className='journal-card-grade-block__count'>
					{timePassed.split(' ')[0]} <span className='journal-card-grade-block__time'>{timePassed.split(' ')?.[1]}</span>
				</span>
			</div>
		);
	}

	return (
		<div className='journal-card-grade-block' style={{ backgroundColor: gradeColors[grade] }}>
			<span className='journal-card-grade-block__title'>Верных&nbsp;ответов</span>
			<span className='journal-card-grade-block__count'>{scores}/{maxScores}</span>
		</div>
	);
});

const getGrade = (scores, maxScores) => {
	const temp = scores / maxScores;
	if (temp >= 0.9) {
		return ResultMarkType.EXCELLENT;
	} else if (temp >= 0.5) {
		return ResultMarkType.GOOD;
	} else {
		return ResultMarkType.BADLY;
	}
};

const gradeColors: Record<ResultMarkType, string> = {
	[ResultMarkType.EXCELLENT]: '#A1FCD3',
	[ResultMarkType.GOOD]: '#FFF25D',
	[ResultMarkType.BADLY]: '#FCB3DD'
};
