import React from 'react';
import Button from '@components/button';
import './style.css';

interface IModificationButtonsProps {
  onClick: (type: string) => void,
	type: 'car' | 'house'
}

export default (props: IModificationButtonsProps) => {
	const { onClick, type = 'house' } = props;
	return (
		<div className='modification-buttons'>
			{buttons[type].map(button =>
				<div key={button.id} className='modification-buttons__button'>
					<Button type='outline' onClick={() => onClick(button.id)}>
						{button.title}
					</Button>
				</div>
			)}
		</div>
	);
};

const buttons = {
	house: [
		{
			id: 'room',
			title: 'Сменить стены и пол'
		},
		{
			id: 'sofa',
			title: 'Сменить диван/кровать'
		},
		{
			id: 'left-wall-detail',
			title: 'Сменить мебель слева'
		},
		{
			id: 'right-wall-detail',
			title: 'Сменить мебель справа'
		},
		{
			id: 'furniture',
			title: 'Сменить полку'
		},
		{
			id: 'central-wall-detail',
			title: 'Сменить деталь интерьера'
		},
		{
			id: 'electrolier',
			title: 'Сменить люстру'
		},
		{
			id: 'carpet',
			title: 'Сменить ковер'
		}
	],
	car: [
		{
			id: 'car',
			title: 'Сменить машину'
		}
	]
};
