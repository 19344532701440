import React, {
	useCallback,
	useMemo,
	useState,
	useEffect,
	memo
} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Lazy } from 'swiper';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import {
	IGetGamificationItemsItemResponse,
	IGetGamificationItemsResponse
} from '@lomonosov/types';

import Button from '@components/button';
import { constants } from '@helpers';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';

import './style.css';
import 'swiper/css/navigation'; // swiper styles
import 'swiper/css';

interface IModificationInteriorProps {
	items: IGetGamificationItemsResponse,
	onUseItem: (item: IGetGamificationItemsItemResponse) => void,
	onBuyItem: (itemId: string) => void,
	onCloseMenu?: () => void,
	title: string,
	loader: boolean
}

export default (props: IModificationInteriorProps) => {
	const { items = [], onUseItem, onBuyItem, onCloseMenu, title, loader } = props;

	return (
		<div className={`modification-interior theme-block-background ${loader ? 'modification-interior_loader' : ''}`}>
			<IconButton className='modification-interior__icon' onClick={onCloseMenu}>
				<CloseIcon color='inherit' className='theme-text'/>
			</IconButton>
			<h4 className='modification-interior__title'>
				{title}
			</h4>
			<IconButton className='modification-interior__arrow modification-interior__arrow_prev'>
				<KeyboardArrowDownIcon fontSize='large'/>
			</IconButton>
			<div className='modification-interior__cards'>
				{loader &&
					<div className='modification-interior__loader'>
						<CircularProgress size={80} thickness={6} color={'inherit'}/>
					</div>
				}
				<Swiper
					className='swiper-container'
					direction={'vertical'}
					slidesPerGroup={3}
					slidesPerView={3}
					spaceBetween={15}
					navigation={{
						nextEl: '.modification-interior__arrow_next',
						prevEl: '.modification-interior__arrow_prev'
					}}
					preloadImages={false}
					lazy={true}
					modules={[Navigation, Lazy]}
				>
					{items.map((item) =>
						<SwiperSlide key={item.id}>
							<ModificationInteriorCard
								onUseItem={onUseItem}
								onBuyItem={onBuyItem}
								item={item}
								loader={loader}
							/>
						</SwiperSlide>
					)}
				</Swiper>
			</div>
			<IconButton className='modification-interior__arrow modification-interior__arrow_next'>
				<KeyboardArrowDownIcon fontSize='large'/>
			</IconButton>
		</div>
	);
};

interface IModificationInteriorCard {
	item: IGetGamificationItemsItemResponse
	onUseItem: (item: IGetGamificationItemsItemResponse) => void
	onBuyItem: (itemId: string) => void,
	loader: boolean
}

const ModificationInteriorCard = memo((props: IModificationInteriorCard) => {
	const { onUseItem, onBuyItem, item, loader } = props;

	const [infoButton, setInfoButton] = useState<{ title: string, disable: boolean}>(
		{ title: 'Использовать', disable: false }
	);

	const canRemove = useMemo(() => !['car', 'body', 'head', 'room', 'sofa', 'electrolier'].includes(item.type), [item.type]);

	const currentItem = useMemo(() => ({
		title: canRemove ? 'Снять' : 'Используется',
		disable: !canRemove
	}), [canRemove]);

	useEffect(() => {
		switch (true) {
		case item.isEquipped:
			setInfoButton(currentItem);
			break;
		case item.isBought:
			setInfoButton({ title: 'Использовать', disable: false });
			break;
		case !item.isEnoughLevel:
			setInfoButton({ title: `Нужен уровень ${item.levelCost}`, disable: true });
			break;
		case !item.isEnoughMoney:
			setInfoButton({ title: 'Не хватает денег', disable: true });
			break;
		default:
			setInfoButton({ title: 'Купить', disable: false });
			break;
		}
	}, [item.isBought, item.isEquipped, item.isEnoughLevel, item.isEnoughMoney, currentItem]);

	const handleClickItem = useCallback(async () => {
		if (item.isBought) {
			setInfoButton(currentItem);
			onUseItem(item);
		} else {
			onBuyItem(item.id);
			setInfoButton({ title: 'Использовать', disable: false });
		}
	}, [onUseItem, onBuyItem, item]);

	const icons = useMemo(() => ({
		true: <CheckCircleIcon
			fontSize='small'
			className='modification-interior-card__icon modification-interior-card__icon_success'
		/>,
		false: <CancelIcon
			fontSize='small'
			className='modification-interior-card__icon modification-interior-card__icon_cancel'
		/>
	}), [item.isEnoughLevel, item.isEnoughMoney]);

	return (
		<div className='modification-interior__cards-item modification-interior-card'>
			<img
				src={item.preview.length !== 0 ? `${constants.URL}/files/${item.preview?.[0]?.id}` : ''}
				className='modification-interior-card__image'
				alt={item.type}
			/>
			<div className='modification-interior-card__text'>
				Необх. уровень: <b>{item.levelCost}</b>
				{!(item.isEquipped || item.isBought) && icons[item.isEnoughLevel.toString()]}
			</div>
			<div className='modification-interior-card__text'>
				Стоимость: <b>{item.moneyCost.toLocaleString('ru')}<span className='dollars'>$</span></b>
				{!(item.isEquipped || item.isBought) && icons[item.isEnoughMoney.toString()]}
			</div>
			<div className='modification-interior-card__button'>
				<Button
					type='main_blue'
					onClick={handleClickItem}
					disable={infoButton.disable || loader}
				>
					{infoButton.title}
				</Button>
			</div>
		</div>
	);
});
