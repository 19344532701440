import React, { useEffect, useState } from 'react';
import { IGetPromoCodesItemResponse, typeDiscount, typePromo } from '@lomonosov/types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { adminPromoCodeStore, generalStore } from '@stores';
import { Button, Input } from '@components';
import { replaceFormatDate } from '@helpers';
import './style.css';

export default (props: IProps) => {
	const {
		data,
		dataL = null
	} = props;

	const [formInputs, setFormInputs] = useState<IForm>(data || defaultFormInputs);
	const [isCourse, setIsCourse] = useState(formInputs.course !== null);

	useEffect(() => {
		if (dataL) {
			setFormInputs({
				promo: dataL.promo,
				description: dataL.description,
				typePromo: dataL.typePromo,
				typeDiscount: dataL.typeDiscount,
				countDiscount: dataL.countDiscount,
				dateEnd: replaceFormatDate(dataL.dateEnd),
				isOnce: dataL.isOnce,
				isActive: dataL.isActive,
				course: dataL.courseId,
				user: dataL.userId
			});
		}
	}, []);

	const addPromo = (obj) => {
		if (obj.promo) {
			adminPromoCodeStore.addPromoCode(obj);
			adminPromoCodeStore.changeAddModalState();
		} else {
			generalStore.changeAlertState('warning', 'Введите название промокода');
		}
	};

	const hangleIsCourse = () => {
		if (isCourse) {
			setFormInputs({ ...formInputs, course: null });
		}
		setIsCourse(!isCourse);
	};

	const hangleTypePromo = (e) => {
		if (e.target.value === 'all') {
			setFormInputs({ ...formInputs, user: null });
		}
		setFormInputs({ ...formInputs, typePromo: e.target.value });
	};

	const convertForm = (formInputs) => {
		const courseId = formInputs.course && { id: formInputs.course };
		const userId = formInputs.user && { id: formInputs.user };
		return {
			...formInputs,
			dataEnd: new Date(formInputs.dataEnd),
			user: userId,
			course: courseId
		};
	};

	const handleClick = () => {
		if (dataL) {
			adminPromoCodeStore.updatePromoCode(dataL.id, convertForm(formInputs));
		} else {
			addPromo(formInputs);
		}
	};

	return (
		<>
			<div className='admin-settings-promo__form'>
				<div className='admin-settings-promo__form-input'>
					<Input
						label='Promo'
						value={formInputs.promo}
						onChange={(e: any) => setFormInputs({
							...formInputs, promo: e.target.value.toUpperCase()
						})}
					/>
				</div>
				<div className='admin-settings-promo__form-input'>
					<Input
						label='Описание'
						multiline
						rows={2}
						value={formInputs.description}
						onChange={(e) => setFormInputs({
							...formInputs, description: e.target.value
						})}
					/>
				</div>
				<div className='admin-settings-promo__form-input'>
					<div className='admin-settings-promo__form-input-left'>
						<Input
							label='Значение скидки'
							value={formInputs.countDiscount.toString()}
							onChange={(e) =>
								setFormInputs({ ...formInputs, countDiscount: +e.target.value })
							}
						/>
					</div>
					<Select
						label='Тип скидки'
						className='admin-settings-promo__form-input-right theme-text'
						value={formInputs.typeDiscount}
						onChange={(e) =>
							setFormInputs({
								...formInputs,
								typeDiscount: e.target.value as unknown as typeDiscount
							})
						}
					>
						{selectTypeDiscount.map((item, index) =>
							<MenuItem key={index} value={item.id}>{item.name}</MenuItem>
						)}
					</Select>
				</div>
				<div className='admin-settings-promo__form-input'>
					<div className='admin-settings-promo__form-input-left'>
						<Input
							label='ID пользователя'
							value={formInputs.user?.toString()}
							onChange={(e) => setFormInputs({ ...formInputs, user: e.target.value })}
							disabled={formInputs.typePromo === 'all'}
						/>
					</div>
					<Select
						label='Тип промокода'
						className='admin-settings-promo__form-input-right theme-text'
						value={formInputs.typePromo}
						onChange={hangleTypePromo}
					>
						{selectTypePromo.map((item, index) =>
							<MenuItem key={index} value={item.id}>{item.name}</MenuItem>
						)}
					</Select>
				</div>
				<div className='admin-settings-promo__form-input'>
					<div className='admin-settings-promo__form-input-left'>
						<Input
							label='ID курса'
							value={formInputs.course?.toString()}
							onChange={(e) => setFormInputs({
								...formInputs, course: e.target.value
							})}
							disabled={!isCourse}
						/>
					</div>
					<FormControlLabel
						className='admin-settings-promo__form-input-right'
						value='top'
						label='Для курса'
						labelPlacement='start'
						control={
							<Switch
								checked={isCourse}
								color='primary'
								onChange={() => hangleIsCourse()}
							/>
						}
					/>
				</div>
				<div className='admin-settings-promo__form-input'>
					<Input
						label='Дата окончания'
						type='date'
						value={formInputs.dateEnd}
						onChange={(e) => setFormInputs({
							...formInputs,
							dateEnd: e.target.value
						})}
						InputLabelProps={{ shrink: true }}
					/>
					<FormControlLabel
						className='admin-course__header_switch'
						value='top'
						label='Активный'
						labelPlacement='start'
						control={
							<Switch
								checked={formInputs.isActive} color='primary'
								onChange={() => setFormInputs({
									...formInputs, isActive: !formInputs.isActive
								})}
							/>
						}
					/>
					<FormControlLabel
						className='admin-course__header_switch'
						value='top'
						label='Одноразовый'
						labelPlacement='start'
						control={
							<Switch
								checked={formInputs.isOnce} color='primary'
								onChange={() => setFormInputs({
									...formInputs, isOnce: !formInputs.isOnce
								})}
							/>
						}
					/>
				</div>
				<div className='admin-settings-promo__button'>
					<Button
						onClick={() => handleClick()}
		        type='main_green'
					>
            Сохранить
					</Button>
				</div>
			</div>
		</>
	);
};

interface IProps {
	data?: IForm
	dataL?: IGetPromoCodesItemResponse
}

interface IForm {
	promo: string,
	description: string,
	typePromo: typePromo,
	course: {} | null,
	user: {} | null,
	typeDiscount: typeDiscount,
	countDiscount: number,
	dateEnd: string,
	isOnce: boolean,
	isActive: boolean,
}

const selectTypePromo = [
	{ id: 'all', name: 'Для всех' },
	{ id: 'user', name: 'Для пользователя' }
];

const selectTypeDiscount = [
	{ id: 'percent', name: 'Процент' },
	{ id: 'rub', name: 'Рубли' }
];

const defaultFormInputs = {
	promo: '',
	description: '',
	typePromo: typePromo.ALL,
	typeDiscount: typeDiscount.PERCENT,
	countDiscount: 0,
	dateEnd: new Date().toISOString().split('T')[0],
	isOnce: false,
	isActive: false,
	course: null,
	user: null
};
