import { AxiosResponse } from 'axios';
import {
	HomeworkAnswerStatus,
	IDeleteHomeworkAnswerResponse,
	IGetAllHomeworkAnswersResponse,
	IUpdateHomeworkAnswerResponse,
	IGetAnswersStatisticResponse,
	HomeworkTemplateType
} from '@lomonosov/types';
import { IAdminHomeworkAnswer } from '@interfaces';
import { $api } from '@helpers';

export default class {
	static async getAllHomeworkAnswers (
		take:number,
		skip: number,
		otherQueryParams?: IGetHomeworkAnswersQueryParams | null
	): Promise<AxiosResponse<IGetAllHomeworkAnswersResponse>> {
		return $api.get<IGetAllHomeworkAnswersResponse>(
			'/answers',
			{ params: { take, skip, ...otherQueryParams } }
		);
	}

	static async getHomeworkAnswersAnalysis (
		queryParams?: IGetHomeworkAnswersQueryParams | null
	): Promise<AxiosResponse<IGetAnswersStatisticResponse>> {
		return $api.get<IGetAnswersStatisticResponse>(
			'/answers/statistic',
			{ params: { ...queryParams } }
		);
	}

	static async getHomeworkAnswerById (answerId: string): Promise<AxiosResponse<IAdminHomeworkAnswer>> {
		return $api.get<IAdminHomeworkAnswer>(`/answers/${answerId}`);
	}

	static async updateAnswer (answerId: string, verifiedAnswer): Promise<AxiosResponse<IUpdateHomeworkAnswerResponse>> {
		return $api.put<IUpdateHomeworkAnswerResponse>(`/answers/${answerId}`, verifiedAnswer);
	}

	static async deleteHomeworkAnswerById (answerId: string): Promise<AxiosResponse<IDeleteHomeworkAnswerResponse>> {
		return $api.delete<IDeleteHomeworkAnswerResponse>(`/answers/${answerId}`);
	}

	static async getFilters (): Promise<AxiosResponse<IGetAnswersFiltersResponse>> {
		return $api.get('/answers/filters');
	}
}

export interface IGetHomeworkAnswersQueryParams {
	direction?: string;
	discipline?: string;
	mentor?: string;
	status?: HomeworkAnswerStatus
	start?: string;
	end?: string;
	search?: string;
	course?: string;
	type?: HomeworkTemplateType
}

interface IGetAnswersFiltersResponse {
  disciplines: Array<{
    id: string;
    name: string;
    order: number;
  }>;
  directions: Array<{
    id: string;
    name: string;
    order: number;
  }>;
}
