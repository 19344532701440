import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@material-ui/core';

import { Button } from '@components';
import { generalStore, userNewslettersStore } from '@stores';
import { images } from '@helpers';

import { CallUsIcon, Phone } from 'lomonosov-icons';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';

import './style.css';

interface HeaderProps {
  changeMenuState?: (value?: boolean) => void,
}

export default observer((props: HeaderProps) => {
	const { changeMenuState } = props;

	const navigate = useNavigate();

	return (
		<header className='header theme-block-background'>
			<div className='header__left-block'>
				<IconButton
					className='left-block_menu-button theme-text'
					onClick={() => changeMenuState?.()}
				>
					<MenuIcon/>
				</IconButton>
				<Link className='left-block_logo-link' to='/'>
					<img src={images[`${generalStore.theme}Logo`]} alt='Error'/>
				</Link>
				<div className='left-block_button-circle'>
					<div className='header__newsletters'>
						<IconButton onClick={() => navigate('mail')}>
							<NotificationsIcon className='theme-text'/>
							{userNewslettersStore.count > 0 &&
								<div className='header__newsletters__count'>
									{userNewslettersStore.count > 9 ? '9+' : userNewslettersStore.count}
								</div>
							}
						</IconButton>
					</div>
					<IconButton href='tel:+79104353535' className='phone'>
						<Phone/>
					</IconButton>
				</div>
				<div className='left-block_title'>
          Профессиональная онлайн-школа подготовки к ЕГЭ и ОГЭ
				</div>
			</div>
			<div className='header__right-block'>
				<div className='header__newsletters'>
					<IconButton onClick={() => navigate('mail')}>
						<NotificationsIcon className='theme-text'/>
						{userNewslettersStore.count > 0 &&
							<div className='header__newsletters__count'>
								{userNewslettersStore.count > 9 ? '9+' : userNewslettersStore.count}
							</div>
						}
					</IconButton>
				</div>
				<div>
					<div className='right-block__button right-block__button_pc right-block__button_color-blue'>
						<Button type='main_blue' icon={<CallUsIcon/>}>
							<a href='tel:+79104353535' className='button_phone-link button_text'>+7 910 435 35 35</a>
						</Button>
					</div>
					<a href='tel:+79104353535' className='right-block__button right-block__button_phone right-block__button_color-blue'>
						<Button type='main_blue' icon={<CallUsIcon/>}>
							<p className='button_text'>Позвоните нам</p>
						</Button>
					</a>
				</div>
			</div>
		</header>
	);
});
