import React from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from '@components';
import { getArrMonthsName } from '@helpers';
import { adminInvoiceStore } from '@stores';
import { IColumn } from '@components/table';
import './style.css';

export default observer((props: any) => {
	const { dataL } = props;

	const data = adminInvoiceStore.currentInvoice?.items.map(item => ({
		...item,
		duration: getArrMonthsName([{ begins: item?.begins, ends: item?.ends }]).monthsName,
		tariffName: item?.tariff,
		course: item?.tariff?.course
	})) || [];

	return (
		<div className='detail-panel-invoice'>
			<div className='info'>
				<p className='info__title'>
					id счета
				</p>
				<p className='info__text'>
					{dataL?.id}
				</p>
				<p className='info__title'>
					Имя покупателя
				</p>
				<p className='info__text'>
					{dataL?.userName}
				</p>
				<p className='info__title'>
					Статус
				</p>
				<p className='info__text'>
					{status[dataL.status || 'red'] || null}
				</p>
				<p className='info__title'>
					vkId
				</p>
				<p className='info__text'>
					{dataL?.user?.vkId}
				</p>
				<p className='info__title'>
					Сумма
				</p>
				<p className='info__text'>
					{dataL?.total} ₽
				</p>
				<p className='info__title'>
					userID
				</p>
				<p className='info__text'>
					{dataL?.user?.id}
				</p>
			</div>
			{adminInvoiceStore.currentInvoice?.description &&
				<div className='description'>
					<span>Заметки</span>
					<p>{adminInvoiceStore.currentInvoice?.description}</p>
				</div>
			}
			<Table
				columns={columns}
				data={data}
				title='Курсы'
			/>
		</div>
	);
});

const columns: IColumn[] = [
	{
		field: 'course',
		name: 'Курс',
		type: 'select',
		options: []
	},
	{
		field: 'tariff',
		name: 'Тариф',
		type: 'select',
		options: []
	},
	{
		field: 'duration',
		name: 'Продолжительность'
	},
	{
		field: 'price',
		name: 'Цена'
	}
];

const status = {
	red: 'Счет без покупателя',
	orange: 'Активирован покупателем',
	yellow: 'Покупатель перешел на форму оплаты',
	green: 'Покупатель оплатил'
};
