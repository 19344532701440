import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from '@material-ui/core';
import { Select, Button, DateInput } from '@components';
import { adminCoursesStore, adminIssuanceOfCoursesStore, fileStore, generalStore } from '@stores';
import './styles.css';

export default observer(() => {
	useEffect(() => {
		(async () => {
			if (!adminCoursesStore.allCourses.length) {
				await adminCoursesStore.getAllCourses(20);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (adminIssuanceOfCoursesStore.currentIssue.course?.id) {
				await adminCoursesStore.getTariffsOfCourse(adminIssuanceOfCoursesStore.currentIssue.course.id);
			}
		})();
	}, [adminIssuanceOfCoursesStore.currentIssue.course?.id]);

	const handleChangeIssue = async (value: any, reason: string, field: string) => {
		if (reason === 'clear') return;

		adminIssuanceOfCoursesStore.changeCurrentIssue(value, field);
	};

	const handleChangeCourseIds = (value: { id: string, name: string }[]) => {
		adminIssuanceOfCoursesStore.changeCourseIds(value);
	};

	const handleChangeTypeSelect = (e, value, reason) => {
		if (reason !== 'clear') {
			adminIssuanceOfCoursesStore.changeType(value);
		}
	};

	const uploadFile = async (file) => {
		const func = (file) => {
			adminIssuanceOfCoursesStore.changeCurrentIssue(file.key, 'fileId');
		};
		await fileStore.uploadFile(file, func);
	};

	const issueCourse = async () => {
		const resetStore = () => {
			adminIssuanceOfCoursesStore.resetCurrentIssue();
			adminIssuanceOfCoursesStore.changeType({ id: '', name: '' });
			adminIssuanceOfCoursesStore.changeCourseIds([]);
			generalStore.changeAlertState('success', 'Курсы выданы');
		};

		if (adminIssuanceOfCoursesStore.type.id === 'file') {
			await adminIssuanceOfCoursesStore.issueCoursesByFile(adminIssuanceOfCoursesStore.currentIssue);
			resetStore();
		}

		if (adminIssuanceOfCoursesStore.type.id === 'course') {
			await adminIssuanceOfCoursesStore.issueCoursesByCourses(adminIssuanceOfCoursesStore.currentIssue, adminIssuanceOfCoursesStore.courseIds);
			resetStore();
		}
	};

	const searchsDelayed = debounce((text: string) => adminCoursesStore.getAllCourses(20, text), 1000);

	return (
		<div className='issuance-of-courses'>
			<h1>Выдача курсов</h1>
			<div className='issuance-of-courses__item'>
				<Select
					options={typeSelectOptions}
					onChange={handleChangeTypeSelect}
					input={{ label: 'Тип выдачи' }}
					value={adminIssuanceOfCoursesStore.type}
				/>
			</div>
			{adminIssuanceOfCoursesStore.type.id === 'file' &&
				<div className='issuance-of-courses__item'>
					<label htmlFor='issuance-of-courses-file'>
						<div className='issuance-of-courses__button_file theme-text'>
							Загрузить файл
						</div>
					</label>
					<input
						className='issuance-of-courses__input'
						id='issuance-of-courses-file'
						type='file' accept='*'
						onChange={(e) => uploadFile(e.target)}
					/>
				</div>
			}
			{adminIssuanceOfCoursesStore.type.id === 'course' &&
				<div className='issuance-of-courses__item'>
					<Select
						options={adminCoursesStore.allCourses}
						onChange={(e, value) => handleChangeCourseIds(value)}
						input={{ label: 'Купленные курсы', onChange: (e) => searchsDelayed(e.target.value) }}
						value={adminIssuanceOfCoursesStore.courseIds}
						multiple
					/>
				</div>
			}
			<div className='issuance-of-courses__item'>
				<Select
					options={adminCoursesStore.allCourses}
					onChange={(e, value, reason) => handleChangeIssue(value, reason, 'course')}
					input={{ label: 'Выдаваемый курс', onChange: (e) => searchsDelayed(e.target.value) }}
					value={{ id: adminIssuanceOfCoursesStore.currentIssue.course?.id, name: adminIssuanceOfCoursesStore.currentIssue.course?.name }}
				/>
			</div>
			<div className='issuance-of-courses__item'>
				<DateInput
					label='Начало периода'
					type='date'
					value={adminIssuanceOfCoursesStore.currentIssue.startPeriod}
					onChange={(e) => handleChangeIssue(e.target.value, '', 'startPeriod')}
				/>
			</div>
			<div className='issuance-of-courses__item'>
				<DateInput
					label='Окончание периода'
					type='date'
					value={adminIssuanceOfCoursesStore.currentIssue.endPeriod}
					onChange={(e) => handleChangeIssue(e.target.value, '', 'endPeriod')}
				/>
			</div>
			<div className='issuance-of-courses__item'>
				<Select
					options={adminCoursesStore.tariffs}
					onChange={(e, value, reason) => handleChangeIssue(value, reason, 'tariff')}
					input={{ label: 'Тариф' }}
					value={{ id: adminIssuanceOfCoursesStore.currentIssue.tariff?.id, name: adminIssuanceOfCoursesStore.currentIssue.tariff?.name }}
				/>
			</div>
			<div className='issuance-of-courses__button'>
				<Button
					type='main_green'
					onClick={issueCourse}
					disable={adminIssuanceOfCoursesStore.loader}
				>
					Выдать
				</Button>
			</div>
		</div>
	);
});

const typeSelectOptions = [
	{ id: 'course', name: 'По купленным курсам' },
	{ id: 'file', name: 'По файлу' }
];
