import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	IGetUserLessonResponse, IUserCourseSettings
} from '@lomonosov/types/dist';
import { ICourseListItem, ICurrentCourseProps } from '@interfaces';
import { IUserSectionsList } from '@interfaces/IUserLesson';

export default class {
	static async getCourses (userId): Promise<AxiosResponse<ICourseListItem[]>> {
		return $api.get<ICourseListItem[]>(`/users/${userId}/courses`);
	}

	static async getCurrentCourse (userId, courseId: string): Promise<AxiosResponse<ICurrentCourseProps>> {
		return $api.get<ICurrentCourseProps>(`/users/${userId}/courses/${courseId}`);
	}

	static async getLessons (userId, sectionId): Promise<AxiosResponse<IUserSectionsList>> {
		return $api.get<IUserSectionsList>(`/users/${userId}/sections/${sectionId}`);
	}

	static async getCurrentLesson (userId, lessonId: string, source?: 'section' | 'gamification' | undefined):
		Promise<AxiosResponse<ICurrentLessonProps>> {
		return $api.get<ICurrentLessonProps>(`/users/${userId}/lessons/${lessonId}`, { params: { source } });
	}

	static async deleteCourse (courseId: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/users/purchases/courses/${courseId}`);
	}

	static async updateSettingCourse (settingId, updatedCourse: IUserCourseSettings): Promise<AxiosResponse<IUserCourseSettings>> {
		return $api.put<IUserCourseSettings>(`/users/settings/${settingId}`, updatedCourse);
	}

	// ///////// KINESCOPE ////////////////////////////////

	static async getKinescopeEvent (eventId: string): Promise<AxiosResponse<string>> {
		return $api.get<string>(`/kinescope/event/${eventId}/user`);
	}
}

export interface ICurrentLessonProps extends IGetUserLessonResponse {
	shortId: string;
	blocks: any
	eventId?: string | null;
}
