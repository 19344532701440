import { AxiosResponse } from 'axios';
import { $api, constants } from '@helpers';
// import {
// 	IGetCoursesAnalysisResponse,
// } from '@lomonosov/types';

export default class {
	static async getCoursesAnalysis (take: number, skip: number, queryParams: string = ''): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/analytics/courses?take=${take}&skip=${skip}${queryParams}`);
	}

	static async getLessonsAnalysis (take: number, skip: number, courseId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>('/analytics/lessons', {
			params: { take, skip, course: courseId }
		});
	}

	static async getTasksAnalysis (take: number, skip: number, courseId: string, type: string | null): Promise<AxiosResponse<any>> {
		return $api.get<any>('/analytics/homeworks', {
			params: { take, skip, course: courseId, type }
		});
	}

	static async getTaskUsersAnalysis (take: number, skip: number, taskId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/analytics/homeworks/${taskId}/answers`, {
			params: { take, skip }
		});
	}

	static async getTasksActivity (take: number, skip, queryParams): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/analytics/homeworks${queryParams}`, {
			params: { take, skip }
		});
	}

	static getUserAnswersFileActivity (queryParams): string {
		return `${constants.URL}/analytics/users/tasks${queryParams}&type=file`;
	}

	static getCurrentTaskAnswersFile (taskId: string, type: 'rate' | 'text'): string {
		return `${constants.URL}/analytics/homeworks/${taskId}?type=${type}`;
	}

	static async getCurrentTaskActivity (take: number, skip, taskId): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/analytics/homeworks/${taskId}/answers`, {
			params: { take, skip }
		});
	}

	static async getUsersActivity (take: number, skip, search): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/analytics/users${search}`, {
			params: { take, skip }
		});
	}

	static async getCurrentUserActivity (take: number, skip, userId): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/analytics/users/${userId}`, {
			params: { take, skip }
		});
	}
}
