import React, { AnchorHTMLAttributes } from 'react';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';

interface IURLLink extends AnchorHTMLAttributes<HTMLAnchorElement> {
	iconname?: string
	customicon?: React.ReactNode
	tooltip?: string
}

export default (props: IURLLink) => {
	const {
		children,
		iconname,
		customicon,
		tooltip,
		...otherProps
	} = props;

	return (
		<Tooltip
			enterDelay={500}
			leaveDelay={300}
			placement='right'
			title={tooltip}
		>
			<a {...otherProps}>
				<ListItem button className='DrawerMenuListItem'>
					<ListItemIcon className='DrawerMenuListItem__icon theme-text'>
						{customicon || <Icon>{iconname}</Icon>}
					</ListItemIcon>
					{children && <ListItemText className='DrawerMenuListItem__text theme-text' primary={children} />}
				</ListItem>
			</a>
		</Tooltip>
	);
};
