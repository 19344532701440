import React from 'react';
import { SkeletonComponent } from '@components';
import './style.css';

export default () => {
	return (
		<div className='journal-card-loader journal-card theme-block-background'>
			<div className='journal-card__preview journal-card-loader__preview'>
				<SkeletonComponent/>
			</div>
			<div className='journal-card__title-block'>
				<div className='portal-courses-card-advantages'>
					<div className='portal-courses-card-advantages__item journal-card-loader__advantages-item'>
						<SkeletonComponent/>
					</div>
					<div className='portal-courses-card-advantages__item journal-card-loader__advantages-item'>
						<SkeletonComponent/>
					</div>
					<div className='portal-courses-card-advantages__item journal-card-loader__advantages-item'>
						<SkeletonComponent/>
					</div>
				</div>
				<div className='journal-card__title journal-card-loader__title'>
					<SkeletonComponent/>
				</div>
			</div>
			<div className='journal-card-grade-block journal-card-loader__grade-block'>
				<SkeletonComponent/>
			</div>
			<div className='journal-card__button journal-card-loader__button'>
				<SkeletonComponent/>
			</div>
		</div>
	);
};
