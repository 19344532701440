import React from 'react';
import { Input } from '@components';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { IActionHeader, ISearch } from '../../type';

interface IProps {
	title?: string
	actionHeader?: IActionHeader
	search?: ISearch
	className?: string
}

export default (props: IProps) => {
	const {
		title,
		actionHeader,
		search,
		className
	} = props;

	return (
		<div className={`table__header ${className}`}>
			<span className='table__header-title'>
				{title}
			</span>
			<div className='table__actions'>
				{search &&
					<Input
						label='Поиск'
						value={search.prop}
						onChange={e => search.action(e.target.value)}
					/>
				}
				{actionHeader &&
					<>
						{
							actionHeader.add !== undefined &&
								<IconButton
									className='table__action_add theme-background'
									key={'table__action_add'}
									onClick={actionHeader.add}
								>
									<span className='table__action_add__span'>Добавить запись</span>
									<AddIcon />
								</IconButton>
						}
					</>

				}
			</div>
		</div>
	);
};
