import { runInAction, makeAutoObservable } from 'mobx';
import {
	IGetCoursesResponse,
	IGetCourseByIdResponse,
	IGetCoursesItemResponse,
	IGetAllTariffsResponse,
	Color,
	BannerType
} from '@lomonosov/types';

import { AdminCourseService } from '@services';
import { generalStore } from '@stores';
import { ICurrentCourse, IGetCoursesNewShopItem, IOrderingNewShopCourseDto } from '@interfaces';

class AdminCourseStore {
	courses = [] as IGetCoursesItemResponse[];
	allCourses = [] as IGetCoursesItemResponse[];
	tariffs: IGetAllTariffsResponse = [];
	currentCourse: ICurrentCourse = {} as ICurrentCourse;
	banner: IGetCourseByIdResponse['banner'] = defaultBanner;
	deletableCourseId: string = '';

	isLoading: boolean = false;
	isArchive: boolean | null = false;

	pagination = { pages: 1, currentPage: 1 };
	filterFields = defaultFilterFields;
	search = '';
	newShopCourses: IGetCoursesNewShopItem[] = [];

	constructor () {
		makeAutoObservable(this);
	}

	/**
	 * Функция для получения всех курсов,
	 * используется в таблице "Все пользователи" для выдачи курсов ученикам
	 */
	async getAllCourses (take?: number, search?: string, isVisible?: boolean, isShop?: boolean) {
		this.setLoading(true);
		try {
			const { data } = await AdminCourseService.getAllCourses(take, search, isVisible, isShop);
			runInAction(() => {
				this.allCourses = data.courses;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	/**
	 * Получение всех шаблонов ДЗ
	 * @param take количество получаемых записей
	 * @param skip количество записей пропустить
	 **/
	async getCourses (take: number = 6, skip: number = 0): Promise<IGetCoursesResponse['courses'] | null> {
		this.setLoading(true);
		try {
			const params = ['discipline', 'direction', 'category']
				.map((value) => this.filterFields[value] !== null
					? `&${value}=${this.filterFields[value].id}`
					: ''
				).join('');

			const isVisible = this.isArchive === null ? '' : `&isVisible=${this.isArchive}`;
			const { data } = await AdminCourseService.getCourses(take, skip, isVisible, params, this.search);

			runInAction(() => {
				this.courses = data.courses;
				this.pagination.pages = Math.ceil(data.count / take);
			});
			return data.courses;
		} catch (e) {
			console.log(e);
			return null;
		} finally {
			this.setLoading(false);
		}
	}

	async getCourseById (courseId: string) {
		try {
			this.setLoading(true);
			const { data } = await AdminCourseService.getCurrentCourse(courseId);
			runInAction(() => {
				this.currentCourse = { ...data, classroom: { id: data.classroom, name: data.classroom } };
				this.banner = data.banner ?? { ...defaultBanner, course: { id: data.id } };
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async createCourse (editableCourse) {
		try {
			const { data } = await AdminCourseService.createCourse(editableCourse);
			runInAction(() => {
				this.currentCourse = { ...data, classroom: { id: data.classroom, name: data.classroom } };
				this.getCourses();
			});
			window.history.replaceState('', '', `course/${data.id}`);
			generalStore.changeAlertState('success', 'Курс создан');
		} catch (e) {
			console.log(e);
		}
	}

	async updateCourse (courseId: string, editableCourse) {
		try {
			const { data } = await AdminCourseService.updateCourse(courseId, editableCourse);
			runInAction(() => {
				this.currentCourse = { ...data, classroom: { id: data.classroom, name: data.classroom } };
				this.getCourses();
			});
			generalStore.changeAlertState('success', 'Сохранено');
		} catch (e) {
			console.log(e);
		}
	}

	async updateCourseTariff (tariff) {
		try {
			const { data } = await AdminCourseService.updateCourseTariff(tariff.id, tariff);
			runInAction(() => {
				this.tariffs = this.tariffs.map(t => t.id === data.id ? data : t);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async syncCourseTariffs (courseId: string, tariffPlanId:string) {
		try {
			await AdminCourseService.syncCourseTariffs(courseId, tariffPlanId);
			runInAction(() => {
				this.getTariffsOfCourse(courseId);
			});
			return true;
		} catch (e) {
			console.log(e);
			return false;
		}
	}

	async deleteCourse () {
		this.setLoading(true);
		try {
			await AdminCourseService.deleteCourse(this.deletableCourseId);
			runInAction(() => {
				this.courses = this.courses.filter((course) => course.id !== this.deletableCourseId);
				this.deletableCourseId = '';
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	changeDeletableCourseId (value: string) {
		this.deletableCourseId = value;
	}

	setLoading (state) {
		this.isLoading = state;
	}

	async getTariffsOfCourse (courseId: string) {
		this.isLoading = true;
		try {
			const { data } = await AdminCourseService.getTariffsOfCourse(courseId);
			runInAction(() => {
				this.tariffs = data;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	async deleteTariff (tariffId: string) {
		try {
			await AdminCourseService.deleteTariff(tariffId);
			runInAction(() => {
				this.currentCourse.tariffs = this.currentCourse.tariffs.filter((t) => t.id !== tariffId);
				this.tariffs = this.tariffs.filter((t) => t.id !== tariffId);
				this.currentCourse = { ...this.currentCourse };
			});
		} catch (e) {
			console.log(e);
		}
	}

	changeCourse (value, field: string) {
		this.currentCourse[field] = value;
	}

	changeBanner (value, field?: string) {
		field
			? this.banner[field] = value
			: this.banner = value;
	}

	async changePage (page: number, take: number) {
		await this.getCourses(take, (page - 1) * take);
		this.pagination.currentPage = page;
	}

	changeIsArchive (value: boolean | null) {
		this.isArchive = value;
	}

	changeSearch (value) {
		if (value) {
			this.filterFields.isFiltered = true;
		}
		this.pagination.currentPage = 1;
		this.search = value;
	}

	changeFilterField (values, fields, take = 6) {
		for (let i = 0; i < fields.length; i++) {
			this.filterFields[fields[i]] = values[i];
		}
		this.filterFields.isFiltered = true;
		this.pagination.currentPage = 1;
		this.getCourses(take, 0).then();
	}

	resetCurrentCourse () {
		this.currentCourse = {} as ICurrentCourse;
		this.banner = defaultBanner;
		this.tariffs = [];
	}

	resetFilterFields () {
		this.filterFields = defaultFilterFields;
		this.search = '';
		this.pagination.currentPage = 1;
		this.getCourses(6, 0).then();
	}

	/* Получение курсов нового магазина */
	async getCoursesNewShop () {
		try {
			const { data } = await AdminCourseService.getCoursesNewShop();
			this.newShopCourses = data;
		} catch (error) {
			console.log(error);
		}
	}

	/* Сортировка курсов нового магазина */
	async orderingNewShopCourses (courses: IOrderingNewShopCourseDto[], sortedArray: IGetCoursesNewShopItem[]) {
		try {
			const { data } = await AdminCourseService.orderingNewShopCourses(courses);
			if (data) {
				generalStore.changeAlertState('success', 'Сортировка сохранена');
				this.newShopCourses = sortedArray;
			}
		} catch (error) {
			console.log(error);
		}
	}
}

const defaultBanner = {
	id: '',
	name: '',
	description: '',
	link: '',
	textButton: '',
	color: 'green' as Color,
	image: '',
	isActive: false,
	type: 'course' as BannerType
};

const defaultFilterFields = {
	discipline: null,
	category: null,
	direction: null,
	isFiltered: false
};

export const adminCoursesStore = new AdminCourseStore();
