import React from 'react';
import { observer } from 'mobx-react-lite';
import { IFile } from '@interfaces';
import { Button, Card, IconButton, Tooltip } from '@mui/material';
import { constants, DocumentIcon, truncateText } from '@helpers';
import { generalStore } from '@stores';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import classNames from 'classnames';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './style.css';

type DownloadFileCardColor = 'primary' | 'white' | 'violet' | 'deep-violet' | 'red';
type DownloadFileTheme = 'primary' | 'secondary' | 'white';

interface DownloadFileCardProps {
	file: IFile;
	color?: DownloadFileCardColor;
	className?: string;
	theme?: DownloadFileTheme;
	onDelete?: (fileId: string) => void;
}

export const DownloadFileCard = observer((props: DownloadFileCardProps) => {
	const { file, color = generalStore.theme === 'dark' ? 'white' : 'primary', className, theme = 'secondary', onDelete } = props;
	return (
		<Card className={classNames('download-file-card', className, `download-file-card_${generalStore.theme}`)} color={theme}>
			{onDelete &&
				<Tooltip title='Удалить файл' arrow>
					<IconButton className='download-file-card__close-button' onClick={() => onDelete(file.id)}>
						<HighlightOffIcon color='inherit'/>
					</IconButton>
				</Tooltip>
			}
			<DocumentIcon
				text={getFileExtension(file)}
				className={classNames('download-file-card__icon', `download-file-card__icon_${color}`)}
				classes={{
					text: classNames(
						{ 'download-file-card__icon_smallText': getFileExtension(file).length >= 4 },
						 `download-file-card__icon-text_${color}`,
						 'download-file-card__icon-text'
					)
				}}
			/>
			<Tooltip title={file?.originalname || 'file task'} arrow placement='top'>
				<span className='download-file-card__title'>{file?.originalname || 'file task'}</span>
			</Tooltip>
			<Button
				variant='contained'
				color='primary' size='small'
				className='download-file-card__button'
				endIcon={<ArrowDownwardOutlinedIcon fontSize='small'/>}
				href={`${constants.URL}/files/${file.id}`}
				target='_blank'
  			rel='noopener noreferrer'
				download={file?.originalname || 'file task'}
			>
				Скачать файл
			</Button>
		</Card>
	);
});

const getFileExtension = (file: IFile): string => {
	if (!file?.originalname) return 'docx';
	const ext: string = file.originalname.split('.').pop() || 'docx';
	if (ext?.length > 4) {
		return truncateText(ext, 4);
	}
	return ext;
};
