import React from 'react';
import { Helmet } from 'react-helmet';

export default () => (
	<Helmet>
		<meta charSet='utf-8'/>
		<title>
      Lomonosov School | Онлайн-платформа подготовки к ЕГЭ, ОГЭ и ДВИ 2025
		</title>
		<link rel='icon' href='../../../public/favicon.ico'/>
		<link rel='canonical' href='https://lomonosov.school'/>
		<meta
			name='description'
			content='Lomonosov School - это курсы подготовки к ЕГЭ, ДВИ, ОГЭ и олимпиадам.
                Занятия online с преподавателями, сотрудниками, выпускниками и студентами МГУ им. М.В. Ломоносова.
                Специально для школьников и студентов.'
		/>
	</Helmet>
);
