import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, DeleteModal } from '@components';
import { adminBannerStore } from '@stores';
import AddBannerUsers from '../../admin-blocks/add-banner-users';
import '../admin-main-block.globalStyle.css';
import './style.css';

export default observer(() => {
	const [deletableBannerId, setDeletableBannerId] = useState<string>('');

	useEffect(() => {
		if (!adminBannerStore.banners?.length) {
			adminBannerStore.getBanners().then();
		}
	}, []);

	const deleteBanner = () => {
		adminBannerStore.deleteBanner(deletableBannerId).finally(() => setDeletableBannerId(''));
	};

	return (
		<>
			<Table
				columns={bannersColumns}
				data={adminBannerStore.banners}
				detailPanel={{ Component: AddBannerUsers }}
				actionRow={{
					edit: (id, newState) => adminBannerStore.updateBanner(id, { ...newState, image: null }),
					delete: (id) => setDeletableBannerId(id),
					add: (newState) => adminBannerStore.addBanner({ ...newState, image: null, link: null })
				}}
				pagination
				search
			/>
			<DeleteModal
				deleteName='Баннер'
				open={!!deletableBannerId}
				handleClose={() => setDeletableBannerId('')}
				action={deleteBanner}
			/>
		</>
	);
});

const bannersColumns = [
	{ field: 'id', name: 'id', copy: true, editable: false },
	{ field: 'name', name: 'Текст баннера' }
];
