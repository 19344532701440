import React from 'react';
import { SkeletonComponent } from '@components';
import './style.css';

export default () => {
	return (
		<div className='courses-analysis-card courses-analysis-card-loader theme-block-background'>
			<div className='courses-analysis-card-loader__block'>
				<div className='courses-analysis-card-loader__title'>
					<SkeletonComponent/>
				</div>
				<div className='courses-analysis-card-loader__info courses-analysis-card__info'>
					<div className='courses-analysis-card-loader__icon-button'>
						<SkeletonComponent/>
					</div>
					<div className='courses-analysis-card-loader__text'>
						<SkeletonComponent/>
					</div>
					<div className='courses-analysis-card-loader__icon-button'>
						<SkeletonComponent/>
					</div>
					<div className='courses-analysis-card-loader__text'>
						<SkeletonComponent/>
					</div>
				</div>
				<div className='courses-analysis-card-loader__info courses-analysis-card__info'>
					<div className='courses-analysis-card-loader__icon-button'>
						<SkeletonComponent/>
					</div>
					<div className='courses-analysis-card-loader__icon-button'>
						<SkeletonComponent/>
					</div>
					<div className='courses-analysis-card-loader__icon-button'>
						<SkeletonComponent/>
					</div>
					<div className='courses-analysis-card-loader__icon-button'>
						<SkeletonComponent/>
					</div>
				</div>
			</div>
			<div className='courses-analysis-card-loader__button'>
				<SkeletonComponent/>
			</div>
		</div>
	);
};
