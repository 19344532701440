import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	IGetRolesResponse,
	IGetRoleByIdResponse,
	ICreateRoleDto,
	ICreateRoleResponse,
	IUpdateRoleDto,
	IUpdateRoleResponse,
	IDeleteRoleResponse
} from '@lomonosov/types';

export default class {
	static async getRoles (): Promise<AxiosResponse<IGetRolesResponse>> {
		return $api.get<IGetRolesResponse>('/roles');
	}

	static async getCurrentRole (roleId: string): Promise<AxiosResponse<IGetRoleByIdResponse>> {
		return $api.get<IGetRoleByIdResponse>(`/roles/${roleId}`);
	}

	static async addRole (role: ICreateRoleDto): Promise<AxiosResponse<ICreateRoleResponse>> {
		return $api.post<ICreateRoleResponse>('/roles', role);
	}

	static async updateRole (roleId: string, newInfoRole: IUpdateRoleDto): Promise<AxiosResponse<IUpdateRoleResponse>> {
		return $api.put<IUpdateRoleResponse>(`/roles/${roleId}`, newInfoRole);
	}

	static async deleteRole (roleId: string): Promise<AxiosResponse<IDeleteRoleResponse>> {
		return $api.delete<IDeleteRoleResponse>(`/roles/${roleId}`);
	}
}
