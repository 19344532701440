import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import { INewsletterInterface } from '@interfaces';

export default class {
	static async getAllNewsLetters (): Promise<AxiosResponse<INewsletterInterface[]>> {
		return $api.get<INewsletterInterface[]>('/newsletters');
	}

	static async getNewsLetterById (newsletterId: string): Promise<AxiosResponse<INewsletterInterface>> {
		return $api.get<INewsletterInterface>(`/newsletters/${newsletterId}`);
	}

	static async createNewsLetter (newsletter: INewsletterInterface): Promise<AxiosResponse<INewsletterInterface>> {
		return $api.post<INewsletterInterface>('/newsletters', newsletter);
	}

	static async updateNewsLetter (newsLetter: INewsletterInterface): Promise<AxiosResponse<INewsletterInterface>> {
		return $api.put<INewsletterInterface>(`/newsletters/${newsLetter.id}`, newsLetter);
	}

	static async deleteNewsLetters (newsletterId: string): Promise<AxiosResponse<any>> {
		return $api.delete(`/newsletters/${newsletterId}`);
	}
}
