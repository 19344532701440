
import { constants } from '@helpers';
import { SocialAuthType } from '../types/auth';

export const getUrlToBindSocial = (provider: SocialAuthType, userId: string): string => {
	const authVK = new URL(constants.VK_AUTH_URI);
	authVK.searchParams.append('client_id', constants.VK_CLIENT_ID);
	authVK.searchParams.append('display', 'page');
	authVK.searchParams.append('redirect_uri', constants.VK_REDIRECT_URI.CONFIRM || '');
	authVK.searchParams.append('scope', 'email,offline');
	authVK.searchParams.append('response_type', 'code');
	authVK.searchParams.append('v', '5.131');

	switch (provider) {
	case 'vk':
		return authVK.href;
	default:
		return `${constants.URL}/users/${userId}/attach-${provider}`;
	}
};
