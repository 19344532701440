import React, { ReactNode } from 'react';
import { Button } from '@mui/material';

import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AppleIcon from '@mui/icons-material/Apple';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import { DiscordIcon, DzenIcon, InstagramIcon, TiktokUncoloredIcon, VkIcon } from '@helpers';

import classNames from 'classnames';
import './style.css';

type SocialButtonSizeType = 'large' | 'medium' | 'small';
export type SocialButtonIconType = 'vk' | 'telegram' | 'youtube' | 'apple' | 'discord' | 'whatsapp' | 'phone'
  | 'instagram' | 'tiktok' | 'dzen';
type SocialLinkVariant = 'circle' | 'rectangle' | 'rounded' | 'clear'
interface ISocialLinkProps {
  className?: string;
  icon?: SocialButtonIconType;
  href?: string;
  label?: string;
  size?: SocialButtonSizeType;
  color?: 'secondary' | 'primary';
  isTargetBlank?: boolean;
  variant?: SocialLinkVariant;
  onClick?: (type?: SocialButtonIconType) => void;
  disabled?: boolean;
	isShadow?: boolean;
	positionIcon?: 'left' | 'right';
}

export const SocialButton = (props: ISocialLinkProps) => {
	const {
		className, icon, href, label, size = 'medium',
		variant = 'rounded', onClick,
		disabled = false, isTargetBlank, color = 'primary', positionIcon = 'right'
	} = props;

	const onClickItem = () => {
		if (disabled) return;
		onClick?.(icon);
	};

	const clsMods: Record<string, boolean> = {
		disabled: disabled,
		onlyIcon: !label
	};

	if (!href) {
		return (
			<Button
				onClick={onClickItem}
				variant='contained'
				disabled={disabled}
				color={color}
				className={
					classNames(
						'SocialButton',
						clsMods,
						size, variant,
						className
					)
				}
			>
				{icon && positionIcon === 'left' &&
          socialIcons[icon]
				}
				{label}
				{icon && positionIcon === 'right' &&
					socialIcons[icon]
				}
			</Button>
		);
	}

	return (
		<Button
			href={href}
			target={isTargetBlank ? '_blank' : '_self'}
			className={classNames('SocialButton', clsMods, size, variant, className)}
			variant='contained'
			color={color}
		>
			{icon && positionIcon === 'left' &&
          socialIcons[icon]
			}
			{label}
			{icon && positionIcon === 'right' &&
				socialIcons[icon]
			}
		</Button>
	);
};

export const socialIcons: Record<SocialButtonIconType, ReactNode> = {
	youtube: <YouTubeIcon />,
	telegram: <TelegramIcon />,
	vk: <VkIcon />,
	apple: <AppleIcon fontSize='inherit'/>,
	discord: <DiscordIcon />,
	whatsapp: <WhatsAppIcon />,
	phone: <PhoneIcon />,
	dzen: <DzenIcon />,
	instagram: <InstagramIcon />,
	tiktok: <TiktokUncoloredIcon />
};
