export const getExamTimes = (discipline?: string) => {
	switch (discipline) {
	case 'Математика': return 14100;
	case 'Базовая математика': return 10800;
	case 'Русский язык': return 12600;
	case 'Обществознание': return 12600;
	case 'Физика': return 14100;
	case 'История': return 12600;
	case 'Биология': return 14100;
	case 'Химия': return 12600;
	case 'Информатика': return 12200; // 14100
	case 'Литература': return 14100;
	case 'Английский язык': return 11400;
	default: return 0;
	}
};
