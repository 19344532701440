import React from 'react';
import { Table } from '@components';
import { adminPromoCodeStore } from '@stores';
import { PromoStatisticsUserPurchases } from './index';

const currentPromoCodeStatisticsColumns = [
	{ name: 'vkId', field: 'vkId' },
	{ name: 'Имя', field: 'firstName' },
	{ name: 'Фамилия', field: 'secondName' },
	{ name: 'Количество курсов в покупке', field: 'purchasedCoursesCount' },
	{ name: 'Сумма', field: 'sum' }
];

export default () => {
	const onOpenDetailPanel = async (id, data) => {
		adminPromoCodeStore.changeCurrentUser(data);
	};

	return (
		<Table
			title='Пользователи'
			columns={currentPromoCodeStatisticsColumns}
			data={adminPromoCodeStore.currentPromoCodeStatistics}
			detailPanel={{
				Component: PromoStatisticsUserPurchases,
				action: (id, data) => onOpenDetailPanel(id, data)
			}}
		/>
	);
};
