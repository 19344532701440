import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AddConsistForm, IConsistInfo } from '../../../../admin-components/AddConsistForm/AddConsistForm';
import Button from '@components/button';
import { adminComboCoursesStore } from '@stores';
import { IComboTariffPeriod } from '@interfaces';

interface DetailPeriodProps {
	dataL: IComboTariffPeriod;
}

export const DetailPeriod = observer((props: DetailPeriodProps) => {
	const { dataL } = props;
	const [consistInfo, setConsistInfo] = useState<IConsistInfo[]>([]);
	const [isChanged, setIsChanged] = useState<boolean>(false);

	useEffect(() => {
		const period = adminComboCoursesStore.comboTariffPeriods.find((t) => t.id === dataL.id);
		if (period?.consist) {
			setConsistInfo(period.consist.map(item => item) || []);
		}
	}, [dataL.id]);

	const onAddConsist = (consist: IConsistInfo) => {
		setConsistInfo([...consistInfo, consist]);
		setIsChanged(true);
	};

	const onDeleteConsist = (id: string) => {
		setConsistInfo(consistInfo.filter((t) => t.id !== id).map((t, i) => ({ ...t, order: i })));
		setIsChanged(true);
	};

	const onEditConsist = (id: string, consist: IConsistInfo) => {
		const i = consistInfo.findIndex((t) => t.id === id);
		consistInfo[i] = consist;
		// setConsistInfo([...consistInfo]);
		setIsChanged(true);
	};

	const handleChangeOrder = (id: string, newOrder: number, prevOrder: number) => {
		consistInfo[prevOrder].order = newOrder;
		consistInfo[newOrder].order = prevOrder;
		setConsistInfo([...consistInfo]);
		setIsChanged(true);
	};

	const onSave = () => {
		setIsChanged(false);
		adminComboCoursesStore.updateComboTariffPeriod(dataL.id, {
			...dataL,
			consist: consistInfo
		});
	};

	return (
		<div className='detail-combo-period'>
			{consistInfo.sort((a, b) => a.order - b.order).map((consist, index, array) => (
				<AddConsistForm
					index={index}
					key={consist.id}
					consist={consist}
					onEdit={onEditConsist}
					onDelete={onDeleteConsist}
					onChangeOrder={handleChangeOrder}
					length={array.length}
				/>
			))}
			<AddConsistForm onAdd={onAddConsist} length={consistInfo.length}/>
			<div className='detail-combo-period__button'>
				<Button type='main_green' disable={!isChanged} onClick={onSave}>
					Сохранить опции
				</Button>
			</div>
		</div>
	);
});
