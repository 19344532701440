import React from 'react';
import { observer } from 'mobx-react-lite';

import { Table } from '@components';
import { Switch } from '@material-ui/core';
import { IColumn } from '@components/table';
import { DetailPeriod } from '../detail-period';
import { adminComboCoursesStore } from '@stores';
import { IComboTariffPeriod, IUpdateOrderingDto } from '@interfaces';
import { debounce } from '@mui/material/utils';

interface ITableComboPeriodsProps {
	combinationTariffId: string;
}

export const TableComboPeriods = observer((props: ITableComboPeriodsProps) => {
	const onAddComboPeriod = async (period: Omit<IComboTariffPeriod, 'consist' | 'id'>) => {
		await adminComboCoursesStore.createComboTariffPeriod({
			...period,
			price: Number(period.price),
			combinationTariffId: props.combinationTariffId,
			order: adminComboCoursesStore.comboTariffPeriods.length * 10 + 10
		});
	};

	const onDeleteComboPeriod = (periodId: string) => {
		adminComboCoursesStore.deleteComboTariffPeriod(periodId);
	};

	const onEditComboPeriod = (periodId: string, period: IComboTariffPeriod) => {
		adminComboCoursesStore.updateComboTariffPeriod(periodId, {
			...period,
			price: Number(period.price),
			combinationTariffId: props.combinationTariffId
		});
	};

	const periodsColumns: IColumn[] = [
		...defaultColumns,
		{
			editable: false,
			type: 'element',
			field: 'isActive',
			name: 'Активность',
			width: 80,
			align: 'center',
			element: (data, func) => (
				data.id &&
				<Switch
					checked={data?.isActive}
					color='primary'
					inputProps={{ 'aria-label': 'controlled' }}
					onChange={async (_e, checked) => {
						await adminComboCoursesStore.activateComboTariffPeriod(data.id, checked);
						func?.(checked);
					}}
				/>
			)
		}
	];

	const orderingPeriods = debounce(async (periods: IComboTariffPeriod[]) => {
		const orderingPeriods: IUpdateOrderingDto[] = periods.map((tariff, index) => ({
			id: tariff.id,
			order: index + 10
		}));
		await adminComboCoursesStore.orderingComboTariffPeriods(orderingPeriods);
	}, 1000);

	return (
		<Table
			className='detail-combo__table'
			data={adminComboCoursesStore.comboTariffPeriods}
			columns={periodsColumns}
			title='Периоды'
			actionRow={{
				add: onAddComboPeriod,
				delete: onDeleteComboPeriod,
				edit: onEditComboPeriod
			}}
			detailPanel={{
				Component: DetailPeriod,
				autoClose: true
			}}
			swap={orderingPeriods}
		/>
	);
});

const defaultColumns: IColumn[] = [
	{
		editable: false,
		field: 'id',
		name: 'id',
		copy: true,
		align: 'center',
		width: 30
	},
	{
		type: 'string',
		field: 'name',
		name: 'Название'
	},
	{
		type: 'date',
		field: 'begins',
		name: 'Начало'
	},
	{
		type: 'date',
		field: 'ends',
		name: 'Конец'
	},
	{
		type: 'string',
		field: 'price',
		name: 'Цена',
		align: 'center',
		width: 120
	}
];
