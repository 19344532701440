import React, { ReactNode, useState } from 'react';
import { Input } from '@components';
import { Checkbox, IconButton } from '@material-ui/core';
import { SelectIcon } from '../SelectIcon/SelectIcon';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import MovingIcon from '@mui/icons-material/Moving';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface AddConsistFormProps {
	onDelete?: (id: string) => void;
	onAdd?: (consist: IConsistInfo) => void;
	consist?: IConsistInfo;
	onChangeOrder?: (id: string, newOrder: number, prevOrder: number) => void;
	onEdit?: (id: string, consist: IConsistInfo) => void;
	length?: number;
	index?: number;
}

export interface IConsistInfo {
	id: string;
	iconName: string;
	title: string;
	order: number;
	disabled: boolean;
}

export const AddConsistForm = (props: AddConsistFormProps) => {
	const { onAdd, onDelete, consist, onChangeOrder, length, onEdit, index = 0 } = props;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [iconName, setIconName] = useState<string>(consist?.iconName || 'default');
	const [optionText, setOptionText] = React.useState<string>(consist?.title || '');
	const [isActive, setIsActive] = useState<boolean>(!consist?.disabled);
	const [error, setError] = useState('');

	const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
		setOptionText(e.target.value);
		if (onEdit && consist) {
			onEdit(consist.id, { ...consist, title: e.target.value });
		}
	};

	const onChangeIcon = (key: string) => {
		if (onEdit && consist) {
			onEdit(consist.id, { ...consist, iconName: key });
		}
		setIconName(key);
		setAnchorEl(null);
	};

	const addConsist = () => {
		if (!optionText) {
			setError('Поле не может быть пустым');
			return;
		}

		if (iconName === 'default') {
			setError('Выберите иконку');
			return;
		}

		setError('');
		if (onAdd) {
			onAdd({
				id: Date.now().toString(),
				iconName,
				title: optionText,
				order: length || 0,
				disabled: !isActive
			});
		}
		setOptionText('');
		setIconName('default');
		setAnchorEl(null);
	};

	const onDeleteConsist = () => {
		if (onDelete && consist) {
			onDelete(consist.id);
		}
	};

	const handleChangeOrderPrev = () => {
		if (onChangeOrder && consist) {
			onChangeOrder(consist.id, index - 1, index);
		}
	};

	const handleChangeOrderNext = () => {
		if (onChangeOrder && consist) {
			onChangeOrder(consist.id, index + 1, 	index);
		}
	};

	const handleChangeDisabled = (_e, checked: boolean) => {
		if (onEdit && consist) {
			onEdit(consist.id, { ...consist, disabled: !checked });
		}
		setIsActive(checked);
	};

	return (
		<div className='tariff__consist'>
			{onChangeOrder &&
			<div className='tariff__consist-ordering'>
				<IconButton size='small' onClick={handleChangeOrderPrev} disabled={index === 0}>
					<KeyboardArrowUpIcon fontSize='small'/>
				</IconButton>
				<hr />
				<IconButton size='small' onClick={handleChangeOrderNext} disabled={index === (length || 0) - 1}>
					<KeyboardArrowDownIcon fontSize='small'/>
				</IconButton>
			</div>
			}
			<IconButton onClick={openPopover} className='theme-text'>
				{advantageIcons[iconName]}
			</IconButton>
			<Input
				className='tariff__consist-input'
				label='опция тарифа'
				value={optionText}
				onChange={handleChangeText}
				error={!!error}
				helperText={error}
			/>
			{onAdd &&
				<IconButton onClick={addConsist}>
					<DoneIcon fontSize='large' color='success'/>
				</IconButton>
			}
			{onDelete &&
			<IconButton onClick={onDeleteConsist}>
				<CloseIcon fontSize='large' color='error'/>
			</IconButton>
			}
			<SelectIcon
				anchorEl={anchorEl}
				onSelect={onChangeIcon}
				icons={advantageIcons}
			/>
			{onDelete &&
				<Checkbox
					style={{ padding: '15px' }}
					checked={isActive}
					onChange={handleChangeDisabled}
				/>
			}
		</div>
	);
};

export const advantageIcons: Record<string, ReactNode> = {
	default: <AddReactionIcon fontSize='large' />,
	arrow: <MovingIcon fontSize='large'/>,
	bell: <NotificationsActiveOutlinedIcon fontSize='large'/>,
	copy: <ContentPasteIcon fontSize='large'/>,
	doc: <HistoryEduIcon fontSize='large'/>,
	folder: <FolderOpenIcon fontSize='large'/>,
	hat: <SchoolOutlinedIcon fontSize='large'/>,
	like: <ThumbUpOffAltIcon fontSize='large'/>,
	materials: <CastForEducationOutlinedIcon fontSize='large'/>,
	note: <EditNoteIcon fontSize='large'/>,
	notebook: <MenuBookIcon fontSize='large'/>,
	smile: <SentimentSatisfiedAltIcon fontSize='large'/>,
	checklist: <PlaylistAddCheckIcon fontSize='large'/>,
	star: <StarBorderIcon fontSize='large'/>,
	video: <VideocamOutlinedIcon fontSize='large'/>
};
