import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from '@components';
import { adminPromoCodeStore } from '@stores';
import { PromoStatisticsDetailPanel } from './blocks';

const promoStatisticsColumns = [
	{ name: 'Название', field: 'promoCode' },
	{ name: 'Количество покупок', field: 'purchasesCount' },
	{ name: 'Количество курсов в покупке', field: 'purchasedCoursesCount' },
	{ name: 'Сумма', field: 'sum' }
];

export default observer(() => {
	useEffect(() => {
		(async () => {
			await adminPromoCodeStore.getPromoCodesStatistics();
		})();
	}, []);

	const onOpenDetailPanel = async (data) => {
		await adminPromoCodeStore.getPromoCodeStatisticsById(data.promoCode);
	};

	return (
		<div>
			<Table
				title='Аналитика промокодов'
				columns={promoStatisticsColumns}
				data={adminPromoCodeStore.promoCodesStatistics}
				detailPanel={{
					Component: PromoStatisticsDetailPanel,
					action: (id, data) => onOpenDetailPanel(data),
					autoClose: true
				}}
			/>
		</div>
	);
});
