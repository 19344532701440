import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components';
import { BookIcon } from 'lomonosov-icons';
import { IGetManyUserDailyQuestsItem, UserDailyStatus, DailyCategory } from '@lomonosov/types/dist';
import { userCourseStore, userGamificationStore, userHomeworkStore, userStore } from '@stores';
import './style.css';

export default observer((props: { tasks: IGetManyUserDailyQuestsItem[] }) => {
	const { tasks } = props;
	const navigate = useNavigate();
	const [taskInfo, setTaskInfo] = useState<any>(null);

	useEffect(() => {
		const lessonTask = tasks.find(task => (task.daily.category === DailyCategory.lesson && task?.daily?.condition));
		const homeworkTask = tasks.find(task => (task.daily.category === DailyCategory.homework && task?.daily?.condition));
		if (lessonTask) {
			userCourseStore.getCurrentLesson(lessonTask.daily.condition, () => {}, 'gamification').then(() =>
				setTaskInfo({ [lessonTask.id]: { name: userCourseStore.currentLesson.name, subject: userCourseStore.currentLesson.disciplineName } })
			);
		}
		if (homeworkTask) {
			userHomeworkStore.getHomeworkById(homeworkTask.daily.condition).then(() =>
				setTaskInfo({ [homeworkTask.id]: { name: userHomeworkStore.homework?.template?.name, subject: userHomeworkStore.homework?.template?.discipline?.name } })
			);
		}
	}, [navigate]);

	const handleCompleteTask = (task: IGetManyUserDailyQuestsItem) => {
		if (task.status === 'open') {
			switch (task.daily.category) {
			case 'gamification':
				changeGamificationPage(task);
				break;
			case 'homework':
				if (task.daily.condition) {
					userHomeworkStore.getHomeworkById(task.daily.condition);
					navigate(`/homework/${task.daily.condition}`);
				} else {
					navigate('/homeworks');
				}
				break;
			case 'lesson':
				if (task.daily.condition) {
					userCourseStore.getCurrentLesson(task.daily.condition);
					navigate(`/lesson/${task.daily.condition}`);
				} else {
					navigate('/study-program');
				}
				break;
			case 'purchase':
				navigate('/trial-activate');
				break;
			case 'user':
				navigate('/personal-info');
				break;
			}
		} else {
			userGamificationStore.updateStatusDailyQuests(userStore.user.id, task?.daily?.id);
		}
	};

	const handleClickItem = (item) => {
		if (userGamificationStore.shopItem.type !== item.type) {
			userGamificationStore.changeShopItem(item);
			userGamificationStore.changeIsShowShopMenu(!(item.type === 'house' || item.type === 'car'));
			userGamificationStore.changePlayerPosition('left');
		} else {
			userGamificationStore.changeShopItem({ type: '', title: '' });
			userGamificationStore.changeIsShowShopMenu(false);
			userGamificationStore.changePlayerPosition();
		}
	};

	const changeGamificationPage = (task: IGetManyUserDailyQuestsItem) => {
		switch (task.daily.subcategory) {
		case 'car':
			handleClickItem({ type: 'car' });
			break;
		case 'head':
			handleClickItem({ type: 'head' });
			break;
		case 'body':
			handleClickItem({ type: 'body' });
			break;
		default:
			handleClickItem({ type: 'house' });
			userGamificationStore.setHouseItemType(task.daily.subcategory);
			break;
		}
		userGamificationStore.changeIsShowShopMenu(true);
		userGamificationStore.changeDaily(task);
		window.scrollTo(0, 0);
	};

	return (
		<div className='gamification-tasks'>
			<h1 className='gamification-tasks__title'>Задания:</h1>
			<div className='gamification-tasks__cards'>
				{tasks.map((task: IGetManyUserDailyQuestsItem) =>
					<div key={task.id} className='gamification-tasks__cards-item  gamification-tasks__card theme-block-background'>
						<p className='gamification-tasks__card-title'>
							{task?.daily?.text} {taskInfo?.[task.id] && `(${taskInfo[task.id]?.name})`}
						</p>
						<p className='gamification-tasks__card-description'>
							{taskInfo?.[task.id] &&
								<>
									<span className='portal-book'><BookIcon/></span>
									{taskInfo?.[task.id]?.subject}
								</>
							}
						</p>
						<div className='gamification-tasks__card-reward'>
							<div>+20 <span className='dollars'>$</span></div>
							<div>+20 <span className='exp'>XP</span></div>
							<div className='gamification-tasks__button'>
								<Button type={task.status === 'reward' ? 'main_green' : 'main_blue'} onClick={() => handleCompleteTask(task)} disable={task.status === UserDailyStatus.complete}>
									{task.status === UserDailyStatus.open && 'Выполнить'}
									{task.status === UserDailyStatus.reward && 'Заберите награду'}
									{task.status === UserDailyStatus.complete && 'Выполнено'}
								</Button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
});
