import React from 'react';
import './style.css';

interface IInvoiceStatistic {
  statistic: {
		red: number,
		orange: number,
		yellow: number,
		green: number
	}
}

export default (props: IInvoiceStatistic) => {
	const { statistic } = props;
	return (
		<div className='invoice-statistic'>
			<div className='invoice-statistic__block'>
				<div className='invoice-statistic__circle red'/>
				<span className='invoice-statistic__count'>
					{statistic.red}
				</span>
				<p className='invoice-statistic__text'>
					Счет без покупателя
				</p>
			</div>
			<div className='invoice-statistic__block'>
				<div className='invoice-statistic__circle orange'/>
				<span className='invoice-statistic__count'>
					{statistic.orange}
				</span>
				<p className='invoice-statistic__text'>
					Активирован покупателем
				</p>
			</div>
			<div className='invoice-statistic__block'>
				<div className='invoice-statistic__circle yellow'/>
				<span className='invoice-statistic__count'>
					{statistic.yellow}
				</span>
				<p className='invoice-statistic__text'>
					Покупатель перешел на форму оплаты
				</p>
			</div>
			<div className='invoice-statistic__block'>
				<div className='invoice-statistic__circle green'/>
				<span className='invoice-statistic__count'>
					{statistic.green}
				</span>
				<p className='invoice-statistic__text'>
					Покупатель оплатил
				</p>
			</div>
		</div>
	);
};
