import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { userCourseStore, userStore } from '@stores';
import { CoursesList, CoursesMenu } from './study-program-blocks';

import './style.css';

export default observer(() => {
	useEffect(() => {
		if (!userCourseStore.courses?.length && userStore.user.id) {
			userCourseStore.getCourses();
		}
	}, [userStore.user.id]);

	return (
		<div className='study-program'>
			<div className='study-program__header'>
				<h1>Мои курсы</h1>
				<CoursesMenu/>
			</div>
			<CoursesList />
		</div>
	);
});
