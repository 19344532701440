import React from 'react';
import { getDaysUntilDeadline, convertMoscowToLocal } from '@helpers';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import './style.css';
import { InfoCard } from '@components';

interface IHomeworkCardDeadline {
	ends: Date
}

export default (props: IHomeworkCardDeadline) => {
	const { ends } = props;

	const calculateTimeLeft = () => {
		const days = getDaysUntilDeadline(new Date(ends), new Date());
		if (days === 0) {
			return 'Дедлайн истек';
		}
		return 'осталось ' + convertMoscowToLocal().to(ends);
	};

	const getDate = () => {
		const date = new Date(ends);
		const day = date.getDate();
		return `${day} ${months[date.getMonth()]}`;
	};

	const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

	return (
		<InfoCard
			icon={<CalendarMonthOutlinedIcon fontSize='small'/>}
			title='Дедлайн'
			theme='light-lemon'
			className='homework-card-deadline'
			classes={{
				container: 'homework-card-deadline__container'
			}}
		>
			<span className='homework-card-deadline__text'>
				<strong>{getDate()}</strong>
				<br/>
	 			{calculateTimeLeft()}
			</span>
		</InfoCard>
	);
};
