import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { Badge } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import ru from 'dayjs/locale/ru';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { IGetCalendarTableItem, userCalendarStore } from '@stores';
import { useWindowDimensions } from '@helpers/hooks';
import { getTimeWithTZ } from '@helpers/functions/get-time-with-tz';
import {
	ClassCalendarDesktop,
	ClassCalendarHeader
} from './class-calendar-components';

import './style.css';

dayjs.locale(ru);

dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Урок становится доступным за 12 часов до начала
 */
const isDateInTheFuture = (a: Date) => {
	const nowInMoscowTime = getTimeWithTZ(new Date(), 'Europe/Moscow');
	const diff = dayjs(a).diff(dayjs(nowInMoscowTime), 'hours');

	return diff >= 12;
};

const oneDayInMS = 1000 * 60 * 60 * 24;

const getDayOfCurrentWeek = (offset, startDate?: Date) => {
	const now = startDate ?? new Date();

	return new Date(
		now.getFullYear(),
		now.getMonth(),
		now.getDate() - now.getDay() + offset
	);
};
const getDay = (date) => {
	const currentDayIndex = new Date(date).getDay();
	return currentDayIndex === 0 ? 6 : currentDayIndex - 1;
};

const getCalendarItemBadge = (item) => {
	if (item.type === 'homework' || item.type === 'probe') {
		const daysLeft = dayjs(item.ends).diff(new Date(), 'day');

		if (daysLeft >= 0 && daysLeft <= 1) {
			return '🔥';
		} else if (daysLeft < 0) {
			return '💀';
		}
	} else if (item.type === 'lesson') {
		if (item.color === '#DCDEFF') {
			return '⭐';
		}
	}

	return null;
};

const createCalendarItem = (item: IGetCalendarTableItem) => {
	const isInFuture = isDateInTheFuture(new Date(item.date));
	const tooltipTitle = isInFuture ? 'Пока не доступен' : item.tooltipText;
	const calendarItemBadge = getCalendarItemBadge(item);

	const content = (
		<Badge className='table-body-subject' badgeContent={calendarItemBadge}>
			<div
				style={{
					backgroundColor: isInFuture ? '#DCDEFF' : item.color,
					color: isInFuture ? '#342B48' : (item.color === '#FF3364' || item.color === '#8417FF' ? '#FFFFFF' : '#342B48')
				}}
			>
				{item.name}
			</div>
		</Badge>
	);

	return (
		<Tooltip key={`tableItem-${item.id}`} title={tooltipTitle} placement='right' arrow TransitionComponent={Zoom}>
			{isInFuture
				? (
					content
				)
				: (
					<Link className='table-body-subject-link' to={`/${item.link}/${item.id}`}>
						{content}
					</Link>
				)}
		</Tooltip>
	);
};

export default observer(() => {
	const dateNow = new Date();
	const mondayOfCurrentWeek = getDayOfCurrentWeek(1);
	const sundayOfCurrentWeek = getDayOfCurrentWeek(7);

	const [startDate, setStartDate] = useState(mondayOfCurrentWeek);
	const [endDate, setEndDate] = useState(sundayOfCurrentWeek);

	const [schedule, setSchedule] = useState({});
	const [dayOffset, setDayOffset] = useState(7);

	const [weekNumber, setWeekNumber] = useState(dayjs(startDate).week());

	const { width } = useWindowDimensions();

	useEffect(() => {
		if (width > 768) {
			setDayOffset(7);
		} else if (width > 480 && width <= 768) {
			setDayOffset(3);
		} else if (width <= 480) {
			setDayOffset(1);
		}
	}, [width]);

	const getDatePeriodByOffset = () => {
		let newStartDate: Date | null = null;
		let newEndDate: Date | null = null;

		// Во избежании проблем с временным сдвигом запрашиваем пару дней до и после
		if (dayOffset === 1 || dayOffset === 3) {
			newStartDate = new Date(dateNow.getTime() - oneDayInMS);
			newEndDate = new Date(newStartDate.getTime() + oneDayInMS * (dayOffset === 1 ? (dayOffset + 1) : dayOffset));
		} else {
			newStartDate = getDayOfCurrentWeek(1, startDate);
			newEndDate = getDayOfCurrentWeek(7, newStartDate);
		}

		setStartDate(newStartDate);
		setEndDate(newEndDate);

		return { newStartDate, newEndDate };
	};

	useEffect(() => {
		const calendarData = userCalendarStore.getFilteredCalendarData();
		buildSchedule(calendarData);
	}, [userCalendarStore.filters.length]);

	useEffect(() => {
		(async () => {
			const { newStartDate, newEndDate } = getDatePeriodByOffset();

			const week = dayjs(newStartDate).week();
			setWeekNumber(week);

			await userCalendarStore.getCalendarData(newStartDate, newEndDate);
			buildSchedule();
		})();
	}, [dayOffset]);

	const changeWeek = async (type: string) => {
		const newStartDate = new Date(startDate.getTime() + oneDayInMS * (type === 'previous' ? -dayOffset : dayOffset));
		const newEndDate = new Date(newStartDate.getTime() + oneDayInMS * Math.abs(dayOffset - 1));

		setStartDate(newStartDate);
		setEndDate(newEndDate);

		const week = dayjs(newStartDate).week();
		setWeekNumber(week);

		await userCalendarStore.getCalendarData(newStartDate, newEndDate);
		buildSchedule();
	};

	const buildSchedule = (calendarItems?: IGetCalendarTableItem[]) => {
		const schedule = {};
		const items = calendarItems || userCalendarStore.items || [];

		items.forEach(item => {
			/**
			 * На текущий момент все даты с сервера приходят без временного сдвига
			 * Принято считать, что все даты с сервера приходят с Москвсковским часовым поясом
			 */

			/**
			 * Даты хранятся в GMT-0, но при этом поставляются, как Московское время (GMT+3)
			 */
			const utc = String(item.date).slice(0, 16).split('T').join(' ');
			const converted = dayjs
				.utc(utc)
				.tz('Europe/Moscow')
				.subtract(
					dayjs.duration({ hours: 3 })
				)
				.toDate();

			const localeDate = getTimeWithTZ(converted);

			const weekKey = dayjs(localeDate).week();
			const dayKey = getDay(localeDate);
			const hourKey = localeDate.getHours();

			const isObjectScheduleWeek = typeof schedule[weekKey] === 'object';
			if (!isObjectScheduleWeek) {
				schedule[weekKey] = {};
			}

			const isObjectScheduleDay = typeof schedule[weekKey][dayKey] === 'object';
			if (!isObjectScheduleDay) {
				schedule[weekKey][dayKey] = {};
			}

			const isArrayScheduleDayHour = Array.isArray(schedule[weekKey][dayKey][hourKey]);
			if (!isArrayScheduleDayHour) {
				schedule[weekKey][dayKey][hourKey] = [];
			}

			const newCalendarItem = createCalendarItem(item);
			schedule[weekKey][dayKey][hourKey].push(newCalendarItem);
		});

		setSchedule(schedule);
	};

	return (
		<div className='class-calendar'>
			<ClassCalendarHeader dayOffset={dayOffset} changeWeek={changeWeek} />
			<ClassCalendarDesktop
				now={dateNow}
				start={startDate}
				end={endDate}
				schedule={schedule}
				weekNumber={weekNumber}
				isLoad={userCalendarStore.calendarLoad}
				dayOffset={dayOffset}
			/>
		</div>
	);
});
