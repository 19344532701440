import { makeAutoObservable, runInAction } from 'mobx';
import {
	HomeworkAnswerStatus,
	IGetAllHomeworkAnswersResponse,
	IGetAllHomeworkAnswersItemResponse,
	IGetAnswersStatisticResponse,
	IUpdateAnswerDto
} from '@lomonosov/types/dist';
import { IAdminHomeworkAnswer } from '@interfaces';
import { AdminHomeworkAnswersService, AdminHomeworkService, IGetHomeworkAnswersQueryParams } from '@services';

class AdminHomeworkAnswersStore {
	homeworkAnswers: IGetAllHomeworkAnswersItemResponse[] = [];
	homeworkAnswer: IAdminHomeworkAnswer | null = null;
	homeworkAnswersAnalysis: IGetAnswersStatisticResponse | { max: null, min: null, avg: null } = { max: null, min: null, avg: null }
	loader: boolean = false;
	isShowVerifiedModal: boolean = false;
	pagination = { pages: 1, currentPage: 1 };
	paginationControl = { currentPage: 0, rows: 10, totalCount: 0 }
	status = {
		main: HomeworkAnswerStatus.VERIFICATION,
		archive: HomeworkAnswerStatus.VERIFIED
	};

	directions: IGetAllHomeworkAnswersResponse['directions'] = [];
	disciplines: IGetAllHomeworkAnswersResponse['disciplines'] = [];
	queryParams: IGetHomeworkAnswersQueryParams | null = null;
	filterFields: Record<keyof IGetHomeworkAnswersQueryParams, any> = defaultFilterFields;
	isLoadingFilters: boolean = false;

	constructor () {
		makeAutoObservable(this);
	}

	/// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/** ЗАПРОСЫ **/

	/**
	 * Получение всех ДЗ на проверке
	 * @param take количество получаемых записей
	 * @param skip количество записей пропустить
	 **/
	async getAllHomeworkAnswers (take: number = 8, skip: number = 0) {
		try {
			this.setLoader(true);
			const { data } =
				await AdminHomeworkAnswersService.getAllHomeworkAnswers(
					take, skip, this.queryParams
				);
			this.homeworkAnswers = data.answers;
			this.pagination.pages = Math.ceil(data.count / take);
			this.paginationControl.totalCount = data.count;
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoader(false);
		}
	}

	async getHomeworkAnswerAnalysis (take: number = 8, skip: number = 0) {
		try {
			this.setLoader(true);
			const [resAnalysis, resAnswers] = await Promise.all([AdminHomeworkAnswersService.getHomeworkAnswersAnalysis(this.queryParams), AdminHomeworkAnswersService.getAllHomeworkAnswers(take, skip, this.queryParams)]);
			runInAction(() => {
				this.homeworkAnswersAnalysis = resAnalysis.data;
				this.homeworkAnswers = resAnswers.data.answers;
				this.pagination.pages = Math.ceil(resAnswers.data.count / take);
				this.paginationControl.totalCount = resAnswers.data.count;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoader(false);
		}
	}

	async getHomeworkAnswerById (answerId: string) {
		try {
			this.setLoader(true);
			const { data } = await AdminHomeworkAnswersService.getHomeworkAnswerById(answerId);
			runInAction(() => {
				this.homeworkAnswer = data;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoader(false);
		}
	}

	async updateAnswer (answerId: string, data: Partial<IUpdateAnswerDto>) {
		try {
			await AdminHomeworkService.updateAnswer(
				answerId,
				data
			);
			this.isShowVerifiedModal = true;
		} catch (e) {
			console.log(e);
		}
	}

	/**
	 * Удаление ответа на ДЗ
	 * @param answerId id удаляемого ответа
	 **/
	async deleteHomeworkAnswerById (answerId: string) {
		try {
			this.setLoader(true);
			await AdminHomeworkAnswersService.deleteHomeworkAnswerById(answerId);
			runInAction(() => {
				this.homeworkAnswers = this.homeworkAnswers.filter(answer => answer.id !== answerId);
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoader(false);
		}
	}

	async getFilters () {
		try {
			this.isLoadingFilters = true;
			const { data } = await AdminHomeworkAnswersService.getFilters();
			runInAction(() => {
				this.directions = data.directions;
				this.disciplines = data.disciplines;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoadingFilters = false;
		}
	}

	/// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/** Различные действия **/

	changeVerifiedModal (bool: boolean) {
		this.isShowVerifiedModal = bool;
	}
	/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/** ПАГИНАЦИЯ, ФИЛЬТРАЦИЯ, ЛОАДЕР **/

	async changePage (page: number, take: number) {
		this.pagination.currentPage = page;
		await this.getAllHomeworkAnswers(take, (page - 1) * take);
	}

	async changeTablePage (page: number, take: number) {
		this.paginationControl.currentPage = page;
		await this.getAllHomeworkAnswers(take, page * take);
	}

	async changeTableRows (take, page) {
		this.paginationControl.rows = take;
		await this.getAllHomeworkAnswers(take, page * take);
	}

	changeFilterField (newValue, field: keyof IGetHomeworkAnswersQueryParams) {
		this.filterFields[field] = newValue;
		this.queryParams = {
			...this.queryParams,
			[field]: newValue?.id || newValue
		};
	}

	resetFilterFields (take = 8) {
		this.pagination.currentPage = 1;
		this.filterFields = defaultFilterFields;
		this.getAllHomeworkAnswers(take, 0);
		this.getHomeworkAnswerAnalysis();
	}

	setLoader (state: boolean) {
		this.loader = state;
	}
}

const defaultFilterFields: Record<keyof IGetHomeworkAnswersQueryParams, any> = {
	direction: null,
	discipline: null,
	type: null,
	search: '',
	start: '',
	end: '',
	course: null,
	mentor: null,
	status: HomeworkAnswerStatus.VERIFICATION
};

export const adminHomeworkAnswersStore = new AdminHomeworkAnswersStore();
