import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Table } from '@components';
import { IColumn } from '@components/table';
import { adminComboCoursesStore } from '@stores';
import { DetailCombo } from './ui/detail-combo';
import './style.css';
import { Switch } from '@material-ui/core';

const ComboCourses = observer(() => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	useEffect(() => {
		adminComboCoursesStore.getCombinations();
	}, []);

	const getDetails = async (comboId: string) => {
		await adminComboCoursesStore.getComboCourses(comboId);
		await adminComboCoursesStore.getTariffsCombo(comboId);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		adminComboCoursesStore.getCombinations(rowsPerPage, newPage * rowsPerPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		adminComboCoursesStore.getCombinations(parseInt(event.target.value, 10), 0);
	};

	return (
		<div>
			<Table
				data={adminComboCoursesStore.combinations.combinations || []}
				columns={columns}
				actionRow={{
					add: newCombo => adminComboCoursesStore.createCombo(newCombo),
					edit: (id, newState) => adminComboCoursesStore.updateCombo(id, { ...newState, addingCourses: [], deletingCourses: [] }),
					delete: id => adminComboCoursesStore.deleteCombo(id)
				}}
				detailPanel={{
					Component: DetailCombo,
					autoClose: true,
					action: getDetails
				}}
				pagination={{
					count: adminComboCoursesStore.combinations.count,
					page: page,
					rowsPerPage: rowsPerPage,
					onPageChange: handleChangePage,
					onRowsPerPageChange: handleChangeRowsPerPage
				}}
			/>
		</div>
	);
});

const columns: IColumn[] = [
	{
		field: 'id',
		name: 'id',
		copy: true,
		align: 'center',
		width: 30
	},
	{
		field: 'name',
		name: 'Название'
	},
	{
		type: 'element',
		field: 'isActive',
		name: 'Активность',
		width: 120,
		align: 'center',
		element: (data, func) => (
			data.id &&
			<Switch
				checked={data.isActive}
				onChange={(e, checked) => {
					adminComboCoursesStore.onActiveCombo(data.id, !data.isActive);
					func?.(checked);
				}}
				inputProps={{ 'aria-label': 'controlled' }}
				color='primary'
			/>
		)
	}
];

export default ComboCourses;
