import { AxiosResponse } from 'axios';
import {
	ICreateSectionDto,
	ICreateSectionResponse,
	IDeleteCourseResponse,
	IDeleteSectionResponse,
	IGetAllSectionsByCourseResponse,
	IGetCoursesResponse,
	IUpdateSectionsDto,
	IUpdateSectionsResponse,
	IGetAllTariffsResponse,
	IDeleteTariffResponse,
	IUpdateTariffResponse,
	IUpdateTariffDto,
	ICreateCourseTariffsResponse
} from '@lomonosov/types';

import { $api } from '@helpers';
import { CreateCourseDto, CreateCourseResponse, ICurrentCourseResponse, IGetCoursesNewShopItem, IOrderingNewShopCourseDto, UpdateCourseDto, UpdateCourseResponse } from '@interfaces';

export default class {
	/* Курсы */
	static async getAllCourses (take: number = 150, search, isVisible?: boolean, isShop?: boolean): Promise<AxiosResponse<IGetCoursesResponse>> {
		return $api.get<IGetCoursesResponse>('/courses', { params: { take: take, skip: 0, search, isVisible, isShop } });
	}

	static async getCourses (
		take?: number,
		skip?: number,
		isVisible?: string,
		queryParams: string = '',
		search: string = ''
	): Promise<AxiosResponse<IGetCoursesResponse>> {
		return $api.get<IGetCoursesResponse>(
			`/courses?take=${take}&skip=${skip}&search=${search}${isVisible}${queryParams}`
		);
	}

	static async getCurrentCourse (courseId: string): Promise<AxiosResponse<ICurrentCourseResponse>> {
		return $api.get<ICurrentCourseResponse>(`/courses/${courseId}`);
	}

	static async createCourse (course: CreateCourseDto): Promise<AxiosResponse<CreateCourseResponse>> {
		return $api.post<CreateCourseResponse>('/courses', course);
	}

	static async updateCourse (
		courseId: string, newInfoCourse: UpdateCourseDto
	): Promise<AxiosResponse<UpdateCourseResponse>> {
		return $api.put<UpdateCourseResponse>(`/courses/${courseId}`, newInfoCourse);
	}

	static async deleteCourse (courseId: string): Promise<AxiosResponse<IDeleteCourseResponse>> {
		return $api.delete<IDeleteCourseResponse>(`/courses/${courseId}`);
	}

	/* Секции курса */
	static async getSections (courseId: string): Promise<AxiosResponse<IGetAllSectionsByCourseResponse>> {
		return $api.get<IGetAllSectionsByCourseResponse>(`/sections/courses/${courseId}`);
	}

	static async createSection (section: ICreateSectionDto): Promise<AxiosResponse<ICreateSectionResponse>> {
		return $api.post<ICreateSectionResponse>('/sections', section);
	}

	static async updateSections (
		newInfoSections: IUpdateSectionsDto
	): Promise<AxiosResponse<IUpdateSectionsResponse>> {
		return $api.put<IUpdateSectionsResponse>('/sections', newInfoSections);
	}

	static async deleteSection (sectionId: string): Promise<AxiosResponse<IDeleteSectionResponse>> {
		return $api.delete<IDeleteSectionResponse>(`/sections/${sectionId}`);
	}

	/* Изменить информацию о тарифе */
	static async updateCourseTariff (courseTariffId: string, tariff: IUpdateTariffDto): Promise<AxiosResponse<IUpdateTariffResponse>> {
		return $api.put<IUpdateTariffResponse>(`tariffs/${courseTariffId}`, tariff);
	}

	/* Синхронизация тарифов курса с тарифным планом */
	static async syncCourseTariffs (courseId: string, tariffPlanId: string): Promise<AxiosResponse<ICreateCourseTariffsResponse>> {
		return $api.post<ICreateCourseTariffsResponse>(`courses/${courseId}/tariff-plans/${tariffPlanId}`, null);
	}

	/* Получение тарифов курса */
	static async getTariffsOfCourse (
		courseId: string
	): Promise<AxiosResponse<IGetAllTariffsResponse>> {
		return $api.get<IGetAllTariffsResponse>(`tariffs/courses/${courseId}`);
	}

	/* Удаление тарифа курса */
	static async deleteTariff (tariffId: string): Promise<AxiosResponse<IDeleteTariffResponse>> {
		return $api.delete<IDeleteTariffResponse>(`/tariffs/${tariffId}`);
	}

	/* Получение курсов и комбо нового магазина */
	static async getCoursesNewShop (): Promise<AxiosResponse<IGetCoursesNewShopItem[]>> {
		return $api.get<IGetCoursesNewShopItem[]>('/shop/admin');
	}

	static async orderingNewShopCourses (array: IOrderingNewShopCourseDto[]): Promise<AxiosResponse<boolean>> {
		return $api.put<boolean>('/shop/ordering', { items: array || [] });
	}
}
