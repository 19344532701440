import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from '@material-ui/core';

import { Button, DateInput, Input, Select } from '@components';
import { adminBannerStore } from '@stores';
import { replaceFormatDateTime } from '@helpers';
import { IGetBannerByIdResponse } from '@lomonosov/types/dist';

import './style.css';

interface AddBannerUsersProps {
	dataL: IGetBannerByIdResponse['portal'],
}

export default observer((props: AddBannerUsersProps) => {
	const { dataL } = props;

	const [info, setInfo] = useState(dataL);

	const saveUsers = async (userInfo) => {
		await adminBannerStore.updateBanner(userInfo.id, { ...userInfo, image: null });
	};

	const activateBanner = async () => {
		await saveUsers({ ...info, isActive: !info.isActive });
		setInfo({ ...info, isActive: !info.isActive });
	};

	const saveBanner = () => {
		setInfo({ ...info, isActive: true });
		if (info.link !== '') {
			saveUsers(info);
		} else {
			saveUsers({ ...info, link: null, textButton: undefined });
		}
	};

	return (
		<div className='settings-banner__details'>
			<div className='settings-banner__block'>
				<div className='banner-title'>
					Название баннера
				</div>
				<div className='settings-banner_field-form'>
					<Input
						label='Название баннера'
						onChange={(e) => setInfo({ ...info, name: e.target.value })}
						value={info.name} multiline rows={3}
					/>
				</div>
			</div>
			<div className='settings-banner__block'>
				<div className='banner-title'>
					Ключевые слова
				</div>
				<div className='settings-banner__support-blocks'>
					{keyWords.slice(0, info.type === 'portal' ? 2 : 4).map((word, index) =>
						<Tooltip key={index} title={word.tooltip} arrow>
							<div className='settings-banner_support-block'>
								{word.name}
							</div>
						</Tooltip>
					)}
				</div>
			</div>
			<div className='settings-banner__block'>
				<div className='banner-title'>
					До какого числа показывать? (необязательно)
				</div>
				<div className='settings-banner_text'>
					Необязательное поле, если его указать, то баннер будет выключен по истечении срока дедлайна.
				</div>
				<div className='settings-banner_field-form'>
					<DateInput
						label='Дедлайн'
						type='datetime-local'
						value={replaceFormatDateTime(info.deadline)}
						onChange={(e) =>
							setInfo({ ...info, deadline: new Date(e.target.value) || null })
						}
					/>
				</div>
			</div>
			<div className='settings-banner__block'>
				<div className='banner-title'>
					Куда отправить пользователей? (необязательно)
				</div>
				<div className='settings-banner_text'>
					Если указана ссылка, то будет отображаться кнопка в баннере.<br/>
					Введите ссылку или нажмите на один из шаблонов ниже.
				</div>
				<div className='settings-banner__support-blocks'>
					{linksTemplates.map((link, index) =>
						<div
							key={index} style={{ backgroundColor: link.color }}
							className='settings-banner_support-block'
							onClick={() => setInfo({ ...info, link: link.link })}
						>
							{link.name}
						</div>
					)}
				</div>
				<div className='settings-banner_field-form'>
					<Input
						label='Введите ссылку'
						onChange={(e) => setInfo({ ...info, link: e.target.value })}
						value={info.link}
					/>
				</div>
				{info.link &&
	        <div className='settings-banner_field-form'>
	          <Input
	            label='Надпись на кнопке'
	            onChange={(e) => setInfo({ ...info, textButton: e.target.value })}
	            value={info.textButton}
	          />
	        </div>
				}
			</div>
			<div className='settings-banner__block'>
				<div className='banner-title'>
					Кому отправить?
				</div>
				<div className='settings-banner_field-form'>
					<Select
						input={{ label: 'Пользователи' }}
						options={whichUsers} value={info.forAll ? whichUsers[0] : whichUsers[1]}
						onChange={(e, value) =>
							setInfo({ ...info, forAll: value.forAll, users: '' })
						}
					/>
				</div>
				{!info.forAll &&
					<div className='settings-banner_field-form'>
						<Input
							label='Введите vk id' multiline rows={10} value={info.users}
							onChange={(e) => setInfo({ ...info, users: e.target.value })}
						/>
					</div>
				}
			</div>
			<div className='settings-banner__buttons'>
				<div className='settings-banner_button'>
					<Button
						type={info.isActive ? 'main_orange' : 'main_blue'}
						onClick={() => activateBanner()}
					>
						{info.isActive ? 'Отключить баннер' : 'Активировать баннер'}
					</Button>
				</div>
				<div className='settings-banner_button'>
					<Button type='main_green' onClick={() => saveBanner()}>
						Сохранить
					</Button>
				</div>
			</div>
		</div>
	);
});

const whichUsers = [
	{
		id: 2,
		forAll: true,
		name: 'Всем пользователям'
	},
	{
		id: 1,
		forAll: false,
		name: 'Конкретным пользователям'
	}
];

const keyWords = [
	{
		name: 'name',
		tooltip: 'За вместо name подставится имя пользователя'
	},
	{
		name: 'day',
		tooltip: 'Если указан дедлайн, то вместо day будет отображаться количество дней до дедлайна'
	},
	{
		name: 'discipline',
		tooltip: 'Заменит слово discipline на предмет к которому относится курс в дательном падеже'
	},
	{
		name: 'month',
		tooltip: 'За вместе month выводит текущий месяц в именительном падеже'
	}
];

const linksTemplates = [
	{
		name: 'Тех поддержка',
		link: 'https://vk.me/lmnsvtech',
		color: '#3E335A'
	},
	{
		name: 'Учебный отдел',
		link: 'https://vk.me/lomonosov_school',
		color: '#624DE0'
	},
	{
		name: 'Магазин',
		link: '/shop',
		color: '#07C76E'
	}
];
