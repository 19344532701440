import { EditorConfig } from '@editorjs/editorjs/types/configs/editor-config';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Delimiter from '@editorjs/delimiter';
import Table from '@editorjs/table';
import Image from '../customTools/SimpleImage';
import CustomHyperlink from '../customTools/CustomHyperlink';
import { fileStore } from '@stores';
// import editorjsCodeflask from '@calumk/editorjs-codeflask';
// import Tooltip from 'editorjs-tooltip';

export const EDITOR_TOOLS: EditorConfig['tools'] = {
	header: {
		class: Header
	},
	list: {
		class: List,
		inlineToolbar: true
	},
	link: {
		class: CustomHyperlink,
		config: {
			shortcut: 'CMD+L',
			target: '_blank',
			rel: 'nofollow',
			availableTargets: ['_blank', '_self'],
			availableRels: ['author', 'noreferrer'],
			validate: false,
			icon: `<svg width="13px" height="13px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`
		}
	},
	delimiter: Delimiter,
	table: Table,
	image: {
		class: Image as any,
		config: {
			uploadFile: async (file, func) => await fileStore.uploadFile(file, func),
			deleteFile: (fileId) => fileStore.deleteFile(fileId, () => {})
		}
	}
	// code: {
	// 	class: editorjsCodeflask,
	// 	inlineToolbar: true
	// },
	// tooltip: {
	// 	class: Tooltip,
	// 	config: {
	// 		location: 'left',
	// 		highlightColor: '#FFEFD5',
	// 		underline: true,
	// 		backgroundColor: '#154360',
	// 		textColor: '#FDFEFE',
	// 		holder: 'editorjs'
	// 	}
	// }
};
