import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from '@material-ui/core';

import { Select, Input } from '@components';
import {
	adminCategoryStore,
	adminCoursesStore,
	adminDirectionStore,
	adminDisciplineStore
} from '@stores';

import './style.css';

export default observer(() => {
	const debouncedSearch = debounce(async () => {
		await adminCoursesStore.getCourses(20, 0);
	}, 1000);

	const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		adminCoursesStore.changeSearch(e.target.value);
		debouncedSearch();
	}, []);

	return (
		<div className='courses-bank-filtering'>
			<Input
				label='Поиск'
				value={adminCoursesStore.search}
				onChange={onSearchChange}
				className='courses-bank-filtering__field'
			/>
			<Select
				input= {{ label: 'Направление' }}
				options={adminDirectionStore.directions}
				value={adminCoursesStore.filterFields.direction}
				onChange={(e, value) =>
					adminCoursesStore.changeFilterField([value], ['direction'])
				}
				className='courses-bank-filtering__field'
			/>
			<Select
				input= {{ label: 'Категория' }}
				options={adminCategoryStore.categories}
				value={adminCoursesStore.filterFields.category}
				onChange={(e, value) =>
					adminCoursesStore.changeFilterField([value], ['category'])
				}
				className='courses-bank-filtering__field'
			/>
			<Select
				input= {{ label: 'Дисциплина' }}
				options={adminDisciplineStore.disciplines}
				value={adminCoursesStore.filterFields.discipline}
				onChange={(e, value) =>
					adminCoursesStore.changeFilterField([value], ['discipline'])
				}
				className='courses-bank-filtering__field'
			/>
		</div>
	);
});
