import React from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from '@mui/material/utils';

import { Table } from '@components';
import { IColumn } from '@components/table';
import { adminComboCoursesStore } from '@stores';
import { Switch } from '@material-ui/core';
import { DetailTariff } from '../detail-tariff';
import { IGetTariffsComboResponse, IUpdateOrderingDto } from '@interfaces';

interface TableComboTariffsProps {
	comboId: string;
}

export const TableComboTariffs = observer((props: TableComboTariffsProps) => {
	const { comboId } = props;

	const tariffsColumns: IColumn[] = [
		{
			field: 'id',
			name: 'id',
			copy: true,
			align: 'center',
			width: 30
		},
		{
			field: 'name',
			name: 'Название'
		},
		{
			type: 'element',
			field: 'isActive',
			name: 'Активность',
			width: 120,
			align: 'center',
			element: (data, func) => (
				data.id &&
				<Switch
					disabled={!data.id}
					checked={data.isActive}
					color='primary'
					onChange={(_e, checked) => {
						adminComboCoursesStore.onActiveTariffCombo(data.id, !data.isActive);
						func?.(checked);
					}}
				/>
			)
		}
	];

	const onAddTariff = async (newTariff: { name: string }) => {
		await adminComboCoursesStore.createTariffCombo({
			name: newTariff.name,
			courseCombinationId: comboId,
			order: (adminComboCoursesStore.comboTariffs.length * 10) + 10 || 0
		});
	};

	const getDetailComboTariff = async (id: string) => {
		await adminComboCoursesStore.getComparisonTariffsCombo(id);
		await adminComboCoursesStore.getComboTariffPeriods(id);
	};

	const orderingTariffs = debounce(async (tariffs: IGetTariffsComboResponse[]) => {
		const orderingTariffs: IUpdateOrderingDto[] = tariffs.map((tariff, index) => ({
			id: tariff.id,
			order: index + 10
		}));
		await adminComboCoursesStore.orderingTariffCombo(orderingTariffs);
	}, 1000);

	return (
		<Table
			className='detail-combo__table'
			data={adminComboCoursesStore.comboTariffs}
			columns={tariffsColumns}
			title='Тарифы'
			actionRow={{
				add: onAddTariff,
				delete: async (id: string) => await adminComboCoursesStore.deleteTariffCombo(id),
				edit: async (id, tariff) => await adminComboCoursesStore.updateTariffCombo(id, tariff)
			}}
			detailPanel={{
				Component: DetailTariff,
				action: getDetailComboTariff,
				autoClose: true
			}}
			swap={orderingTariffs}
		/>
	);
});
