import React, { lazy } from 'react';

import { ShopLayout } from '../../layouts';

const NewShop = lazy(() => import('../../../pages/shop-pages/new-shop'));
const SuccessfulPayment = lazy(() => import('../../../pages/shop-pages/successful-payment'));

export default {
	path: '/',
	element: <ShopLayout/>,
	children: [
		{ path: '/shop', element: <NewShop/> },
		{ path: '/successful-payment', element: <SuccessfulPayment/> },
		{ path: '/trial-activate', element: <NewShop /> }
	]
};
