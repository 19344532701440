import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { userStore } from '@stores';
import { images } from '@helpers';
import { LinkProps } from '@material-ui/core';

interface IProps extends LinkProps {
	tooltip?: string
}

export default observer((props: IProps) => {
	const {
		tooltip,
		...otherProps
	} = props;

	return (
		<Tooltip
			enterDelay={500}
			leaveDelay={300}
			placement='right'
			title={tooltip}
		>
			<Link to={'personal-info'} {...otherProps}>
				<ListItem button className='DrawerMenuListItem'>
					<ListItemIcon className='DrawerMenuListItem__icon theme-text'>
						<img
							src={userStore.user.photo === '' || !userStore.user.photo
								? images.personalInfoAvatar
								: userStore.user.photo
							}
							alt='error'
						/>
					</ListItemIcon>
					<ListItemText
						className='DrawerMenuListItem__text theme-text'
						primary={`${userStore.user.firstName} ${userStore.user.secondName}`}
					/>
				</ListItem>
			</Link>
		</Tooltip>
	);
});
