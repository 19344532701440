import React, { useEffect, useState } from 'react';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import {
	Box,
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell, Tooltip
} from '@mui/material';
import TableRowItem from './components/tableRow';
import TableHeader from './components/tableHeader';
import PaginationFooter from './components/paginationFooter';
import AddRow from './components/addRow';
import { ITable } from './type';

import './style.css';
import classNames from 'classnames';

export default (props: ITable) => {
	const {
		data,
		columns,
		actionRow,
		detailPanel,
		pagination,
		title,
		actionHeader,
		search,
		draggable,
		customCellsRender,
		className = '',
		styleOption = {
			size: 'medium',
			classNameHeader: '',
			classNameRow: '',
			classNameFooter: '',
			container: ''
		},
		swap = false
	} = props;

	const [dataTemp, setDataTemp] = useState<any[]>([]);
	const [colSpan, setColSpan] = useState(1);
	const [searchLocal, setSearch] = useState('');
	const [activeDetailPanel, setActiveDetailPanel] = useState<string | null>(null);

	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const changeSearch = (newSearch: string) => {
		if (search && typeof search === 'object') {
			search?.action(newSearch);
		} else {
			setSearch(newSearch);
		}
	};

	const swapRow = (a, b) => {
		[dataTemp[a], dataTemp[b]] = [dataTemp[b], dataTemp[a]];
		const temp = [...dataTemp];
		setDataTemp(temp);
		if (typeof swap === 'function') { swap(temp); }
	};

	useEffect(() => {
		setDataTemp(data);
	}, [data]);

	const columnsCount = () => {
		let countTemp = 0;
		countTemp += columns.length;
		if (detailPanel) countTemp += 1;
		if (swap) countTemp += 1;
		if (actionRow?.delete || actionRow?.edit || actionRow?.custom) countTemp += 1;
		setColSpan(countTemp);
	};

	useEffect(() => {
		columnsCount();
	}, [columns]);

	const onSortEnd = ({ oldIndex, newIndex }: {oldIndex: number, newIndex: number}) => {
		setDataTemp(arrayMove(dataTemp, oldIndex, newIndex));
		if (typeof draggable === 'function') {
			draggable({ oldIndex, newIndex }, arrayMove(dataTemp, oldIndex, newIndex));
		}
	};

	return (
		<Box className={className}>
			<Paper className='theme-block-background'>
				{
					(title || actionHeader || search) &&
					<TableHeader
						title={title}
						actionHeader={actionHeader}
						search={search
							? {
								prop: typeof search === 'object' ? search?.prop : searchLocal,
								action: changeSearch
							}
							: undefined}
						className={styleOption.classNameHeader}
					/>
				}
				<TableContainer>
					<Table size={styleOption.size} classes={{
						root: classNames('table', styleOption.container)
					}}>
						<TableHead className={styleOption.classNameHeaderTable}>
							<TableRow>
								{draggable &&
									<TableCell>&nbsp;</TableCell>
								}
								{detailPanel && <TableCell width={styleOption.size === 'small' ? 34 : 40} align='center' />}
								{swap && <TableCell width={styleOption.size === 'small' ? 34 : 40} align='center' />}
								{columns.map(({ name, width, align = 'left', tooltip = '' }, index) =>
									<TableCell key={index} width={width || ''} align={align}>
										 <Tooltip title={tooltip} arrow>
											<span>{name}</span>
										</Tooltip>
									</TableCell>
								)}
								{customCellsRender?.map(({ name, width, align = 'left' }, index) =>
									<TableCell key={index} width={width || ''} align={align}>
										{name}
									</TableCell>
								)}
								{(actionRow?.delete || actionRow?.edit || actionRow?.custom) && <TableCell />}
							</TableRow>
						</TableHead>
						<TableBodySortable useDragHandle onSortEnd={onSortEnd}>
							{dataTemp.length === 0
								? <TableRow>
									<TableCell colSpan={colSpan}><div className='table__no-records theme-text'>Записей нет</div> </TableCell>
								</TableRow>
								: dataTemp.filter(row => columns.map(column => row[column.field] === null || row[column.field] === undefined
									? row[column.field]
									: row[column.field].toString().toLowerCase().includes(searchLocal.toLowerCase())).includes(true))
									.slice(typeof pagination === 'boolean' ? rowsPerPage * page : 0, typeof pagination === 'boolean' ? rowsPerPage * (page + 1) : dataTemp.length)
									.map((row, index) =>
										<TableRowItem
											disabled={!draggable}
											activeDetailPanel={[activeDetailPanel, setActiveDetailPanel]}
											key={row.id}
											columns={columns}
											colSpan={colSpan}
											data={row}
											detailPanel={detailPanel}
											actionRow={actionRow}
											customCellsRender={customCellsRender}
											styleOption={styleOption}
											swap={swap}
											swapRow={swapRow}
											count={dataTemp.length}
											index={index}
											draggable={!!draggable}
											order={index}
										/>
									)
							}
							{actionRow?.add &&
								<AddRow
									activeDetailPanel={[activeDetailPanel, setActiveDetailPanel]}
									colSpan={colSpan}
									action={actionRow?.add}
									columns={columns}
									detailPanel={detailPanel}
									styleOption={styleOption}
									swap={swap}
								/>
							}
						</TableBodySortable>
						{pagination &&
							<PaginationFooter
								pagination={
									typeof pagination === 'object'
										? pagination
										: {
											count: dataTemp.length,
											page: page,
											rowsPerPage: rowsPerPage,
											onPageChange: handleChangePage,
											onRowsPerPageChange: handleChangeRowsPerPage
										}
								}
								className={styleOption.classNameFooter}
							/>}
					</Table>
				</TableContainer>
			</Paper>
		</Box >
	);
};

// Universal component for turning a TableBody into a sortable container
const TableBodySortable = SortableContainer(({ children }) => (
	<TableBody>
		{children}
	</TableBody >
));

export type {
	ITable,
	IColumn,
	IActionHeader,
	IActionRow,
	ISearch,
	IDetailPanel,
	ICustomCellRender
} from './type';
