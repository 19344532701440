import React, { useMemo } from 'react';
import { constants, images } from '@helpers';
import { GamificationSex } from '@lomonosov/types';
import './style.css';

interface IPlayer {
  head: string,
  body: string,
  position?: 'left' | 'center' | 'right',
  gender: GamificationSex | undefined,
	name?: string
}

export default (props: IPlayer) => {
	const { head, body, position = 'right', gender = GamificationSex.man, name } = props;

	const defaultBody = useMemo(() => ({
		woman: images.defaultGirl,
		man: images.defaultBoy
	}), [gender]);

	const defaultHead = useMemo(() => ({
		woman: images.headWoman,
		man: images.headMan
	}), [gender]);

	return (
		<div className={`gamification-player gamification-player_${position}`}>
			{name &&
				<p className='gamification-player__name'>{name}</p>
			}
			<img
				className='gamification-player__head'
				src={head ? `${constants.URL}/files/${head}` : defaultHead[gender]} alt='head'
			/>
			<div className='gamification-player__body'>
				<video
					poster={defaultBody[gender]}
					muted autoPlay loop
					src={body ? `${constants.URL}/files/${body}` : ''}
				/>
			</div>
		</div>
	);
};
