import { $api } from '@helpers';
import {
	EventFolderType,
	ICreateKinescopeEventDto, ICreateKinescopeEventResponse,
	IGetCurrentLesson,
	IGetKinescopeParentFolderResponse,
	IUpdateKinescopeEventDto,
	IUpdateLesson
} from '@interfaces';
import {
	ICreateLessonDto,
	ICreateLessonResponse,
	IDeleteLessonResponse,
	IUpdateLessonDto
} from '@lomonosov/types';
import { AxiosResponse } from 'axios';

export default class {
	static async createLesson (lesson: ICreateLessonDto): Promise<AxiosResponse<ICreateLessonResponse>> {
		return $api.post<ICreateLessonResponse>('/lessons', lesson);
	}

	// static async getLessons (id: string): Promise<AxiosResponse<IGetLessonByIdResponse>> {
	// 	return $api.get<IGetLessonByIdResponse>('/lessons');
	// }

	static async getLessonById (id: string): Promise<AxiosResponse<IGetCurrentLesson>> {
		return $api.get<IGetCurrentLesson>(`/lessons/${id}`);
	}

	static async updateLesson (id: string, lesson: IUpdateLessonDto): Promise<AxiosResponse<IUpdateLesson>> {
		return $api.put<IUpdateLesson>(`/lessons/${id}`, lesson);
	}

	static async updateLessonsOrder (lessons): Promise<AxiosResponse<void>> {
		return $api.put<void>('/lessons/order', lessons);
	}

	static async deleteLesson (id: string): Promise<AxiosResponse<IDeleteLessonResponse>> {
		return $api.delete<IDeleteLessonResponse>(`/lessons/${id}`);
	}

	// //////// KINESCOPE ////////////////////////////////////////////////////////////

	static async getKinescopeParentFolder (disciplineName: string, directionName: string, type: EventFolderType): Promise<AxiosResponse<IGetKinescopeParentFolderResponse>> {
		return $api.get<IGetKinescopeParentFolderResponse>('/kinescope/parent-folder', {
			params: { names: `${directionName}, ${disciplineName}`, type }
		});
	}

	static async getKinescopeEvent (eventId: string): Promise<AxiosResponse<ICreateKinescopeEventResponse>> {
		return $api.get<ICreateKinescopeEventResponse>(`/kinescope/event/${eventId}`);
	}

	static async createKinescopeEvent (data: ICreateKinescopeEventDto): Promise<AxiosResponse<ICreateKinescopeEventResponse>> {
		return $api.post<ICreateKinescopeEventResponse>('/kinescope/event', data);
	}

	static async updateKinescopeEvent (eventId: string, data: IUpdateKinescopeEventDto): Promise<AxiosResponse<ICreateKinescopeEventResponse>> {
		return $api.put<ICreateKinescopeEventResponse>(`/kinescope/event/${eventId}`, data);
	}

	static async startStreamKinescope (eventId: string): Promise<AxiosResponse<any>> {
		return $api.put(`/kinescope/event/${eventId}/enable`);
	}

	static async stopStreamKinescope (eventId: string): Promise<AxiosResponse<void>> {
		return $api.put(`/kinescope/event/${eventId}/complete`);
	}

	static async deleteKinescopeEvent (eventId: string): Promise<AxiosResponse<void>> {
		return $api.delete(`/kinescope/event/${eventId}`);
	}

	static async uploadKinescopePoster (eventId: string, file: File): Promise<AxiosResponse<void>> {
		const formData = new FormData();
		formData.append('file', file);
		return $api.post('/kinescope/poster/upload', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			params: { eventId }
		});
	}
}
