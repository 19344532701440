import { makeAutoObservable, runInAction } from 'mobx';
import {
	ICreateDirectionDto,
	IGetDirectionByIdResponse,
	IGetDirectionsResponse,
	IUpdateDirectionDto
} from '@lomonosov/types';
import { AdminDirectionService } from '@services';

class AdminDirectionStore {
	directions = [] as IGetDirectionsResponse;
	currentDirection: IGetDirectionByIdResponse;

	constructor () {
		makeAutoObservable(this);
	}

	async getDirections () {
		try {
			const { data } = await AdminDirectionService.getDirections();
			runInAction(() => {
				this.directions = data;
			});

			return data;
		} catch (e) {
			console.log(e);
			return null;
		}
	}

	async getDirectionById (disciplineId: string) {
		try {
			const { data } = await AdminDirectionService.getDirectionById(disciplineId);
			runInAction(() => {
				this.currentDirection = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async createDirection (direction: ICreateDirectionDto) {
		try {
			const { data } = await AdminDirectionService.createDirection(direction);
			runInAction(() => {
				this.directions = [data, ...this.directions];
			});
		} catch (e) {
			console.log(e);
		}
	}

	async updateDirection (directionId: string, directionNew: IUpdateDirectionDto) {
		try {
			const { data } = await AdminDirectionService.updateDirection(directionId, directionNew);
			runInAction(() => {
				this.directions = this.directions.map(direction => direction.id === directionId ? data : direction);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async deleteDirection (id: string) {
		try {
			await AdminDirectionService.deleteDirection(id);
			runInAction(() => {
				this.directions = this.directions.filter(direction => direction.id !== id);
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const adminDirectionStore = new AdminDirectionStore();
