import React, { useCallback, useState } from 'react';
import { DateInput, Input, Select } from '@components';
import { IconButton, debounce } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { getArrMonthsName } from '@helpers';
import { generalStore } from '@stores';
import './style.css';

interface IInvoiceAddCourseProps {
	courses: any[]
	addCourse: (course: any) => void
	countCourses: number;
	onSearchCourses: (text: string) => void
}

export default (props: IInvoiceAddCourseProps) => {
	const { courses, addCourse, countCourses, onSearchCourses } = props;
	const [newCourse, setNewCourse] = useState<any>(null);
	const [newTariff, setNewTariff] = useState<any>(null);
	const [tariffPeriod, setTariffPeriod] = useState<any>(null);
	const [newStartPeriod, setNewStartPeriod] = useState<any>();
	const [newEndPeriod, setNewEndPeriod] = useState<any>();
	const [price, setPrice] = useState<number>(0);
	const [discount, setDiscount] = useState<number>(0);

	const onTariffChange = useCallback((e, newValue) => {
		setNewTariff(newValue);
	}, []);

	const onTariffPeriodChange = useCallback((e, newValue) => {
		setTariffPeriod(newValue);
		setPrice(newValue?.price || 0);
		setNewStartPeriod(newValue.begins);
		setNewEndPeriod(newValue.ends);
	}, []);

	const onAddCourse = () => {
		if (countCourses >= 4) {
			generalStore.changeAlertState('warning', 'Достигнуто максимальное количество курсов');
			return;
		}
		const priceWithDiscount = discount > 0 ? price - (price * discount) / 100 : price;
		addCourse({
			course: newCourse,
			tariff: newTariff,
			period: tariffPeriod,
			tariffId: newTariff.id,
			begins: newStartPeriod,
			ends: newEndPeriod,
			price: Math.round(priceWithDiscount),
			duration: getArrMonthsName([{ begins: newStartPeriod, ends: newEndPeriod }]).monthsName
		});
		setNewCourse(null);
		setNewTariff(null);
		setTariffPeriod(null);
		setNewStartPeriod(null);
		setNewEndPeriod(null);
		setPrice(0);
		setDiscount(0);
	};

	const searchDelayed = debounce((text: string) => onSearchCourses(text), 1000);

	return (
		<div className='add-course'>
			<Select
				input={{ label: 'Курс', onChange: (e) => searchDelayed(e.target.value) }} options={courses}
				value={newCourse}
				onChange={(e, newValue) => setNewCourse(newValue)}

			/>
			<Select
				input={{ label: 'Тариф' }}
				options={newCourse?.tariffs}
				value={newTariff}
				onChange={onTariffChange}
				disabled={!newCourse}
			/>
			<Select
				input={{ label: 'Период' }}
				options={newTariff?.periods}
				value={tariffPeriod}
				onChange={onTariffPeriodChange}
				disabled={!newTariff}
			/>
			<DateInput
				label='Начало периода'
				type='date'
				value={newStartPeriod}
				onChange={(e) => setNewStartPeriod(e.target.value)}
				disabled={!newTariff}
			/>
			<DateInput
				label='Окончание периода'
				type='date'
				value={newEndPeriod}
				onChange={(e) => setNewEndPeriod(e.target.value)}
				disabled={!newTariff}
			/>
			<Input
				label='Цена'
				type='number'
				value={price}
				onChange={(e) => setPrice(+e.target.value)}
			/>
			<Input
				label='Скидка'
				type='number'
				value={discount}
				onChange={(e) => setDiscount(+e.target.value)}
			/>
			<IconButton onClick={onAddCourse} disabled={!newCourse || !newTariff || !newStartPeriod || !newEndPeriod}>
				<DoneIcon color='success'/>
			</IconButton>
		</div>
	);
};
