import React from 'react';
import { Tabs, Tab } from '@mui/material';
import './style.css';

interface SwitcherProps {
	options: Array<{
		label: string;
		value: string;
	}>
	onChange?: (value: string) => void;
	value?: string;
	className?: string;
	color?: 'orange' | 'blue' | 'green'
}

export const Switcher = (props: SwitcherProps) => {
	const { options, value, onChange, className = '', color = 'blue' } = props;

	const handleChange = (_e, newValue) => {
		onChange?.(newValue);
	};

	return (
		<Tabs
			indicatorColor='transparent'
			value={value}
			onChange={handleChange}
			className={`switcher ${className}`}
		>
			{options.map(tab =>
				<Tab
					key={tab.value}
					label={tab.label}
					value={tab.value}
					className={`switcher__tab ${value === tab.value ? `active ${color}` : 'noactive'}`}
				/>
			)}
		</Tabs>
	);
};
