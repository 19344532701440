import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { PortalLayout, MainLayout } from '../../layouts';
import {
	ClassCalendar,
	Gamification,
	Homeworks,
	Journal,
	StudyProgram,
	Proforientation
} from '../../../pages/portal';

const Mail = lazy(() => import('../../../pages/portal/portal-pages/mail'));
const Lesson = lazy(() => import('../../../pages/portal/portal-pages/lesson'));
// const Homework = lazy(() => import('../../../pages/portal/portal-pages/homework'));
const HomeworkPage = lazy(() => import('../../../pages/portal/portal-pages/task/HomeworkPage'));
// const VerifiedHomework = lazy(() => import('../../../pages/portal/portal-pages/homework'));
const VerifiedHomeworkPage = lazy(() => import('../../../pages/portal/portal-pages/task/VerifiedHomeworkPage'));
const Sections = lazy(() => import('../../../pages/portal/portal-pages/course-info'));
const Referrals = lazy(() => import('../../../pages/portal/portal-pages/referrals'));
const Essay = lazy(() => import('../../../pages/portal/portal-pages/essay'));
const ProfilePage = lazy(() => import('../../../pages/portal/portal-main/profile'));
const ProfilePageMain = lazy(() => import('../../../pages/portal/portal-main/profile/panels/ProfileMainPanel'));
const ProfilePageForm = lazy(() => import('../../../pages/portal/portal-main/profile/panels/ProfileFormPanel'));

export default {
	path: '/',
	element: <MainLayout/>,
	children:
		[
			{ path: '', element: <Navigate to='study-program' /> },
			{
				path: '',
				element: <PortalLayout/>,
				children: [
					{ path: 'gamification', element: <Gamification/> },
					{ path: 'study-program', element: <StudyProgram/> },
					{ path: 'course/:courseId', element: <Sections/> },
					{ path: 'proforientation', element: <Proforientation/> },
					{ path: 'calendar', element: <ClassCalendar/> },
					{ path: 'homeworks', element: <Homeworks/> },
					{ path: 'journal', element: <Journal/> },
					{
						path: 'personal-info',
						element: <ProfilePage/>,
						children: [{ path: '', element: <ProfilePageMain/> }, { path: 'settings', element: <ProfilePageForm/> }]
					}
				]
			},
			// {
			// 	path: 'course',
			// 	children: [
			// 		{ path: ':courseId', element: <Sections/> },
			// 		{ path: ':courseId/:sectionId/:lessonId', element: <Lesson/> }
			// 	]
			// },
			{ path: 'homework/:homeworkId', element: <HomeworkPage /> },
			{ path: 'tasks/:homeworkId', element: <VerifiedHomeworkPage/> },
			{ path: 'mail', element: <Mail/> },
			{ path: 'lesson/:lessonId', element: <Lesson/> },
			{ path: 'referrals', element: <Referrals/> },
			{ path: 'essay', element: <Essay/> }
		]
};
