import React, { useEffect, Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { ThemeProvider } from 'styled-components';
import { StylesProvider } from '@material-ui/core';
import { createTheme, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';

import { AppHelmet, Loader, Alert } from '@components';
import { generalStore } from '@stores';
import { Navigation, NavigationScroll } from './navigation';
import { themes } from './global-themes';
import { GlobalStyles } from './global-theme-settings';
import { MuiStyledComponents } from '@helpers';
import { useQueryParamsToLocalStorage } from './helpers/hooks';
import { useAuthCallback } from '@features/auth/utils';

import './helpers/styles/index.css';
import './app.css';

export const App = observer(() => {
	const theme = createTheme(
		{
			palette: {
				mode: generalStore.theme,
				primary: {
					main: generalStore.theme === 'light' ? '#191919' : '#ffffff',
					dark: '#ffffff',
					light: '#191919'
				},
				error: {
					main: '#FF0040'
				},
				secondary: {
					main: generalStore.theme === 'light' ? '#F9F9F9' : '#1E1E1E',
					light: '#F9F9F9',
					dark: '#1E1E1E'
				}
			},
			typography: {
				allVariants: {
					fontFamily: 'Geologica, sans-serif'
				},
				fontFamily: 'Geologica, sans-serif',
				button: {
					fontFamily: 'UA-brand, sans-serif'
				}
			},
			components: MuiStyledComponents(generalStore.theme)
		}
	);

	useEffect(() => {
		generalStore.setTheme();
	}, []);

	useQueryParamsToLocalStorage();

	useAuthCallback();

	return (
		<ThemeProvider theme={themes[generalStore.theme]}>
			<GlobalStyles />
			<MaterialThemeProvider theme={theme}>
				<StylesProvider injectFirst>
					<AppHelmet />
					<Suspense fallback={<Loader />}>
						<NavigationScroll>
							<Navigation />
						</NavigationScroll>
						<Alert />
					</Suspense>
				</StylesProvider>
			</MaterialThemeProvider>
		</ThemeProvider>
	);
});
