import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import { UserCalendarService } from '@services';
import { HomeworkTemplateType } from '@lomonosov/types';

export type CalendarTypeItems = 'lesson' | 'homework' | 'probe';

export interface IGetCalendarTableItem {
	id: string;
	name: string;
	date: string | Date;
	courseId: string;
	tooltipText: string;
	type: CalendarTypeItems;
	link: 'homework' | 'lesson';
	ends?: string | Date;
	color: string;
}

class UserCalendarStore {
	homeworks: any = [];
	lessons: any = [];
	items: IGetCalendarTableItem[] = [];
	filters: CalendarTypeItems[] = ['lesson', 'homework', 'probe'];
	calendarLoad: boolean = false;

	constructor () {
		makeAutoObservable(this);
	}

	convertDate (date) {
		return date.toLocaleDateString().split('.').reverse().join('-');
	}

	changeCalendarLoad (value: boolean | undefined = undefined) {
		this.calendarLoad = value !== undefined ? value : !this.calendarLoad;
	}

	setFilters (newFilters: CalendarTypeItems[]) {
		this.filters = newFilters;
	}

	getFilteredCalendarData () {
		return this.items.filter(item => this.filters.includes(item?.type));
	}

	async getCalendarData (startDate, endDate) {
		const oneDayInMS = 1000 * 60 * 60 * 24;
		const newStartDate = new Date(startDate.getTime() - oneDayInMS);
		const newEndDate = new Date(endDate.getTime() + oneDayInMS);

		const start = dayjs(newStartDate).format('YYYY-MM-DD');
		const end = dayjs(newEndDate).format('YYYY-MM-DD');

		try {
			this.changeCalendarLoad(false);
			const [homeworks, lessons] = await Promise.all([UserCalendarService.getCalendarHomeworks(start, end), UserCalendarService.getCalendarLessons(start, end)]);
			const tableHomeworks: IGetCalendarTableItem[] = homeworks.data.map(item => ({
				id: item.id,
				name: item.template.discipline.name,
				// date: new Date(String(item.lesson.begins)?.slice(0, 16)),
				date: item.lesson.begins,
				courseId: item.lesson.section.course.id,
				tooltipText: item.template.name,
				type: 'homework',
				link: 'homework',
				ends: new Date(item?.ends),
				color: this.getColor(item.template.type)
			}));

			const tableLessons: IGetCalendarTableItem[] = lessons.data.map(item => ({
				id: item.id,
				name: item.section.course.discipline.name,
				// date: new Date(String(item.begins)?.slice(0, 16)),
				date: item.begins,
				courseId: item.section.course.id,
				tooltipText: item.name,
				type: 'lesson',
				link: 'lesson',
				color: item?.isWrongTariff ? '#DCDEFF' : '#ADF000'
			}));
			this.items = [...tableHomeworks, ...tableLessons];
		} catch (e) {
			console.log(e);
		} finally {
			this.changeCalendarLoad(true);
		}
	}

	// async getCalendarHomeworks (startDate: Date, endDate: Date) {
	// 	const start = dayjs(startDate).format('YYYY-MM-DD');
	// 	const end = dayjs(endDate).format('YYYY-MM-DD');
	//
	// 	try {
	// 		this.changeCalendarLoad(false);
	// 		const { data } = await UserCalendarService.getCalendarHomeworks(start, end);
	// 		runInAction(() => {
	// 			const homeworks = data.map(homework => (
	// 				{
	// 					id: homework.id,
	// 					name: homework.template.discipline.name,
	// 					// @ts-ignore
	// 					date: getTimeWithTZ(new Date(homework.lesson.begins?.slice(0, 16))),
	// 					courseId: homework.lesson.section.course.id,
	// 					tooltipText: homework.template.name,
	// 					link: 'homework',
	// 					color: this.getColor(homework.template.type)
	// 				}
	// 			));
	//
	// 			this.items.push(...homeworks);
	// 		});
	// 	} catch (e) {
	// 		console.log(e);
	// 	} finally {
	// 		this.changeCalendarLoad(true);
	// 	}
	// }

	// async getCalendarLessons (startDate: Date, endDate: Date) {
	// 	const start = dayjs(startDate).format('YYYY-MM-DD');
	// 	const end = dayjs(endDate).format('YYYY-MM-DD');
	//
	// 	try {
	// 		this.changeCalendarLoad(false);
	// 		const { data } = await UserCalendarService.getCalendarLessons(start, end);
	// 		runInAction(() => {
	// 			const lessons = data.map(lesson => (
	// 				{
	// 					id: lesson.id,
	// 					name: lesson.section.course.discipline.name,
	// 					// @ts-ignore
	// 					date: new Date(lesson.begins?.slice(0, 16)),
	// 					courseId: lesson.section.course.id,
	// 					tooltipText: lesson.name,
	// 					link: 'lesson',
	// 					color: lesson?.isWrongTariff ? '#DCDEFF' : '#ADF000'
	// 				}
	// 			));
	//
	// 			this.items.push(...lessons);
	// 		});
	// 	} catch (e) {
	// 		console.log(e);
	// 	} finally {
	// 		this.changeCalendarLoad(true);
	// 	}
	// }

	getColor (type) {
		switch (type) {
		case HomeworkTemplateType.AUTOMATIC: return '#FFC800';
		case HomeworkTemplateType.MANUAL: return '#FFC800';
		case HomeworkTemplateType.PROBE: return '#FF3364';
		default: return '#FFC800';
		}
	}
}

export const userCalendarStore = new UserCalendarStore();
