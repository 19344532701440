import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { ArrowBackButton, TabPanel } from '@components';
import { CoursesPage, LessonsPage, TasksPage } from './blocks';
import { adminAnalyticsStore } from '@stores';
import './style.css';

export default observer(() => {
	const [currentTable, setCurrentTable] = useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setCurrentTable(newValue);
	};

	return (
		<div className='courses-analysis-main'>
			<SwipeableViews index={adminAnalyticsStore.activeScreen} onChangeIndex={adminAnalyticsStore.changeActiveScreen} disabled={true}>
				<TabPanel value={adminAnalyticsStore.activeScreen} name={0} overflow>
					<CoursesPage/>
				</TabPanel>
				<TabPanel value={adminAnalyticsStore.activeScreen} name={1} overflow>
					<ArrowBackButton onClick={() => adminAnalyticsStore.changeActiveScreen(0)}/>
					<Tabs onChange={handleChange} value={currentTable}>
						<Tab label='Уроки' value={0}/>
						{/* {adminAnalyticsStore.currentCourse.countTasks > 0 && */}
						<Tab label='Домашние задания' value={1}/>
						{/* } */}
					</Tabs>
					<SwipeableViews index={currentTable} onChangeIndex={setCurrentTable} disabled={true}>
						<TabPanel value={currentTable} name={0} overflow>
							<LessonsPage/>
						</TabPanel>
						<TabPanel value={currentTable} name={1} overflow>
							<TasksPage/>
						</TabPanel>
					</SwipeableViews>
				</TabPanel>
			</SwipeableViews>
		</div>
	);
});
