import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

import classNames from 'classnames';
import { ArrowLeftIcon } from '@helpers';
import './style.css';

interface IArrowBackButton {
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
	to?: string,
	className?: string
}

export default (props: IArrowBackButton) => {
	const navigate = useNavigate();
	const {
		onClick = () => navigate(-1),
		to = '',
		className = ''
	} = props;

	return (
		<Button
			href={to}
			onClick={onClick}
			startIcon={<ArrowLeftIcon />}
			className={classNames('arrow-back-button', className, 'theme-text')}
		>
			Назад
		</Button>
	);
};
