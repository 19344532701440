import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Select } from '@components';
import { IconButton } from '@material-ui/core';
import { adminComboCoursesStore, adminCoursesStore } from '@stores';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

interface ISelectOption {
	id: string;
	name: string;
}

interface ITariff extends ISelectOption {
	periods?: ISelectOption[];
}

interface ComparisonCourseProps {
	course: ISelectOption;
	tariffId: string;
}

export const ComparisonCourse = observer((props: ComparisonCourseProps) => {
	const { course, tariffId } = props;
	const [tariff, setTariff] = useState<ITariff | null>(null);
	const [isChange, setIsChange] = useState<boolean>(false);
	const [comparisonTariffId, setComparisonTariffId] = useState<string | null>(null);

	useEffect(() => {
		const comparisonTariff = adminComboCoursesStore.comparisonTariffs.find(
			comparisonTariff => comparisonTariff.courseTariff?.courseId === course.id
		);
		setTariff(comparisonTariff?.courseTariff || null);
		setComparisonTariffId(comparisonTariff?.id || null);
	}, [adminComboCoursesStore.comparisonTariffs]);

	const getCourseById = async (courseId: string) => {
		await adminCoursesStore.getTariffsOfCourse(courseId);
	};

	const handleChangeTariff = (e, tariff: ITariff, reason: string) => {
		setTariff(tariff);
		setIsChange(true);
	};

	const saveComparisonCourse = async () => {
		if (!tariff?.id) return;
		if (comparisonTariffId) {
			await adminComboCoursesStore.updateComparisonTariffCombo(
				comparisonTariffId,
				{
					courseTariffId: tariff.id
				}
			);
		} else {
			await adminComboCoursesStore.createComparisonTariffCombo({
				combinationTariffId: tariffId,
				courseTariffId: tariff.id
			});
		}
		setIsChange(false);
	};

	const onDeleteComparisonCourse = async () => {
		if (!comparisonTariffId) return;
		await adminComboCoursesStore.deleteComparisonTariffCombo(comparisonTariffId);
		setTariff(null);
		setIsChange(false);
	};

	return (
		<div className='detail-combo-tariff__course'>
			<p className='detail-combo-tariff__title'>
				Курс: {course.name}
			</p>
			<div className='detail-combo-tariff__block'>
				<Select
					loading={adminCoursesStore.isLoading}
					options={adminCoursesStore.tariffs || []}
					input={{ label: 'Тариф' }}
					className='detail-combo-tariff__select'
					onFocus={() => getCourseById(course.id)}
					onChange={handleChangeTariff}
					value={tariff}
				/>
				<IconButton
					onClick={saveComparisonCourse}
					disabled={!tariff?.id || !isChange}
					className='detail-combo-tariff__btn-save'
				>
					<SaveIcon color='inherit'/>
				</IconButton>
				{comparisonTariffId &&
					<IconButton onClick={onDeleteComparisonCourse}>
						<DeleteIcon color='error'/>
					</IconButton>
				}
			</div>
		</div>
	);
});
