import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Button } from '@components';
import { userCourseStore } from '@stores';
import { BookIcon, GraduateCap } from 'lomonosov-icons';
import './style.css';

interface SubjectCardProps {
  lesson: any
  link: string
}

export default observer((props: SubjectCardProps) => {
	const { lesson, link } = props;

	return (
		<div className='subject-card theme-block-background'>
			<div className='subject-card__left-block'>
				<div className='subject-card__title'>
					{lesson.name} {new Date(lesson.begins).toLocaleDateString()}
				</div>
				<div className='subject-card__description'>
					{`${userCourseStore.currentCourse.category.name} ${userCourseStore.currentCourse.direction.name}`}
				</div>
				<div className='subject-card__subject'>
					<div className='subject-card__subject-block'>
						<div className='portal-book'><BookIcon/></div>
						<div className='subject-card__subject-title'>
							{userCourseStore.currentCourse.discipline.name}
						</div>
					</div>
					{lesson.teacher &&
            <div className='subject-card__subject-block'>
            	<div className='portal-cap'><GraduateCap/></div>
            	<a onClick={() => userCourseStore.setCurrentTeacher(lesson.teacher)}
            		className='subject-card__subject-title'>
            		{(`${lesson.teacher.firstName} ${lesson.teacher.secondName}`).substr(0, 25)}
            	</a>
            </div>
					}
				</div>
			</div>
			<div className='subject-card__right-block'>
				<div className='subject-card__buttons'>
					<Link to={checkDateNow(lesson.begins, lesson.isBlocked, lesson.isWrongTariff) ? '#' : link} className='subject-card__button'>
						<Button
							type={checkDateNow(lesson.begins, lesson.isBlocked, lesson.isWrongTariff) ? 'disabled' : lesson.isViewed ? 'main_green' : 'main_blue'}
						  disable={checkDateNow(lesson.begins, lesson.isBlocked, lesson.isWrongTariff)}
						>
							{lesson.isViewed ? 'Просмотрено' : 'К уроку'}
						</Button>
					</Link>
					{lesson.isWrongTariff && lesson.isBlocked &&
          <div className='subject-card__blocked'>
						Недоступно на твоем тарифе
          </div>
					}
					{lesson.isBlocked && !lesson.isWrongTariff &&
          <div className='subject-card__blocked'>
						Нет подписки на {months[new Date(lesson.begins).getMonth()]}
          </div>
					}
				</div>
				<div className='subject-card__status'>
					<div>
          	<img
          		src={'https://cdn1.lomonosov.tech/src-images/card2smile.png'} alt='head'
          		className='subject-card__image'
          	/>
					</div>
					<div className='subject-card__score'>
						<p className='subject-card__score-xp'>
							+{lesson.xpCost} <span>XP</span>
						</p>
						<p className='subject-card__score-dollar'>
							+{lesson.moneyCost} <span>$</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
});

const checkDateNow = (a, blocked, tariff) => {
	const now = new Date().toLocaleString('en-US', { timeZone: 'Europe/Moscow' });
	return (new Date(now).getTime() < new Date(a).getTime() - 86400000 || blocked || tariff);
};

const months = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'];
