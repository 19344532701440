import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, DeleteModal } from '@components';
import { adminDirectionStore } from '@stores';

export default observer(() => {
	const [deletableDirectionId, setDeletableDirectionId] = useState<string>('');

	useEffect(() => {
		if (!adminDirectionStore.directions.length) {
			adminDirectionStore.getDirections().then();
		}
	}, []);

	const deleteDirection = () => {
		adminDirectionStore.deleteDirection(deletableDirectionId).finally(() => setDeletableDirectionId(''));
	};

	return (
		<>
			<Table
				columns={columnsDirections}
				data={adminDirectionStore.directions}
				search pagination
				actionRow={{
					add: newState => adminDirectionStore.createDirection(newState),
					edit: (id, newState) => adminDirectionStore.updateDirection(id, newState),
					delete: id => setDeletableDirectionId(id)
				}}
				draggable
			/>
			<DeleteModal
				deleteName={adminDirectionStore.directions.find(direction => direction.id === deletableDirectionId)?.name}
				open={!!deletableDirectionId}
				handleClose={() => setDeletableDirectionId('')}
				action={deleteDirection}
			/>
		</>
	);
});

const columnsDirections = [
	{ field: 'id', name: 'id', copy: true, editable: false },
	{ field: 'name', name: 'Название' }
];
