import React from 'react';
import { getNoun, images } from '@helpers';
import './style.css';

interface ICommentMentorProps {
	teacher: {
		originalUser?: {
			photo?: string
		},
		name: string,
		photo?: string
	}
	comment: string,
	extraScores: number
}

export default (props: ICommentMentorProps) => {
	const { teacher, comment, extraScores } = props;

	return (
		<div className='mentor-comment'>
			<img
				src={teacher?.originalUser?.photo || teacher?.photo || images.personalInfoAvatar}
				className='mentor-comment__image'
				alt='error'
			/>
			<div className='mentor-comment__container'>
				<div className='mentor-comment__mentor-name theme-blue-to-orange'>
					{teacher?.name}
				</div>
				<div className='mentor-comment__text theme-text'>
					{comment}
				</div>
				{extraScores !== 0 &&
        <div className='mentor-comment__rate'>
            + {extraScores} экстра-{getNoun(+extraScores, 'балл', 'балла', 'баллов')}
        </div>
				}
			</div>
		</div>
	);
};
