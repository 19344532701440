import React from 'react';
import {
	TableFooter,
	TablePagination,
	TableRow,
	TablePaginationProps
} from '@mui/material';
interface IProps {
	pagination: TablePaginationProps,
	className?: string
}

export default (props: IProps) => {
	const { pagination, className } = props;

	return (
		<TableFooter className={className}>
			<TableRow>
				<TablePagination
					rowsPerPageOptions={[5, 10, 15]}
					{...pagination}
				/>
			</TableRow>
		</TableFooter>
	);
};
