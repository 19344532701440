import React from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@mui/material';

import { userGamificationStore } from '@stores';

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { constants } from '@helpers';

export default observer((props: any) => {
	const { item, handleCloseMenu, type, onLoad = (type: string) => {} } = props;

	return (
		<div id={type} className='gamification-homepage-new__window-item'>
			{item?.file?.[0]?.id
				? <img
					src={`${constants.FILE_URL}/${item.file?.[0]?.id}`}
					alt={type}
					loading={'lazy'}
					height={'60vw'}
					onLoad={() => onLoad(type)}
				/>
				: ''
			}
			{userGamificationStore.shopItem.type === 'house' && !userGamificationStore.isShowShopMenu &&
				<IconButton
					onClick={() => handleCloseMenu(type)}
					className={`gamification-homepage-new__icon-button gamification-homepage-new__icon-button_${type}`}
				>
					<ChangeCircleIcon fontSize='large' className='gamification-homepage-new__icon'/>
				</IconButton>
			}
		</div>
	);
});
