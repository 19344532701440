import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	ICreateDirectionDto,
	ICreateDirectionResponse,
	IGetDirectionByIdResponse,
	IGetDirectionsResponse,
	IUpdateDirectionDto,
	IUpdateDirectionResponse
} from '@lomonosov/types';

export default class {
	/* Направления */
	static async createDirection (newDirection: ICreateDirectionDto): Promise<AxiosResponse<ICreateDirectionResponse>> {
		return $api.post<ICreateDirectionResponse>('/directions', newDirection);
	}

	static async getDirections (): Promise<AxiosResponse<IGetDirectionsResponse>> {
		return $api.get<IGetDirectionsResponse>('/directions');
	}

	static async getDirectionById (directionId: string): Promise<AxiosResponse<IGetDirectionByIdResponse>> {
		return $api.get<IGetDirectionByIdResponse>(`/directions/${directionId}`);
	}

	static async updateDirection (
		directionId: string,
		newInfoDirection: IUpdateDirectionDto
	): Promise<AxiosResponse<IUpdateDirectionResponse>> {
		return $api.put<IUpdateDirectionResponse>(`/directions/${directionId}`, newInfoDirection);
	}

	static async deleteDirection (directionId: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/directions/${directionId}`);
	}
}
