import { AxiosResponse } from 'axios';
import {
	IGetUserHomeworksResponse,
	IDeleteHomeworkAnswerResponse,
	ICreateAnswerTaskDto
} from '@lomonosov/types';
import { $api } from '@helpers';
import { IUserHomework, IUserHomeworkById } from '@interfaces';

export default class {
	static async getHomeworks (userId: string, take: number, skip: number, queryParams: string, route: string):
		Promise<AxiosResponse<IGetHomeworks>> {
		return $api.get<IGetHomeworks>(
			`/users/${userId}/homeworks?route=${route}&take=${take}&skip=${skip}${queryParams}`
		);
	}

	static async getHomeworkById (userId: string, homeworkId: string): Promise<AxiosResponse<IUserHomeworkById>> {
		return $api.get<IUserHomeworkById>(`/users/${userId}/homeworks/${homeworkId}`);
	}

	static async createAnswer (homeworkId, userAnswers: ICreateAnswerTaskDto[]): Promise<AxiosResponse<IUserHomeworkById>> {
		return $api.post<IUserHomeworkById>('/answers', { homeworkId, userAnswers });
	}

	static async deleteAnswer (answerId: string): Promise<AxiosResponse<boolean>> {
		return $api.delete<IDeleteHomeworkAnswerResponse>(`/answers/${answerId}`);
	}
}

interface IGetHomeworks extends Omit<IGetUserHomeworksResponse, 'homeworks'>{
	homeworks: IUserHomework[]
}
