import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { adminAnalyticsStore, adminDisciplineStore } from '@stores';
import { Tab, Tabs } from '@mui/material';
import { TabPanel } from '@components';
import {
	FilteringStudentAnalysis,
	TableStudentsAnalysis,
	TableTasksAnalysis
} from './blocks';

import './style.css';

export default observer(() => {
	const [currentTable, setCurrentTable] = useState<'tasks' | 'students'>('tasks');
	// const isSaveFile = analyticsStore.tableTasksAnalysis.rows.length !== 0 && !adminAnalyticsStore.isLoading;

	useEffect(() => {
		if (!adminAnalyticsStore.taskActivity?.length) {
			adminAnalyticsStore.getTasksActivity(8, 0);
		}
		if (!adminDisciplineStore.disciplines?.length) {
			adminDisciplineStore.getDisciplines();
		}
	}, []);

	const handleChange = (event: React.SyntheticEvent, newValue: 'tasks' | 'students') => {
		setCurrentTable(newValue);
	};

	return (
		<div className='student-analysis'>
			{currentTable === 'tasks' &&
				<FilteringStudentAnalysis activeScreen={currentTable}/>
			}
			<Tabs onChange={handleChange} value={currentTable} textColor='secondary' indicatorColor='secondary'>
				<Tab label='Домашние задания' value={'tasks'}/>
				<Tab label='Студенты' value={'students'}/>
			</Tabs>
			<TabPanel value={currentTable} name={'tasks'} overflow>
				<TableTasksAnalysis/>
			</TabPanel>
			<TabPanel value={currentTable} name={'students'} overflow>
				<TableStudentsAnalysis/>
			</TabPanel>
			{/* <div className='student-analysis__block'>
				{isSaveFile && analyticsStore.tableCurrentTaskAnalysis.columns.length > 0 &&
			  <a href={analyticsStore.getFileCurrentTask()} download className='student-analysis__button'>
			  	<Button icon={<SaveIcon/>} type='main_green'>
						Скачать файл xlsx
			 	</Button>
		  </a>
			}
		 </div> */}
		</div>
	);
});
