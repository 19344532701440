import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import { IStrapiTeacher, IUser, IUserNotificationsItem } from '@interfaces';
import { $strapiApi } from '@helpers/http/strapi-instance';

export default class {
	static async getUserInfo (): Promise<AxiosResponse<IUser>> {
		return $api.get<IUser>('/users/me');
	}

	static async getPurchases (): Promise<AxiosResponse<any>> {
		return $api.get<any>('/users/purchases');
	}

	static async updateUserInfo (newUserInfo: any): Promise<AxiosResponse<any>> {
		return $api.put<any>('/users/me', newUserInfo);
	}

	static async subscribeNotification (userId: string, courseId: string): Promise<AxiosResponse<any>> {
		return $api.post<any>(`/notifications/users/${userId}/courses/${courseId}`);
	}

	static async unsubscribeNotification (userId: string, courseId: string): Promise<AxiosResponse<any>> {
		return $api.delete<any>(`/notifications/users/${userId}/courses/${courseId}`);
	}

	static async getNotifications (userId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/notifications/users/${userId}`);
	}

	static async getProfessionPromo (userId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/profession-promo?userId=${userId}`);
	}

	static async checkCareerGuidance (userId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/profession-promo/availability?userId=${userId}`);
	}

	static async getBanners (userId: string, type: 'course' | 'portal'): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/banners?userUUID=${userId}&type=${type}&isActive=true`);
	}

	static async getStrapiTeachers (): Promise<AxiosResponse<StrapiTeachersResponse>> {
		return $strapiApi.get<StrapiTeachersResponse>('/api/section-teachers/1',
			{ params: { 'populate[teachers][populate]': '*' } }
		);
	}

	// static async changePassword (oldPassword: string, newPassword: string): Promise<AxiosResponse<{ status: boolean }>> {
	// 	return $api.get<{ status: boolean }>(`/users/change-password?oldPassword=${oldPassword}&newPassword=${newPassword}`);
	// }

	static async confirmTelegram (telegramUser: {
		first_name: string;
		last_name: string;
		photo_url: string;
		auth_date: string;
		username: string;
		hash: string;
		id: string;
	}, userId: string) {
		return $api.get(`/users/${userId}/confirm-telegram`, { params: telegramUser });
	}

	static async subscribeNotificationsByDisciplines (userId: string, disciplineIds: Array<string>): Promise<AxiosResponse<Array<IUserNotificationsItem>>> {
		return $api.post<Array<IUserNotificationsItem>>(`/notifications/users/${userId}/disciplines`, { disciplineIds });
	}
}

interface StrapiTeachersResponse {
	data: {
		id: number;
		attributes: {
			sectionTitle: string;
			teachers: IStrapiTeacher[];
		}
	}
}
