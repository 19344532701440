import React from 'react';
import { observer } from 'mobx-react-lite';
import { TeacherCard } from '@blocks';
import { CustomModal, Loader } from '@components';
import { IStrapiTeacher } from '@interfaces';

import './style.css';

interface ITeacherCardModalProps {
	open: boolean;
	handleClose: () => void;
	isLoading?: boolean;
	teacher: {
		name: string,
		photo: string
	};
	strapiTeachers: IStrapiTeacher[];
}

export const TeacherCardModal = observer((props: ITeacherCardModalProps) => {
	const { handleClose, open, isLoading = false, teacher, strapiTeachers } = props;

	return (
		<CustomModal
			open={open}
			viewClose='cross'
			handleClose={handleClose}
			heightModal='auto'
			widthModal='1100px'
			className='teacher-card-modal theme-block-background'
		>
			{isLoading
				? <Loader className='teacher-card-modal__loader theme-block-background'/>
				: <TeacherCard
					teacher={teacher}
					strapiTeachers={strapiTeachers}
				/>
			}
		</CustomModal>
	);
});
