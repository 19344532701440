import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CustomModal, Table } from '@components';
import { IColumn } from '@components/table';
import { adminInvoiceStore } from '@stores';
import { DetailPanelInvoice, AddPanelInvoice, FilteringInvoice } from './blocks';
import { InvoiceStatistic, CloseOrOpenIcon } from './components';
import DifferenceIcon from '@mui/icons-material/Difference';

import '../admin-main-block.globalStyle.css';
import './style.css';

export default observer(() => {
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);
	const [isAdd, setIsAdd] = useState(false);
	const [timer, setTimer] = useState<any>();

	const [duplicateData, setDuplicateData] = useState<any>(null);

	useEffect(() => {
		if (adminInvoiceStore.invoices?.invoices?.length !== 0) {
			adminInvoiceStore.getInvoices();
		}
	}, []);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		adminInvoiceStore.getInvoices(rowsPerPage, newPage * rowsPerPage);
	};

	const onSearch = async (newSearch: string) => {
		adminInvoiceStore.changeFieldFiltering(newSearch, 'search');
		clearTimeout(timer);
		setPage(0);
		setTimer(setTimeout(() =>
			adminInvoiceStore.getInvoices(rowsPerPage, 0, newSearch), 1000
		));
	};

	const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const getCurrentInvoice = async (id) => {
		await adminInvoiceStore.getInvoiceById(id);
	};

	const updateInvoice = async (data) => {
		await adminInvoiceStore.updateInvoiceStatus(data.id, data);
	};

	const addInvoice = () => {
		setIsAdd(true);
	};

	const createInvoice = (invoice) => {
		adminInvoiceStore.createInvoice(invoice).then(() => setIsAdd(false));
	};

	const onDuplicationInvoice = (id, data) => {
		setIsAdd(true);
		const temp = data.items.map(item => ({ ...item }));
		setDuplicateData({ courses: temp });
	};

	useEffect(() => {
		handleChangePage(undefined, 0);
	}, [rowsPerPage]);

	const columnsPromo: IColumn[] = [
		{ field: 'shortId', name: 'ссылка', copy: { prefix: `${window.location.host}/shop?shortId=` }, editable: false, align: 'center' },
		{ field: 'userName', name: 'Пользователь', editable: false },
		{ field: 'total', name: 'Сумма, ₽', align: 'center' },
		{ field: 'dateCreate', name: 'Дата создания', editable: false },
		{ field: 'status', name: 'Статус', align: 'center', type: 'element', className: 'status', editable: false },
		{ field: 'managerName', name: 'Менеджер', editable: false },
		{
			editable: false,
			field: 'isClose',
			name: '',
			align: 'center',
			type: 'element',
			element: (data) => <CloseOrOpenIcon data={data} updateStatus={updateInvoice}/>
		}
	];

	return (
		<div className='invoice'>
			<FilteringInvoice />
			{adminInvoiceStore.statistic &&
				<InvoiceStatistic statistic={adminInvoiceStore.statistic} />
			}
			<Table
				columns={columnsPromo}
				data={adminInvoiceStore.invoices?.invoices}
				search={{
					prop: adminInvoiceStore.filtering.search || '',
					action: onSearch
				}}
				pagination={{
					count: adminInvoiceStore.invoices?.count,
					page: page,
					rowsPerPage: rowsPerPage,
					onPageChange: handleChangePage,
					onRowsPerPageChange: handleChangeRowsPerPage
				}}
				detailPanel={{
					Component: DetailPanelInvoice,
					action: getCurrentInvoice,
					autoClose: true
				}}
				actionHeader={{
					add: addInvoice
				}}
				actionRow={{
					custom: {
						icon: <DifferenceIcon />,
						action: onDuplicationInvoice,
						tooltip: 'Дублировать'
					}
				}}
			/>
			<CustomModal
				open={isAdd}
				handleClose={() => {
					setDuplicateData(null);
					setIsAdd(false);
				}}
				viewClose={'cross'}
				textTitle={'Создать счёт'}
				heightModal='auto'
				widthModal='auto'
				className='theme-block-background'
			>
				<AddPanelInvoice duplicateInvoiceData={duplicateData} createInvoice={createInvoice} />
			</CustomModal>
		</div>
	);
});
