import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	IGetAllHomeworksResponse,
	IGetHomeworkByIdResponse,
	ICreateHomeworkDto,
	ICreateHomeworkResponse,
	IUpdateHomeworkDto,
	IUpdateHomeworkResponse,
	ICreateTemplateDto,
	ICreateHomeworkTemplateResponse,
	IGetAllHomeworkTemplatesResponse,
	IGetHomeworkTemplateByIdResponse,
	IUpdateTemplateDto,
	IUpdateHomeworkTemplateResponse,
	IGetAllHomeworkAnswersResponse,
	IGetHomeworkAnswerByIdResponse,
	ICreateHomeworkAnswerResponse,
	ICreateAnswerDto,
	IUpdateAnswerDto,
	IUpdateHomeworkAnswerResponse,
	HomeworkTemplateType
} from '@lomonosov/types';

export default class {
	/* Домашние задания (не нужно?) */
	static async getHomeworks (lessonId): Promise<AxiosResponse<IGetAllHomeworksResponse>> {
		return $api.get<IGetAllHomeworksResponse>(`/homeworks?lesson=${lessonId}`);
	}

	static async getHomeworkById (homeworkId: string): Promise<AxiosResponse<IGetHomeworkByIdResponse>> {
		return $api.get<IGetHomeworkByIdResponse>(`/homeworks/${homeworkId}`);
	}

	static async createHomework (homework: ICreateHomeworkDto): Promise<AxiosResponse<ICreateHomeworkResponse>> {
		return $api.post<ICreateHomeworkResponse>('/homeworks', homework);
	}

	static async updateHomework (
		homeworkId: string,
		newInfoHomework: IUpdateHomeworkDto
	): Promise<AxiosResponse<IUpdateHomeworkResponse>> {
		return $api.put<IUpdateHomeworkResponse>(`/homeworks/${homeworkId}`, newInfoHomework);
	}

	static async deleteHomework (homeworkId: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/homeworks/${homeworkId}`);
	}

	/* Шаблон домашнего задания */
	static async getTemplates (
		take: number,
		skip: number,
		queryParams?: IGetTemplatesHomeworkQueryParams | null
	): Promise<AxiosResponse<IGetAllHomeworkTemplatesResponse>> {
		return $api.get<IGetAllHomeworkTemplatesResponse>('/templates',
			{
				params: {
					take, skip, ...queryParams
				}
			}
		);
	}

	static async getTemplateById (templateId: string): Promise<AxiosResponse<IGetHomeworkTemplateByIdResponse>> {
		return $api.get<IGetHomeworkTemplateByIdResponse>(`/templates/${templateId}`);
	}

	static async createTemplate (homework: ICreateTemplateDto): Promise<AxiosResponse<ICreateHomeworkTemplateResponse>> {
		return $api.post<ICreateHomeworkTemplateResponse>('/templates', homework);
	}

	static async updateTemplate (
		templateId: string,
		newInfoTemplate: IUpdateTemplateDto
	): Promise<AxiosResponse<IUpdateHomeworkTemplateResponse>> {
		return $api.put<IUpdateHomeworkTemplateResponse>(`/templates/${templateId}`, newInfoTemplate);
	}

	static async deleteTemplate (templateId: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/templates/${templateId}`);
	}

	/* Ответы домашнего задания */
	static async getAnswers (): Promise<AxiosResponse<IGetAllHomeworkAnswersResponse>> {
		return $api.get<IGetAllHomeworkAnswersResponse>('/answers');
	}

	static async getAnswerById (answerId: string): Promise<AxiosResponse<IGetHomeworkAnswerByIdResponse>> {
		return $api.get<IGetHomeworkAnswerByIdResponse>(`/answers/${answerId}`);
	}

	static async createAnswer (answer: ICreateAnswerDto): Promise<AxiosResponse<ICreateHomeworkAnswerResponse>> {
		return $api.post<ICreateHomeworkAnswerResponse>('/answers', answer);
	}

	static async updateAnswer (
		answerId: string,
		newInfoAnswer: Partial<IUpdateAnswerDto>
	): Promise<AxiosResponse<IUpdateHomeworkAnswerResponse>> {
		return $api.put<IUpdateHomeworkAnswerResponse>(`/answers/${answerId}`, newInfoAnswer);
	}

	static async deleteAnswer (answerId: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/answers/${answerId}`);
	}
}

export interface IGetTemplatesHomeworkQueryParams {
	discipline?: string;
	search?: string;
	type?: HomeworkTemplateType
}
