import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, CustomModal, DeleteModal } from '@components';
import EditIcon from '@mui/icons-material/Edit';
import { IGetCategoriesResponse, IGetDirectionsResponse } from '@lomonosov/types';
import { adminTariffPlansStore, adminCategoryStore, adminDirectionStore } from '@stores';
import DetailTablePlan from './components/detailTablePlan';
import ChangePlanModal from './components/changePlanModal';
import './style.css';

export default observer(() => {
	let category: IGetCategoriesResponse = [];
	let direction: IGetDirectionsResponse = [];
	const [changePlanModal, setChangePlanModal] = useState(false);
	const [changeDataPlan, setChangeDataPlan] = useState<any>(null);
	const [deletablePlanId, setDeletablePlanId] = useState<string>('');

	useEffect(() => {
		adminTariffPlansStore.getPlans();
	}, []);

	const getPlan = async (id: string): Promise<any | null> => {
		if (category.length === 0) {
			const temp = await adminCategoryStore.getCategories();
			if (temp) { category = temp; }
		}
		if (direction.length === 0) {
			const temp = await adminDirectionStore.getDirections();
			if (temp) { direction = temp; }
		}
		const data = await adminTariffPlansStore.getPlanById(id);
		if (data) { return { data, category, direction }; } else { return null; }
	};

	const getPlanForEdit = async (id: string) => {
		const data = await adminTariffPlansStore.getPlanById(id);
		setChangeDataPlan(data);
		setChangePlanModal(true);
	};

	return (
		<>
			<Table
				columns={[
					{ field: 'name', name: 'Название' },
					{ field: 'description', name: 'Описание', maxCharacters: 30 },
					{ field: 'courseCount', name: 'Подключенные курсы', align: 'center' }
				]}
				data={adminTariffPlansStore.plans}
				detailPanel={{
					Component: DetailTablePlan,
					action: getPlan
				}}
				actionHeader={{
					add: () => setChangePlanModal(true)
				}}
				actionRow={{
					custom: {
						tooltip: 'edit',
						icon: <EditIcon />,
						action: (id) => getPlanForEdit(id)
					},
					delete: (id) => {
						setDeletablePlanId(id);
					}
				}}
			/>
			<CustomModal
				open={changePlanModal}
				viewClose='cross'
				handleClose={() => { setChangePlanModal(false); setChangeDataPlan(null); }}
				textTitle={changeDataPlan ? 'Редактирование плана' : 'Создание плана'}
				widthModal='600px'
				heightModal='auto'
			>
				<ChangePlanModal
					save={(state, id) => {
						(changeDataPlan && id)
							? adminTariffPlansStore.updateTariffPlan(id, state)
							: adminTariffPlansStore.addTariffPlan(state);
						setChangePlanModal(false);
						setChangeDataPlan(null);
					}}
					data={changeDataPlan}
				/>
			</CustomModal>
			<DeleteModal
				deleteName={
					adminTariffPlansStore.plans.find(tariffPlan => tariffPlan.id === deletablePlanId)?.name
				}
				open={!!deletablePlanId}
				handleClose={() => setDeletablePlanId('')}
				action={() => {
					adminTariffPlansStore.deleteTariffPlan(deletablePlanId);
					setDeletablePlanId('');
				}}
			/>
		</>
	);
});
