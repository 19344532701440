import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@components';
import { adminTeacherStore, adminUserStore, adminRoleStore, adminMentorsStore } from '@stores';
import './style.css';

export default observer(() => {
	const [isMentor, setIsMentor] = useState<boolean>(!!adminUserStore.currentUser.mentor);
	const [isTeacher, setIsTeacher] = useState<boolean>(!!adminUserStore.currentUser.teacher);

	useEffect(() => {
		adminRoleStore.getRoles();
		adminUserStore.getUserRoles(adminUserStore.currentUser.id);
	}, []);

	const changeTeacherRole = useCallback(() => {
		adminUserStore.currentUser.teacher
			? adminTeacherStore.deleteTeacherRole(adminUserStore.currentUser.teacher.id)
				.then(() => setIsTeacher(!isTeacher))
			: adminTeacherStore.createTeacher(adminUserStore.currentUser.id)
				.then(() => setIsTeacher(!isTeacher));
	}, [isTeacher]);

	const changeMentorRole = useCallback(() => {
		adminUserStore.currentUser.mentor
			? adminMentorsStore.deleteMentor(adminUserStore.currentUser.mentor.id)
				.then(() => setIsMentor(!isMentor))
			: adminMentorsStore.createMentor(adminUserStore.currentUser.id)
				.then(() => setIsMentor(!isMentor));
	}, [isMentor]);

	return (
		<div className='role-panel'>
			<div className='role-panel__section'>
				{adminRoleStore.roles.map(role =>
					<RoleItem key={role.id} role={role}/>
				)}
			</div>
			<div className='role-panel__section'>
				<div className='role-panel__button'>
					<Button
						type={isTeacher ? 'main_red' : 'main_green'}
						onClick={changeTeacherRole}
					>
						{isTeacher ? 'Разжаловать учителя' : 'Сделать учителем'}
					</Button>
				</div>
				<div className='role-panel__button'>
					<Button
						type={isMentor ? 'main_red' : 'main_green'}
						onClick={changeMentorRole}
					>
						{isMentor ? 'Разжаловать ментора' : 'Сделать ментором'}
					</Button>
				</div>
			</div>
		</div>
	);
});

const RoleItem = observer((props: { role }) => {
	const { role } = props;
	const [isAdded, setIsAdded] = useState<boolean>(false);

	useEffect(() => {
		setIsAdded(adminUserStore.currentUser.roles.some(r => r.id === role.id));
	}, []);

	const onClick = async () => {
		const updatedRoles = isAdded
			? adminUserStore.currentUser.roles.filter(r => r.id !== role.id)
			: [...adminUserStore.currentUser.roles, role];
		await adminUserStore.updateUserRoles(adminUserStore.currentUser.id, { roles: updatedRoles })
			.then(() => setIsAdded(!isAdded));
	};

	return <div className='role-panel__button'>
		<Button
			type={isAdded ? 'main_red' : 'main_green'}
			onClick={onClick}
		>
			{isAdded ? 'Удалить' : 'Добавить'} роль &#34;{role.name}&#34;
		</Button>
	</div>;
});
