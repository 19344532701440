import React from 'react';
import { Card, CardActionArea } from '@mui/material';
import classNames from 'classnames';
import './style.css';

type InfoCardTheme = 'default' | 'light-pink' | 'light-lemon' | 'white';

interface InfoCardProps {
	icon?: React.ReactNode;
	title: string | React.ReactNode;
	children?: React.ReactNode;
	className?: string;
	theme?: InfoCardTheme;
	onClick?: () => void;
	classes?: {
		container?: string;
		header?: string;
	}
}

export const InfoCard = (props: InfoCardProps) => {
	const { icon, title, children, theme = 'default', onClick, className, classes } = props;

	return (
		<Card className={classNames('info-card', theme, className)} color={theme === 'default' ? 'secondary' : 'inherit'}>
			{onClick && <CardActionArea className={'info-card__action'} onClick={onClick} />}
			<span className={classNames('info-card__header', classes?.header)}>
				{icon && icon} {title}
			</span>
			{children && <div className={classNames('info-card__container', classes?.container)}>{children}</div>}
		</Card>
	);
};
