export const buttonTypes = {
	light: {
		main_blue: {
			backgroundColor: '#8417FF',
			color: '#FFFFFF',
			'&:hover': {
				backgroundColor: '#7800FF'
			},
			'&:active': {
				backgroundColor: '#9435FF'
			}
		},
		main_blue_light: {
			backgroundColor: '#8383FF',
			color: '#FFFFFF',
			'&:hover': {
				backgroundColor: '#7575e5'
			},
			'&:active': {
				backgroundColor: '#9435FF'
			}
		},
		main_green: {
			backgroundColor: '#ADF000',
			color: '#191919',
			'&:hover': {
				backgroundColor: '#BBFF0C'
			},
			'&:active': {
				backgroundColor: '#CCFF47'
			}
		},
		main_orange: {
			backgroundColor: '#FFC800',
			color: '#191919',
			'&:hover': {
				backgroundColor: '#EEBB00'
			},
			'&:active': {
				backgroundColor: '#FFD436'
			}
		},
		main_red: {
			backgroundColor: '#FF3364',
			color: '#FFFFFF',
			'&:hover': {
				backgroundColor: '#EE2555'
			},
			'&:active': {
				backgroundColor: '#FF4F79'
			}
		},
		main: {
			backgroundColor: '#FFFFFF',
			color: '#191919',
			'&:hover': {
				backgroundColor: '#F3F3F3'
			}
		},
		inverse: {
			backgroundColor: '#191919',
			color: '#FFFFFF',
			'&:hover': {
				backgroundColor: '#191919'
			}
		},
		outline: {
			backgroundColor: '#FFFFFF',
			color: '#191919',
			border: '4px solid #191919',
			'&:hover': {
				backgroundColor: '#F3F3F3'
			}
		},
		custom: {
			backgroundColor: '#FFFFFF',
			color: '#191919'
		},
		outline_blue: {
			backgroundColor: '#FFFFFF',
			color: '#191919',
			border: '4px solid #8417FF',
			'&:hover': {
				backgroundColor: '#8417FF',
				color: '#FFFFFF'
			},
			'&:active': {
				backgroundColor: '#F3F3F3'
			}
		},
		outline_green: {
			backgroundColor: '#FFFFFF',
			color: '#191919',
			border: '4px solid #ADF000',
			'&:hover': {
				backgroundColor: '#BBFF0C',
				borderColor: '#BBFF0C'
			},
			'&:active': {
				backgroundColor: '#F3F3F3'
			}
		},
		outline_orange: {
			backgroundColor: '#FFFFFF',
			color: '#191919',
			border: '4px solid #FFC800',
			'&:hover': {
				backgroundColor: '#EEBB00',
				borderColor: '#EEBB00'
			},
			'&:active': {
				backgroundColor: '#F3F3F3'
			}
		},
		outline_red: {
			backgroundColor: '#FFFFFF',
			color: '#191919',
			border: '4px solid #FF3364',
			'&:hover': {
				backgroundColor: '#FF3364'
			},
			'&:active': {
				backgroundColor: '#F3F3F3'
			}
		}
	},
	dark: {
		main_blue: {
			backgroundColor: '#8417FF',
			color: '#FFFFFF',
			'&:hover': {
				backgroundColor: '#7800FF'
			},
			'&:active': {
				backgroundColor: '#9435FF'
			}
		},
		main_green: {
			backgroundColor: '#BBFF0C',
			color: '#191919',
			'&:hover': {
				backgroundColor: '#ADF000'
			},
			'&:active': {
				backgroundColor: '#CCFF47'
			}
		},
		main_orange: {
			backgroundColor: '#FFC800',
			color: '#191919',
			'&:hover': {
				backgroundColor: '#EEBB00'
			},
			'&:active': {
				backgroundColor: '#FFD436'
			}
		},
		main_red: {
			backgroundColor: '#FF3364',
			color: '#FFFFFF',
			'&:hover': {
				backgroundColor: '#EE2555'
			},
			'&:active': {
				backgroundColor: '#FF4F79'
			}
		},
		main: {
			backgroundColor: '#191919',
			color: '#FFFFFF',
			'&:hover': {
				backgroundColor: '#191919'
			}
		},
		inverse: {
			backgroundColor: '#FFFFFF',
			color: '#191919',
			'&:hover': {
				backgroundColor: '#F3F3F3'
			}
		},
		custom: {
			backgroundColor: '#191919',
			color: '#FFFFFF'
		},
		outline: {
			backgroundColor: '#191919',
			color: '#FFFFFF',
			border: '4px solid #FFFFFF',
			'&:hover': {
				backgroundColor: '#191919'
			}
		},
		outline_blue: {
			backgroundColor: '#191919',
			color: '#FFFFFF',
			border: '4px solid #8417FF',
			'&:hover': {
				backgroundColor: '#8417FF'
			},
			'&:active': {
				backgroundColor: '#514B60'
			}
		},
		outline_green: {
			backgroundColor: '#191919',
			color: '#FFFFFF',
			border: '4px solid #BBFF0C',
			'&:hover': {
				backgroundColor: '#BBFF0C',
				color: '#191919'
			},
			'&:active': {
				backgroundColor: '#514B60'
			}
		},
		outline_orange: {
			backgroundColor: '#191919',
			color: '#FFFFFF',
			border: '4px solid #FFC800',
			'&:hover': {
				backgroundColor: '#EEBB00',
				borderColor: '#EEBB00',
				color: '#191919'
			},
			'&:active': {
				backgroundColor: '#514B60'
			}
		},
		outline_red: {
			backgroundColor: '#191919',
			color: '#FFFFFF',
			border: '4px solid #FF3364',
			'&:hover': {
				backgroundColor: '#FF3364'
			},
			'&:active': {
				backgroundColor: '#514B60'
			}
		}
	}
};
