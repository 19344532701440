import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, CustomModal, Select } from '@components';
import { images } from '@helpers';
import { userGamificationStore, userStore } from '@stores';
import '../style.css';

export default observer(() => {
	const [sex, setSex] = useState<{id: string, name: string} | null>(null);

	useEffect(() => {
		if (!userGamificationStore.isLoadingHomepage) {
			setSex(options.find(item => item.id === userGamificationStore.gamificationInfo?.sex) ?? null);
		}
	}, [userGamificationStore.isLoadingHomepage]);

	const saveUserSex = useCallback(async () => {
		if (sex?.id) {
			userGamificationStore.changeWelcomeModal(false);
			await userGamificationStore.createDefaultGamification(userStore.user.id, { sex: sex.id });
		}
	}, [sex]);

	const changeSelectSex = useCallback((e, newValue) => {
		setSex(newValue);
	}, []);

	return (
		<CustomModal
			open={userGamificationStore.isShowWelcomeModal}
			handleClose={() => userGamificationStore.changeWelcomeModal(false)} backdropColor='white'
			heightModal='auto' widthModal='700px'
		>
			<div className='gamification-modal'>
				<h3 className='gamification-modal__title'>
          Добро пожаловать в портал
				</h3>
				<div className='gamification-modal__media-block'>
					<div className='gamification-modal__header-block'>
						<div className='gamification-modal__center-block'>
							<img
								className='gamification-modal__image gamification-modal__image_rotate'
								src={images.head3} alt='head'
							/>
							<div className='gamification-modal__level'>
                1
							</div>
						</div>
						<div className='gamification-modal__text'>
              Теперь ты 1 уровня!
						</div>
					</div>
					<iframe
						width='270' height='152' frameBorder='0' allowFullScreen
						src='https://www.youtube.com/embed/DMYZMd6yNP4' title='YouTube video player'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					/>
				</div>
				<div className='gamification-modal__description'>
					Портал смоделирует твое будущее через 10 лет на основании того,<br/>
					как усердно ты учишься и готовишься к экзаменам.<br/><br/>
					Портал Lomonosov School предскажет твою внешность, работу, заработок<br/>
					и даже дом, в котором ты будешь жить.<br/><br/>
					Чем лучше ты будешь учиться, тем больших высот добьется твой аватар.
				</div>
				<div className='gamification-modal__buttons'>
					<div className='gamification-modal__select gamification-modal__select_h50'>
						<Select
							input={{ label: 'Укажите пол' }}
							options={options}
							value={sex}
							onChange={changeSelectSex}
						/>
					</div>
					<div className='gamification-modal__button gamification-modal__button_h50'>
						<Button
							type={sex === null ? 'disabled' : 'main_blue'}
							onClick={saveUserSex}
							disable={sex === null}
						>
              Принять
						</Button>
					</div>
				</div>
			</div>
		</CustomModal>
	);
});

const options: { id: string, name: string }[] = [
	{
		id: 'woman',
		name: 'Девушка'
	},
	{
		id: 'man',
		name: 'Парень'
	}
];
