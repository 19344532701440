import { makeAutoObservable, runInAction } from 'mobx';
import { UserHomeworkService } from '@services';
import {
	ICreateAnswerTaskDto,
	IGetUserHomeworksResponse
} from '@lomonosov/types';
import { userStore } from './user-store';
import { IUserHomework, IUserHomeworkById } from '@interfaces';

class UserHomeworkStore {
	tasks = {
		homework: [] as IUserHomework[],
		journal: [] as IUserHomework[],
		calendar: []
	};

	homework: IUserHomeworkById | null = null;
	isLoadingHomeworkById = true;

	disciplines: IGetUserHomeworksResponse['disciplines'] = [];

	isLoading = {
		homework: false,
		journal: false
	};

	pagination = {
		homework: {
			pages: 1,
			currentPage: 1
		},
		journal: {
			pages: 1,
			currentPage: 1
		}
	};

	filterFields = {
		homework:	defaultFilterFields,
		journal:	defaultFilterFields
	};

	constructor () {
		makeAutoObservable(this);
	}

	resetHomeworkById () {
		this.homework = null;
	}

	/**
	 * Получение всех ДЗ пользователя
	 * @param take количество получаемых записей
	 * @param skip количество записей пропустить
	 * @param route активный экран (homework, journal, calendar)
	 **/
	async getHomeworks (route: 'homework' | 'journal' = 'homework', take: number = 5, skip: number = 0) {
		try {
			this.setIsLoading(route, true);
			const params = ['discipline', 'type']
				.map((value) => this.filterFields[route][value].id !== 'all'
					? `&${value}=${this.filterFields[route][value].id}`
					: ''
				).join('');
			const { data } = await UserHomeworkService.getHomeworks(userStore.user.id, take, skip, params, route);
			runInAction(() => {
				this.tasks[route] = data.homeworks;
				this.disciplines = data.disciplines;
				this.pagination[route].pages = Math.ceil(data.count / take);
			});
		} finally {
			this.setIsLoading(route, false);
		}
	}

	// Получить конкретную домашку по ID
	async getHomeworkById (homeworkId: string) {
		try {
			this.isLoadingHomeworkById = true;
			const { data } = await UserHomeworkService.getHomeworkById(userStore.user.id, homeworkId);
			this.homework = data;
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoadingHomeworkById = false;
		}
	}

	// Создание ответа на ДЗ
	async createAnswer (homeworkId: string, userAnswers: ICreateAnswerTaskDto[], shortId: string) {
		try {
			this.isLoadingHomeworkById = true;
			const { data } = await UserHomeworkService.createAnswer(homeworkId, userAnswers);
			this.homework = data;
			const localHomeworks = JSON.parse(localStorage.getItem('homeworksAnswers') || '[]');
			delete localHomeworks[shortId];
			localStorage.setItem('homeworksAnswers', JSON.stringify(localHomeworks));
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoadingHomeworkById = false;
		}
	}

	// Удаление ответа на ДЗ
	async deleteAnswer (homeworkId: string) {
		try {
			await UserHomeworkService.deleteAnswer(homeworkId);
			this.homework = { ...this.homework!, answers: null };
		} catch (e) {
			console.log(e);
		}
	}

	async changePage (activeScreen: 'homework' | 'journal', page: number, take: number) {
		await this.getHomeworks(activeScreen, take, (page - 1) * take);
		this.pagination[activeScreen].currentPage = page;
	}

	async changeFilterField (newValue, field, activeScreen: 'homework' | 'journal') {
		this.filterFields[activeScreen][field] = newValue;
		this.filterFields[activeScreen].isFiltered = true;
		this.pagination[activeScreen].currentPage = 1;
		await this.getHomeworks(activeScreen, 5, 0);
	}

	resetFilterFields (activeScreen: 'homework' | 'journal') {
		this.filterFields[activeScreen] = defaultFilterFields;
		this.pagination[activeScreen].currentPage = 1;
		this.getHomeworks(activeScreen, 5, 0).then();
	}

	setIsLoading (route: string, state: boolean) {
		this.isLoading[route] = state;
	}
}

const defaultFilterFields = {
	discipline: { id: 'all', name: 'Все предметы' },
	type: { id: 'all', name: 'Все типы' },
	isFiltered: false
};

export const userHomeworkStore = new UserHomeworkStore();
