import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export default (props: DateInputProps) => {
	const { type } = props;
	const theme = localStorage.getItem('theme');
	return (
		<TextField
			{...props}
			type={type}
			fullWidth
			InputLabelProps={{ shrink: true }}
			inputProps={{
				className: theme === 'dark' ? 'date-dark' : ''
			}}
		/>
	);
};

type DateInputProps = TextFieldProps & {
	type: 'datetime-local' | 'date' | 'time',
}
