import React from 'react';
import { observer } from 'mobx-react-lite';
import { adminHomeworkAnswersStore } from '@stores';
import { Button, CommentMentor } from '@components';
import { FileList } from '@blocks';
import { BookIcon, GraduateCap } from 'lomonosov-icons/index';
import './style.css';

export default observer(() => {
	return (
		<div className='control-mentors-details'>
			<p className='control-mentors-details__title'>
				{adminHomeworkAnswersStore.homeworkAnswer?.homework?.template?.name}
			</p>
			<div className='control-mentors-details__description'>
				<div className='control-mentors-details__icon portal-book'>
					<BookIcon/>
				</div>
				<span className='control-mentors-details__text'>
					 {adminHomeworkAnswersStore?.homeworkAnswer?.homework?.direction?.name}
				</span>
				<div className='control-mentors-details__icon portal-cap'>
					<GraduateCap/>
				</div>
				<span className='control-mentors-details__text'>
					{adminHomeworkAnswersStore?.homeworkAnswer?.homework?.discipline?.name}
				</span>
			</div>
			<div className='control-mentors-details__files'>
				<p className='control-mentors-details__files-title'>
					Файл ментора
				</p>
				{adminHomeworkAnswersStore?.homeworkAnswer?.mentorFiles?.length !== 0
					? <FileList files={adminHomeworkAnswersStore?.homeworkAnswer?.mentorFiles} buttonVisible={false}/>
					: <span>Нет прикрепленных файлов</span>
				}
			</div>
			{adminHomeworkAnswersStore.homeworkAnswer.comment &&
      <CommentMentor
      	teacher={adminHomeworkAnswersStore.homeworkAnswer.mentor}
      	comment={adminHomeworkAnswersStore.homeworkAnswer.comment}
      	extraScores={adminHomeworkAnswersStore.homeworkAnswer.extraScores}
      />
			}
			<p className='control-mentors-details__scores'>
				{adminHomeworkAnswersStore.homeworkAnswer.scores} / {adminHomeworkAnswersStore.homeworkAnswer.maxScores}
			</p>
			<a
				href={`/admin/homework-answer/${adminHomeworkAnswersStore.homeworkAnswer.id}`}
				className='control-mentors-details__button'
				target='_blank' rel='noreferrer'
			>
				<Button type='main_blue'>Подробнее</Button>
			</a>
		</div>
	);
});
