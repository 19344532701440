import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import { CustomModal } from '@components';
import { constants, imageUrl } from '@helpers';
import './style.css';

interface FileListProps {
  files: any,
  action?: (id: string, index?:number) => void,
  buttonVisible?: boolean,
  className?: string
}

export default observer((props: FileListProps) => {
	const { files, action, buttonVisible = true, className = '' } = props;

	const [openImage, setOpenImage] = useState(false);
	const [fileId, setFileId] = useState();

	const setFileModal = (bool: boolean, file) => {
		setOpenImage(bool);
		setFileId(file);
	};

	return (
		<div className={`file-list ${className}`}>
			{files?.map((file, index) =>
				!buttonVisible
					? audioTypes.includes(file.mimetype)
						? <audio key={file.id} className='file-list__player' controls src={`${constants.URL}/files/${file.id}`}/>
						: imageTypes.includes(file.mimetype)
							? <a key={file.id} className='file-list__link'
								onClick={() => setFileModal(true, file)}>
								<img src={imageUrl(file)} className='file-list__link-image' alt='Задание в картинке'/>
							</a>
							: <a key={file.id} className='file-list__file' href={`${constants.URL}/files/${file.id}`}
								target='_blank' rel='noreferrer'>
								<img className='file-list__file-preview' src={imageUrl(file)} alt='error'/>
								<div className='file-list__file-title theme-text'>
									{file.originalname}
								</div>
							</a>
					: <div key={file.id} className='file-list__file'>
						{audioTypes.includes(file.mimetype)
							? <audio controls src={`${constants.URL}/files/${file.id}`}/>
							: <>
								<img className='file-list__file-preview' src={imageUrl(file)} alt='error'/>
								<div className='file-list__file-title'>
									{file.originalname}
								</div>
							</>
						}
						<IconButton className='file-list__file-delete-button' onClick={() => action ? action(file.id, index) : null}>
							<CancelIcon className='file-list__file-delete-button-icon'/>
						</IconButton>
					</div>
			)}
			<CustomModal open={openImage} viewClose='cross' heightModal='auto' widthModal='auto' handleClose={() => setOpenImage(false)}>
				<div className='file-list__modal-images'>
					<img src={imageUrl(fileId)} alt='error'/>
				</div>
			</CustomModal>
		</div>
	);
});

const audioTypes = ['audio/mpeg', 'audio/mp4'];
const imageTypes = ['image/jpeg', 'image/png'];
