import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { userReferralStore } from '@stores';
import { Input } from '@components';
import { AdminReferralTable } from '../../admin-components';

import './style.css';

export default observer((props: any) => {
	const { data } = props;

	useEffect(() => {
		if (userReferralStore.userRefInfo.id !== data.id) {
			userReferralStore.userRefInfo(data.id);
		}
	}, []);

	return (
		<div className='detail-referral-info theme-block-background'>
			<div className='detail-referral-info__fields'>
				<div className='detail-referral-info__field'>
					<Input label='ФИО заявителя' value={data.fio} disabled/>
				</div>
				<div className='detail-referral-info__field'>
					<Input label='Сумма вывода' value={data.sum} disabled/>
				</div>
				<div className='detail-referral-info__field'>
					<Input label='Банк' value={data.bank} disabled/>
				</div>
				<div className='detail-referral-info__field'>
					<Input label='Реквизиты' value={data.requisite} disabled/>
				</div>
			</div>
			<div className='detail-referral-info__title'>Рефералы</div>
			{/* <AdminReferralTable referrals={withdrawalRequestsStore.currentReferral.referralUsers} /> */}
			<AdminReferralTable referrals={userReferralStore.userRefInfo.referralInformation.invitedReferrals} />
		</div>
	);
});
