import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, CustomModal, Select } from '@components';
import timezones from './timezones';

import './style.css';

interface CalendarChangeTimezoneModalProps {
	isOpen: boolean;
	handleClose: () => void;
}

const CalendarChangeTimezoneModal = observer(({ isOpen, handleClose }: CalendarChangeTimezoneModalProps) => {
	const [localTimezone, setLocalTimezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);

	useEffect(() => {
		const currentLocalTimezone = localStorage.getItem('localTimezone') || Intl.DateTimeFormat().resolvedOptions().timeZone;
		setLocalTimezone(currentLocalTimezone);
	}, []);

	const setTimezone = (value) => {
		setLocalTimezone(value.timezone);
	};

	const changeLocalTimezone = () => {
		localStorage.setItem('localTimezone', localTimezone);

		handleClose();
		window.location.reload();
	};

	const getCurrentTimezone = () => {
		return timezones.find(tz => tz.timezone === localTimezone);
	};

	return (
		<CustomModal
			open={isOpen}
			backdropColor='white'
			handleClose={handleClose}
			viewClose='cross'
			widthModal='500px'
		>
			<div className='calendar-change-timezone-modal'>
				<div className='calendar-change-timezone-modal__info'>
					<h3 className='calendar-change-timezone-modal__info__title'>Изменить часовой пояс</h3>
					<div className='calendar-change-timezone-modal__info__desc'>Время в календаре будет показано в выбранном часовом поясе</div>
					<Select
						input={{ label: 'Часовой пояс' }}
						options={timezones}
						value={getCurrentTimezone()}
						onChange={(_, value) => { setTimezone(value); }}
						className='calendar-change-timezone-modal__info__select'
					/>
				</div>

				<Button type='custom' className='calendar-change-timezone-modal__button' onClick={() => changeLocalTimezone()}>
					Сохранить
				</Button>
			</div>
		</CustomModal>
	);
});

export default CalendarChangeTimezoneModal;
