import { makeAutoObservable, runInAction } from 'mobx';
import { IUpdateTeacherDto } from '@lomonosov/types';
import { AdminTeacherService } from '@services';
import { adminUserStore } from './admin-user-store';
import { ICurrentCourseProps } from '@interfaces';

class AdminTeacherStore {
	teachers = [] as ICurrentCourseProps['teacher'][];
	totalCount: number = 0;

	constructor () {
		makeAutoObservable(this);
	}

	async getTeachers (page: number = 0, pageSize: number = 5, search: string = '') {
		try {
			const { data } = await AdminTeacherService.getTeachers(page, pageSize, search);
			runInAction(() => {
				this.teachers = data.teachers;
				this.totalCount = data.count;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async createTeacher (userId: string) {
		try {
			const { data } = await AdminTeacherService.createTeacher(userId);
			runInAction(() => {
				this.teachers.push(data);
				adminUserStore.currentUser.teacher = data;
			});
			return data;
		} catch (e) {
			console.log(e);
		}
	}

	async updateTeacher (teacherId: string, teacher: IUpdateTeacherDto) {
		try {
			const { data } = await AdminTeacherService.updateTeacher(teacher, teacherId);
			runInAction(() => {
				this.teachers = this.teachers.map(teacher => teacher.id === teacherId ? data : teacher);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async deleteTeacherRole (teacherId: string) {
		try {
			await AdminTeacherService.deleteTeacher(teacherId);
			runInAction(() => {
				adminUserStore.currentUser.teacher = undefined;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async deleteTeacher (id: string) {
		try {
			await AdminTeacherService.deleteTeacher(id);
			runInAction(() => {
				this.teachers = this.teachers.filter(teacher => teacher.id !== id);
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const adminTeacherStore = new AdminTeacherStore();
