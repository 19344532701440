import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Loader, TabPanel } from '@components';
import { userGamificationStore, userStore } from '@stores';
import {
	GamificationHomepageNew,
	GamificationProfession,
	LevelUpModal,
	NotificationModal,
	SalaryModal,
	WelcomeModal,
	GamificationHeader
} from './gamification-blocks';

import './style.css';

export default observer(() => {
	useEffect(() => {
		if (userStore.user.id && (userGamificationStore.isLoadingHomepage || !userGamificationStore.gamificationInfo)) {
			userGamificationStore.getUserGamification(userStore.user.id);
		}
		// if (Number(localStorage.getItem('level')) < userInfo.user.level) {
		// 	gamificationStore.changeLevelUpModal(true);
		// }
	}, [userStore.user.id]);

	const equippedItems = useMemo(() => ({
		body: userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'body'),
		head: userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'head'),
		room: userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'room'),
		sofa: userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'sofa'),
		carpet: userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'carpet'),
		electrolier: userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'electrolier'),
		furniture: userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'furniture'),
		centralWallDetail: userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'central-wall-detail'),
		leftWallDetail: userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'left-wall-detail'),
		rightWallDetail: userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'right-wall-detail'),
		car: userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'car')
	}), [userGamificationStore.gamificationInfo]);

	return (
		<div className='gamification'>
			{!userGamificationStore.isLoadingHomepage &&
				<GamificationHeader />
			}
			<div>
				<TabPanel name='homepage' value={userGamificationStore.activeScreen}>
					{userGamificationStore.isLoadingHomepage
						? <Loader/>
						: <GamificationHomepageNew/>
					}
				</TabPanel>
				<TabPanel name='profession' value={userGamificationStore.activeScreen}>
					<GamificationProfession equippedItems={equippedItems}/>
				</TabPanel>
				<LevelUpModal/>
				<WelcomeModal/>
				<SalaryModal/>
				<NotificationModal/>
			</div>
		</div>
	);
});
