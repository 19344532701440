import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Input, Table, CustomModal } from '@components';
import { PurchasesModal } from '../index';
import { adminReferralStore, adminUserStore } from '@stores';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import './style.css';

export default observer((props: any) => {
	const { dataL } = props;
	const [currentRefs, setCurrentRefs] = useState<any>([]);
	const [currentUser, setCurrentUser] = useState<any>();

	useEffect(() => {
		adminReferralStore.getWithdrawalById(dataL.id)
			.then(() => setCurrentRefs(adminReferralStore.currentWithdrawal.referral?.invitedReferrals));
	}, []);

	const openPurchasesModal = (user) => {
		adminUserStore.getUserPurchases(user.user.id).then(() => setCurrentUser(user));
	};

	return (
		<div className='detail-referral-info'>
			<div className='detail-referral-info__fields'>
				<div className='detail-referral-info__field'>
					<Input label='ФИО заявителя' value={dataL.name} disabled/>
				</div>
				<div className='detail-referral-info__field'>
					<Input label='Сумма вывода' value={dataL.withdrawalFunds} disabled/>
				</div>
				<div className='detail-referral-info__field'>
					<Input label='Банк' value={dataL.bank} disabled/>
				</div>
				<div className='detail-referral-info__field'>
					<Input label='Реквизиты' value={dataL.requisite} disabled/>
				</div>
			</div>
			<div className='detail-referral-info__title'>Рефералы</div>
			<Table
				columns={[
					{ field: 'name', name: 'ФИО' },
					{ field: 'referralStatus', name: 'Статус' },
					{ field: 'referralUserPrice', name: 'Сумма' }
				]}
				data={currentRefs}
				actionRow={{
					custom: {
						icon: <ShoppingCartIcon sx={{ color: '#FFC800' }}/>,
						action: (id, data) => openPurchasesModal(data),
						tooltip: 'Покупки'
					}
				}}
			/>
			<CustomModal
				open={!!currentUser}
				handleClose={() => setCurrentUser(null)}
				viewClose='cross'
			>
				<PurchasesModal user={currentUser}/>
			</CustomModal>
		</div>
	);
});
