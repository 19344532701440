import { useEffect } from 'react';
import { userStore, authStore } from '@stores';
import { QueryParams } from '@helpers';
import { useNavigate } from 'react-router-dom';

export const useAuthCallback = () => {
	const ssoToken = localStorage.getItem(QueryParams.SSO_TOKEN);
	const navigate = useNavigate();

	useEffect(() => {
		(async () => {
			if (ssoToken) {
				await	authStore.installSSOSession(ssoToken);
			}
			// else {
			// 	const hasActiveSession = await authStore.hasActiveSession();
			// 	hasActiveSession
			// 		? window.location.replace(`${process.env.SSO_AUTH_URI}/auth/login`)
			// 		: await authStore.logout();
			// }
		})();
	}, [ssoToken]);

	useEffect(() => {
		(async () => {
			if (localStorage.getItem(QueryParams.TOKEN)) {
				if (!userStore.user.id) {
					await userStore.getUserInfo();

					/** КОСТЫЛЬ! УБРАТЬ В ПН */
					await userStore.checkCareerGuidance(userStore.user.id);
				}
				const navigationPath = localStorage.getItem(QueryParams.NAVIGATION_PATH);
				localStorage.removeItem(QueryParams.NAVIGATION_PATH);
				switch (window.location.pathname) {
				case '/login': navigate(navigationPath ?? '/study-program'); break;
				case '/register': navigate(navigationPath ?? '/study-program'); break;
				case '/password-recovery': navigate(navigationPath ?? '/study-program'); break;
				default: break;
				}
			}
		})();
	}, [localStorage.getItem(QueryParams.TOKEN), authStore.isAuth]);
};
