import { makeAutoObservable, runInAction } from 'mobx';

import { UserReferralService } from '@services';
import { IWithdrawalHistory } from '@interfaces';

import { userStore } from './user-store';

class UserReferralStore {
	loader: boolean = true;
	userRefInfo: any = {};
	invitedReferrals: any = [];
	withdrawHistory: IWithdrawalHistory[] | any[] = [];
	withdrawInfo: any = defaultWithdrawInfo;

	constructor () {
		makeAutoObservable(this);
	}

	async getRefInfo () {
		try {
			const { data } = await UserReferralService.getRefInfo(userStore.user.id);
			runInAction(() => {
				this.userRefInfo = data;
				this.invitedReferrals = data.referralInformation.invitedReferrals;
				this.getWithdrawHistory();
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.loader = false;
		}
	}

	async getWithdrawHistory () {
		try {
			const { data } = await UserReferralService.getRefHistory(userStore.user.id);
			runInAction(() => {
				this.withdrawHistory = data.withdrawalReferrals;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async createWithdraw (withdrawInfo) {
		try {
			const { data } = await UserReferralService.createWithdraw({
				...withdrawInfo,
				bank: withdrawInfo.bank.name,
				name: `${withdrawInfo.secondName} ${withdrawInfo.firstName} ${withdrawInfo.lastName}`
			});
			runInAction(() => {
				this.withdrawHistory.push(data);
				this.userRefInfo.withdrawalFunds = 0;
				this.withdrawInfo = defaultWithdrawInfo;
			});
		} catch (e) {
			console.log(e);
		}
	}

	setWithdrawInfo (field, value) {
		this.withdrawInfo[field] = value;
	}
}

const defaultWithdrawInfo = {
	firstName: '',
	secondName: '',
	lastName: '',
	requisite: '',
	bank: { id: 'sber', name: 'Сбер' }
};

export const userReferralStore = new UserReferralStore();
