import { $api } from '@helpers';
import { AxiosResponse } from 'axios';
import {
	IGiveCoursesPurchaseResponse,
	ICreateAdminPurchaseDto,
	IGetAllTariffPlansResponse,
	IUpdateTariffPlanResponse,
	ICreateTariffPlanResponse,
	ICreateTariffPlanDto,
	IDeleteTariffPlanResponse,
	ICreateShopButtonDto,
	IRemoveShopButtonResponse,
	IBulkEditShopButtonDto,
	IBulkEditShopButtonResponse,
	ICreateTariffDto,
	ICreateTariffResponse,
	IUpdateTariffDto,
	IUpdateTariffResponse,
	IDeleteTariffResponse
} from '@lomonosov/types';
import { GetTariffPlanByIdResponse, ICreateAdminShopButtonResponse, IGetAdminShopButtonsItemResponse, IUpdateAdminShopButtonDto, UpdateTariffPlanDto } from '@interfaces';

export default class {
	static async createPurchase (purchase: ICreateAdminPurchaseDto): Promise<AxiosResponse<IGiveCoursesPurchaseResponse>> {
		return $api.post<IGiveCoursesPurchaseResponse>('/purchases/admin', purchase);
	}

	static async getTariffPlans (): Promise<AxiosResponse<IGetAllTariffPlansResponse>> {
		return $api.get<IGetAllTariffPlansResponse>('/tariff-plans');
	}

	static async getCurrentTariffPlan (tariffPlanId: string): Promise<AxiosResponse<GetTariffPlanByIdResponse>> {
		return $api.get<GetTariffPlanByIdResponse>(`/tariff-plans/${tariffPlanId}`);
	}

	static async updateTariffPlan (
		tariffPlanId: string,
		tariffplan: UpdateTariffPlanDto
	): Promise<AxiosResponse<IUpdateTariffPlanResponse>> {
		return $api.put<IUpdateTariffPlanResponse>(`/tariff-plans/${tariffPlanId}`, tariffplan);
	}

	static async addTariffPlan (tariffplan: ICreateTariffPlanDto): Promise<AxiosResponse<ICreateTariffPlanResponse>> {
		return $api.post<ICreateTariffPlanResponse>('/tariff-plans', tariffplan);
	}

	static async deleteTariffPlan (tariffPlanId: string): Promise<AxiosResponse<IDeleteTariffPlanResponse>> {
		return $api.delete<IDeleteTariffPlanResponse>(`/tariff-plans/${tariffPlanId}`);
	}

	static async addTariff (tariff: ICreateTariffDto): Promise<AxiosResponse<ICreateTariffResponse>> {
		return $api.post<ICreateTariffResponse>('/tariffs', tariff);
	}

	static async updateTariff (
		tariffId: string,
		tariff: IUpdateTariffDto
	): Promise<AxiosResponse<IUpdateTariffResponse>> {
		return $api.put<IUpdateTariffResponse>(`/tariffs/${tariffId}`, tariff);
	}

	static async deleteTariff (tariffId: string): Promise<AxiosResponse<IDeleteTariffResponse>> {
		return $api.delete<IDeleteTariffResponse>(`/tariffs/${tariffId}`);
	}

	static async updateTariffsOrder (data: { tariffs: { id: string, order: number }[]}): Promise<AxiosResponse<boolean>> {
		return $api.put<boolean>('/tariffs/ordering', data);
	}

	static async getButtonsShop (id?: string): Promise<AxiosResponse<IGetAdminShopButtonsItemResponse[]>> {
		return $api.get<IGetAdminShopButtonsItemResponse[]>(`/shop-buttons?type=admin&parentButtonId=${id ? `${id}` : 'null'}`);
	}

	static async getButtonShopById (id: string): Promise<AxiosResponse<IGetAdminShopButtonsItemResponse[]>> {
		return $api.get<IGetAdminShopButtonsItemResponse[]>(`/shop-buttons/${id}?type=admin`);
	}

	static async createShopButton (data: ICreateShopButtonDto): Promise<AxiosResponse<ICreateAdminShopButtonResponse>> {
		return $api.post<ICreateAdminShopButtonResponse>('/shop-buttons', data);
	}

	static async editShopButton (data: IUpdateAdminShopButtonDto, id: string): Promise<AxiosResponse<ICreateAdminShopButtonResponse>> {
		return $api.put<ICreateAdminShopButtonResponse>(`/shop-buttons/${id}`, data);
	}

	static async editShopButtons (data: IBulkEditShopButtonDto): Promise<AxiosResponse<IBulkEditShopButtonResponse>> {
		return $api.put<IBulkEditShopButtonResponse>('/shop-buttons', data);
	}

	static async deleteShopButton (id: string): Promise<AxiosResponse<IRemoveShopButtonResponse>> {
		return $api.delete<IRemoveShopButtonResponse>(`/shop-buttons/${id}`);
	}
}
