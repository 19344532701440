import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	IGetAllBannersResponse
} from '@lomonosov/types';

export default class {
	/* Баннеры  */
	static async getBanners (userId: string): Promise<AxiosResponse<IGetAllBannersResponse>> {
		return $api.get<IGetAllBannersResponse>('/banners', { params: { userId, isActive: true } });
	}
}
