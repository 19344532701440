import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';
import { FormControlLabel, Switch } from '@mui/material';
import {
	IGetTariffPlanByIdResponse,
	IGetCategoriesResponse,
	IGetDirectionsResponse,
	ICreateTariffDto,
	IUpdateTariffDto
} from '@lomonosov/types';
import { Select, Table, Button, DeleteModal } from '@components';
import DetailTableTariffs from '../detailTableTariffs';
import { IColumn } from '@components/table';
import { adminTariffPlansStore } from '@stores';
import Input from '@components/input';
import { DiscountPerCount } from '@interfaces';

interface IData {
	data: IGetTariffPlanByIdResponse
	category: IGetCategoriesResponse
	direction: IGetDirectionsResponse,
	dataL: any
}

export default observer((props: IData) => {
	const {
		category,
		direction,
		data,
		dataL
	} = props;

	const [currentCategory, setCurrentCategory] = useState(data.category);
	const [currentDirection, setCurrentDirection] = useState(data.direction);
	const [tariffs, setTariffs] = useState<any>(data.tariffs || []);
	const [showInfo, setShowInfo] = useState<boolean>(false);
	const [columnsInfo, setColumnsInfo] = useState<(IColumn & { id: string })[]>([]);
	const [dataInfo, setDataInfo] = useState<any[] | any>(null);
	const [isComparisonTableChanged, setIsComparisonTableChanged] = useState<boolean>(false);
	const [deletableTariffId, setDeletableTariffId] = useState<string>('');
	const [discountPerCount, setDiscountPerCount] = useState<Record<DiscountPerCount, number>>({
		[DiscountPerCount.FIRST]: dataL?.discountPerCount?.first || 0,
		[DiscountPerCount.SECOND]: dataL?.discountPerCount?.second || 0,
		[DiscountPerCount.THIRD]: dataL?.discountPerCount?.third || 0,
		[DiscountPerCount.FOURTH]: dataL?.discountPerCount?.fourth || 0
	});

	useEffect(() => {
		if (data.comparisonTable) {
			const comparisonTable = JSON.parse(data.comparisonTable);
			setColumnsInfo(comparisonTable.columns);
			setDataInfo(comparisonTable.data);
			setShowInfo(!!comparisonTable.data);
		} else {
			const id = v4();
			setColumnsInfo([
				{ id: id, field: 'desc', name: 'Свойство' },
				...tariffs.map(tariff => ({ id: tariff.id, field: tariff.id, name: tariff.name }))
			]);
		}
	}, [data.comparisonTable]);

	const addInfo = (state: any) => {
		setDataInfo([...dataInfo, { ...state, id: v4() }]);
		setIsComparisonTableChanged(true);
	};

	const editInfo = (id: string, state: any) => {
		const i = dataInfo.findIndex((t) => t.id === id);
		dataInfo[i] = state;
		setDataInfo([...dataInfo]);
		setIsComparisonTableChanged(true);
	};

	const deleteInfo = (id: string) => {
		setDataInfo(dataInfo.filter((t) => t.id !== id));
		setIsComparisonTableChanged(true);
	};

	const addTariff = (state: any) => {
		const id = v4();
		adminTariffPlansStore.changeCurrentTariff(
			{ ...state, periods: [], description: '', subtitle: '', _id: id }
		);
		setTariffs([
			...tariffs,
			{
				...state,
				_id: id,
				periods: [],
				description: '',
				subtitle: '',
				tariffPlanId: dataL.id
			}]);
		setColumnsInfo([...columnsInfo, {
			id: id,
			field: id,
			name: state.name
		}]);
	};

	const editTariff = (id: string, state: any) => {
		adminTariffPlansStore.changeCurrentTariff(state);
		const info = columnsInfo.findIndex((i) => i.id === id);
		columnsInfo[info] = {
			id: id,
			field: id,
			name: state.name
		};
		const i = tariffs.findIndex((t) => t.id === id);
		tariffs[i] = state;
		setTariffs([...tariffs]);
		saveTariff();
	};

	const deleteTariff = async () => {
		setColumnsInfo(columnsInfo.filter((c: any) => c.id !== deletableTariffId));
		setTariffs(tariffs.filter((t) => t.id !== deletableTariffId));
		adminTariffPlansStore.changeCurrentTariff(null);
		if (deletableTariffId) {
			await adminTariffPlansStore.deleteTariff(deletableTariffId);
		}
		setDeletableTariffId('');
	};

	/// /////////////////////////////////////////////////////////

	const addPeriod = (idTariff: string, state: any) => {
		adminTariffPlansStore.changeCurrentTariff(tariffs.find(t => t.id === idTariff));
		const tariffIndex = tariffs.findIndex((t) => t.id === idTariff);
		if (tariffs[tariffIndex].periods === undefined) { tariffs[tariffIndex].periods = []; }
		tariffs[tariffIndex].periods = [...tariffs[tariffIndex].periods, state];
	};

	const editPeriod = (idTariff: string, id: string, state: any) => {
		adminTariffPlansStore.changeCurrentTariff(tariffs.find(t => t.id === idTariff));
		const tariffIndex = tariffs.findIndex((t) => t.id === idTariff);
		const periodIndex = tariffs[tariffIndex].periods.findIndex((p) => p.id === id);
		tariffs[tariffIndex].periods[periodIndex] = state;
	};

	const deletePeriod = (idTariff: string, id: string) => {
		adminTariffPlansStore.changeCurrentTariff(tariffs.find(t => t.id === idTariff));
		const tariffIndex = tariffs.findIndex((t) => t.id === idTariff);
		tariffs[tariffIndex].periods = tariffs[tariffIndex].periods.filter((p) => p.id !== id);
	};

	const changeDescription = (idTariff: string, str: string) => {
		adminTariffPlansStore.changeCurrentTariff(tariffs.find(t => t.id === idTariff));
		const tariffIndex = tariffs.findIndex((t) => t.id === idTariff);
		tariffs[tariffIndex].description = str;
	};

	const changeSubTitle = (idTariff: string, str: string) => {
		adminTariffPlansStore.changeCurrentTariff(tariffs.find(t => t.id === idTariff));
		const tariffIndex = tariffs.findIndex((t) => t.id === idTariff);
		tariffs[tariffIndex].subtitle = str;
	};

	const saveTariff = async () => {
		const tariff = adminTariffPlansStore.currentTariff;
		tariffs.forEach((tariff, indexT) => {
			tariff.order = indexT;
			if (tariff.periods) {
				tariff.periods.forEach((period, indexP) => {
					period.order = indexP;
					period.price = +period.price;
					period.marketingDiscount = +period.marketingDiscount;
				});
			}
		});
		if (tariff) {
			if (tariff.id) {
				const updatedTariff = tariffs.find(item => item.id === tariff.id);
				await adminTariffPlansStore.updateTariff(tariff?.id, updatedTariff as IUpdateTariffDto);
			} else {
				const newTariff = tariffs.find(item => item._id === tariff._id);
				await adminTariffPlansStore.addTariff(newTariff as ICreateTariffDto);
				if (adminTariffPlansStore.tariff) {
					const newTariffs = tariffs.filter(item => item._id !== tariff._id);
					setTariffs([...newTariffs, adminTariffPlansStore.tariff]);
				}
			}
			if (tariffs.filter(item => item.id).length > 1) {
				await adminTariffPlansStore.updateTariffsOrder({
					tariffs: tariffs.filter(item => item.id).map(tariff =>
						({
							id: tariff.id,
							order: tariff.order
						})
					)
				});
			}
		}
	};

	const saveComparisonTariffs = () => {
		const info: any = {
			columns: columnsInfo,
			data: dataInfo
		};
		adminTariffPlansStore.updateTariffPlan(dataL.id, {
			...dataL,
			categoryId: undefined,
			directionId: undefined,
			comparisonTable: dataInfo === null ? '' : JSON.stringify(info),
			discountPerCount
		});
		setIsComparisonTableChanged(false);
	};

	const onChangeDiscount = (e) => {
		const num: number = Number(e.target.value) || 0;
		const name: DiscountPerCount = e.target.name as DiscountPerCount;
		setDiscountPerCount({
			...discountPerCount,
			[name]: num
		});
		setIsComparisonTableChanged(true);
	};

	return (
		data
			? <div className='tarifPlan__table-detailPanel'>
				<div className='tarifPlan__table-detailPanel-header'>
					<h4 className='tarifPlan__table-detailPanel-header-title'>
						{data.name}
					</h4>
					<div className='tarifPlan__table-detailPanel-header-info'>
						<div className='tarifPlan__table-detailPanel-header-info-item'> id: {data.id} </div>
					</div>
					<div className='tarifPlan__table-detailPanel-header-info'>
						<div className='tarifPlan__table-detailPanel-header-info-item'> Заметки: {data.description} </div>
					</div>
				</div>
				<div className='tarifPlan__table-detailPanel-selects'>
					<Select
						input={{ label: 'Категория' }}
						options={category}
						value={currentCategory}
						onChange={(e, value) => { setCurrentCategory(value); }}
						className='tarifPlan__table-detailPanel-selects-select'
						disabled
					/>
					<Select
						input={{ label: 'Направление' }}
						options={direction}
						value={currentDirection}
						onChange={(e, value) => { setCurrentDirection(value); }}
						className='tarifPlan__table-detailPanel-selects-select'
						disabled
					/>
				</div>
				<h4 className='tarifPlan__title'>
					Скидки, %
				</h4>
				<div className='tarifPlan__discountsPerCount'>
					<Input
						name={DiscountPerCount.FIRST}
						value={discountPerCount?.first}
						onChange={onChangeDiscount}
						type='number'
						label='Первый курс'
					/>
					<Input
						name={DiscountPerCount.SECOND}
						value={discountPerCount?.second}
						onChange={onChangeDiscount}
						type='number'
						label='Второй курс'
					/>
					<Input
						name={DiscountPerCount.THIRD}
						value={discountPerCount?.third}
						onChange={onChangeDiscount}
						type='number'
						label='Третий курс'
					/>
					<Input
						name={DiscountPerCount.FOURTH}
						value={discountPerCount?.fourth}
						onChange={onChangeDiscount}
						type='number'
						label='Четвертый курс'
					/>
				</div>
				<Table
					swap={(arr) => setTariffs(arr)}
					title='Тарифы'
					columns={columnsTariffs}
					detailPanel={{
						Component: (props) =>
							<DetailTableTariffs
								{...props}
								actions={{
									addPeriod,
									editPeriod,
									deletePeriod,
									changeDescription,
									changeSubTitle,
									saveTariff
								}}
							/>
					}}
					data={tariffs}
					styleOption={{ size: 'small' }}
					className='tarifPlan__table-detailPanel-tariffs'
					actionRow={{
						add: addTariff,
						delete: setDeletableTariffId,
						edit: editTariff
					}}
				/>
				<div className='tarifPlan__modal-inputs-switch tarifPlan__modal-inputs-input'>
					<FormControlLabel
						control={
							<Switch
								value={showInfo}
								checked={showInfo}
								onChange={(v) => {
									setShowInfo(v.target.checked);
									setDataInfo(v.target.checked ? [] : null);
									setIsComparisonTableChanged(true);
								}}
							/>
						}
						label='Таблица сравнения тарифов'
						labelPlacement='start'
					/>
				</div>
				{showInfo &&
					<Table
						className='tarifPlan__table-detailPanel-tariffs'
						title='Таблица сравнения тарифов'
						columns={columnsInfo}
						data={dataInfo || []}
						styleOption={{
							size: 'small',
							classNameRow: 'tarifPlan__table-detailPanel-tariffs-a'
						}}
						actionRow={{
							add: addInfo,
							delete: deleteInfo,
							edit: editInfo
						}}
					/>
				}
				<div className='tarifPlan__modal-button'>
					<Button
						type='main_green'
						onClick={saveComparisonTariffs}
						disable={!isComparisonTableChanged}
					>
						Сохранить
					</Button>
				</div>
				<div className='tarifPlan__table-detailPanel-tariffs'>
					<h4>Список подключенных курсов</h4>
					{data.courses.map(course =>
						<div key={course.id}>
							<span>{course.name}</span>
						</div>
					)}
				</div>
				<DeleteModal
					deleteName={tariffs.find(tariff => tariff.id === deletableTariffId)?.name}
					open={!!deletableTariffId}
					handleClose={() => setDeletableTariffId('')}
					action={deleteTariff}
				/>
			</div>
			: <div>no</div>
	);
});

const columnsTariffs: IColumn[] = [
	{ field: 'isActive', name: 'Активен', type: 'boolean', width: 30, defaultValue: true },
	{ field: 'isRecommended', name: 'Рекомендован', type: 'boolean', width: 30, defaultValue: false },
	{ field: 'name', name: 'Название' }
];
