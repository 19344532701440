import React, { lazy } from 'react';

import { MainLayout, AuthMainLayout } from '../../layouts';
const PasswordRecovery = lazy(() => import('../../../pages/main-pages/auth/password-recovery'));
const Login = lazy(() => import('../../../pages/main-pages/auth/login'));
const Register = lazy(() => import('../../../pages/main-pages/auth/register'));
const NotFound = lazy(() => import('../../../pages/main-pages/not-found'));
const TeacherChat = lazy(() => import('../../../pages/main-pages/chat'));
const Error = lazy(() => import('../../../pages/main-pages/error-boundary-page'));

export default {
	path: '/',
	children: [
		{ path: '/chat', element: <TeacherChat/> },
		{ path: '/error', element: <Error/> },
		{
			path: '/',
			element: <AuthMainLayout/>,
			children: [
				{ path: '/login', element: <Login/> },
				{ path: '/password-recovery', element: <PasswordRecovery/> },
				{ path: '/register', element: <Register/> }
			]
		},
		{
			path: '/',
			element: <MainLayout/>,
			children: [
				{ path: '*', element: <NotFound/> }
			]
		}
	]
};
