import React from 'react';
import { observer } from 'mobx-react-lite';
import { DateInput, Select } from '@components';
import { adminInvoiceStore } from '@stores';
import './style.css';

export default observer(() => {
	const onChangeIsClose = (_e, value) => {
		adminInvoiceStore.changeFieldFiltering(value, 'isClose');
		adminInvoiceStore.getInvoices(5, 0);
	};

	const onChangeStatus = (_e, value, reason) => {
		adminInvoiceStore.changeFieldFiltering(value, 'status');
		if (reason === 'clear') {
			adminInvoiceStore.changeFieldFiltering(null, 'status');
		}
		adminInvoiceStore.getInvoices(5, 0);
	};

	const onChangeDate = (e) => {
		adminInvoiceStore.changeFieldFiltering(e.target.value, e.target.name);
		if (adminInvoiceStore.filtering?.start && adminInvoiceStore.filtering?.end) {
			adminInvoiceStore.getInvoices(5, 0);
		}
	};

	return (
		<div className='filtering-invoice'>
			<Select
				options={CloseOpenInvoices}
				input={{
					label: 'Закрытые/Открытые счета'
				}}
				value={adminInvoiceStore.filtering?.isClose}
				onChange={onChangeIsClose}
			/>
			<Select
				options={StatusInvoices}
				input={{
					label: 'Статус'
				}}
				value={adminInvoiceStore.filtering?.status}
				onChange={onChangeStatus}
			/>
			<DateInput
				name='start'
				onChange={onChangeDate}
				value={adminInvoiceStore.filtering?.start || null}
				label='Период от' type='date'
			/>
			<DateInput
				name='end'
				onChange={onChangeDate}
				value={adminInvoiceStore.filtering?.end || null}
				label='Период до' type='date'
			/>
		</div>
	);
});

const CloseOpenInvoices = [
	{
		id: 'close',
		name: 'Закрытые'
	},
	{
		id: 'open',
		name: 'Открытые'
	}
];

const StatusInvoices = [
	{
		id: 'red',
		name: 'Счет без покупателя'
	},
	{
		id: 'orange',
		name: 'Активирован покупателем'
	},
	{
		id: 'yellow',
		name: 'Покупатель перешел на форму оплаты'
	},
	{
		id: 'green',
		name: 'Покупатель оплатил'
	}
];
