import { makeAutoObservable, runInAction } from 'mobx';
import { IGetAllMentorsResponse } from '@lomonosov/types';
import { AdminMentorsService } from '@services';
import { adminUserStore } from './admin-user-store';

class AdminMentorsStore {
	mentors: IGetAllMentorsResponse['mentors'] = [];
	students: any = [];
	pageCount: number = 0;

	constructor () {
		makeAutoObservable(this);
	}

	async getMentors (page = 0, pageSize = 5, search = '') {
		try {
			const { data } = await AdminMentorsService.getMentors(pageSize, pageSize * page, search);
			runInAction(() => {
				this.mentors = data.mentors;
				this.pageCount = data.count;
			});
		} catch (e) {
			console.log(e);
		}
	}

	setStudentMentors (students) {
		this.students = students;
	}

	async getMentorStudents (page = 0, pageSize = 5, mentorId: string, search: string = '') {
		try {
			const { data } = await AdminMentorsService.getMentorStudents(pageSize, pageSize * page, mentorId, search);
			runInAction(() => {
				this.students = data.students;
				this.pageCount = data.count;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async createMentor (userId: string) {
		try {
			const { data } = await AdminMentorsService.createMentor(userId);
			runInAction(() => {
				adminUserStore.currentUser.mentor = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async deleteMentor (mentorId: string) {
		try {
			await AdminMentorsService.deleteMentor(mentorId);
			runInAction(() => {
				adminUserStore.currentUser.mentor = undefined;
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const adminMentorsStore = new AdminMentorsStore();
