import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	IGetUserGamificationResponse,
	IBuyGamificationItemResponse,
	IGetAllProfessionBranchesResponse,
	IGetGamificationItemsResponse,
	IUpdateUserGamificationResponse,
	ICreateDefaultGamificationResponse,
	IUpdateUserGamificationDto,
	ICreateDefaultGamificationDto,
	IGetManyUserDailyQuestsResponse,
	ICreateUserDailyQuestsResponse,
	ICheckUserDailyQuestsResponse,
	IUpdateUserDailyStatusResponse
} from '@lomonosov/types/dist';

export default class {
	// Получение своей геймификации
	static async getUserGamification (userId: string): Promise<AxiosResponse<IGetUserGamificationResponse>> {
		return $api.get<IGetUserGamificationResponse>(`/users/${userId}/gamification`);
	}

	static async updateUserGamification (userId: string, info: IUpdateUserGamificationDto): Promise<AxiosResponse<IUpdateUserGamificationResponse>> {
		return $api.put<IUpdateUserGamificationResponse>(`/users/${userId}/gamification`, info);
	}

	static async createDefaultGamification (userId: string, sex: ICreateDefaultGamificationDto): Promise<AxiosResponse<ICreateDefaultGamificationResponse>> {
		return $api.put<ICreateDefaultGamificationResponse>(`/users/${userId}/gamification/preview-visibility`, sex);
	}

	static async getItemsShop (
		gamificationId: string,
		params: { sex: string, part: 'head' | 'body' | 'car' | 'house' | '' }
	): Promise<AxiosResponse<IGetGamificationItemsResponse>> {
		const { sex, part } = params;
		return $api.get<IGetGamificationItemsResponse>(`/game-store/gamification/${gamificationId}?sex=${sex}&part=${part}`);
	}

	static async buyGameItem (itemId: string, userId: string): Promise<AxiosResponse<IBuyGamificationItemResponse>> {
		return $api.post<IBuyGamificationItemResponse>('/game-store', { userId, itemId });
	}

	static async receiveSalary (userId: string): Promise<AxiosResponse<any>> {
		return $api.put(`/users/${userId}/gamification/receive-salary`);
	}

	// Ветки профессий
	static async getProfessionBranches (): Promise<AxiosResponse<IGetAllProfessionBranchesResponse>> {
		return $api.get<IGetAllProfessionBranchesResponse>('/profession-branches');
	}

	// Ежедневные задания
	static async getDailyQuests (userId): Promise<AxiosResponse<IGetManyUserDailyQuestsResponse>> {
		return $api.get<IGetManyUserDailyQuestsResponse>(`users/${userId}/daily-quest`);
	}

	static async createDailyQuests (userId): Promise<AxiosResponse<ICreateUserDailyQuestsResponse>> {
		return $api.post<ICreateUserDailyQuestsResponse>(`users/${userId}/daily-quest`);
	}

	static async checkDailyQuests (userId): Promise<AxiosResponse<ICheckUserDailyQuestsResponse>> {
		return $api.patch<ICheckUserDailyQuestsResponse>(`users/${userId}/daily-quest/check`);
	}

	static async updateStatusDailyQuests (userId: string, dailyId: string): Promise<AxiosResponse<IUpdateUserDailyStatusResponse>> {
		return $api.patch<IUpdateUserDailyStatusResponse>(`users/${userId}/daily-quest/${dailyId}/status`);
	}
}
