import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { userGamificationStore } from '@stores';
import { Button, CustomModal } from '@components';
import { constants, images } from '@helpers';

import '../style.css';

export default observer(() => {
	const [head, setHead] = useState<string>('');

	const closeModal = () => {
		localStorage.setItem('level', userGamificationStore.gamificationInfo?.level.toString());
		userGamificationStore.changeLevelUpModal();
	};

	useEffect(() => {
		const temp = userGamificationStore.gamificationInfo?.equippedItems.find(item => item.type === 'head');
		const url = temp?.id ? `${constants.URL}/files/${temp.id}` : images.headsBlocked;
		setHead(url);
	}, []);

	return (
		<CustomModal
			open={userGamificationStore.isShowLevelUpModal} handleClose={ () => closeModal() } backdropColor='white'
			viewClose='cross' heightModal='0px' widthModal='550px'
		>
			<div className='gamification-modal'>
				<h3 className='gamification-modal__title'>Поздравляем!</h3>
				<div className='gamification-modal__center-block'>
					<img
						className='gamification-modal__image gamification-modal__image_rotate'
						src={head} alt='head'
					/>
					<div className='gamification-modal__level'>{userGamificationStore.gamificationInfo?.level}</div>
				</div>
				<div className='gamification-modal__text'>Теперь ты {userGamificationStore.gamificationInfo?.level} уровня!</div>
				<div className='gamification-modal__button'>
					<Button onClick={ () => closeModal() } type='main_blue'>
            Круто!
					</Button>
				</div>
			</div>
		</CustomModal>
	);
});
