import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@material-ui/core';

import Button from '@components/button';
import Loader from '@components/loader';
import { userGamificationStore, generalStore, userStore } from '@stores';
import { constants } from '@helpers';
import { ProfessionModal } from '../index';
import { Player } from '../../gamification-components';

import { ArrowLeftIcon, ArrowRightIcon, QuestionIcon } from 'lomonosov-icons';

import 'swiper/css';
import './style.css';

export default observer((props: any) => {
	const { equippedItems } = props;
	const swiperRef = useRef<any>();
	const [currentBranch, setCurrentBranch] = useState(userGamificationStore.currentBranch);
	const [currentProfession, setCurrentProfession] = useState();
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

	useEffect(() => {
		if (!userGamificationStore.branches?.length) {
			userGamificationStore.getProfessionBranches().then(() => {
				setCurrentBranch(userGamificationStore.currentBranch);
			});
		}
	}, []);

	const checkAvailabilitySkill = (userSkills, skillCost: number) => {
		const currentSkills = userSkills
			.filter(userSkill => currentBranch.disciplinesGroups
				.some(branchSkill => userSkill.disciplinesGroup.id === branchSkill.id));
		return currentSkills.some(skill => skill.value >= skillCost);
	};

	const changeUserProfession = (profession) => {
		if (userGamificationStore.gamificationInfo?.level < profession.levelCost) {
			generalStore.changeAlertState('warning', 'Слишком низкий уровень');
		} else if (!checkAvailabilitySkill(userGamificationStore.gamificationInfo.skills, profession.skillCost)) {
			generalStore.changeAlertState('warning', 'Ты не подходишь по умению');
		} else {
			setCurrentProfession(profession);
			userGamificationStore.updateUserGamification(null, userStore.user.id, profession.id);
			setIsOpenModal(true);
		}
	};

	return (
		<div className='gamification-profession'>
			{userGamificationStore.isLoadingProfession
				?	<Loader/>
				: <div className='gamification-profession__container'>
					<div className='gamification-profession__player'>
						<Player
							head={equippedItems.head?.file?.[0]?.id || ''}
							body={equippedItems.body?.file?.[0]?.id || ''}
							gender={equippedItems.body?.sex}
							position={'center'}
						/>
					</div>
					<div className='gamification-profession__content theme-block-background'>
						<h4>
							Ветки профессий:
						</h4>
						<div className='gamification-profession__main-block'>
							<div className='gamification-profession__left-block'>
								<div className='gamification-profession__blocks'>
									{userGamificationStore.branches?.length !== 0 &&
									userGamificationStore.branches.map((branch) =>
										<div
											className={`
											gamification-profession__blocks-item ${branch?.id === currentBranch?.id &&
											'gamification-profession__blocks-item_active'}
										`}
											key={branch.id} onClick={() => setCurrentBranch(branch)}
										>
											{branch?.name}
										</div>
									)}
								</div>
								<div className='gamification-profession__skills'>
									Ключевые умения:<br/>
									{currentBranch?.disciplinesGroups.map((discipline, index, array) =>
										<span key={discipline.id}>
											{array.length === userGamificationStore.gamificationInfo?.skills?.length
												? 'Все предметы.'
												: `${discipline.name}${array.length - 1 === index ? '.' : ','}`
											}&#8194;
										</span>
									)
									}
								</div>
								<div className='gamification-profession__warning'>
									<QuestionIcon/>
									<span>Для продвижения по карьере, необходимо прокачивать хотя бы одно из ключевых умений</span>
								</div>
							</div>
							<div className='gamification-profession__right-block'>
								<IconButton
									onClick={() => swiperRef.current.slidePrev()}
									className='gamification-profession__arrow-button gamification-profession__arrow-button_left'
								>
									<ArrowLeftIcon/>
								</IconButton>
								<div className='gamification-profession__profession-info'>
									<Swiper
										slidesPerView={1}
										autoplay autoHeight
										spaceBetween={10}
										onSwiper={(swiper) => {
											swiperRef.current = swiper;
										}}
									>
										{currentBranch?.professions.map((profession) =>
											<SwiperSlide key={profession.id}>
												<div className='gamification-profession__slider'>
													<div className='gamification-profession__slider-content'>
														<h4 className='gamification-profession__slider-title'>
															{profession.name}
														</h4>
														<div className='gamification-profession__slider-image'>
															<img src={`${constants.URL}/files/${profession.preview?.[0]?.id}`} alt='profession'/>
														</div>
														<div className='gamification-profession__slider-text'>
															Зарплата: <span>{profession.salary} <span className='dollars'>$</span></span> в день<br/>
															Необходимый уровень: <span>{profession?.levelCost}</span><br/>
															Необходимое умение: <span>{profession.skillCost}</span><br/>
														</div>
														<div className='gamification-profession__slider-button'>
															<Button
																onClick={() => changeUserProfession(profession)}
																type={'main_blue'}
																// disable={!checkAvailableProfessionForUser(profession)}
															>
																	Сменить профессию
																{/*: <span style={{ color: 'white' }}>Ты не подходишь по уровню</span>} */}
															</Button>
														</div>
													</div>
												</div>
											</SwiperSlide>
										)}
									</Swiper>
								</div>
								<IconButton onClick={() => swiperRef.current.slideNext()}
									className='gamification-profession__arrow-button gamification-profession__arrow-button_right'>
									<ArrowRightIcon/>
								</IconButton>
							</div>
						</div>
					</div>
				</div>
			}
			<ProfessionModal
				isOpenModal={isOpenModal} handleCloseModal={() => setIsOpenModal(false)}
				profession={currentProfession}
			/>
		</div>
	);
});
