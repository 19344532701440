import React from 'react';
import { observer } from 'mobx-react-lite';
import { LinearProgress } from '@material-ui/core';
import './style.css';

interface ISkillsProps {
	skills: {
		disciplinesGroup: {
			id: string
			name: string
		}
		value: number
	}[]
}

export default observer((props: ISkillsProps) => {
	const { skills = [] } = props;
	return (
		<div className='gamification-skills theme-block-background'>
			<div className='gamification-skills__block'>
				<h4 className='gamification-skills__title'>Умения</h4>
				{skills.map((skill, index) =>
					<div key={index} className='gamification-skills__skill-block'>
						<div className='gamification-skills__name'>
							{skill.disciplinesGroup.name}
						</div>
						<div className='gamification-skills__progress-block'>
							<LinearProgress variant='determinate' className='gamification-skills__progress' value={skill.value}/>
						</div>
						<div className='gamification-skills__text-percent'>
							{skill.value}%
						</div>
					</div>
				)}
			</div>
		</div>
	);
});
