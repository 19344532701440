import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { adminCategoryStore, adminDirectionStore } from '@stores';
import {
	Input,
	Select,
	Button
} from '@components';
import {
	IGetCategoriesItemResponse,
	IGetDirectionsItemResponse,
	ICreateTariffPlanDto,
	IGetTariffPlanByIdResponse
} from '@lomonosov/types';

interface IProps {
	save: (state: ICreateTariffPlanDto, id?: string) => void
	data?: IGetTariffPlanByIdResponse
}

export default observer((props: IProps) => {
	const [name, setName] = useState('');
	const [note, setNote] = useState('');
	const [category, setCategory] = useState<IGetCategoriesItemResponse | null>(null);
	const [direction, setDirection] = useState<IGetDirectionsItemResponse | null>(null);
	const [idPlan, setIdPlan] = useState<string | undefined>(undefined);

	const { data } = props;

	/// /////////////////////////////////////////////////////////////

	useEffect(() => {
		adminCategoryStore.getCategories(); /// ??
		adminDirectionStore.getDirections(); /// ??

		if (data) {
			setIdPlan(data.id);
			setName(data.name);
			setNote(data.description);
			setCategory(data.category);
			setDirection(data.direction);
		}
	}, []);

	const save = () => {
		if (category !== null && direction !== null) {
			const sa: ICreateTariffPlanDto = {
				comparisonTable: '',
				name,
				categoryId: category?.id,
				directionId: direction?.id,
				description: note,
				isActive: true
			};

			props.save(sa, idPlan);
		}
	};

	return (
		<div className='tarifPlan__modal'>
			<div className='tarifPlan__modal-block tarifPlan__modal-inputs'>
				<div className='tarifPlan__modal-inputs-input'>
					<Input label='Название' value={name} onChange={(e) => setName(e.target.value)} />
				</div>
				<div className='tarifPlan__modal-inputs-input'>
					<Input label='Заметки' value={note} onChange={(e) => setNote(e.target.value)} />
				</div>
				<div className='tarifPlan__modal-inputs-select tarifPlan__modal-inputs-input'>
					<Select
						input={{ label: 'Категория' }}
						options={adminCategoryStore.categories}
						value={category}
						onChange={(e, value) => { setCategory(value); }}

					/>
				</div>
				<div className='tarifPlan__modal-inputs-select tarifPlan__modal-inputs-input'>
					<Select
						input={{ label: 'Направление' }}
						options={adminDirectionStore.directions}
						value={direction}
						onChange={(e, value) => { setDirection(value); }}

					/>
				</div>
			</div>
			<div className='tarifPlan__modal-block tarifPlan__modal-button'>
				<Button onClick={save} type='main_green'>Save</Button>
			</div>
		</div>
	);
});
