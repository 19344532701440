import { AxiosResponse } from 'axios';
import { $api } from '@helpers';

export default class {
	static async getRefInfo (userId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/users/${userId}/withdrawal-referral/info`);
	}

	static async getRefHistory (userId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/users/${userId}/withdrawal-referral/history`);
	}

	static async createWithdraw (withdraw): Promise<AxiosResponse<any>> {
		return $api.post<any>('/withdrawal-referrals', withdraw);
	}
}
