import React from 'react';
import Skeleton from '@components/skeleton';
import './style.css';

export const LessonCardLoader = () => {
	return (
		<div className='lesson-card-loader lesson-card'>
			<Skeleton />
			<Skeleton className='lesson-card-loader__title'/>
			<div className='lesson-card__container'>
				<div className='lesson-card__list lesson-card-loader__list'>
					<div className='lesson-card__list-item'>
						<Skeleton className='lesson-card-loader__icon'/>
						<Skeleton className='lesson-card-loader__name'/>
					</div>
					<div className='lesson-card__list-item'>
						<Skeleton className='lesson-card-loader__icon'/>
						<Skeleton className='lesson-card-loader__name'/>
					</div>
				</div>
				<Skeleton className='lesson-card-loader__button lesson-card__button'/>
			</div>
		</div>
	);
};
