import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

import { IconButton } from '@material-ui/core';
import { ArrowLeftIcon, ArrowRightIcon } from 'lomonosov-icons';
import { getTimeWithTZ } from '@helpers/functions/get-time-with-tz';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { CalendarTypeItems, userCalendarStore } from '@stores';
import CalendarChangeTimezoneModal from '../calendar-change-timezone-modal';

import './style.css';

type ICalendarHeader = {
	changeWeek: Function,
	dayOffset: number
}

export default observer(({ changeWeek }: ICalendarHeader) => {
	const [isOpen, setOpen] = useState(false);
	const [filters, setFilters] = useState(['lesson', 'homework', 'probe']);
	const changeLocalTimezone = () => {
		setOpen(true);
	};

	const getTime = () => {
		const hours = getTimeWithTZ().getHours();
		const minutes = getTimeWithTZ().getMinutes();

		return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
	};

	const handleFilters = (_, newFilters: CalendarTypeItems[]) => {
		userCalendarStore.setFilters(newFilters);
		setFilters(newFilters);
	};

	const handleCloseTimezoneModal = () => {
		setOpen(false);
	};

	return (
		<>
			<div className='class-calendar-header'>
				<div className='class-calendar-header__container'>
					<div className='class-calendar-header__arrows'>
						<IconButton className='arrows__arrow theme-text' onClick={async () => await changeWeek('previous')}>
							<ArrowLeftIcon/>
						</IconButton>
						<IconButton className='arrows__arrow theme-text' onClick={async () => await changeWeek('next')}>
							<ArrowRightIcon/>
						</IconButton>
					</div>

					<div className='class-calendar-header__time'>
						<div className='class-calendar-header__time-current' onClick={() => changeLocalTimezone()}>
							<WatchLaterIcon className='time__icon'/>
							<span>Сейчас у вас {getTime()}</span>
						</div>
						<div className='class-calendar-header__time-info'>Время в календаре указано в вашем часовом поясе</div>
					</div>
					<div className='class-calendar-header__help-info'>
						<ToggleButtonGroup
							value={filters}
							onChange={handleFilters}
							className='class-calendar-header__help-info__legend'
						>
							{legend.map((item, index) => (
								<ToggleButton key={index} value={item.name} className='class-calendar-legend-button'>
									<div key={`help-info__item-${index}`} className='help-info__item'>
										<div className={`help-info__item_${item.color}`}/>
										<span>{item.title}</span>
									</div>
								</ToggleButton>
							))}
						</ToggleButtonGroup>
					</div>
				</div>
			</div>
			<CalendarChangeTimezoneModal handleClose={handleCloseTimezoneModal} isOpen={isOpen}/>
		</>
	);
});

interface ILegend {
	name: CalendarTypeItems,
	title: string,
	color: string
}

const legend: ILegend[] = [
	{
		name: 'lesson',
		title: 'Уроки',
		color: 'green'
	},
	{
		name: 'homework',
		title: 'Домашние задания',
		color: 'orange'
	},
	{
		name: 'probe',
		title: 'Пробный вариант',
		color: 'red'
	}
];
