import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { userGamificationStore, userStore } from '@stores';
import { Button, CustomModal, Select } from '@components';
import { images } from '@helpers';

import '../style.css';

// @ts-ignore
const VK = window.VK;
// TODO ts-ignore стоит, может можно как-то исправить?

export default observer(() => {
	const selectUserSex = () => {
		userStore.handleChangeUserInfo('gamePreviewVisibility', false);
		userGamificationStore.updateUserGamification(userStore.user.id, userGamificationStore.gamificationInfo);
		userGamificationStore.getGameStore();
		userGamificationStore.changeNotificationModal();
	};

	useEffect(() => {
		setTimeout(() => {
			notificationInfo.forEach((notificate) => {
				if (document.getElementById(`vk_send_message_${notificate.subjectId}`)?.innerHTML === '') {
					return VK.Widgets.AllowMessagesFromCommunity(`vk_send_message_${notificate.subjectId}`, { height: 30 }, notificate.id);
				}
			});
		}, 150);
		return () =>
			VK.Observer.unsubscribe('widgets.allowMessagesFromCommunity.allowed');
	}, []);

	const handleChangeGamificationInfo = (value) => {
		userGamificationStore.changeGamificationInfo('sex', value.id);
	};

	return (
		<CustomModal
			open={userGamificationStore.isShowNotificationModal}
			handleClose={() => userGamificationStore.changeNotificationModal()} heightModal='auto'
			widthModal='700px'
		>
			<div className='gamification-modal'>
				<h3 className='gamification-modal__title'>Отлично, твои курсы уже ждут тебя</h3>
				<div className='gamification-modal__text'>
					Они начнутся 1.09.21. Не беспокойся, мы тебе напомним 😇<br/><br/>
					Но только если ты подпишешься на уведомления 😊
				</div>
				<div className='gamification-modal__notifications'>
					{notificationInfo.map((notificate, index) =>
						<div key={index} className='gamification-modal__notifications-block'>
							<div className='gamification-modal__notifications-title'>
								Уведомления по {notificate.subject}:
							</div>
							<div className='gamification-modal__notifications-button'>
								<div id={`vk_send_message_${notificate.subjectId}`}/>
							</div>
						</div>
					)}
				</div>
				<div className='gamification-modal__text'>
					А чтобы скоротать ожидание, можешь настроить своего персонажа.<br/>
					Рука об руку с этим персонажем ты прорвешься к звездам и сдашь ЕГЭ на <br/>
					высокие баллы  🚀
				</div>
				<div className='gamification-modal__center-block'>
					<img className='gamification-modal__image' src={ images.notificationHead1 } alt='head'/>
					<img className='gamification-modal__image' src={ images.notificationHead2 } alt='head'/>
				</div>
				<div className='gamification-modal__buttons'>
					{userGamificationStore.isShowWelcomeModal &&
          <div className='gamification-modal__select'>
          	<Select
          		input={{ label: 'Укажите пол' }}
		          options={options}
		          value={getSex(userGamificationStore.gamificationInfo?.sex)}
          		onChange={(value: any) =>
			          handleChangeGamificationInfo(value)
          		}
          	/>
          </div>
					}
					<div className='gamification-modal__button gamification-modal__button_h50'>
						<Button
							type={userGamificationStore.gamificationInfo?.sex === 'common' ? 'disabled' : 'main_blue'}
							onClick={() => selectUserSex()}
							disable={userGamificationStore.gamificationInfo?.sex === 'common'}
						>
							Принять
						</Button>
					</div>
				</div>
			</div>
		</CustomModal>
	);
});

const notificationInfo = [
	{
		id: 175664191,
		subject: 'Обществознанию',
		subjectId: 'social'
	},
	{
		id: 177646145,
		subject: 'Русскому языку',
		subjectId: 'russian'
	},
	{
		id: 176198213,
		subject: 'Английскому языку',
		subjectId: 'english'
	},
	{
		id: 175663225,
		subject: 'Истории',
		subjectId: 'history'
	},
	{
		id: 177646209,
		subject: 'Литературе',
		subjectId: 'literature'
	}
];

const getSex = (id) => {
	if (id === 0) {
		return null;
	}
	if (id === 1) {
		return {
			id,
			title: 'Девушка'
		};
	}
	if (id === 2) {
		return {
			id,
			title: 'Парень'
		};
	}
};

const options = [
	{
		id: 1,
		name: 'Девушка'
	},
	{
		id: 2,
		name: 'Парень'
	}
];
