import React from 'react';
import { observer } from 'mobx-react-lite';
import { adminAnalyticsStore } from '@stores';
import { Select, Table } from '@components';
import { IColumn } from '@components/table';
import { UsersTable } from '../index';

export default observer(() => {
	const openUsersModal = async (homeworkId) => {
		adminAnalyticsStore.setCurrentUserHomeworkId(homeworkId);
		await adminAnalyticsStore.getTaskUsersAnalysis(8, 0);
	};

	const changePage = async (event, newPage) => {
		await adminAnalyticsStore.changePageCourses(
			newPage,
			adminAnalyticsStore.pagination.tasks.rows,
			'tasks'
		);
	};

	const changeTableRows = async (e: React.ChangeEvent<HTMLInputElement>) => {
		await adminAnalyticsStore.changePageCourses(
			adminAnalyticsStore.pagination.tasks.currentPage,
			+e.target.value,
			'tasks'
		);
	};

	return (
		<div className='courses-analysis-table theme-background'>
			<Select
				options={optionsType}
				input={{
					label: 'Выбрать тип ДЗ'
				}}
				sx={{ maxWidth: '250px', margin: '10px 0' }}
				value={adminAnalyticsStore.taskType}
				onChange={(e, value) => adminAnalyticsStore.setTaskType(value)}
			/>
			<Table
				columns={columnsTable}
				data={adminAnalyticsStore.tasks}
				detailPanel={{
					Component: UsersTable,
					action: (id) => openUsersModal(id),
					autoClose: true
				}}
				pagination={{
					count: adminAnalyticsStore.pagination.tasks.totalCount,
					page: adminAnalyticsStore.pagination.tasks.currentPage,
					rowsPerPage: adminAnalyticsStore.pagination.tasks.rows,
					onPageChange: changePage,
					onRowsPerPageChange: changeTableRows,
					rowsPerPageOptions: [8, 10, 25, 50]
				}}
			/>
		</div>
	);
});

const columnsTable: IColumn[] = [
	{ name: 'Название работы', field: 'name', align: 'left' },
	{ name: 'Тип ДЗ', field: 'type', align: 'center' },
	{ name: 'Проверенно работ', field: 'count', align: 'center' },
	{ name: 'Средний балл', field: 'avg', align: 'center' }
];

const optionsType = [
	{ id: 'probe', name: 'Пробники' },
	{ id: 'automatic', name: 'Автомат. проверкой' },
	{ id: 'manual', name: 'Ручной. проверкой' }
];
