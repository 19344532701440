import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IColumn } from '@components/table';
import { adminAbandonedCartsStore } from '@stores';
import { Table } from '@components';
import { DetailPanelCart } from './blocks';

import '../admin-main-block.globalStyle.css';

export default observer(() => {
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);

	useEffect(() => {
		if (adminAbandonedCartsStore.abandonedCarts.items?.length === 0) {
			adminAbandonedCartsStore.getAbandonedCarts();
		}
	}, []);

	const getCurrentCart = async (id) => {
		await adminAbandonedCartsStore.getAbandonedCartsById(id);
	};
	const handleChangePage = async (event: unknown, newPage: number) => {
		setPage(newPage);
		await adminAbandonedCartsStore.getAbandonedCarts(rowsPerPage, newPage * rowsPerPage);
	};

	const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const columns: IColumn[] = [
		{ field: 'userName', name: 'Пользователь' },
		{ field: 'vkId', name: 'vkId', align: 'center' },
		{ field: 'phone', name: 'Телефон', align: 'center' },
		{ field: 'email', name: 'Email', align: 'center' },
		{ field: 'dateCreate', name: 'Дата создания' }
	];

	return (
		<div className='abandoned-carts'>
			<Table
				columns={columns}
				data={adminAbandonedCartsStore.abandonedCarts.items}
				pagination={{
					count: adminAbandonedCartsStore.abandonedCarts?.count,
					page: page,
					rowsPerPage: rowsPerPage,
					onPageChange: handleChangePage,
					onRowsPerPageChange: handleChangeRowsPerPage
				}}
				detailPanel={{
				  Component: DetailPanelCart,
				  action: getCurrentCart,
				  autoClose: true
				}}
			/>
		</div>
	);
});
