import { AxiosResponse } from 'axios';
import { $api } from '@helpers';

export default class {
	static async generateUrlFile (file: any): Promise<AxiosResponse<any>> {
		return $api.post<any>('/files', file);
	}

	static async saveFile (generatedUrl: string, file: File): Promise<AxiosResponse<void>> {
		return $api.put<void>(generatedUrl, file, { headers: { 'Content-Type': file.type } });
	}

	static async confirmFile (file: any): Promise<AxiosResponse<any>> {
		return $api.put<any>('/files', file);
	}

	static async deleteFile (fileId): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/files/${fileId}`);
	}
}
