import React, { memo } from 'react';
import { images } from '@helpers';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { IGetUserCoursesItemResponse } from '@lomonosov/types';

import classNames from 'classnames';
import { InfoCard } from '@components';
import './style.css';

interface CardTeacherProps {
	teacher: IGetUserCoursesItemResponse['teacher']
	openTeacherModal: (teacher: IGetUserCoursesItemResponse['teacher']) => void;
	className?: string;
}

export const CardTeacher = memo((props: CardTeacherProps) => {
	const { teacher, openTeacherModal, className } = props;

	return (
		<InfoCard
			icon={<SentimentSatisfiedAltIcon fontSize='small'/>}
			title='Преподаватель'
			className={classNames('card-teacher', className)}
			onClick={() => openTeacherModal(teacher)}
		>
			<div className='card-teacher__info'>
 				<img
					src={teacher?.originalUser?.photo || images.defaultCourse}
				/>
				<span>{teacher?.name}</span>
			</div>
		</InfoCard>
	);
});
