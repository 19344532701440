import { make } from '../../utils/dom';

/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class Ui {
	/**
   * @param {object} ui - image tool Ui module
   * @param {object} ui.api - Editor.js API
   * @param {ImageConfig} ui.config - user config
   * @param {Function} ui.onSelectFile - callback for clicks on Select file button
   * @param {boolean} ui.readOnly - read-only mode flag
   */
	constructor ({ api, config, onSelectFile, readOnly }) {
		this.api = api;
		this.config = config;
		this.onSelectFile = onSelectFile;
		this.readOnly = readOnly;
		this.nodes = {
			wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
			imageContainer: make('div', [this.CSS.imageContainer]),
			fileButton: this.createFileButton(),
			imageEl: undefined,
			imagePreloader: make('div', this.CSS.imagePreloader)
		};

		this.nodes.imageContainer.appendChild(this.nodes.imagePreloader);
		this.nodes.wrapper.appendChild(this.nodes.imageContainer);
		this.nodes.wrapper.appendChild(this.nodes.fileButton);
	}

	/**
   * CSS classes
   *
   * @returns {object}
   */
	get CSS () {
		return {
			baseClass: this.api.styles.block,
			loading: this.api.styles.loader,
			input: this.api.styles.input,
			button: this.api.styles.button,

			/**
       * Tool's classes
       */
			wrapper: 'image-tool',
			imageContainer: 'image-tool__image',
			imagePreloader: 'image-tool__image-preloader',
			imageEl: 'image-tool__image-picture'
		};
	}

	/**
   * Ui statuses:
   * - empty
   * - uploading
   * - filled
   *
   * @returns {{EMPTY: string, UPLOADING: string, FILLED: string}}
   */
	static get status () {
		return {
			EMPTY: 'empty',
			UPLOADING: 'loading',
			FILLED: 'filled'
		};
	}

	/**
   * Renders tool UI
   *
   * @param {ImageToolData} toolData - saved tool data
   * @returns {Element}
   */
	render (toolData) {
		if (!toolData.fileId) {
			this.toggleStatus(Ui.status.EMPTY);
		} else {
			this.toggleStatus(Ui.status.UPLOADING);
		}

		return this.nodes.wrapper;
	}

	/**
   * Creates upload-file button
   *
   * @returns {Element}
   */
	createFileButton () {
		const button = make('div', [this.CSS.button]);
		const input = document.createElement('input');
		input.type = 'file';

		input.addEventListener('change', (e) => {
			// получаем выбранный файл
			const files = e.target;

			// дальнейшая обработка файла
			this.onSelectFile(files);
		});

		button.innerHTML = this.config.buttonContent ||
		`<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
		<div>Загрузить изображение</div>
		`;

		button.addEventListener('click', () => {
			input.click();
		});

		return button;
	}

	/**
   * Shows uploading preloader
   *
   * @param {string} src - preview source
   * @returns {void}
   */
	showPreloader (src) {
		this.nodes.imagePreloader.style.backgroundImage = `url(${src})`;

		this.toggleStatus(Ui.status.UPLOADING);
	}

	/**
   * Hide uploading preloader
   *
   * @returns {void}
   */
	hidePreloader () {
		this.nodes.imagePreloader.style.backgroundImage = '';
		this.toggleStatus(Ui.status.EMPTY);
	}

	/**
   * Shows an image
   *
   * @param {string} url - image source
   * @returns {void}
   */
	fillImage (url) {
		/**
     * Check for a source extension to compose element correctly: video tag for mp4, img — for others
     */
		const tag = /\.mp4$/.test(url) ? 'VIDEO' : 'IMG';

		const attributes = {
			src: url
		};

		/**
     * We use eventName variable because IMG and VIDEO tags have different event to be called on source load
     * - IMG: load
     * - VIDEO: loadeddata
     *
     * @type {string}
     */
		let eventName = 'load';

		/**
     * Update attributes and eventName if source is a mp4 video
     */
		if (tag === 'VIDEO') {
			/**
       * Add attributes for playing muted mp4 as a gif
       *
       * @type {boolean}
       */
			attributes.autoplay = true;
			attributes.loop = true;
			attributes.muted = true;
			attributes.playsinline = true;

			/**
       * Change event to be listened
       *
       * @type {string}
       */
			eventName = 'loadeddata';
		}

		/**
     * Compose tag with defined attributes
     *
     * @type {Element}
     */
		this.nodes.imageEl = make(tag, this.CSS.imageEl, attributes);

		/**
     * Add load event listener
     */
		this.nodes.imageEl.addEventListener(eventName, () => {
			this.toggleStatus(Ui.status.FILLED);

			/**
       * Preloader does not exists on first rendering with presaved data
       */
			if (this.nodes.imagePreloader) {
				this.nodes.imagePreloader.style.backgroundImage = '';
			}
		});

		this.nodes.imageContainer.appendChild(this.nodes.imageEl);
	}

	/**
   * Changes UI status
   *
   * @param {string} status - see {@link Ui.status} constants
   * @returns {void}
   */
	toggleStatus (status) {
		for (const statusType in Ui.status) {
			if (Object.prototype.hasOwnProperty.call(Ui.status, statusType)) {
				this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper}--${Ui.status[statusType]}`, status === Ui.status[statusType]);
			}
		}
	}

	/**
   * Apply visual representation of activated tune
   *
   * @param {string} tuneName - one of available tunes {@link Tunes.tunes}
   * @param {boolean} status - true for enable, false for disable
   * @returns {void}
   */
	applyTune (tuneName, status) {
		this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper}--${tuneName}`, status);
	}
}
