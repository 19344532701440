import { makeAutoObservable } from 'mobx';
import { INewsletterInterface } from '@interfaces';
import { userStore, generalStore } from '../../stores';
import adminNewslettersService from '../../services/admin-services/admin-newsletters-service';

class AdminNewslettersStore {
	constructor () {
		makeAutoObservable(this);
	}

	newsletters: INewsletterInterface[] = [];
	currentNewsletter: INewsletterInterface = defaultNewsletter;
	deletableNewsletterId: string = '';
	isOpenModal: boolean = false;

	async getAllNewsletters () {
		try {
			const { data } = await adminNewslettersService.getAllNewsLetters();
			this.newsletters = data;
		} catch (e) {
			console.log(e);
		}
	}

	async getNewsletterById (newsletterId: string) {
		try {
			const { data } = await adminNewslettersService.getNewsLetterById(newsletterId);
			this.currentNewsletter = { ...data };
		} catch (e) {
			console.log(e);
		}
	}

	async createNewsletter (newsletter: INewsletterInterface) {
		try {
			const { data } = await adminNewslettersService.createNewsLetter(newsletter);
			this.newsletters.push({ ...data, owner: userStore.user });
			this.isOpenModal = false;
			generalStore.changeAlertState('success', 'Отправлено');
		} catch (e) {
			console.log(e);
		}
	}

	async updateNewsletter (newsletter: INewsletterInterface) {
		try {
			const { data } = await adminNewslettersService.updateNewsLetter(newsletter);
			this.newsletters = this.newsletters.map(letter => letter.id === newsletter.id ? data : letter);
			this.currentNewsletter = data;
			generalStore.changeAlertState('success', 'Переотправлено');
		} catch (e) {
			console.log(e);
		}
	}

	async deleteNewsletter () {
		try {
			await adminNewslettersService.deleteNewsLetters(this.deletableNewsletterId);
			this.newsletters = this.newsletters.filter(letter => letter.id !== this.deletableNewsletterId);
			this.deletableNewsletterId = '';
			generalStore.changeAlertState('success', 'Удалено');
		} catch (e) {
			console.log(e);
		}
	}

	changeCurrentNewsletter (value: any, field: string | null = null) {
		field
			? this.currentNewsletter[field] = value
			: this.currentNewsletter = value;
	}

	resetCurrentNewsletter () {
		this.currentNewsletter = defaultNewsletter;
		this.currentNewsletter.sender = `${userStore.user.secondName} ${userStore.user.firstName}`;
	}

	changeDeletableNewsletterId (id: string) {
		this.deletableNewsletterId = id;
	}

	changeIsOpenModal (value: boolean) {
		this.isOpenModal = value;
	}
}

const defaultNewsletter: INewsletterInterface = {
	topic: '',
	text: [],
	sender: '',
	courses: [],
	type: 'All',
	isViewed: false,
	owner: null
};

export const adminNewslettersStore = new AdminNewslettersStore();
