import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, useNavigate } from 'react-router-dom';

import { AdminMainMenu } from '../../../pages/admin/admin-blocks';

import './style.css';

export default observer(() => {
	const [activeScreen, setActiveScreen] = useState<string>('courses');
	const navigate = useNavigate();

	useEffect(() => {
		const path = window.location.pathname.split('/');
		if (path.length > 2) {
			setActiveScreen(path[2]);
		}
	}, []);

	const handleClick = (newValue: string) => {
		navigate(newValue);
		setActiveScreen(newValue);
	};

	return (
		<div className='admin-main-container theme-background'>
			<div className='admin-main'>
				<h2 className='admin-main__title'>
					{adminActiveScreenLabel[activeScreen]}
				</h2>
				<AdminMainMenu handleClick={handleClick} />
				<Outlet />
			</div>
		</div>
	);
});

const adminActiveScreenLabel = {
	courses: 'Курсы',
	'homework-bank': 'Банк домашних заданий',
	'homework-answers': 'Проверка заданий',
	'student-analysis': 'Анализ активности студентов',
	banners: 'Банеры',
	promo: 'Промокоды',
	'tariff-plan': 'Тарифный план',
	'purchase-info': 'Сводка по покупкам',
	'traffic-sources': 'Источники траффика',
	'control-mentors': 'Контроль менторов',
	categories: 'Категории',
	directions: 'Направления',
	disciplines: 'Дисциплины',
	referral: 'Заявки на вывод',
	'all-users': 'Пользователи',
	'my-students': 'Мои ученики',
	teachers: 'Учителя',
	mentors: 'Менторы',
	employees: 'Сотрудники',
	'analytics-courses': 'Аналитика курсов',
	'analytics-students': 'Аналитика активности студентов',
	shop: 'Магазин',
	invoice: 'Счета',
	'abandoned-carts': 'Брошенные корзины',
	essays: 'Сочинения',
	'check-essays': 'Проверка сочинений',
	'combo-courses': 'Комбо-курсы',
	'showcase-courses': 'Витрина курсов'
};
