import { ICombo, IComboCourse, IComboTariffPeriod, ICreateComboDto, ICreateComparisonTariffsComboDto, ICreateTariffComboDto, IGetComboResponse, IGetComparisonTariffsComboResponse, IGetTariffsComboResponse, IUpdateComboDto, IUpdateComparisonTariffComboDto, IUpdateOrderingDto, IUpdateTariffComboDto } from '@interfaces';
import { AdminComboCoursesService } from '@services';
import { makeAutoObservable } from 'mobx';
import { generalStore } from '../general-stores';

class AdminComboCoursesStore {
	combinations: IGetComboResponse = {
		combinations: [],
		count: 0
	};

	comboCourses: IComboCourse[] = [];
	comboTariffs: IGetTariffsComboResponse[] = [];
	addingCourses: Array<{ courseId: string }> = [];
	deletingCourses: Array<{ courseId: string }> = [];
	comparisonTariffs: IGetComparisonTariffsComboResponse[] = [];
	comboTariffPeriods: IComboTariffPeriod[] = [];

	constructor () {
  	makeAutoObservable(this);
	}

	async getCombinations (take: number = 10, skip: number = 0) {
		try {
			const { data } = await AdminComboCoursesService.getCombinations(take, skip);
			this.combinations = data;
		} catch (e) {
			console.log(e);
		}
	}

	async createCombo (combo: ICreateComboDto) {
		try {
			const { data } = await AdminComboCoursesService.createCombo(combo);
			this.combinations.combinations = [...this.combinations.combinations, data as ICombo];
			generalStore.changeAlertState('success', 'Комбо создано');
		} catch (e) {
			generalStore.changeAlertState('warning', 'Не удалось создать комбо');
			console.log(e);
		}
	}

	async updateCombo (id: string, combo: IUpdateComboDto) {
		try {
			const { data } = await AdminComboCoursesService.updateCombo(id, combo);
			const comboIndex = this.combinations.combinations.findIndex(c => c.id === id);
			this.combinations.combinations[comboIndex] = data as ICombo;
			this.comboCourses = this.comboCourses.map(c => ({
				...c,
				id: data.combinationToCourses.find(cc => cc.courseId === c.id)?.id || c.id
			}));
			this.addingCourses = [];
			this.deletingCourses = [];
			generalStore.changeAlertState('success', 'Комбо обновлено');
		} catch (e) {
			generalStore.changeAlertState('warning', 'Не удалось обновить комбо');
			console.log(e);
		}
	}

	async onActiveCombo (id: string, isActive: boolean) {
		try {
			await AdminComboCoursesService.activeCombo(id, isActive);
			const comboIndex = this.combinations.combinations.findIndex(c => c.id === id);
			this.combinations.combinations[comboIndex].isActive = isActive;
			generalStore.changeAlertState('success', isActive ? 'Активировано' : 'Деактивировано');
		} catch (e) {
			generalStore.changeAlertState('warning', e?.response?.data?.message || 'Не удалось активировать комбо', true);
			console.log(e);
		}
	}

	async deleteCombo (id: string) {
		try {
			await AdminComboCoursesService.deleteCombo(id);
			this.combinations.combinations = this.combinations.combinations.filter(c => c.id !== id);
			generalStore.changeAlertState('success', 'Комбо удалено');
		} catch (e) {
			generalStore.changeAlertState('warning', e?.response?.data?.message || 'Не удалось удалить комбо');
			console.log(e);
		}
	}

	// ///////////// COMBO COURSES /////////////////////

	async getComboCourses (comboId: string) {
		try {
			const { data } = await AdminComboCoursesService.getComboCourses(comboId);
			this.comboCourses = data;
		} catch (e) {
			console.log(e);
		}
	}

	async onActiveComboCourse (courseId: string, isActive: boolean) {
		try {
			await AdminComboCoursesService.activateComboCourse(courseId, isActive);
			const courseIndex = this.comboCourses.findIndex(c => c.id === courseId);
			this.comboCourses[courseIndex].isActive = isActive;
			generalStore.changeAlertState('success', isActive ? 'Активировано' : 'Деактивировано');
		} catch (e) {
			generalStore.changeAlertState('warning', e?.response?.data?.message || 'Не удалось активировать курс', true);
			console.log(e);
		}
	}

	addComboCourse (course: IComboCourse) {
		const isExist = this.comboCourses.some(c => c.course.id === course.course.id) || this.addingCourses.some(c => c.courseId === course.course.id);
		if (isExist) {
			generalStore.changeAlertState('warning', 'Курс уже добавлен в комбо');
			return;
		}
		this.comboCourses = [...this.comboCourses, { ...course, id: course.course.id }];
		this.addingCourses.push({
			courseId: course.course.id
		});
	}

	deleteComboCourse (courseId: string) {
		const deletedCourse = this.comboCourses.find(c => c.id === courseId);
		if (deletedCourse && deletedCourse?.id !== deletedCourse?.course.id) {
			this.deletingCourses.push({ courseId: deletedCourse.course.id });
		}
		this.addingCourses = this.addingCourses.filter(c => c.courseId !== courseId);
		this.comboCourses = this.comboCourses.filter(c => c.id !== courseId);
	}

	// ///////////// COMBO TARIFFS /////////////////////

	async getTariffsCombo (comboId: string) {
		try {
			const { data } = await AdminComboCoursesService.getTariffsCombo(comboId);
			this.comboTariffs = data.sort((a, b) => a.order - b.order);
		} catch (e) {
			console.log(e);
		}
	}

	async createTariffCombo (tariff: ICreateTariffComboDto) {
		try {
			const { data } = await AdminComboCoursesService.createTariffCombo(tariff);
			this.comboTariffs = [...this.comboTariffs, data];
			generalStore.changeAlertState('success', 'Тариф добавлен в комбо');
		} catch (e) {
			generalStore.changeAlertState('warning', e?.response?.data?.message || 'Не удалось добавить тариф', true);
			console.log(e);
		}
	}

	async updateTariffCombo (tariffId: string, tariff: IUpdateTariffComboDto) {
		try {
			const { data } = await AdminComboCoursesService.updateTariffCombo(tariffId, {
				...tariff,
				description: tariff.description || undefined
			});
			const tariffIndex = this.comboTariffs.findIndex(c => c.id === tariffId);
			this.comboTariffs[tariffIndex] = data;
			generalStore.changeAlertState('success', 'Тариф обновлен');
		} catch (error) {
			generalStore.changeAlertState('warning', error?.response?.data?.message || 'Не удалось обновить тариф', true);
			console.log(error);
		}
	}

	async onActiveTariffCombo (tariffId: string, isActive: boolean) {
		try {
			await AdminComboCoursesService.activateTariffCombo(tariffId, isActive);
			const tariffIndex = this.comboTariffs.findIndex(c => c.id === tariffId);
			this.comboTariffs[tariffIndex].isActive = isActive;
			generalStore.changeAlertState('success', isActive ? 'Активировано' : 'Деактивировано');
		} catch (e) {
			generalStore.changeAlertState('warning', e?.response?.data?.message || 'Не удалось активировать тариф', true);
			console.log(e);
		}
	}

	async deleteTariffCombo (tariffId) {
		try {
			await AdminComboCoursesService.deleteTariffCombo(tariffId);
			this.comboTariffs = this.comboTariffs.filter(c => c.id !== tariffId);
			generalStore.changeAlertState('success', 'Тариф удален из комбо');
		} catch (error) {
			console.log(error);
		}
	}

	async orderingTariffCombo (tariffs: IUpdateOrderingDto[]) {
		try {
			await AdminComboCoursesService.orderingTariffsCombo(tariffs);
			generalStore.changeAlertState('success', 'Сортировка сохранена');
		} catch (error) {
			console.log(error);
		}
	}

	// ///////////// COMPARISON TARIFFS COMBO /////////////////////

	async getComparisonTariffsCombo (tariffId: string) {
		try {
			const { data } = await AdminComboCoursesService.getComparisonTariffsCombo(tariffId);
			this.comparisonTariffs = data;
		} catch (e) {
			console.log(e);
		}
	}

	async createComparisonTariffCombo (data: ICreateComparisonTariffsComboDto) {
		try {
			const { data: comparisonTariff } = await AdminComboCoursesService.createComparisonTariffCombo(data);
			this.comparisonTariffs = [...this.comparisonTariffs, comparisonTariff];
			generalStore.changeAlertState('success', 'Сопоставление сохранено');
		} catch (e) {
			console.log(e);
		}
	}

	async updateComparisonTariffCombo (comparisonTariffId: string, data: IUpdateComparisonTariffComboDto) {
		try {
			await AdminComboCoursesService.updateComparisonTariffCombo(comparisonTariffId, data);
			generalStore.changeAlertState('success', 'Сопоставление обновлено');
		} catch (e) {
			console.log(e);
		}
	}

	async deleteComparisonTariffCombo (comparisonTariffId: string) {
		try {
			await AdminComboCoursesService.deleteComparisonTariffCombo(comparisonTariffId);
			this.comparisonTariffs = this.comparisonTariffs.filter(c => c.id !== comparisonTariffId);
			generalStore.changeAlertState('success', 'Сопоставление удалено');
		} catch (e) {
			console.log(e);
		}
	}

	// ///////////// COMBO TARIFF PERIODS /////////////////////

	async getComboTariffPeriods (comboTariffId: string) {
		try {
			const { data } = await AdminComboCoursesService.getComboTariffPeriods(comboTariffId);
			this.comboTariffPeriods = data.sort((a, b) => a.order - b.order);
		} catch (e) {
			console.log(e);
		}
	}

	async createComboTariffPeriod (data: Omit<IComboTariffPeriod, 'consist' | 'id'>) {
		try {
			const { data: period } = await AdminComboCoursesService.createComboTariffPeriod(data);
			this.comboTariffPeriods = [...this.comboTariffPeriods, period];
			generalStore.changeAlertState('success', 'Период создан');
		} catch (e) {
			console.log(e);
		}
	}

	async updateComboTariffPeriod (periodId: string, data: IComboTariffPeriod) {
		try {
			const { data: period } = await AdminComboCoursesService.updateComboTariffPeriod(periodId, data);
			const periodIndex = this.comboTariffPeriods.findIndex(c => c?.id === periodId);
			this.comboTariffPeriods[periodIndex] = period;
			generalStore.changeAlertState('success', 'Период обновлен');
		} catch (e) {
			console.log(e);
		}
	}

	async activateComboTariffPeriod (periodId: string, isActive: boolean) {
		try {
			await AdminComboCoursesService.activateComboTariffPeriod(periodId, isActive);
			const periodIndex = this.comboTariffPeriods.findIndex(c => c?.id === periodId);
			this.comboTariffPeriods[periodIndex].isActive = isActive;
			generalStore.changeAlertState('success', isActive ? 'Активировано' : 'Деактивировано');
		} catch (e) {
			generalStore.changeAlertState('warning', e?.response?.data?.message || 'Не удалось активировать период', true);
			console.log(e);
		}
	}

	async deleteComboTariffPeriod (periodId: string) {
		try {
			await AdminComboCoursesService.deleteComboTariffPeriod(periodId);
			this.comboTariffPeriods = this.comboTariffPeriods.filter(c => c.id !== periodId);
			generalStore.changeAlertState('success', 'Период удален');
		} catch (e) {
			console.log(e);
		}
	}

	async orderingComboTariffPeriods (periods: IUpdateOrderingDto[]) {
		try {
			await AdminComboCoursesService.orderingComboTariffPeriods(periods);
			generalStore.changeAlertState('success', 'Порядок периодов обновлен');
		} catch (e) {
			console.log(e);
		}
	}
}

export const adminComboCoursesStore = new AdminComboCoursesStore();
