import React, { useEffect, useState } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';

interface ILink extends LinkProps {
	iconname?: string
	customicon?: React.ReactNode
	tooltip?: string
}

export default (props: ILink) => {
	const {
		children,
		iconname,
		customicon,
		tooltip,
		...otherProps
	} = props;
	const [isActive, setIsActive] = useState(false);
	const loc = useLocation();

	useEffect(() => {
		setIsActive(loc.pathname.includes(otherProps.to.toString()));
	}, [loc]);

	return (
		<Tooltip
			enterDelay={500}
			leaveDelay={300}
			placement='right'
			title={tooltip}
		>
			<Link {...otherProps}>
				<ListItem button className='DrawerMenuListItem'>
					<ListItemIcon className={`DrawerMenuListItem__icon ${isActive ? 'theme-blue-to-orange' : 'theme-text'}`}>
						{customicon || <Icon>{iconname}</Icon>}
					</ListItemIcon>
					{children && <ListItemText className='DrawerMenuListItem__text theme-text' primary={children} />}
				</ListItem>
			</Link>
		</Tooltip>
	);
};
