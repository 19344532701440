import React, { useCallback, useEffect, memo } from 'react';
import { observer } from 'mobx-react-lite';
import Pagination from '@mui/material/Pagination';
import { PortalPlug } from '@components';
import { JournalCardLoader } from './journal-components/idnex';
import { HomeworksFilteringMenu } from '../../portal-blocks';
import { JournalCard } from './journal-blocks';
import { userHomeworkStore, userStore } from '@stores';
import './style.css';

export default observer(() => {
	useEffect(() => {
		(async () => {
			if (!userHomeworkStore.tasks.journal?.length && userStore.user.id) {
				await userHomeworkStore.getHomeworks('journal', 5, 0);
			}
		})();
	}, []);

	const changePage = useCallback(async (e, page) => {
		await userHomeworkStore.changePage('journal', page, 5);
	}, []);

	return (
		<div className='journal-page'>
			<div className='journal-page__header'>
				<h1 className='journal-page__title'>Дневник</h1>
				<HomeworksFilteringMenu activeScreen='journal'/>
			</div>
			{userHomeworkStore.isLoading.journal
				? <Loader/>
				: userHomeworkStore.tasks.journal.length !== 0
					? userHomeworkStore.tasks.journal.map(homework =>
						<JournalCard key={homework.id} homework={homework}/>
					)
					: <PortalPlug>Проверенных домашних заданий ещё нет</PortalPlug>
			}
			{userHomeworkStore.pagination.journal.pages > 1 &&
				<div className='journal-page__pagination'>
					<Pagination
						count={userHomeworkStore.pagination.journal.pages}
						page={userHomeworkStore.pagination.journal.currentPage}
						siblingCount={0} boundaryCount={1}
						onChange={changePage}
						defaultPage={1} size='large' showFirstButton showLastButton
						classes={{ ul: 'pagination' }}
					/>
				</div>
			}
		</div>
	);
});

const Loader = memo(() =>
	<>
		<JournalCardLoader/>
		<JournalCardLoader/>
		<JournalCardLoader/>
		<JournalCardLoader/>
		<JournalCardLoader/>
	</>
);
