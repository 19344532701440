import { makeAutoObservable, runInAction, reaction } from 'mobx';
import {
	IGetUserGamificationResponse,
	IGetGamificationItemsResponse,
	IGetAllProfessionBranchesResponse
} from '@lomonosov/types/dist';

import { generalStore, userStore } from '@stores';
import { UserGamificationService } from '../../services';

interface IShopItem {
	title: string,
	type: 'head' | 'body' | 'car' | 'house' | ''
}

class UserGamificationStore {
	gamificationInfo: IGetUserGamificationResponse;
	branches: IGetAllProfessionBranchesResponse = [];
	currentBranch: any;
	activeScreen: string = 'homepage';
	shop: IGetGamificationItemsResponse = [];
	shopItem: IShopItem = { title: '', type: '' };
	houseItemType: string = '';
	playerPosition: 'left' | 'right' | 'center' = 'right';
	isShowShopMenu: boolean = false;
	isLoadingHomepage: boolean = true;
	isLoadingShop: boolean = false;
	isLoadingProfession: boolean = false;
	isShowNotificationModal: boolean = false;
	isShowWelcomeModal: boolean = false;
	isShowSalaryModal: boolean = false;
	isSliderLoading: boolean = false;
	isShowLevelUpModal: boolean = false;
	isDailyLoading: boolean = false;
	friend: any = { head: '', body: '', isShow: false, shopItems: [], name: '' };
	daily: any[] = [];
	currentDaily = null;

	constructor () {
		makeAutoObservable(this);
	}

	changeShopItem (data) {
		this.shopItem = data;
	}

	changeDaily (daily) {
		this.currentDaily = daily;
	}

	async getUserGamification (userId: string) {
		this.setIsLoading('Homepage', true);
		try {
			const { data } = await UserGamificationService.getUserGamification(userId);
			runInAction(() => {
				this.gamificationInfo = data;
				this.isShowWelcomeModal = data.previewVisibility;
				this.isShowSalaryModal = !data.receivedSalary;
				this.setIsLoading('Homepage', false);
				if (data?.isDailyEntry) {
					this.getDailyQuests(userId);
				} else {
					this.createDailyQuests(userId);
				}
			});
		} catch (e) {
			console.log(e);
		}
	}

	async createDefaultGamification (userId, sex) {
		this.setIsLoading('Homepage', true);
		try {
			const { data } = await UserGamificationService.createDefaultGamification(userId, sex);
			runInAction(() => {
				this.gamificationInfo = data;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setIsLoading('Homepage', false);
		}
	}

	// Получить предметы магазина
	async getGameStore () {
		this.setIsLoading('Shop', true);
		try {
			const gamificationId = this.gamificationInfo.id;
			const params = {
				sex: this.gamificationInfo.sex,
				part: this.shopItem.type
			};
			const { data } = await UserGamificationService.getItemsShop(gamificationId, params);
			runInAction(() => {
				this.shop = data;
				this.setIsLoading('Shop', false);
			});
		} catch (e) {
			console.log(e);
		}
	}

	// получения рандомного друга
	getRandomFriend () {
		this.friend.isShow = false;
		try {
			const gamificationId = this.gamificationInfo.id;
			const sex = this.gamificationInfo.sex === 'man' ? 'woman' : 'man';
			if (this.friend.shopItems.length < 1) {
				Promise.all([
					UserGamificationService.getItemsShop(gamificationId, { sex: sex, part: 'body' }),
					UserGamificationService.getItemsShop(gamificationId, { sex: sex, part: 'head' })
				]).then((values) => {
					this.friend.shopItems = values;
					this.setRandomFriend(values);
				});
			} else {
				this.setRandomFriend(this.friend.shopItems);
			}
		} catch (e) {
			console.log(e);
		}
	}

	setRandomFriend (response) {
		const sex = this.gamificationInfo.sex === 'man' ? 'woman' : 'man';
		const names =
		{
			man: ['Руслан Зорин', 'Данил Антонов', 'Никита Петров', 'Илья Герасимов', 'Дмитрий Шульгин', 'Дима Лихащенко', 'Артем Козлов', 'Артем Бонадернко'],
			woman: ['Яна Гауэргоф']
		};
		this.friend.body = response[0].data[~~(Math.random() * response[0].data.length)].file?.[0].id || '';
		this.friend.head = response[1].data[~~(Math.random() * response[0].data.length)].file?.[0].id || '';
		this.friend.name = names[sex][~~(Math.random() * names[sex].length)];
		this.friend.isShow = true;
	}

	// Купить предмет
	async buyGameItem (itemId: string, userId: string = userStore.user.id) {
		this.setIsLoading('Shop', true);
		try {
			const { data } = await UserGamificationService.buyGameItem(itemId, userId);
			runInAction(() => {
				const newItemIndex = this.shop.findIndex((item) => item.id === itemId);
				this.shop[newItemIndex].isBought = true;
				this.gamificationInfo = data;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setIsLoading('Shop', false);
		}
	}

	async updateUserGamification (newItem, userId = userStore.user.id, professionId?: string) {
		this.setIsLoading('Shop', true);
		try {
			let equippedItems: any = [];
			if (newItem) {
				const currentItem = userGamificationStore.gamificationInfo?.equippedItems.find(item => item.id === newItem.id);
				if (currentItem) {
					equippedItems = userGamificationStore.gamificationInfo?.equippedItems.filter(item => item.id !== newItem.id);
				} else {
					equippedItems = userGamificationStore.gamificationInfo?.equippedItems.filter(item => item.type !== newItem.type);
					equippedItems.push(newItem);
				}
			}

			const info = {
				equippedItems: equippedItems.length > 0 ? equippedItems : undefined,
				professionId: professionId || userGamificationStore.gamificationInfo?.profession?.id
			};

			const { data } = await UserGamificationService.updateUserGamification(userId, info);

			runInAction(() => {
				this.shop = this.shop.map(item => ({ ...item, isEquipped: item.id === newItem.id ? newItem.isEquipped : false }));
				this.gamificationInfo.equippedItems = data.equippedItems;
				this.gamificationInfo.profession = data.profession;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setIsLoading('Shop', false);
		}
	}

	// Получить ветки профессий
	async getProfessionBranches () {
		try {
			this.setIsLoading('Profession', true);
			const { data } = await UserGamificationService.getProfessionBranches();
			runInAction(() => {
				this.branches = data;
				this.changeCurrentBranch(data[0]);
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setIsLoading('Profession', false);
		}
	}

	async getSalary (userId) {
		try {
			const { data } = await UserGamificationService.receiveSalary(userId);
			this.gamificationInfo = data;
			this.isShowSalaryModal = false;
		} catch (e) {
			console.log(e);
		}
	}

	// Ежедневные задания
	async getDailyQuests (userId: string) {
		this.isDailyLoading = true;
		try {
			const { data } = await UserGamificationService.getDailyQuests(userId);
			this.daily = data;
			this.isDailyLoading = false;
		} catch (e) {
			console.log(e);
		}
	}

	async createDailyQuests (userId: string) {
		this.isDailyLoading = true;
		try {
			const { data } = await UserGamificationService.createDailyQuests(userId);
			this.daily = data;
			this.isDailyLoading = false;
		} catch (e) {
			console.log(e);
		}
	}

	async checkDailyQuests (userId: string) {
		this.isDailyLoading = true;
		try {
			const { data } = await UserGamificationService.checkDailyQuests(userId);
			if (data) {
				this.daily = this.daily.map(daily => data.find(({ id }) => daily.id === id) ?? daily);
			}
			this.isDailyLoading = false;
		} catch (e) {
			console.log(e);
		}
	}

	async updateStatusDailyQuests (userId: string, dailyId: string) {
		this.isDailyLoading = true;
		try {
			const { data } = await UserGamificationService.updateStatusDailyQuests(userId, dailyId);
			this.daily = this.daily.map(daily => daily?.daily?.id === dailyId ? data : daily);
			if (data.status === 'reward') {
				generalStore.changeAlertState('success', 'Задание выполнено');
			} else {
				generalStore.changeAlertState('success', 'Награда выдана');
			}
			this.isDailyLoading = false;
		} catch (e) {
			console.log(e);
		}
	}

	changeGamificationInfo (field, value) {
		this.gamificationInfo[field] = value;
	}

	changePage (screen: string) {
		this.activeScreen = screen;
	}

	changeWelcomeModal (bool: boolean) {
		this.isShowWelcomeModal = bool;
	}

	changeSliderLoading (bool: boolean) {
		this.isSliderLoading = bool;
		setTimeout(() => {
			this.isSliderLoading = false;
		}, 1000);
	}

	changeCurrentBranch (info) {
		this.currentBranch = info;
	}

	changeLevelUpModal () {
		this.isShowLevelUpModal = !this.isShowLevelUpModal;
	}

	changeNotificationModal () {
		this.isShowNotificationModal = !this.isShowNotificationModal;
	}

	setIsLoading (field: string, value: boolean) {
		this[`isLoading${field}`] = value || !this[`isLoading${field}`];
	}

	setHouseItemType (type: string) {
		this.houseItemType = type;
	}

	changeIsShowShopMenu (value: boolean = !this.isShowShopMenu) {
		this.isShowShopMenu = value;
	}

	changePlayerPosition (value: 'left' | 'right' | 'center' = 'right') {
		this.playerPosition = value;
	}
}

export const userGamificationStore = new UserGamificationStore();

reaction(
	() => userGamificationStore.shopItem,
	() => {
		if (userGamificationStore.shopItem.type !== '') {
			userGamificationStore.getGameStore();
		}
	}
);
