import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { adminAnalyticsStore } from '@stores';
import { Table } from '@components';
import { IColumn } from '@components/table';
import { TableCurrentStudentAnalysis } from '../index';

export default observer(() => {
	const [search, setSearch] = useState('');
	const [timer, setTimer] = useState<any>();

	useEffect(() => {
		adminAnalyticsStore.getUsersActivity();
	}, []);

	const onSearchChange = (newSearch: string) => {
		setSearch(newSearch);
		clearTimeout(timer);
		setTimer(setTimeout(async () => {
			// adminUserStore.getUsers(page, rowsPerPage, newSearch),
			adminAnalyticsStore.changeFilterFieldStudentActivity(newSearch, 'search');
			await adminAnalyticsStore.getFiltersStudentActivity('users');
		},
		1000
		));
	};

	const getCurrentUser = async (id: string) => {
		await adminAnalyticsStore.getCurrentUserActivity(8, 0, id);
	};

	const changePage = async (event, newPage) => {
		await adminAnalyticsStore.changePageStudentActivity(newPage, adminAnalyticsStore.paginationStudentActivity.users.rows, 'users');
	};

	const changeTableRows = async (e: React.ChangeEvent<HTMLInputElement>) => {
		await adminAnalyticsStore.changePageStudentActivity(adminAnalyticsStore.paginationStudentActivity.users.currentPage, +e.target.value, 'users');
	};

	return (
		<div className='student-analysis__table'>
			<Table
				columns={columns}
				data={adminAnalyticsStore.usersActivity}
				search={{
					prop: search,
					action: (str) => { onSearchChange(str); }
				}}
				detailPanel={{
					Component: TableCurrentStudentAnalysis,
					action: (id) => getCurrentUser(id)
				}}
				pagination={{
					count: adminAnalyticsStore.paginationStudentActivity.users.totalCount,
					page: adminAnalyticsStore.paginationStudentActivity.users.currentPage,
					rowsPerPage: adminAnalyticsStore.paginationStudentActivity.users.rows,
					onPageChange: changePage,
					onRowsPerPageChange: changeTableRows,
					rowsPerPageOptions: [8, 10, 25, 50]
				}}
			/>
		</div>
	);
});

const columns: IColumn[] = [
	{ field: 'name', name: 'Студент' },
	{ field: 'vkId', name: 'ВК id' },
	{ field: 'phone', name: 'Телефон' },
	{ field: 'count', name: 'Кол-во работ' }
];
