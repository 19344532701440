import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@mui/material';
import { images } from '@helpers';
import { userStore } from '@stores';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '../../../../../../components';
import './style.css';
import '../../../../portal.global.style.css';

export default observer(() => {
	useEffect(() => {
		console.log('userStore.showCareerGuidance', userStore.showCareerGuidance);
		if (!userStore.professionPromo && userStore.showCareerGuidance) {
			(async () => {
				await userStore.getProfessionPromo(userStore.user.id);
			})();
		}
	}, [userStore.showCareerGuidance]);

	const pictures = [
		{
			className: 'tech-support-block__image key-banner-code-block__boy',
			img: images.techSupportImage
		},
		{
			className: 'key-banner-code-block__lock1',
			img: images.lock
		},
		{
			className: 'key-banner-code-block__lock2',
			img: images.lock
		}
	];

	return (
		<div className='tech-support-block__container key-banner'>
			<div className='tech-support-block key-banner__container'>
				<div className='tech-support-block__title'>
					{userStore.showCareerGuidance ? 'Твой персональный ключ активации' : 'Доступ закрыт'}
				</div>
				<span className='key-banner__tutorial'>
					{userStore.showCareerGuidance
						? <>
							Переходи по кнопке “Пройти тест”.<br/>
							Ключ подставиться автоматически и активирует доступ к тесту.
						</>
						: <>
							У тебя пока нет доступа к профориентации,<br/>
							тест станет доступен сразу после покупки любого<br/>
							образовательного продукта <span>Lomonosov School!</span>
						</>
					}
				</span>
				{userStore.showCareerGuidance &&
					<div className='key-banner-code-block tech-support-block__description theme-background'>
						<span>{userStore.professionPromo}</span>
						<IconButton onClick={() => navigator.clipboard.writeText(userStore.professionPromo)}>
							<ContentCopyIcon/>
						</IconButton>
					</div>
				}
				<div className='tech-support-block__button-container key-banner-code-block__link'>
					<a
						className='tech-support-block__button'
						href={userStore.showCareerGuidance ? `https://work-way.ru/?key=${userStore.professionPromo}` : '/shop'}
						target='_blank'
						rel='noreferrer'
					>
						<Button type='main_green'>
							{userStore.showCareerGuidance ? 'Пройти тест' : 'Продолжить'}
						</Button>
					</a>
				</div>
				<div className='tech-support-block__images'>
					{pictures.map((pic, index) =>
						<img key={index} className={pic.className} src={pic.img} alt='error'/>
					)}
				</div>
			</div>
		</div>
	);
});
