import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Input, Table, SocialButtonIconType } from '@components';
import { ICurrentCourseProps } from '@interfaces';
import { IColumn } from '@components/table';
import { adminTeacherStore, generalStore } from '@stores';
import './style.css';

export default observer((props: { dataL: ICurrentCourseProps['teacher'] }) => {
	const { dataL } = props;
	const [currentTeacher, setCurrentTeacher] = useState<ICurrentCourseProps['teacher']>(dataL);

	const addSocial = (newState: SocialTableButtonsItem) => {
		if (!newState.link) {
			generalStore.changeAlertState('warning', 'ссылка обязательна');
			return;
		}
		if (currentTeacher.socials.some((s) => s.link === newState.link)) {
			generalStore.changeAlertState('warning', 'такая ссылка уже есть');
			return;
		}
		setCurrentTeacher({
			...currentTeacher,
			socials: [
				...currentTeacher.socials,
				{ ...newState, iconName: newState.iconName.id }
			]
		});
	};

	const deleteSocial = (id: string) => {
		setCurrentTeacher({
			...currentTeacher,
			socials: currentTeacher.socials.filter((s) => s.link !== id)
		});
	};

	const onEditSocial = (id: string, social: SocialTableButtonsItem) => {
		if (!social.link) {
			generalStore.changeAlertState('warning', 'ссылка обязательна');
			return;
		}
		setCurrentTeacher({
			...currentTeacher,
			socials: currentTeacher.socials.map((s) => s.link === id ? { ...social, iconName: social.iconName.id, id: s.link } : s)
		});
	};

	const handleChangeFieldTeacher = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.name) return;
		setCurrentTeacher({
			...currentTeacher,
			[e.target.name]: e.target.value
		});
	};

	const updateTeacher = async () => {
		await adminTeacherStore.updateTeacher(dataL.id, currentTeacher);
	};

	return (
		<div className='teacher-info'>
			<div className='teacher-info__element'>
				<Input
					label='ФИО'
					name='name'
					value={currentTeacher.name}
					onChange={handleChangeFieldTeacher}
				/>
			</div>
			<div className='teacher-info__element'>
				<Input
					name='description'
					label='Описание'
					rows={3} multiline
					value={currentTeacher.description}
					onChange={handleChangeFieldTeacher}
				/>
			</div>
			<div className='teacher-info__element'>
				<Input
					name='legalInfo'
					label='Юр. информация'
					rows={3} multiline
					value={currentTeacher.legalInfo}
					onChange={handleChangeFieldTeacher}
				/>
			</div>
			{currentTeacher.socials &&
				<Table
					title='Социальные сети'
					className='teacher-info__socials-table'
					columns={socialsColumns}
					data={currentTeacher.socials.map(s => ({ ...s, iconName: socialIcons.find(sI => sI.id === s.iconName), id: s.link }))}
					actionRow={{
						delete: deleteSocial,
						edit: onEditSocial,
						add: addSocial
					}}
					swap
				/>
			}
			<div className='teacher-info__button'>
				<Button
					type='main_green'
					onClick={updateTeacher}
				>
					Сохранить
				</Button>
			</div>
		</div>
	);
});

const socialIcons: SocialButton[] = [
	{ id: 'youtube', name: 'YouTube' },
	{ id: 'telegram', name: 'Telegram' },
	{ id: 'whatsapp', name: 'WhatsApp' },
	{ id: 'discord', name: 'Discord' },
	{ id: 'instagram', name: 'Instagram' },
	{ id: 'vk', name: 'Вконтакте' },
	{ id: 'apple', name: 'Apple' }
];

const socialsColumns: IColumn[] = [
	// { field: 'id', name: 'id', editable: false, copy: true },
	{ field: 'label', name: 'Надпись на кнопке' },
	{ field: 'link', name: 'Ссылка' },
	{ field: 'iconName', name: 'Иконка', type: 'select', options: socialIcons, defaultValue: null, width: 200 }
];

type SocialButton = { id: SocialButtonIconType; name: string }
type SocialTableButtonsItem = {
	id: string;
	label: string;
	link: string;
	iconName: SocialButton;
}
