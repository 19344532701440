import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default (props: { children?: ReactElement }) => {
	const location = useLocation();
	const { pathname } = location;

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}, [pathname]);

	return props.children || null;
};
