import { OutputBlockData } from '@editorjs/editorjs';
import { ICreateKinescopeEventResponse, IGetCurrentLesson, IUpdateKinescopeEventDto } from '@interfaces';
import { ICreateLessonResponse, IGetLessonByIdResponse } from '@lomonosov/types/dist';
import { AdminLessonsService } from '@services';
import { makeAutoObservable, runInAction } from 'mobx';
import { adminCoursesStore } from './admin-course-store';

class AdminLessonsStore {
	currentLesson: IGetCurrentLesson = {} as IGetCurrentLesson;
	isLoading: boolean = false;
	lessons: IGetLessonByIdResponse[] | ICreateLessonResponse[] = []
	isCreatingLesson: boolean = false;
	blocks: OutputBlockData[] | null = null;
	kinescope: ICreateKinescopeEventResponse | null = null;
	isLoadingKinescope: boolean = false;

	constructor () {
		makeAutoObservable(this);
	}

	setBlocks (blocks) {
		this.blocks = blocks;
	}

	async getLessonById (lessonId: string) {
		try {
			this.isLoading = true;
			const { data } = await AdminLessonsService.getLessonById(lessonId);
			runInAction(() => {
				this.currentLesson = data;
				this.blocks = data.blocks;
			});
			if (data.eventId && data.videoType === 'kinescope') {
				const { data: kinescope } = await AdminLessonsService.getKinescopeEvent(data.eventId);
				this.kinescope = kinescope || null;
			} else {
				this.kinescope = null;
			}
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	resetLesson () {
		this.currentLesson = { videoType: 'none', order: 0 } as IGetCurrentLesson;
		this.blocks = [];
	}

	async createLesson (newLesson) {
		this.isCreatingLesson = true;
		try {
			const { data } = await AdminLessonsService.createLesson(newLesson);
			runInAction(() => {
				this.currentLesson = data as IGetCurrentLesson;
				// const updatedOrder = this.lessons.map(lesson => lesson.id ? { id: lesson.id, order: lesson.order } : { id: data.id, order: lesson.order });
				// this.updateLessonsOrder({ lessons: updatedOrder });
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isCreatingLesson = false;
		}
	}

	async updateLesson (newLesson) {
		try {
			const { data } = await AdminLessonsService.updateLesson(newLesson.id, newLesson);
			runInAction(() => {
				this.currentLesson = data as IGetCurrentLesson;
				this.blocks = data.blocks;
				const updatedOrder = this.lessons.map(({ id, order }) => ({ id, order }));
				if (this.lessons.length > 1) {
					this.updateLessonsOrder({ lessons: updatedOrder });
				}
				if (this.currentLesson.eventId && this.currentLesson.begins !== newLesson.begins) {
					this.updateKinescopeEvent(this.currentLesson.eventId, { time: newLesson.begins });
				}
			});
		} catch (e) {
			console.log(e);
		}
	}

	async updateLessonsOrder (lessons) {
		try {
			await AdminLessonsService.updateLessonsOrder(lessons);
		} catch (e) {
			console.log(e);
		}
	}

	async deleteLesson (lessonId: string) {
		await AdminLessonsService.deleteLesson(lessonId);
	}

	// ////////// KINESCOPE /////////////////

	async createKinescopeEvent (name: string, time: string) {
		try {
			this.isLoadingKinescope = true;
			const { data: liveFolder } = await AdminLessonsService.getKinescopeParentFolder(
				adminCoursesStore.currentCourse.discipline.name, adminCoursesStore.currentCourse.direction.name,
				'live'
			);
			const { data: videoFolder } = await AdminLessonsService.getKinescopeParentFolder(
				adminCoursesStore.currentCourse.discipline.name, adminCoursesStore.currentCourse.direction.name,
				'video'
			);
			const { data: event } = await AdminLessonsService.createKinescopeEvent({
				name,
				time: time,
				liveFolderId: liveFolder.folderId,
				recordFolderId: videoFolder.folderId
			 });
			 this.kinescope = event;
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoadingKinescope = false;
		}
	}

	async updateKinescopeEvent (eventId: string, data: IUpdateKinescopeEventDto) {
		try {
			await AdminLessonsService.updateKinescopeEvent(eventId, data);
		} catch (e) {
			console.log(e);
		}
	}

	async startStreamKinescope (eventId: string) {
		this.isLoadingKinescope = true;
		try {
			await AdminLessonsService.startStreamKinescope(eventId);
			this.kinescope!.stream.status = 'on-air';
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoadingKinescope = false;
		}
	}

	async stopStreamKinescope (eventId: string) {
		this.isLoadingKinescope = true;
		try {
			await AdminLessonsService.stopStreamKinescope(eventId);
			this.kinescope!.stream.status = 'finished';
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoadingKinescope = false;
		}
	}

	async deleteKinescopeEvent (eventId: string) {
		try {
			await AdminLessonsService.deleteKinescopeEvent(eventId);
			this.kinescope = null;
			this.currentLesson.eventId = null;
		} catch (e) {
			console.log(e);
		}
	}

	async uploadKinescopePoster (eventId: string, file: File) {
		try {
			this.isLoadingKinescope = true;
			await AdminLessonsService.uploadKinescopePoster(eventId, file);
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoadingKinescope = false;
		}
	}

	// ///////////////////////////////////////////////////

	changeCurrentLesson (field: string, value: any) {
		this.currentLesson[field] = value;
	}

	setLessons (lessons) {
		this.lessons = lessons;
	}
}

export const adminLessonsStore = new AdminLessonsStore();
