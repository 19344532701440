import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { adminAnalyticsStore } from '@stores';
import { Table } from '@components';
import { IColumn } from '@components/table';

export default observer(() => {
	const changePage = async (event, newPage) => {
		await adminAnalyticsStore.changePageCourses(
			newPage,
			adminAnalyticsStore.pagination.answers.rows,
			'answers'
		);
	};

	const changeTableRows = async (e: React.ChangeEvent<HTMLInputElement>) => {
		await adminAnalyticsStore.changePageCourses(
			adminAnalyticsStore.pagination.answers.currentPage,
			+e.target.value,
			'answers'
		);
	};

	return (
		<div className='courses-analysis-table'>
			<h4>
				В данной таблице выводятся все сданные работы.
			</h4>
			<br/>
			<Table
				columns={columnsTable}
				data={adminAnalyticsStore.answers}
				pagination={{
					count: adminAnalyticsStore.pagination.answers.totalCount,
					page: adminAnalyticsStore.pagination.answers.currentPage,
					rowsPerPage: adminAnalyticsStore.pagination.answers.rows,
					onPageChange: changePage,
					onRowsPerPageChange: changeTableRows,
					rowsPerPageOptions: [8, 10, 25, 50]
				}}
			/>
			{adminAnalyticsStore.isLoading &&
				<div className='courses-analysis-table__loader'>
					<div className='courses-analysis-table__circle'>
						<CircularProgress/>
					</div>
				</div>
			}
		</div>
	);
});

const columnsTable: IColumn[] = [
	{
		name: 'Студент', field: 'name'
	},
	{
		name: 'Кол-во баллов', field: 'scores', width: 150
	},
	{
		name: 'Коммент ментора', field: 'comment'
	}
];
