import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Pagination from '@mui/material/Pagination';
import { adminDisciplineStore, adminHomeworkStore } from '@stores';
import { HomeworkTemplateFiltering, HomeworkTemplateList } from './homework-template-bank-blocks';
import './style.css';

export default observer(() => {
	useEffect(() => {
		if (!adminHomeworkStore.templates?.length) {
			adminHomeworkStore.getTemplates();
		}
		if (!adminDisciplineStore.disciplines?.length) {
			adminDisciplineStore.getDisciplines();
		}
	}, []);

	return (
		<div className='homework-template-bank'>
			<HomeworkTemplateFiltering/>
			<HomeworkTemplateList />
			<hr/>
			<Pagination
				count={adminHomeworkStore.pagination.pages}
				page={adminHomeworkStore.pagination.currentPage}
				onChange={(e, page) => adminHomeworkStore.changePage(page, 5)}
				defaultPage={1} size='large' showFirstButton showLastButton
				classes={{ ul: 'homework-template-bank__paginator' }}
			/>
		</div>
	);
});
