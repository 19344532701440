import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Pagination from '@mui/material/Pagination';
import { FilteringMenu } from '../../blocks';
import { CourseCard, CourseCardLoader } from '../../components';
import { adminAnalyticsStore } from '@stores';
import { PortalPlug } from '@components';
import '../../style.css';

export default observer(() => {
	useEffect(() => {
		if (!adminAnalyticsStore.courses?.length) {
			adminAnalyticsStore.getCoursesAnalysis();
		}
	}, []);

	const selectCourse = (courseId: string) => {
		adminAnalyticsStore.setCurrentCourseId(courseId);
		adminAnalyticsStore.getLessonsAnalysis(8, 0, courseId);
		adminAnalyticsStore.getTasksAnalysis(8, 0, courseId);
		adminAnalyticsStore.changeActiveScreen(1);
	};

	return (
		<div className='courses-analysis'>
			<FilteringMenu/>
			<div className='courses-analysis__cards'>
				{adminAnalyticsStore.isLoading
					? <>
						<CourseCardLoader/>
						<CourseCardLoader/>
						<CourseCardLoader/>
						<CourseCardLoader/>
						<CourseCardLoader/>
					</>
					: adminAnalyticsStore.courses.length !== 0
						? adminAnalyticsStore.courses.map((course: CoursesCardProps) =>
							<CourseCard key={course.id} course={course} selectCourse={selectCourse}/>
						)
						: <PortalPlug>Курсы не найдены</PortalPlug>
				}
			</div>
			<Pagination
				count={adminAnalyticsStore.pagination.courses.pages}
				page={adminAnalyticsStore.pagination.courses.currentPage}
				onChange={(e, page) => adminAnalyticsStore.changePageCourses(page, 5, 'courses')}
				defaultPage={1} size='large' showFirstButton showLastButton
				classes={{ ul: 'courses-analysis__paginator' }}
			/>
		</div>
	);
});

interface CoursesCardProps {
	id: string,
	isVisible: boolean,
	isArchive: boolean,
	name: string,
	direction: {
		id: string,
		name: string
	},
	discipline: {
		id: string,
		name: string
	},
	category: {
		id: string,
		name: string
	},
	teacher: {
		id: string,
		photo: string,
		firstName: string,
		secondName: string
	},
	sections: number,
	lessons: number,
	users: number,
	homeworks: number,
	manualHomeworks: number,
	probes: number,
	tasks: number
}
