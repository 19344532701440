import React from 'react';
import { observer } from 'mobx-react-lite';

import { Table } from '@components';
import { debounce } from '@mui/material/utils';
import { adminComboCoursesStore, adminCoursesStore } from '@stores';
import { Switch } from '@material-ui/core';
import { IColumn } from '@components/table';
import { IComboCourse } from '@interfaces';

interface TableComboCoursesProps {
	onSaveCombo?: () => void;
}

export const TableComboCourses = observer((props: TableComboCoursesProps) => {
	const { onSaveCombo } = props;

	const getAllCourses = () => {
		if (!adminCoursesStore.allCourses?.length) {
			adminCoursesStore.getAllCourses(20, undefined, true);
		}
	};

	const onAddComboCourse = async (course: IComboCourse) => {
		adminComboCoursesStore.addComboCourse(course);
		onSaveCombo?.();
	};

	const onDeleteComboCourse = (courseId: string) => {
		adminComboCoursesStore.deleteComboCourse(courseId);
		onSaveCombo?.();
	};

	const searchsDelayed = debounce((text: string) => adminCoursesStore.getAllCourses(20, text, true), 1000);

	const coursesColumns: IColumn[] = [
		{
			field: 'id',
			name: 'id',
			copy: true,
			align: 'center',
			width: 30
		},
		{
			type: 'select',
			field: 'course',
			name: 'Название',
			options: adminCoursesStore.allCourses,
			onFocus: getAllCourses,
			onChangeInput: searchsDelayed
		},
		{
			type: 'element',
			field: 'isActive',
			name: 'Активность',
			width: 120,
			align: 'center',
			element: (data, func) => (
				data.id &&
				<Switch
					checked={data?.isActive}
					onChange={(_e, checked) => {
						adminComboCoursesStore.onActiveComboCourse(data.id, !data.isActive);
						func?.(checked);
					}}
					color='primary'
					disabled={data.id === data.course.id}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			)
		}
	];

	return (
		<Table
			className='detail-combo__table'
			data={adminComboCoursesStore.comboCourses}
			columns={coursesColumns}
			title='Курсы'
			actionRow={{
				add: onAddComboCourse,
				delete: onDeleteComboCourse
			}}
		/>
	);
});
