import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	ICreateCategoryDto,
	ICreateCategoryResponse,
	IGetCategoriesResponse,
	IGetCategoryByIdResponse,
	IUpdateCategoryDto,
	IUpdateCategoryResponse
} from '@lomonosov/types';

export default class {
	/* Категории */
	static async createCategory (newCategory: ICreateCategoryDto): Promise<AxiosResponse<ICreateCategoryResponse>> {
		return $api.post<ICreateCategoryResponse>('/categories', newCategory);
	}

	static async getCategories (): Promise<AxiosResponse<IGetCategoriesResponse>> {
		return $api.get<IGetCategoriesResponse>('/categories');
	}

	static async getCategoryById (categoryId: string): Promise<AxiosResponse<IGetCategoryByIdResponse>> {
		return $api.get<IGetCategoryByIdResponse>(`/categories/${categoryId}`);
	}

	static async updateCategory (
		categoryId: string,
		newInfoCategory: IUpdateCategoryDto
	): Promise<AxiosResponse<IUpdateCategoryResponse>> {
		return $api.put<IUpdateCategoryResponse>(`/categories/${categoryId}`, newInfoCategory);
	}

	static async deleteCategory (categoryId: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/categories/${categoryId}`);
	}
}
