import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { BuyFourCoursesBanner } from '@blocks';
import { AppHelmet, PortalBanner } from '@components';
import { userBannerStore, userStore } from '@stores';
import { PortalMenu, PortalLostUsersModal } from '../../../pages/portal/portal-components';

import './style.css';

export default observer(() => {
	const location = useLocation();
	const navigate = useNavigate();

	const [activeScreen, setActiveScreen] = useState<string>(location.pathname.slice(1) || 'study-program');
	const [lostUsersModal, setLostUsersModal] = useState<boolean>(false);

	useEffect(() => {
		if (userStore.user.id && !userBannerStore.bannersPortal?.length) {
			userBannerStore.getBanners(userStore.user.id);
		}
	}, []);

	useEffect(() => {
		setActiveScreen(location.pathname.slice(1).split('/')[0]);
	}, [location.pathname]);

	const handleChange = (newValue: string) => {
		setActiveScreen(newValue);
		navigate(newValue);
	};

	return (
		<div className='portal-container theme-background'>
			<AppHelmet />
			{!userBannerStore.isLoading &&
				<PortalBanner banners={userBannerStore.bannersPortal} userName={userStore.user.firstName} />
			}
			<div className='portal'>
				<BuyFourCoursesBanner/>
				<div className='portal-header'>
					<PortalMenu value={activeScreen} handleChange={handleChange} />
				</div>
				<Outlet/>
			</div>
			<PortalLostUsersModal lostUsersModal={lostUsersModal} setLostUsersModal={setLostUsersModal} />
		</div>
	);
});
