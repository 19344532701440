import { makeAutoObservable, runInAction } from 'mobx';
import {
	ICreateSectionDto,
	IGetAllSectionsByCourseItemResponse
} from '@lomonosov/types';
import { AdminCourseService } from '@services';
import { adminCoursesStore } from '@stores';

interface IActiveMenu {
	id: string,
	type: 'section' | 'lesson'
}

interface ICurrentSection {
	id?: string
}

class AdminSectionsStore {
	sections: IGetAllSectionsByCourseItemResponse[] = [];
	currentSection: ICurrentSection & ICreateSectionDto;
	activeMenuItem: IActiveMenu = { type: 'section', id: '' };
	isLoading: boolean = false;

	constructor () {
		makeAutoObservable(this);
	}

	async getSections (courseId: string) {
		this.setIsLoading(true);
		try {
			const { data } = await AdminCourseService.getSections(courseId);
			runInAction(() => {
				this.sections = data;
				this.currentSection = data[data.length - 1];
				this.activeMenuItem = data.length !== 0
					? { id: data[data.length - 1].id, type: 'section' }
					: { id: '', type: 'section' };
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setIsLoading(false);
		}
	}

	async createSection () {
		this.setIsLoading(true);
		try {
			this.currentSection = {
				name: 'Новая секция',
				order: this.sections.length,
				courseId: adminCoursesStore.currentCourse.id
			};
			const { data } = await AdminCourseService.createSection(this.currentSection);
			runInAction(() => {
				this.sections.push(data);
				this.currentSection = data;
				this.activeMenuItem = { id: data.id, type: 'section' };
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setIsLoading(false);
		}
	}

	async updateSections (sections) {
		try {
			const { data } = await AdminCourseService.updateSections(sections);
			runInAction(() => {
				this.sections = data.map((section) => {
					const prevSection = this.sections.find((sect) => sect.id === section.id);
					prevSection && (section.lessons = prevSection.lessons);
					return section;
				}); // штука которая при апдейте секций подставляет уроки к обновленным секциям
			});
		} catch (e) {
			console.log(e);
		}
	}

	async deleteSection (sectionId: string) {
		this.setIsLoading(true);
		try {
			await AdminCourseService.deleteSection(sectionId);
			runInAction(() => {
				this.sections = this.sections.filter(section => section.id !== sectionId);
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setIsLoading(false);
		}
	}

	changeCurrentSection (sectionId: ICurrentSection & ICreateSectionDto) {
		this.currentSection = sectionId;
	}

	resetSections () {
		this.sections = [] as IGetAllSectionsByCourseItemResponse[];
	}

	setActiveMenuItem (item) {
		this.activeMenuItem = item;
	}

	setIsLoading (value: boolean) {
		this.isLoading = value;
	}
}

export const adminSectionsStore = new AdminSectionsStore();
