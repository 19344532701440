import React from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Header from '@blocks/header';
import Footer from '@blocks/footer';
import MailLayout from '../main-layout';

import '../main-layout/style.css';

const ShopLayout = observer(() => {
	return (
		localStorage.token
			? <MailLayout/>
			: <>
				<Header changeMenuState={() => null}/>
				<main className='mainLayout mainLayout__margin-left-static-close mainLayout__margin-top theme-background'>
					<Outlet/>
				</main>
				<Footer/>
			</>
	);
});

export default ShopLayout;
