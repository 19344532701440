import { makeAutoObservable } from 'mobx';
import { ISendEssayDto, UserEssayService, IBuyEssayDto, EssayType, IAllUserEssayResponse, IUserEssay } from '@services';

class UserEssayStore {
	essayList: IAllUserEssayResponse = {
		count: 0,
		items: []
	}

	essayById: IUserEssay | null = null;
	isLoading: boolean = true;
	isLoadingByEssay: boolean = false;

	constructor () {
		makeAutoObservable(this);
	}

	// Получить все сочинения
	async getListEssay (take: number, skip: number, type: EssayType) {
		this.isLoading = true;
		try {
			const { data } = await UserEssayService.getListEssay(take, skip, type);
			this.essayList = data;
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
		await this.getEssayById(this.essayList.items[0].id || '');
	}

	async getEssayById (id: string) {
		this.isLoading = true;
		try {
			const { data } = await UserEssayService.getByIdEssay(id);
			// const index = this.essayList.items.findIndex(essay => essay.id === id);
			// this.essayList.items[index] = { ...this.essayList.items[index], ...data };
			this.essayById = data.essay;
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	async createEssay (essay: ISendEssayDto) {
		try {
			const { data } = await UserEssayService.createEssay(essay);
			if (data) {
				await this.getEssayById(essay.literaryText.id);
			}
		} catch (e) {
			console.log(e);
		}
	}

	async buyEssay (buyEssayInfo: IBuyEssayDto) {
		try {
			const { data } = await UserEssayService.buyEssay(buyEssayInfo);
			if (data.Success) {
				location.assign(data.PaymentURL);
			}
		} catch (error) {
			console.log(error);
		}
	}

	saveFileEssayById (file, essayId: string) {
		this.essayList.items = this.essayList.items.map(essay => essay.id === essayId ? { ...essay, userFile: file } : essay);
	}

	deleteFileEssayById (essayId) {
		this.essayList.items = this.essayList.items.map(essay => essay.id === essayId ? { ...essay, userFile: null } : essay);
	}
}

export const userEssayStore = new UserEssayStore();
