import React, { useEffect, useState } from 'react';
import { GetShopButtonResponse } from '@lomonosov/types';

import { Button, MultiSelect, Select } from '@components';
import { adminShopStore } from '@stores';
import { UploadPreview } from '../../../../admin-components/upload-preview';

type obj = {
	id: string,
	name: string
}

interface IProps {
	dataL: any
	info: GetShopButtonResponse['Admin']
	tariffPlan: obj[]
	courses: obj[],
	onUpdatePreview: (fileId: string) => void
}

export default (props: IProps) => {
	const {
		dataL,
		info,
		tariffPlan,
		courses,
		onUpdatePreview
	} = props;

	const [currentCourses, setCurrentCourses] = useState<obj[]>([]);
	const [currentTariffPlan, setCurrentTariffPlan] = useState<any>(null);
	const [currentWay, setCurrentWay] = useState(optionsWay[0]);
	const [data, setData] = useState<any[]>([]);

	useEffect(() => {
		setData(makeUniq([...courses, ...info.courses]));
		setCurrentCourses(info.courses);
		setCurrentTariffPlan(info.tariffPlan);
	}, []);

	const saveOptions = () => {
		// @ts-ignore
		adminShopStore.editButton(dataL.id, {
			way: currentWay,
			courses: currentCourses,
			tariffPlan: currentTariffPlan
		});
	};

	const makeUniq = (arr) => {
		const seen = {};
		const result: any[] = [];
		let j = 0;

		for (let i = 0; i < arr.length; i++) {
			const item = arr[i];
			const itemType = typeof item.id;
			const key = `${itemType}_${item.id}`;
			if (!seen[key]) {
				seen[key] = 1;
				const { discipline, id, name } = item;
				result[j++] = { discipline, id, name };
			}
		}

		return result;
	};

	return (
		<>
			<UploadPreview
				className='admin-main-shop__upload'
				fileId={dataL.previewId}
				isLocalDelete
				onChangeImage={onUpdatePreview}
			/>
			<MultiSelect
				label={'Курсы'}
				data={data}
				value={currentCourses}
				onChange={setCurrentCourses}
			/>
			<div className='admin-main-shop__child-buttons_fields'>
				<div className='admin-main-shop__child-buttons_field'>
					<Select
						title='Тарифный план'
						input={{ label: 'Тарифный план' }}
						options={tariffPlan}
						value={currentTariffPlan}
						onChange={(e, value) => {
							setCurrentTariffPlan(value);
						}}
					/>
				</div>
				<div className='admin-main-shop__child-buttons_field'>
					<Select
						title='Путь'
						input={{ label: 'Путь' }}
						options={optionsWay}
						value={currentWay}
						onChange={(e, value) => {
							setCurrentWay(value);
						}}
					/>
				</div>
				<div className='admin-main-shop__child-buttons_field'>
					<Button type='main_green' onClick={saveOptions}>
						Сохранить
					</Button>
				</div>
			</div>
		</>
	);
};

const optionsWay = [
	{ id: 'main', name: 'Одна дисциплина - один курс' },
	{ id: 'spec', name: 'Одна дисциплина - несколько курсов' }
];
