import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Input from '@components/input';
import FileList from '@blocks/file-list';
import { Button, Select, UploadFileButton } from '@components';
import { EssayType, ICreateEssayDto, IGetEssayResponse } from '@services';
import { fileStore, adminEssaysStore, generalStore } from '@stores';
import './style.css';

interface DetailPanelEssayProps {
	dataL?: IGetEssayResponse;
	isNew?: boolean;
	handleCloseModal?: () => void;
}

export const DetailPanelEssay = observer((props: DetailPanelEssayProps) => {
	const { dataL, isNew = false, handleCloseModal } = props;
	const [text, setText] = useState<string>(dataL?.text || '');
	const [dateBegin, setDateBegin] = useState<string | Date>(dataL?.begins || '');
	const [dateEnds, setDateEnds] = useState<string | Date>(dataL?.ends || '');
	const [typeEssay, setTypeEssay] = useState<EssayType>(dataL?.type || 'simple');

	const checkChanges = (value, field: keyof ICreateEssayDto): any => {
		if (field === 'file') {
			return dataL?.[field]?.id === value?.id ? undefined : { file: { id: value?.id } };
		}
		return dataL?.[field] === value ? undefined : value;
	};

	const handleChangeText = (e) => {
		setText(e.target.value);
	};

	const handleChangeDateBegin = (e) => {
		setDateBegin(e.target.value);
	};

	const handleChangeDateEnd = (e) => {
		setDateEnds(e.target.value);
	};

	const handleChangeTypeEssay = (_e, value, reason) => {
		if (reason !== 'clear') {
			setTypeEssay(value?.id);
		}
	};

	const updateEssay = async () => {
		if (new Date(dateBegin) > new Date(dateEnds)) {
			generalStore.changeAlertState('warning', 'Неверный временной промежуток');
			return;
		}
		if (dataL?.id) {
			await adminEssaysStore.updateEssay(dataL.id, {
				file: checkChanges(adminEssaysStore.essay?.file, 'file'),
				text: checkChanges(text, 'text'),
				begins: checkChanges(dateBegin, 'begins'),
				ends: checkChanges(dateEnds, 'ends'),
				type: checkChanges(typeEssay, 'type')
			});
		}
	};

	const createEssay = async () => {
		if (!adminEssaysStore.essay?.file) {
			generalStore.changeAlertState('warning', 'Загрузите файл');
			return;
		}

		if (!dateBegin || !dateEnds || !text || !typeEssay) {
			generalStore.changeAlertState('warning', 'Заполните все поля');
			return;
		}

		await adminEssaysStore.createEssay(
			{
				 begins: dateBegin,
				 ends: dateEnds,
				 file: adminEssaysStore.essay.file,
				 text: text,
				 type: typeEssay
			}
		);
		handleCloseModal?.();
	};

	const onSaveFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const uploadFile = (file) => {
			adminEssaysStore.handleChangeFieldEssay(file, 'file');
		};
		await fileStore.uploadFile(e.target, uploadFile);
	};

	const onDeleteFile = async (fileId: string) => {
		const deleteFile = () => {
			adminEssaysStore.handleChangeFieldEssay(null, 'file');
		};
		await fileStore.deleteFile(fileId, deleteFile);
	};

	return (
		<div className='detail-panel-essay'>
			{!isNew &&
			<p className='detail-panel-essay__text'>
				Создатель: {`${dataL?.owner?.firstName} ${dataL?.owner?.secondName}`}
			</p>
			}
			<div className='detail-panel-essay__header'>
				<div className='detail-panel-essay__block'>
					<p className='detail-panel-essay__text'>
						Временной промежуток:
					</p>
					<div className='detail-panel-essay__dates'>
						<Input
							label='Дата начала'
							type='date'
							InputLabelProps={{ shrink: true }}
							value={dateBegin}
							onChange={handleChangeDateBegin}
						/>
						<Input
							label='Дата конца'
							type='date'
							InputLabelProps={{ shrink: true }}
							value={dateEnds}
							onChange={handleChangeDateEnd}
						/>
					</div>
				</div>
				<div className='detail-panel-essay__block'>
					<p className='detail-panel-essay__text'>
						Тип сочинения
					</p>
					<Select
						options={typesEssay}
						defaultValue={typesEssay[0]}
						value={typesEssay.find(type => type.id === typeEssay)}
						onChange={handleChangeTypeEssay}
					/>
				</div>
			</div>
			<p className='detail-panel-essay__text'>
				Текст
			</p>
			<Input
				multiline
				rows={8}
				value={text}
				onChange={handleChangeText}
			/>
			<p className='detail-panel-essay__text'>
				Файл
			</p>
			{adminEssaysStore.essay?.file
				? <FileList files={[adminEssaysStore.essay.file]} action={onDeleteFile}/>
				: <UploadFileButton
					onSaveFile={onSaveFile}
					isLoading={fileStore.loading}
				/>
			}
			<Button
				type='main_green'
				onClick={isNew ? createEssay : updateEssay}
				className='detail-panel-essay__button'
			>
				{isNew ? 'Создать' : 'Сохранить'}
			</Button>
		</div>
	);
});

const typesEssay: { id: EssayType, name: string }[] = [
	{
		id: 'simple',
		name: 'Обычное'
	},
	{
		id: 'final',
		name: 'Итоговое'
	}
];
