import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { DropdownList } from '@components';
import { userHomeworkStore } from '@stores';
import './style.css';

export default observer((props: { activeScreen: 'homework' | 'journal' }) => {
	const { activeScreen } = props;

	const setFilterDiscipline = useCallback(async (e, value, discipline) => {
		await userHomeworkStore.changeFilterField(discipline, 'discipline', activeScreen);
	}, []);

	const setFilterType = useCallback(async (e, value, type) => {
		await userHomeworkStore.changeFilterField(type, 'type', activeScreen);
	}, []);

	const taskTypes = useMemo(() => ([
		{
			id: 'all',
			name: 'Все типы'
		},
		{
			id: 'probe',
			name: 'Пробный экзамен'
		},
		{
			id: 'automatic',
			name: 'Домашнее задание'
		},
		{
			id: 'manual',
			name: 'Домашнее задание по 2 части'
		}
	]), []);

	return (
		<div className='homework-filtering-menu'>
			<div className='homework-filtering-menu__input'>
				<DropdownList
					className='homework-filtering-menu__dropdown-list'
					options={[{ id: 'all', name: 'Все предметы' }, ...userHomeworkStore.disciplines]}
					value={userHomeworkStore.filterFields[activeScreen].discipline}
					onChange={(e, value, discipline) => setFilterDiscipline(e, value, discipline)}
					fieldOptionKey='name'
				/>
			</div>
			<div className='homework-filtering-menu__input'>
				<DropdownList
					className='homework-filtering-menu__dropdown-list'
					options={taskTypes}
					value={userHomeworkStore.filterFields[activeScreen].type}
					onChange={setFilterType}
					fieldOptionKey='name'
				/>
			</div>
		</div>
	);
});
