import React from 'react';
import { Button, CustomModal } from '@components';
import { constants } from '@helpers';
import '../style.css';

interface BuyItemModalProps {
  open: boolean,
  setOpen: (boolean) => void,
  currentItem: any,
  useItem: (any) => void
}

export default (props: BuyItemModalProps) => {
	const { open, setOpen, currentItem, useItem } = props;

	return (
		<CustomModal
			open={open} handleClose={() => setOpen(!open)} backdropColor='white' viewClose='cross'
			heightModal='auto' widthModal='auto'
		>
			<div className='gamification-modal'>
				<h3 className='gamification-modal__title'>Поздравляем!</h3>
				<img
					className='gamification-modal__image'
					src={`${constants.URL}/files/${currentItem?.preview?.[0]?.id ?? currentItem?.files?.[0]?.id}`}
					alt='error'
				/>
				<div className='gamification-modal__text'>Предмет куплен!</div>
				<div className='gamification-modal__button'>
					<Button
						onClick={() => {
							useItem(currentItem);
							setOpen(!open);
						}}
						type='main_blue'
					>
            Использовать
					</Button>
				</div>
			</div>
		</CustomModal>
	);
};
