import Table, { IColumn } from '@components/table';
import { IGetCoursesNewShopItem } from '@interfaces';
import { adminCoursesStore } from '@stores';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

const ShowcaseCourses = observer(() => {
	useEffect(() => {
		if (!adminCoursesStore.newShopCourses?.length) {
			adminCoursesStore.getCoursesNewShop();
		}
	}, []);

	const onSorted = async ({ oldIndex, newIndex }, newArray) => {
		const changedItems: IGetCoursesNewShopItem[] = newArray.reduce((acc, item, index) => {
			if (adminCoursesStore.newShopCourses[index].id !== item.id) {
				acc.push({ ...item, order: index + 10 });
			}
			return acc;
		}, []);
		if (changedItems.length !== 0) {
			await adminCoursesStore.orderingNewShopCourses(changedItems, newArray);
		}
	};

	return (
		<div>
			<Table
				title={`Курсы: ${adminCoursesStore.newShopCourses?.length || 0}`}
				columns={columns}
				data={adminCoursesStore.newShopCourses || []}
				draggable={onSorted}
			/>
		</div>
	);
});

const columns: IColumn[] = [
	{
		field: 'name',
		name: 'Название'
	},
	{
		field: 'type',
		name: 'Тип',
		align: 'center',
		width: 120
	},
	{
		field: 'directions',
		name: 'Направления'
	},
	{
		field: 'disciplines',
		name: 'Дисциплины'
	}
];

export default ShowcaseCourses;
