import React, { useState } from 'react';
import { AddConsistForm, IConsistInfo } from '../../../../admin-components/AddConsistForm/AddConsistForm';
import { Input } from '@components';
import Button from '@components/button';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface DetailTablePeriodProps {
	dataL: {
		id: string;
		consist: IConsistInfo[] | string;
		description: string;
		marketingDiscount: number;
		title: string;
	}
	actions: {
		changeDetailPeriod: (id: string, state: any) => void
	}
}

export const DetailTablePeriod = (props: DetailTablePeriodProps) => {
	const { dataL, actions } = props;
	const [consistInfo, setConsistInfo] = useState<IConsistInfo[]>(
		typeof dataL.consist === 'string' ? (JSON.parse(dataL.consist) || []) : dataL.consist || []
	);
	const [description, setDescription] = useState(dataL.description || '');
	const [marketingDiscount, setMarketingDiscount] = useState(dataL.marketingDiscount || 0);
	const [title, setTitle] = useState(dataL.title || '');
	const [isChanged, setIsChanged] = useState<boolean>(false);

	const onAddConsist = (consist: IConsistInfo) => {
		setConsistInfo([...consistInfo, consist]);
		setIsChanged(true);
	};

	const onDeleteConsist = (id: string) => {
		setConsistInfo(consistInfo.filter((t) => t.id !== id).map((t, i) => ({ ...t, order: i })));
		setIsChanged(true);
	};

	const onEditConsist = (id: string, consist: IConsistInfo) => {
		const i = consistInfo.findIndex((t) => t.id === id);
		consistInfo[i] = consist;
		// setConsistInfo([...consistInfo]);
		setIsChanged(true);
	};

	const handleChangeOrder = (id: string, newOrder: number, prevOrder: number) => {
		consistInfo[prevOrder].order = newOrder;
		consistInfo[newOrder].order = prevOrder;
		setConsistInfo([...consistInfo]);
		setIsChanged(true);
	};

	const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDescription(e.target.value);
		setIsChanged(true);
	};

	const handleChangeDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMarketingDiscount(Number(e.target.value));
		setIsChanged(true);
	};

	const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
		setIsChanged(true);
	};

	const save = () => {
		actions.changeDetailPeriod(dataL.id, {
			consist: consistInfo,
			description,
			marketingDiscount,
			title
		});
	};

	return (
		<div className='detailTablePeriod'>
			<div className='detailTablePeriod__block'>
				<Input
					label='Название периода'
					value={title}
					onChange={handleChangeTitle}
				/>
				<Input
					label='Скидка %'
					type='number'
					InputProps={{
						endAdornment: '%',
						inputProps: {
							min: 0,
							max: 100
						}
					}}
					value={marketingDiscount}
					onChange={handleChangeDiscount}
				/>
			</div>
			<Input
				style={{ marginTop: '10px' }}
				label='Описание'
				multiline
				maxRows={10}
				rows={5}
				value={description}
				onChange={handleChangeDescription}
			/>
			<Accordion className='detailTablePeriod__accordion'>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					Опции тарифа
				</AccordionSummary>
				<AccordionDetails style={{ flexDirection: 'column' }}>
					{consistInfo.sort((a, b) => a.order - b.order).map((consist, index, array) => (
						<AddConsistForm
							index={index}
							key={consist.id}
							consist={consist}
							onEdit={onEditConsist}
							onDelete={onDeleteConsist}
							onChangeOrder={handleChangeOrder}
							length={array.length}
						/>
					))}
					<AddConsistForm onAdd={onAddConsist} length={consistInfo.length}/>
				</AccordionDetails>
			</Accordion>
			<div className='detailTablePeriod__button'>
				<Button type='main_green' onClick={save} disable={!isChanged}>
					Сохранить
				</Button>
			</div>
		</div>
	);
};
