import Ui from './ui';
// import { constants } from '@helpers';

/**
 * @typedef {object} ImageToolData
 * @description Image Tool's input and output data format
 * @property {string} caption — image caption
 * @property {boolean} withBorder - should image be rendered with border
 * @property {boolean} withBackground - should image be rendered with background
 * @property {boolean} stretched - should image be stretched to full width of container
 * @property {object} file — Image file data returned from backend
 * @property {string} file.url — image URL
 */

import './style.css';
import { constants } from '@helpers';

class Image {
	/**
   * @param {object} tool - tool properties got from editor.js
   * @param {ImageToolData} tool.data - previously saved data
   * @param {ImageConfig} tool.config - user config for Tool
   * @param {object} tool.api - Editor.js API
   * @param {boolean} tool.readOnly - read-only mode flag
   */
	constructor ({ data, config, api, readOnly }) {
		this.api = api;
		this.readOnly = readOnly;
		this.config = config;

		this.ui = new Ui({
			api,
			config: this.config,
			onSelectFile: (eventTarget) => {
				this.onUpload(eventTarget);
			},
			readOnly
		});

		this._data = {};
		this.data = data;
	}

	static get isReadOnlySupported () {
		return true;
	}

	static get toolbox () {
		return {
			title: 'Изображение',
			icon: `<svg width="13px" height="13px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`
		};
	}

	static get tunes () {
		return [
			{
				name: 'stretched',
				icon: '<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 12H21M3 12L7 8M3 12L7 16M21 12L17 16M21 12L17 8" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
				title: 'Растянуть по ширине',
				toggle: true
			},
			{
				name: 'withBackground',
				icon: '<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 12.5001L3.75159 10.9675C4.66286 10.1702 6.03628 10.2159 6.89249 11.0721L11.1822 15.3618C11.8694 16.0491 12.9512 16.1428 13.7464 15.5839L14.0446 15.3744C15.1888 14.5702 16.7369 14.6634 17.7765 15.599L21 18.5001" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/><path d="M15 5.5H18.5M18.5 5.5H22M18.5 5.5V9M18.5 5.5V2" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/><path d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 10.8717 2 9.87835 2.02008 9M12 2C7.28595 2 4.92893 2 3.46447 3.46447C3.03965 3.88929 2.73806 4.38921 2.52396 5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/></svg>',
				title: 'Выровнять по центру',
				toggle: true
			}
		];
	}

	get CSS () {
		return {
			baseClass: this.api.styles.block,
			loading: this.api.styles.loader,
			input: this.api.styles.input,
			button: this.api.styles.button,

			wrapper: 'image-tool',
			imageContainer: 'image-tool__image',
			imagePreloader: 'image-tool__image-preloader',
			imageEl: 'image-tool__image-picture'
		};
	}

	render () {
		return this.ui.render(this.data);
	}

	save () {
		return this.data;
	}

	renderSettings () {
		const tunes = Image.tunes.concat(this.config.actions || []);

		return tunes.map(tune => ({
			icon: tune.icon,
			label: tune.title,
			name: tune.name,
			toggle: tune.toggle,
			isActive: this.data[tune.name],
			onActivate: () => {
				/* If it'a user defined tune, execute it's callback stored in action property */
				if (typeof tune.action === 'function') {
					tune.action(tune.name);

					return;
				}
				this.tuneToggled(tune.name);
			}
		}));
	}

	appendCallback () {
		this.ui.nodes.fileButton.click();
	}

	/**
	 * Stores all Tool's data
	 *
	 * @private
	 *
	 * @param {ImageToolData} data - data in Image Tool format
	 */
	set data (data) {
		this.image = { id: data.fileId };

		Image.tunes.forEach(({ name: tune }) => {
			const value = typeof data[tune] !== 'undefined' ? data[tune] === true || data[tune] === 'true' : false;

			this.setTune(tune, value);
		});
	}

	 /**
   * Return Tool data
   *
   * @private
   *
   * @returns {ImageToolData}
   */
	 get data () {
		return this._data;
	}

	get image () {
		return {
			id: this._data.fileId
		};
	}

	/**
	 * Set new image file
	 *
	 * @private
	 *
	 * @param {object} file - uploaded file data
	 */
	 set image (file) {
		if (file) {
	 	this._data.fileId = file.id || '';

			if (file.id) {
				this.ui.fillImage(`${constants.URL}/files/${file.id}`);
			}
		}
	 }

	/**
   * File uploading callback
   *
   * @private
   *
   * @returns {void}
   */
	onUpload (eventTarget) {
		if (eventTarget.files) {
			this.config.uploadFile(eventTarget, (data) => {
				this.image = {
					id: data?.id
				};
			});
		} else {
			this.uploadingFailed('incorrect response: ' + JSON.stringify(eventTarget.files[0]));
		}
	}

	uploadingFailed (errorText) {
		console.log('Image Tool: uploading failed because of', errorText);

		this.api.notifier.show({
			message: 'Couldn’t upload image. Please try another.',
			style: 'error'
		});
		this.ui.hidePreloader();
	}

	tuneToggled (tuneName) {
		// inverse tune state
		this.setTune(tuneName, !this._data[tuneName]);
	}

	setTune (tuneName, value) {
		this._data[tuneName] = value;

		this.ui.applyTune(tuneName, value);

		if (tuneName === 'stretched') {
			/**
       * Wait until the API is ready
       */
			Promise.resolve().then(() => {
				const blockId = this.api.blocks.getCurrentBlockIndex();

				this.api.blocks.stretchBlock(blockId, value);
			})
				.catch(err => {
					console.error(err);
				});
		}
	}
}

export default Image;
