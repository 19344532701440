import React from 'react';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';

export default (props: LessonsCardProps) => {
	const { lesson } = props;
	return (
		<div className='courses-analysis-card theme-block-background'>
			<div className='courses-analysis-card__block'>
				<p className='courses-analysis-card__title'>
					{lesson.name}
				</p>
				<div className='courses-analysis-card__info'>
					<div className='courses-analysis-card__tooltip-block'>
						<div className='courses-analysis-card__icon'>
							<Tooltip title='Количесвто просмотров'>
								<IconButton>
									<VisibilityIcon/>
								</IconButton>
							</Tooltip>
						</div>
						<span>
							{lesson.viewers}
						</span>
					</div>
					<div className='courses-analysis-card__tooltip-block'>
						<div className='courses-analysis-card__icon'>
							<Tooltip
								className='courses-analysis-card__tooltip'
								title={<span>
									Кол-во выполененых ДЗ: {lesson.tasks}
								</span>}
							>
								<IconButton>
									<HomeWorkIcon/>
								</IconButton>
							</Tooltip>
						</div>
						<span>
							{lesson.tasks}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

interface LessonsCardProps {
	lesson: {
		id: string,
		name: string,
		section: {
			id: string,
			name: string
			course: {
				name: string,
				id: string
			}
		},
		viewers: number,
		homeworks: number,
		manualHomeworks: number,
		probes: number,
		tasks: number
	}
}
