import React from 'react';
import { SkeletonComponent } from '@components';
import './style.css';

export default () => {
	return (
		<div className='courses-card-loader courses-card theme-block-background'>
			<div className='courses-card-loader__preview courses-card__preview' >
				<SkeletonComponent/>
			</div>
			<div className='courses-card__content'>
				<div className='portal-courses-card-advantages courses-card-advantages'>
					<div className='portal-courses-card-advantages__item courses-card-loader__advantage'>
						<SkeletonComponent/>
					</div>
					<div className='portal-courses-card-advantages__item courses-card-loader__advantage'>
						<SkeletonComponent/>
					</div>
					<div className='portal-courses-card-advantages__item courses-card-loader__advantage'>
						<SkeletonComponent/>
					</div>
					<div className='portal-courses-card-advantages__item courses-card-loader__advantage'>
						<SkeletonComponent/>
					</div>
				</div>
				<div className='courses-card__info'>
					<div className='courses-card__subscription courses-card-subscription courses-card-loader__subscription'>
						<SkeletonComponent/>
					</div>
					<div className='courses-card__teacher courses-card-teacher courses-card-loader__teacher theme-block-background'>
						<SkeletonComponent/>
					</div>
				</div>
				<div className='courses-card__title-block'>
					<div className='courses-card__title courses-card-loader__title'>
						<SkeletonComponent/>
					</div>
					<div className='courses-card__description courses-card-loader__description'>
						<SkeletonComponent/>
					</div>
				</div>
				<div className='courses-card__button-block'>
					{/* <div className='courses-card__icon-button courses-card-loader__icon-button'>
						<SkeletonComponent/>
					</div> */}
					<div className='courses-card__button courses-card-loader__button'>
						<SkeletonComponent/>
					</div>
				</div>
			</div>
		</div>
	);
};
