import React from 'react';
import { observer } from 'mobx-react-lite';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import './style.css';

interface PortalMenuProps {
  value: string,
  handleChange: (newValue: string) => void
}

export default observer((props: PortalMenuProps) => {
	const { value, handleChange } = props;

	return (
		<AppBar position='static' className='portal-menu theme-block-background'>
			<Tabs
				value={value}
				onChange={(e, newValue) => handleChange(newValue)}
				TabIndicatorProps={{ className: 'tabs__indicator' }}
				classes={{ root: 'theme-text', flexContainer: 'tabs-container' }}
			>
				<Tab className='tab' label='Личный кабинет' value={'personal-info'}/>
				<Tab className='tab' label='Мои курсы' value='study-program'/>
				<Tab className='tab' label='Расписание' value='calendar'/>
				<Tab className='tab' label='Домашние задания' value='homeworks'/>
				<Tab className='tab' label='Дневник' value='journal'/>
				<Tab label='Игра' value='gamification' />
				<Tab className='tab' value='referrals' label='Партнерка'/>
				{/* <Tab className='tab tab_opacity' value='' label='Подписки'/> */}
			</Tabs>
		</AppBar>
	);
});
