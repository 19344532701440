import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { LinearProgress } from '@material-ui/core';

import { userGamificationStore, userStore } from '@stores';
import { ArrowBackButton } from '@components';

import './style.css';

export default observer(() => {
	const [money, setMoney] = useState(userGamificationStore.gamificationInfo?.money);
	const [profession, setProfession] = useState(userGamificationStore.gamificationInfo?.profession?.name);

	useEffect(() => {
		setMoney(userGamificationStore.gamificationInfo?.money);
		setProfession(userGamificationStore.gamificationInfo?.profession?.name);
	}, [userGamificationStore.isLoadingShop]);

	const titles = {
		homepage: `${userStore.user?.firstName} ${userStore.user?.secondName}`,
		profession: 'Смена профессии',
		car: 'Гараж'
	};

	const descriptions = {
		homepage: <p className='gamification-header__pre-title'>Через 10 лет</p>,
		profession:
			<p className='gamification-header__pre-title'>
				Профессия: {profession || 'Нет'}
			</p>
	};

	const handleClick = () => {
		userGamificationStore.changeShopItem({ title: '', type: '' });
		userGamificationStore.changePage('homepage');
	};

	return (
		<div className='gamification-header'>
			{userGamificationStore.activeScreen !== 'homepage' &&
				<div className='gamification-header__back'>
					<ArrowBackButton onClick={handleClick}/>
				</div>
			}
			<h1 className='gamification-header__title'>
				{titles[userGamificationStore.activeScreen]}
			</h1>
			{descriptions[userGamificationStore.activeScreen]}
			<div className='gamification-header__lvl'>
				<div className='gamification-header__lvl-title'>
						Уровень: {userGamificationStore.gamificationInfo?.level}
				</div>
				<div className='gamification-header__lvl-progress'>
					<LinearProgress
						variant='determinate' className='gamification-header__progress'
						value={(userGamificationStore.gamificationInfo?.xp / userGamificationStore.gamificationInfo?.xpRequired) * 100}
					/>
				</div>
				<div className='gamification-header__lvl-title'>
					{userGamificationStore.gamificationInfo?.xp}/{userGamificationStore.gamificationInfo?.xpRequired}
					<span className='exp'>
						XP
					</span>
				</div>
			</div>
			<div className='gamification-header__cash'>
				<span className='gamification-header__cash-count'>
					{money.toLocaleString('ru')}
				</span>
				<span className='dollars'>$</span>
			</div>
		</div>
	);
});
