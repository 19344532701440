import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import { BuyEssayType } from '@services';

export default class {
	static async getEssays (take: number, skip: number): Promise<AxiosResponse<IGetAllEssayResponse>> {
		return $api.get<IGetAllEssayResponse>('/literary-texts', { params: { take, skip } });
	}

	static async getEssaysById (essayId: string): Promise<AxiosResponse<IGetEssayResponse>> {
		return $api.get<IGetEssayResponse>(`/literary-texts/${essayId}`);
	}

	static async createEssay (essayDto: ICreateEssayDto): Promise<AxiosResponse<any>> {
		return $api.post<any>('/literary-texts', essayDto);
	}

	static async updateEssay (essayId: string, essayUpdate: Partial<ICreateEssayDto>): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/literary-texts/${essayId}`, essayUpdate);
	}

	static async deleteEssay (essayId): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/literary-texts/${essayId}`);
	}

	static async getCheckEssays (take: number, skip: number, status?: EssayStatusCheck): Promise<AxiosResponse<IGetCheckAllEssaysResponse>> {
		return $api.get<IGetCheckAllEssaysResponse>('/essays', { params: { take, skip, status } });
	}

	static async checkEssay (checkEssayDto: ICheckEssayDto, essayId: string): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/essays/${essayId}`, checkEssayDto);
	}
}

export type EssayStatusCheck = 'verified' | 'verification';

export type EssayType = 'simple' | 'final';

export interface IGetAllEssayResponse {
	items: IGetEssayResponse[];
	count: number;
}

export interface IGetEssayResponse {
	id: string;
	begins: Date | string;
	ends: Date | string;
	file: {
		id: string;
	};
	owner: {
		id: string;
		firstName: string;
		secondName: string;
	};
	text: string;
	type: EssayType;
}

export interface ICreateEssayDto {
	text: string;
	begins: string | Date;
	ends: string | Date;
	file: {
		id: string;
	};
	type: EssayType;
}

export interface ICheckEssayDto {
	mentorFile: {
		id: string;
	}
}

export interface IGetCheckAllEssaysResponse {
	items: IGetCheckEssaysResponse[];
	count: number;
}

export interface IGetCheckEssaysResponse {
	id: string;
	literaryText: IGetEssayResponse;
	createdAt: Date;
	user: {
		id: string;
		firstName: string;
		secondName: string;
	};
	purchaseType: BuyEssayType;
	userFile: {
		id: string;
		originalname: string;
	};
	mentorFile: {
		id: string;
	} | null;
}
