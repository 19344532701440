import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { AuthService, IPasswordRecoveryParams } from '@services';
import { userStore, generalStore, userCourseStore } from '@stores';
import { QueryParams, convertPhone } from '@helpers';
import { IRegisterDto } from '@features/auth/utils';

class AuthStore {
	isAuth: boolean = false;
	isActiveSession: boolean = false;
	isCorrectCode: boolean | 'unknown' = 'unknown';
	code: string = '';
	statusUser: 'new'| 'old' | 'unknown' = 'unknown';
	currentPage: string = 'main';
	login: string = '';
	isLoading: boolean = false;
	isCheckedPolicy: boolean = true;

	constructor () {
		makeAutoObservable(this);
	}

	changePage (page: string) {
		this.currentPage = page;
	}

	changeLogin (login: string) {
		this.login = login;
	}

	changeStatusCode (status: boolean | 'unknown') {
		this.isCorrectCode = status;
	}

	changeCode (code: string) {
		this.code = code;
	}

	changeStatusUser (status: 'new'| 'old' | 'unknown') {
		this.statusUser = status;
	}

	changeIsAuth (value?: boolean) {
		this.isAuth = value || !this.isAuth;
	}

	changeIsCheckedPolicy () {
		this.isCheckedPolicy = !this.isCheckedPolicy;
	}

	// Логин через телефон
	async logIn (login: string, password: string) {
		const validateLogin = convertPhone(login);
		try {
			const { data } = await AuthService.logIn(validateLogin, password, 'email');
			localStorage.setItem('token', data.accessToken.value);
			this.isAuth = true;
		} catch (e) {
			this.isAuth = false;
		}
	}

	// Выйти из аккаунта
	async logout () {
		this.isActiveSession = false;
		try {
			await AuthService.logOut();
			localStorage.removeItem(QueryParams.TOKEN);
			localStorage.removeItem('userId');
			localStorage.removeItem(QueryParams.RET_PATH);
			localStorage.removeItem(QueryParams.SSO_TOKEN);
			localStorage.removeItem(QueryParams.NAVIGATION_PATH);

			this.isLoading = false;
			this.isAuth = false;
			userStore.clearUserData();
			axios.defaults.headers.common.Authorization = 'Bearer 0';
			userCourseStore.reset();
		} catch (e) {
			console.log(e);
		}
	}

	// Отправить код
	async sendEmailMessage (email: string) {
		const { data } = await AuthService.sendMessage(email, 'confirmation');
		if (data.status) {
			generalStore.changeAlertState('success', 'Проверь свою почту');
		} else {
			generalStore.changeAlertState('warning', 'При отправки смс произошла ошибка');
		}
	}

	async sendRecoveryPasswordLink (email: string) {
		try {
			const { data } = await AuthService.sendMessage(email, 'recovery');
			if (data) {
				generalStore.changeAlertState('success', 'Ссылка для восстановления пароля отправлена на почту');
			}
		} catch (error) {
			console.log(error);
		}
	}

	async resetPassword (recoveryInfo: IPasswordRecoveryParams) {
		try {
			await AuthService.resetPassword(recoveryInfo);
		} catch (error) {
			console.log(error);
		} finally {
			localStorage.removeItem(QueryParams.EMAIL);
			localStorage.removeItem(QueryParams.CODE);
		}
	}

	// Проверить код
	async checkCode (userCode: string) {
		const validateLogin = convertPhone(this.login);
		try {
			const response = await AuthService.checkCode({ email: validateLogin }, userCode);
			if (response.data?.status ?? false) {
				this.changeStatusCode(true);
			} else {
				this.changeStatusCode(false);
				generalStore.changeAlertState('warning', 'Неверный код');
			 }
		} catch (e) {
			this.changeStatusCode('unknown');
		}
	}

	async hasActiveSession () {
		try {
			const { data } = await AuthService.checkSession();

			if (data) {
				this.isActiveSession = true;
				return true;
			} else {
				return false;
			}
		} catch (e) {
			this.isActiveSession = false;
			return false;
		}
	}

	async installSSOSession (ssoToken: string) {
		try {
			const { data } = await AuthService.installSSOSession(ssoToken);

			if (data?.accessToken) {
				localStorage.setItem('token', data.accessToken);
				this.isAuth = true;
			} else {
				this.isAuth = false;
			}
		} catch (e) {
			generalStore.changeAlertState('warning');
		}
	}

	// Проверить существование логина
	async checkLoginExists (value: string) {
		const login = convertPhone(value);
		try {
			const { data } = await AuthService.checkLoginExists(login, 'email');
			if (data?.status) {
				this.changeStatusUser('old'); // если true то пользователь существует
			} else {
				this.changeStatusUser('new');
			}
		} catch (e) {
			this.changeStatusUser('unknown');
		}
	}

	// Регистрация
	async registration (userData: IRegisterDto) {
		try {
			const response = await AuthService.registration(userData);
			if (response.data?.accessToken.value ?? false) {
				localStorage.setItem('token', response.data.accessToken.value);
				this.isAuth = true;
			}
		} catch (e) {
			generalStore.changeAlertState('warning', e.response?.data?.message);
		}
	}
}

export const authStore = new AuthStore();
