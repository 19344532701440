import React from 'react';
import { observer } from 'mobx-react-lite';
import { adminAnalyticsStore, adminDisciplineStore } from '@stores';
import { DateInput, Select } from '@components';

export default observer((props: { activeScreen: 'tasks' | 'users' }) => {
	const { activeScreen } = props;

	const changeFilterField = async (value, field, isPeriod: boolean = false) => {
		adminAnalyticsStore.changeFilterFieldStudentActivity(value, field);
		if (isPeriod) {
			if (adminAnalyticsStore.filtersStudentActivity.start && adminAnalyticsStore.filtersStudentActivity.end) {
				await adminAnalyticsStore.getFiltersStudentActivity(activeScreen);
			}
		} else {
			await adminAnalyticsStore.getFiltersStudentActivity(activeScreen);
		}
	};

	return (
		<div className='student-analysis__filtering'>
			<div className='student-analysis__field'>
				<Select
					input={{ label: 'Выберите предмет' }}
					value={adminAnalyticsStore.filtersStudentActivity.discipline}
					options={adminDisciplineStore.disciplines}
					onChange={(e, value) => changeFilterField(value, 'discipline') }
				/>
			</div>
			<div className='student-analysis__field'>
				<Select
					input={{ label: 'Выберите тип ДЗ' }}
					value={adminAnalyticsStore.filtersStudentActivity.type}
					options={typeTasks}
					onChange={(e, value) => changeFilterField(value, 'type') }
				/>
			</div>
			<div className='student-analysis__field'>
				<DateInput
					value={adminAnalyticsStore.filtersStudentActivity.start}
					onChange={(e) => changeFilterField(e.target.value, 'start', true)}
					label='Период от' type='date'
				/>
			</div>
			<div className='student-analysis__field'>
				<DateInput
					value={adminAnalyticsStore.filtersStudentActivity.end}
					onChange={(e) => changeFilterField(e.target.value, 'end', true)}
					label='Период до' type='date'
				/>
			</div>
			{/* {adminAnalyticsStore.filtersStudentActivity.isFiltered && */}
			{/* <div className='student-analysis__button'> */}
			{/*	<Button type='main_orange' onClick={() => adminAnalyticsStore.resetFiltersStudentActivity()}> */}
			{/*			Сбросить */}
			{/*	</Button> */}
			{/* </div> */}
			{/* } */}
		</div>
	);
});

const typeTasks = [
	{ id: 'probe', name: 'Пробники' },
	{ id: 'manual', name: 'Ручные дз' },
	{ id: 'automatic', name: 'Автомат. дз' }
];
