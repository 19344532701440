import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, CustomModal } from '@components';
import { userStore } from '@stores';
import './style.css';

export default observer((props: PortalLostUsersModalProps) => {
	const { lostUsersModal, setLostUsersModal } = props;

	return (
		<CustomModal
			open={lostUsersModal}
			viewClose='cross'
			heightModal='350px'
			widthModal='560px'
			handleClose={() =>
				setLostUsersModal(false)
			}
		>
			<div className='lost-users-modal'>
				<span className='lost-users-modal__title'>
					{userStore.user?.firstName}, мы тебя потеряли!
				</span>
				<span className='lost-users-modal__text'>
					Чтобы продолжить заниматься, напиши в учебный отдел!
				</span>
				<a
					className='lost-users-modal__button'
					href='https://vk.me/lomonosov_school'
					target='_blank' rel='noreferrer noopener'
				>
					<Button type='main_blue'>
            Написать в учебный отдел
					</Button>
				</a>
			</div>
		</CustomModal>
	);
});

interface PortalLostUsersModalProps {
	lostUsersModal: boolean;
	setLostUsersModal: (newValue: boolean) => void;
}
