import React, { memo } from 'react';
import { ArrowBackButton } from '@components';
import classNames from 'classnames';
import './styles.css';

interface PortalPageHeaderProps {
	title?: string;
	className?: string;
	onBack?: () => void;
}

export const PortalPageHeader = memo((props: PortalPageHeaderProps) => {
	const { title, className, onBack } = props;
	return (
		<header className={classNames('portal-page-header', className)}>
			<ArrowBackButton className='portal-page-header__back' onClick={onBack}/>
			{title &&
			<h1 className='portal-page-header__title'>
				{title}
			</h1>
			}
		</header>
	);
});
