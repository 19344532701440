import { makeAutoObservable, runInAction } from 'mobx';
import { IGetAllBannersResponse, IUpdateBannerDto } from '@lomonosov/types';

import { AdminBannerService } from '@services';
import { adminCoursesStore, generalStore } from '@stores';

class AdminBannerStore {
	banners: IGetAllBannersResponse = [];

	constructor () {
  	makeAutoObservable(this);
	}

	// Получить все баннеры
	async getBanners () {
		try {
			const { data } = await AdminBannerService.getBanners();
			runInAction(() => {
				this.banners = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	// Добавить новый баннер
	async addBanner (newBanner) {
		try {
			const { data } = await AdminBannerService.createBanner(newBanner);
			runInAction(() => {
				this.banners = [data, ...this.banners];
				adminCoursesStore.changeBanner(data);
			});
		} catch (e) {
			console.log(e);
		}
	}

	// Изменить конкретный баннер
	async updateBanner (bannerId: string, newInfoBanner: IUpdateBannerDto) {
		try {
			const { data } = await AdminBannerService.updateBanner(bannerId, newInfoBanner);
			runInAction(() => {
				this.banners = this.banners.map((banner) =>
					banner.id === data.id ? data : banner
				);
				generalStore.changeAlertState('success', 'Баннер изменён');
			});
		} catch (e) {
			console.log(e);
		}
	}

	// Удалить конкретный баннер
	async deleteBanner (id: string) {
		try {
			await AdminBannerService.deleteBanner(id);
			runInAction(() => {
				this.banners = this.banners.filter(banner => banner.id !== id);
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const adminBannerStore = new AdminBannerStore();
