import EditorJS, { OutputBlockData } from '@editorjs/editorjs';
import React, { useEffect, useRef } from 'react';
import { INTERNATIONAL_CONFIG } from './config/internationalzationConfig';
import { EDITOR_TOOLS } from './tools/tools';
import classNames from 'classnames';
import './style.css';

interface IEditorProps {
  className?: string;
  readonly?: boolean;
	onChange?: (blocks: OutputBlockData[]) => void;
	blocks?: OutputBlockData[];
}

export function Editor (props: IEditorProps) {
	const { className, readonly, onChange, blocks = [] } = props;
	const ejInstance = useRef<any>(null);

	if (readonly && !blocks.length) return null;

	const initEditor = () => {
		const editor = new EditorJS({
			holder: 'editorjs',
			hideToolbar: true,
			tools: EDITOR_TOOLS,
			i18n: INTERNATIONAL_CONFIG,
			inlineToolbar: true,
			minHeight: 50,
			onReady: () => {
				ejInstance.current = editor;
			},
			autofocus: false,
			onChange: async () => {
				const content = await editor.saver?.save();
				onChange?.(content?.blocks);
			},
			data: {
				blocks: blocks,
				time: new Date().getTime(),
				version: '1'
			},
			readOnly: readonly
		});
	};

	useEffect(() => {
		if (ejInstance.current === null || readonly) {
			initEditor();
		}

		return () => {
			ejInstance?.current?.destroy();
			ejInstance.current = null;
		};
	}, [readonly]);

	return (
		<div id={'editorjs'} className={classNames('editor', className)}></div>
	);
}
