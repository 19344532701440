import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ru';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getTimeWithTZ = (date?: Date, timezone?: string) => {
	const currentLocalTimezone = localStorage.getItem('localTimezone') ?? timezone ?? 'Europe/Moscow';
	const now = date ?? new Date();

	const converted = dayjs.tz(now, currentLocalTimezone).format('YYYY-MM-DD HH:mm');
	return new Date(converted);
};
