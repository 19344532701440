
import { QueryParams } from '@helpers';
import { APPLE_AUTH_PATH, AUTH_LOGIN, DISCORD_AUTH_PATH, VK_AUTH_PATH } from '../consts/authPaths';
import { SocialAuthType } from '../types/auth';

export const onRedirectToAuth = (provider?: SocialAuthType) => {
	const retPath = localStorage.getItem(QueryParams.RET_PATH) || `${window.location.origin}${window.location.pathname}`;
	const refParam = localStorage.getItem(QueryParams.REF) ? `&ref=${localStorage.getItem('ref')}` : '';
	let url;

	switch (provider) {
	case 'vk':
		url = `${process.env.SSO_AUTH_URI}${VK_AUTH_PATH}?retPath=${retPath}${refParam}`;
		break;
	case 'discord':
		url = `${process.env.SSO_AUTH_URI}${DISCORD_AUTH_PATH}?retPath=${retPath}${refParam}`;
		break;
	case 'apple':
		url = `${process.env.SSO_AUTH_URI}${APPLE_AUTH_PATH}?retPath=${retPath}${refParam}`;
		break;
	default:
		url = `${process.env.SSO_AUTH_URI}${AUTH_LOGIN}?retPath=${retPath}${refParam}`;
	}
	if (localStorage.getItem(QueryParams.RET_PATH)) {
		localStorage.removeItem(QueryParams.RET_PATH);
	}

	window.location.replace(url);
};
