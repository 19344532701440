import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import IconButton from '@material-ui/core/IconButton';
import { SkeletonComponent, Button } from '@components';
import { userGamificationStore, userStore } from '@stores';
import { constants, images } from '@helpers';

import { GamificationHomeItem, GamificationSkills, GamificationMenu, GamificationTasks } from '../../gamification-blocks';
import { Player, ModificationInterior, ModificationButtons, GamificationTasksLoader } from '../../gamification-components';
import { ArrowBackIcon } from 'lomonosov-icons';
import { GamificationSex } from '@lomonosov/types';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import './style.css';

export default observer(() => {
	const [menuPosition, setMenuPosition] = useState<'right' | 'left'>('right');
	const [isLoadImages, setIsLoadImages] = useState<boolean>(false);
	const imagesLoad: string[] = [];

	useEffect(() => {
		userGamificationStore.checkDailyQuests(userStore.user.id);
	}, []);

	const handleImageLoaded = (type: string, status: boolean = false) => {
		if (status) {
			setIsLoadImages(false);
		}
		imagesLoad.push(type);
		if (imagesLoad.length >= 3 || status) {
			setTimeout(() => {
				setIsLoadImages(true);
			}, 1000);
		}
	};

	const handleShowFriend = (e) => {
		if (e.detail === 2) {
			userGamificationStore.getRandomFriend();
		}
	};

	const buyItem = async (itemId: string) => {
		await userGamificationStore.buyGameItem(itemId);
	};

	const useItem = async (newItem) => {
		await userGamificationStore.updateUserGamification({ ...newItem, isEquipped: !newItem.isEquipped });
		if (newItem.type === userGamificationStore.currentDaily?.daily?.subcategory) {
			userGamificationStore.updateStatusDailyQuests(userStore.user.id, userGamificationStore.currentDaily?.daily?.id);
		}
	};

	const closeEditMode = () => {
		if (userGamificationStore.shopItem.type === 'car') {
			handleImageLoaded('car', true);
		}
		userGamificationStore.changeShopItem({ title: '', type: '' });
		userGamificationStore.changePlayerPosition('right');
	};

	const equippedItems = useMemo(() => {
		const items = userGamificationStore.gamificationInfo?.equippedItems;
		return ({
			body: items.find(item => item.type === 'body'),
			head: items.find(item => item.type === 'head'),
			room: items.find(item => item.type === 'room'),
			sofa: items.find(item => item.type === 'sofa'),
			carpet: items.find(item => item.type === 'carpet'),
			electrolier: items.find(item => item.type === 'electrolier'),
			furniture: items.find(item => item.type === 'furniture'),
			centralWallDetail: items.find(item => item.type === 'central-wall-detail'),
			leftWallDetail: items.find(item => item.type === 'left-wall-detail'),
			rightWallDetail: items.find(item => item.type === 'right-wall-detail'),
			car: items.find(item => item.type === 'car')
		});
	}, [userGamificationStore.gamificationInfo?.equippedItems, useItem]);

	const friend = useMemo(() => ({
		woman: GamificationSex.man,
		man: GamificationSex.woman
	}), [equippedItems.body?.sex]);

	const changeProfession = useCallback(() => {
		userGamificationStore.changePage('profession');
	}, []);

	const handleCloseMenu = useCallback((type?: string) => {
		if (type) {
			if (type === 'right-wall-detail') {
				setMenuPosition('left');
			} else {
				setMenuPosition('right');
			}
			userGamificationStore.setHouseItemType(type);
		}
		userGamificationStore.changePlayerPosition(userGamificationStore.playerPosition === 'right' ? 'left' : 'right');
		userGamificationStore.changeIsShowShopMenu();
		if (userGamificationStore.shopItem.type !== 'house' && userGamificationStore.shopItem.type !== 'car') {
			userGamificationStore.changeShopItem({ title: '', type: '' });
		}
	}, [userGamificationStore.isShowShopMenu]);

	// const types = ['head', 'body', 'car'];

	return (
		<div className='gamification-homepage-new'>
			<div className='gamification-homepage-new__window'>
				{userGamificationStore.shopItem.type && !userGamificationStore.isShowShopMenu &&
				<IconButton
					className='theme-blue-to-orange gamification-homepage-new__icon-back theme-block-background'
					onClick={closeEditMode}
				>
					<ArrowBackIcon /><span>&nbsp;Назад</span>
				</IconButton>
				}
				{!isLoadImages &&
					<div className='gamification-homepage-new__window-loader theme-block-background'>
						<SkeletonComponent/>
						<span>Loading...</span>
					</div>
				}
				{userGamificationStore.shopItem.type !== 'house' && (userGamificationStore.shopItem.type !== 'car' || !userGamificationStore.isShowShopMenu) &&
					<>
						<Player
							head={equippedItems.head?.file?.[0]?.id || ''}
							body={equippedItems.body?.file?.[0]?.id || ''}
							gender={equippedItems.body?.sex}
							position={userGamificationStore.playerPosition}
						/>
						{userGamificationStore.friend.isShow && userGamificationStore.shopItem.type === '' &&
							<Player
								head={userGamificationStore.friend.head}
								body={userGamificationStore.friend.body}
								gender={friend[equippedItems.body?.sex || 'man']}
								position={'left'}
								name={userGamificationStore.friend.name}
							/>
						}
					</>
				}
				<div className='show-friend' onClick={handleShowFriend}/>
				{userGamificationStore.shopItem.type !== 'car'
					? <>
						<GamificationHomeItem
							item={equippedItems.room}
							handleCloseMenu={handleCloseMenu}
							type='room'
							onLoad={handleImageLoaded}
						/>
						<GamificationHomeItem
							item={equippedItems.leftWallDetail}
							handleCloseMenu={handleCloseMenu}
							type='left-wall-detail'
						/>
						<GamificationHomeItem
							item={equippedItems.centralWallDetail}
							handleCloseMenu={handleCloseMenu}
							type='central-wall-detail'
						/>
						<GamificationHomeItem
							item={equippedItems.rightWallDetail}
							handleCloseMenu={handleCloseMenu}
							type='right-wall-detail'
						/>
						<GamificationHomeItem
							item={equippedItems.carpet}
							handleCloseMenu={handleCloseMenu}
							type='carpet'
						/>
						<GamificationHomeItem
							item={equippedItems.sofa}
							handleCloseMenu={handleCloseMenu}
							type='sofa'
							onLoad={handleImageLoaded}
						/>
						<GamificationHomeItem
							item={equippedItems.electrolier}
							handleCloseMenu={handleCloseMenu}
							type='electrolier'
							onLoad={handleImageLoaded}
						/>
						<GamificationHomeItem
							item={equippedItems.furniture}
							handleCloseMenu={handleCloseMenu}
							type='furniture'
						/>
					</>
					: <>
						<div className='gamification-homepage-new__window-item' id='garage'>
							<img src={images.garage} alt={'garage'} loading={'lazy'} height={'60vw'}/>
						</div>
						<div className='gamification-homepage-new__window-item' id={'car'}>
							<img src={`${constants.FILE_URL}/${equippedItems.car?.file?.[0]?.id}`} alt={'car'} loading={'lazy'} />
						</div>
						{!userGamificationStore.isShowShopMenu &&
					<IconButton
						onClick={() => handleCloseMenu('car')}
						className={'gamification-homepage-new__icon-button gamification-homepage-new__icon-button_car'}
					>
						<ChangeCircleIcon fontSize='large' className='gamification-homepage-new__icon'/>
					</IconButton>
						}
					</>
				}
				{userGamificationStore.isShowShopMenu &&
					<div className={`gamification-homepage-new__menu gamification-homepage-new__menu_${menuPosition}`}>
						<ModificationInterior
							title={userGamificationStore.shopItem.title}
							items={
								userGamificationStore.shopItem.type === 'house'
									? userGamificationStore.shop.filter(item => item.type === userGamificationStore.houseItemType)
									: userGamificationStore.shop
							}
							onBuyItem={buyItem}
							onUseItem={useItem}
							loader={userGamificationStore.isLoadingShop}
							onCloseMenu={handleCloseMenu}
						/>
					</div>
				}
			</div>
			{(userGamificationStore.shopItem.type === 'house' || userGamificationStore.shopItem.type === 'car') && !userGamificationStore.isShowShopMenu &&
				<ModificationButtons onClick={handleCloseMenu} type={userGamificationStore.shopItem.type}/>
			}
			{userGamificationStore.isShowShopMenu &&
				<div className={`gamification-homepage-new__menu gamification-homepage-new__menu_${menuPosition} gamification-homepage-new__menu_mobile`}>
					<ModificationInterior
						title={userGamificationStore.shopItem.title}
						items={
							userGamificationStore.shopItem.type === 'house'
								? userGamificationStore.shop.filter(item => item.type === userGamificationStore.houseItemType)
								: userGamificationStore.shop
						}
						onBuyItem={buyItem}
						onUseItem={useItem}
						loader={userGamificationStore.isLoadingShop}
						onCloseMenu={handleCloseMenu}
					/>
				</div>
			}
			<div className='gamification-homepage-new__footer'>
				<div className='gamification-homepage-new__profession'>
					<div className='gamification-homepage-new__profession-name'>
						Профессия: {userGamificationStore.gamificationInfo?.profession?.name || 'Нет'}<br/>
						<div className='gamification-homepage-new__profession-salary'>
							Зарплата:&nbsp; <b>{userGamificationStore.gamificationInfo?.profession?.salary || 0}
								<span className='dollars'>$</span></b>&nbsp;в день
						</div>
					</div>
					<div className='gamification-homepage-new__profession-button'>
						<Button type='main_blue' onClick={changeProfession}>Сменить</Button>
					</div>
				</div>
				<div className='gamification-homepage-new__personalization'>
					<GamificationMenu onLoad={handleImageLoaded}/>
				</div>
				<div className='gamification-homepage-new__tasks'>
					{userGamificationStore.daily.length !== 0 &&
						<>
							{userGamificationStore.isDailyLoading
								? <GamificationTasksLoader/>
								: <GamificationTasks tasks={userGamificationStore.daily}/>}
						</>
					}
				</div>
				<div className='gamification-homepage-new__skills'>
					{userGamificationStore.gamificationInfo?.skills &&
						<GamificationSkills skills={userGamificationStore.gamificationInfo.skills}/>
					}
				</div>
			</div>
		</div>
	);
});
