import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
 	IUpdateInvoiceDto,
	ICreateInvoiceDto, ICreateInvoiceResponse, IGetStatisticResponse
} from '@lomonosov/types/dist';
import { IGetInvoiceById, IGetInvoices, InvoicesQueryParamsDto, InvoicesStatisticQueryParamsDto } from '@interfaces';

export default class {
	static async getInvoices (query: Partial<InvoicesQueryParamsDto>): Promise<AxiosResponse<IGetInvoices>> {
		return $api.get<IGetInvoices>('/invoices', { params: { ...query } });
	}

	static async getInvoiceById (invoiceId: string): Promise<AxiosResponse<IGetInvoiceById>> {
	  return $api.get<IGetInvoiceById>(`/invoices/${invoiceId}`);
	}

	static async getInvoiceStatistic (query?: InvoicesStatisticQueryParamsDto): Promise<AxiosResponse<IGetStatisticResponse>> {
		return $api.get<IGetStatisticResponse>('/invoices/statistic', { params: { ...query } });
	}

	static async updateInvoice (invoiceId: string, updateInvoice: IUpdateInvoiceDto): Promise<AxiosResponse<IGetInvoiceById>> {
	  return $api.put<IGetInvoiceById>(`/invoices/${invoiceId}`, updateInvoice);
	}

	// static async updateStatusInvoice (shortId: string): Promise<AxiosResponse<IGetInvoiceByShortIdAndUpdateResponse>> {
	// 	return $api.put<IGetInvoiceByShortIdAndUpdateResponse>(`/invoices/shorts/${shortId}`);
	// }

	static async createInvoice (invoice: ICreateInvoiceDto): Promise<AxiosResponse<ICreateInvoiceResponse>> {
	  return $api.post<ICreateInvoiceResponse>('/invoices', invoice);
	}

	static async deleteInvoice (invoiceId: string): Promise<AxiosResponse<void>> {
	  return $api.delete<void>(`/invoices/${invoiceId}`);
	}
}
