import { AxiosResponse } from 'axios';
import {
	IGiveCoursesPurchaseResponse,
	ICreateFreePurchaseDto
} from '@lomonosov/types';

import { $api } from '@helpers';
import { IGetShopButtonsItemResponse, IGetShopCategoryResponse, IShopInvoice, PurchaseCourse, PurchasePaymentDto, PurchasePaymentResponse } from '@interfaces';
import { IPromocodeCheckResponse } from '@features/activate-promocode';

export default class {
	static async getDirectionsForShop (): Promise<AxiosResponse<any>> {
		return $api.get<any>('/shop-directions');
	}

	static async getCategoriesForShop (directionId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/shop-categories/directionId=${directionId}`);
	}

	static async createFreePurchase (purchase: ICreateFreePurchaseDto): Promise<AxiosResponse<IGiveCoursesPurchaseResponse>> {
		return $api.post<IGiveCoursesPurchaseResponse>('/purchases/free', purchase);
	}

	static async getShopCart (shopCartId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/shop-carts/${shopCartId}`);
	}

	static async putShopCart (shopCart: any, shopCartId: string): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/shop-carts/${shopCartId}`, shopCart);
	}

	static async getInfoPromoCode (promocode: string, userId?: string): Promise<AxiosResponse<IPromocodeCheckResponse>> {
		return $api.get<IPromocodeCheckResponse>(`promo-codes/${promocode}/check${userId ? `?userId=${userId}` : ''}`);
	}

	static async getRefDiscount (): Promise<AxiosResponse<boolean>> {
		return $api.get<boolean>('/purchases/is-first-purchase');
	}

	static async getInvoiceByShortId (shortId: string): Promise<AxiosResponse<IShopInvoice>> {
		return $api.get<IShopInvoice>(`invoices/shorts/${shortId}`);
	}

	static async putInvoiceByShortId (shortId: string, userId: string): Promise<AxiosResponse<any>> {
		return $api.put(`invoices/shorts/${shortId}`, {}, { params: { userId } });
	}

	/// //////////////////////////////////////////////////////////////////////////////////////

	static async getButtons (parentButtonId?: string | 'null', trial?: boolean): Promise<AxiosResponse<IGetShopButtonsItemResponse[]>> {
		return $api.get<IGetShopButtonsItemResponse[]>('/shop-buttons', {
			params: {
				type: 'shop',
				parentButtonId,
				trial
			}
		});
	}

	static async getCourses (id: string): Promise<AxiosResponse<IGetShopCategoryResponse>> {
		return $api.get<IGetShopCategoryResponse>(`/shop-buttons/${id}?type=shop`);
	}

	static async createInvoice (userId: string | undefined, shortId: string | undefined, shopCart: PurchasePaymentDto): Promise<AxiosResponse<PurchasePaymentResponse>> {
		return $api.post<PurchasePaymentResponse>('/purchases/buy', shopCart, { params: { userId, shortId } });
	}

	static async createQR (orderId: string): Promise<AxiosResponse<{Data: string, Success: boolean}>> {
		return $api.get<any>(`/purchases/buy/QR?orderId=${orderId}`);
	}

	static async checkQR (orderId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/purchases/buy/status?orderId=${orderId}`);
	}

	static async activateTrialCourses (shopCart: { shopCourseData: PurchaseCourse[] }): Promise<AxiosResponse<any>> {
		return $api.post<any>('/purchases/freeCourse', shopCart);
	}

	static async createAbandonedCart (cart: ISaveAbandonedCartParam): Promise<AxiosResponse<any>> {
		return $api.post<any>('/abandoned-carts', cart);
	}
}

export interface ISaveAbandonedCartParam {
	items: Array<{
		startPeriod: Date;
		endPeriod: Date;
		tariffId: string;
		price: number
	}>;
	userId: string;
}
