import React from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from '@components';
import { IColumn } from '@components/table';
import { adminAnalyticsStore } from '@stores';

export default observer((props: any) => {
	const { dataL } = props;

	const changePage = async (event, newPage) => {
		await adminAnalyticsStore.changePageStudentActivity(
			newPage,
			adminAnalyticsStore.paginationStudentActivity.currentUser.rows,
			'currentUser',
			dataL.id
		);
	};

	const changeTableRows = async (e: React.ChangeEvent<HTMLInputElement>) => {
		await adminAnalyticsStore.changePageStudentActivity(
			adminAnalyticsStore.paginationStudentActivity.currentUser.currentPage,
			+e.target.value,
			'currentUser',
			dataL.id
		);
	};

	return (
		<div className='student-analysis__table'>
			<Table
				columns={columnsTable}
				data={adminAnalyticsStore.currentUserActivity}
				pagination={{
					count: adminAnalyticsStore.paginationStudentActivity.currentUser.totalCount,
					page: adminAnalyticsStore.paginationStudentActivity.currentUser.currentPage,
					rowsPerPage: adminAnalyticsStore.paginationStudentActivity.currentUser.rows,
					onPageChange: changePage,
					onRowsPerPageChange: changeTableRows,
					rowsPerPageOptions: [8, 10, 25, 50]
				}}
			/>
		</div>
	);
});

const columnsTable: IColumn[] = [
	{ field: 'name', name: 'ДЗ' },
	{ field: 'scores', name: 'Баллы' }
];
