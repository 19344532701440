import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton, debounce } from '@material-ui/core';
import { IGetUserPurchasesItemResponse } from '@lomonosov/types';
import { Button, Select, DateInput, DeleteModal } from '@components';
import { adminUserStore, adminCoursesStore, generalStore } from '@stores';
import { replaceFormatDate } from '@helpers';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import './style.css';

export default observer(() => {
	const [panelOpen, setPanelOpen] = useState<boolean>(false);
	const [newCourse, setNewCourse] = useState<any>();
	const [newTariff, setNewTariff] = useState<any>();
	const [newStartPeriod, setNewStartPeriod] = useState<any>();
	const [newEndPeriod, setNewEndPeriod] = useState<any>();
	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const [currentPurchaseId, setCurrentPurchaseId] = useState('');
	const [deleteModalTitle, setDeleteModalTitle] = useState<string>('');

	const changePanelOpenState = () => {
		// if (!panelOpen && adminCoursesStore.allCourses.length <= 6) {
		// 	adminCoursesStore.getAllCourses(20);
		// }
		setPanelOpen(!panelOpen);
	};

	const fetchCourses = async () => {
		if (adminCoursesStore.allCourses.length < 20) {
			await adminCoursesStore.getAllCourses(20);
		}
	};

	useEffect(() => {
		adminCoursesStore.changeIsArchive(null);
		// adminCoursesStore.getAllCourses();
		adminUserStore.getUserPurchases(adminUserStore.currentUser.id);
	}, []);

	const addUserPurchase = useCallback(() => {
		if (newStartPeriod < newEndPeriod) {
			adminUserStore.createUserPurchase({
				courseId: newCourse.id,
				tariffId: newTariff.id,
				userId: adminUserStore.currentUser.id,
				startPeriod: newStartPeriod,
				endPeriod: newEndPeriod
			}).then(() => {
				setNewCourse(null);
				setNewTariff(null);
				setNewStartPeriod(null);
				setNewEndPeriod(null);
			});
			changePanelOpenState();
		} else {
			generalStore.changeAlertState('warning', 'Некорректные даты периода');
		}
	}, [newCourse, newTariff, newStartPeriod, newEndPeriod, adminUserStore.currentUser]);

	const onTariffChange = useCallback((e, newValue) => {
		setNewTariff(newValue);
	}, []);

	const openDeleteModal = (purchase: IGetUserPurchasesItemResponse) => {
		setDeleteModal(true);
		setCurrentPurchaseId(purchase.id);
		setDeleteModalTitle(purchase.course.name);
	};

	const deletePurchase = () => {
		setDeleteModal(false);
		adminUserStore.deleteUserPurchase(currentPurchaseId).then();
	};

	// const debouncedFetchData = useCallback(
	// 	debounce(async (searchTerm) => {
	// 		try {
	// 			await adminCoursesStore.getAllCourses(searchTerm);
	// 		} catch (error) {
	// 			console.error(error);
	// 		}
	// 	}, 1000),
	// 	[]
	// );
	//
	// const handleInputChange = (event) => {
	// 	// обновляем опции только при изменении значения в поле ввода
	// 	if (event.target.value !== '') {
	// 		debouncedFetchData(event.target.value);
	// 	}
	// };

	const searchsDelayed = debounce((text: string) => adminCoursesStore.getAllCourses(20, text), 1000);

	const handleChangeCourse = async (e, newValue, reason) => {
		if (reason === 'clear') {
			setNewCourse(null);
			await adminCoursesStore.getAllCourses(20);
			return;
		}
		setNewCourse(newValue);
	};

	return (
		<div className='add-course-panel'>
			<h3 className='add-course-panel_title'>
        Курсы пользователя
				<IconButton className='add-course-panel_add-button' onClick={changePanelOpenState}>
					{panelOpen ? <RemoveIcon/> : <AddIcon/>}
				</IconButton>
			</h3>
			{adminUserStore.purchases.length > 0
				? <div className='add-course-panel__list'>
					{adminUserStore.purchases.map((purchase, index) =>
						<div className='add-course-panel__list__user-course' key={'курс' + index}>
							<div className='user-course_item'>
								Курс: {purchase?.course?.name}
							</div>
							<div className='user-course_item'>
								Тариф: {purchase?.tariff?.name}
							</div>
							<div className='user-course_item'>
								Продолжительность:<br/>{replaceFormatDate(purchase?.startPeriod)} - {replaceFormatDate(purchase?.endPeriod)}
							</div>
							<div className='user-course_item'>
								Способ активации:<br/>{purchase?.activationType}
							</div>
							<IconButton className='user-course_delete-button' onClick={() => openDeleteModal(purchase)}>
								<DeleteIcon/>
							</IconButton>
						</div>
					)}
				</div>
				: <span className='add-course-panel_no-records'>Покупок нет</span>
			}
			{panelOpen &&
				<div className='add-course-panel__fields'>
					<div className='add-course-panel__fields_item'>
						<Select
							input={{ label: 'Курс', onChange: (e) => searchsDelayed(e.target.value) }}
							options={adminCoursesStore.allCourses}
							value={newCourse}
							onChange={handleChangeCourse}
							onFocus={fetchCourses}
						/>
					</div>
					<div className='add-course-panel__fields_item'>
						<Select
							input={{ label: 'Тариф' }}
							options={newCourse?.tariffs}
							value={newTariff}
							onChange={onTariffChange}
							disabled={!newCourse}
						/>
					</div>
					<div className='add-course-panel__fields_item'>
						<DateInput
							label='Начало периода'
							type='date'
							value={newStartPeriod}
							onChange={(e) => setNewStartPeriod(e.target.value)}
							disabled={!newTariff}
						/>
					</div>
					<div className='add-course-panel__fields_item'>
						<DateInput
							label='Окончание периода'
							type='date'
							value={newEndPeriod}
							onChange={(e) => setNewEndPeriod(e.target.value)}
							disabled={!newTariff}
						/>
					</div>
					<div className='add-course-panel__fields_item'>
						<Button
							type='main_green'
							onClick={addUserPurchase}
							disable={!newStartPeriod || !newEndPeriod}
						>
							Добавить курс
						</Button>
					</div>
				</div>
			}
			<DeleteModal
				deleteName={deleteModalTitle}
				open={deleteModal}
				handleClose={() => setDeleteModal(false)}
				action={() => deletePurchase()}
			/>
		</div>
	);
});
