export const adminErrorRules = {
	name: {
		required: 'Поле обазательно к заполнению'
	},
	maxLength: {
		maxLength: {
			value: 200,
			message: 'Максимальное кол-во символов 200'
		}
	},
	number: {
		required: 'Поле обазательно к заполнению',
		pattern: {
			value: /^\d+$/,
			message: 'Поле принимает только цифры'
		}
	}
};
