import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from '@mui/material/utils';
import { HomeworkAnswerStatus, HomeworkTemplateType } from '@lomonosov/types';
import {
	adminHomeworkAnswersStore,
	userStore,
	adminCoursesStore
} from '@stores';
import { Button, FormControlLabel, Switch } from '@mui/material';
import Input from '@components/input';
import { MailInbox } from 'lomonosov-icons';
import Select from '@components/select';
import './style.css';
import { IGetHomeworkAnswersQueryParams } from '@services';

export default observer(() => {
	const [isChecked, setIsChecked] = useState(true);

	const searchCourses = debounce(async (e) => {
		await adminCoursesStore.getAllCourses(20, e.target.value);
	}, 1000);

	const debouncedSearch = debounce(async () => {
		await adminHomeworkAnswersStore.getAllHomeworkAnswers(8, 0);
	}, 1000);

	const handleChangeSearch = useCallback((e) => {
		adminHomeworkAnswersStore.changeFilterField(e.target.value, 'search');
		debouncedSearch();
	}, []);

	const handleChangeFilter = async (value: OptionSelect, field: keyof IGetHomeworkAnswersQueryParams) => {
		adminHomeworkAnswersStore.changeFilterField(value, field);
		await adminHomeworkAnswersStore.getAllHomeworkAnswers(8, 0);
	};

	const handleChangeShowAllAnswers = async (event) => {
		setIsChecked(event.target.checked);
		if (event.target.checked) {
			adminHomeworkAnswersStore.changeFilterField(userStore.user?.mentor?.id, 'mentor');
		} else {
			adminHomeworkAnswersStore.changeFilterField(null, 'mentor');
		}
		await adminHomeworkAnswersStore.getAllHomeworkAnswers(8, 0);
	};

	const handleChangeStatus = async () => {
		if (adminHomeworkAnswersStore.filterFields.status === HomeworkAnswerStatus.VERIFIED) {
			adminHomeworkAnswersStore.changeFilterField(HomeworkAnswerStatus.VERIFICATION, 'status');
		} else {
			adminHomeworkAnswersStore.changeFilterField(HomeworkAnswerStatus.VERIFIED, 'status');
		}
		await adminHomeworkAnswersStore.getAllHomeworkAnswers(8, 0);
	};

	return (
		<div className='filtering-menu-check'>
			{userStore.isManager &&
				<div className='filtering-menu-check__admin-settings'>
					<FormControlLabel
						control={
							<Switch checked={isChecked} onChange={handleChangeShowAllAnswers} />
						}
						label={isChecked ? 'Мои ученики' : 'Все ученики'}
						labelPlacement='top'
						disabled={adminHomeworkAnswersStore.loader}
					/>
					<Select
						input={{ label: 'Курс', onChange: searchCourses }}
						options={adminCoursesStore.allCourses}
						value={adminHomeworkAnswersStore.filterFields?.course}
						onChange={(e, value) => handleChangeFilter(value, 'course')}
						className='filtering-menu-check__item'
						disabled={adminHomeworkAnswersStore.loader}
					/>
				</div>
			}
			<Input
				className='filtering-menu-check__item'
				label='Поиск'
				value={adminHomeworkAnswersStore.filterFields?.search}
				onChange={handleChangeSearch}
				disabled={adminHomeworkAnswersStore.loader}
			/>
			<Select
				className='filtering-menu-check__item'
				input={{ label: 'Направление' }}
				value={adminHomeworkAnswersStore.filterFields?.direction}
				onChange={(e, value) => handleChangeFilter(value, 'direction')}
				disabled={adminHomeworkAnswersStore.loader}
				options={adminHomeworkAnswersStore.directions}
			/>
			<Select
				className='filtering-menu-check__item'
				input={{ label: 'Предмет' }}
				value={adminHomeworkAnswersStore.filterFields?.discipline}
				onChange={(e, value) => handleChangeFilter(value, 'discipline')}
				disabled={adminHomeworkAnswersStore.loader}
				options={adminHomeworkAnswersStore.disciplines}
			/>
			<Select
				className='filtering-menu-check__item'
				input={{ label: 'Тип задания' }}
				value={adminHomeworkAnswersStore.filterFields?.type}
				onChange={(e, value) => handleChangeFilter(value, 'type')}
				disabled={adminHomeworkAnswersStore.loader}
				options={taskTypes}
			/>
			<Button
				variant='outlined'
				className='filtering-menu-check__item'
				startIcon={<MailInbox/>}
				onClick={handleChangeStatus}
				disabled={adminHomeworkAnswersStore.loader}
			>
				{adminHomeworkAnswersStore.filterFields.status === HomeworkAnswerStatus.VERIFICATION ? 'Архив' : 'Назад'}
			</Button>
		</div>
	);
});

const taskTypes = [
	{
		id: HomeworkTemplateType.MANUAL,
		name: 'С ручной проверкой'
	},
	{
		id: HomeworkTemplateType.PROBE,
		name: 'Пробник'
	}
];
