import { makeAutoObservable, runInAction } from 'mobx';
import {
	IGetWithdrawalReferralByIdResponse,
	WithdrawalStatus,
	IGetUserReferralsResponse,
	IGetWithdrawalReferralsItemResponse
} from '@lomonosov/types/dist';
import { AdminReferralService } from '@services';
import { replaceFormatDateForUsers } from '@helpers';

class AdminReferralStore {
	loader: boolean = true;
	withdrawalReferrals: IGetWithdrawalReferralsItemResponse[] | [] = [];
	currentWithdrawal: IGetWithdrawalReferralByIdResponse;
	status: IStatusProps = { id: WithdrawalStatus.wait, name: 'Ожидает подтверждения' };
	referrals: IGetUserReferralsResponse = [];
	pageCount: number = 0;
	withdrawalCount: number = 0;

	constructor () {
		makeAutoObservable(this);
	}

	async changeStatus (value: IStatusProps) {
		this.status = value;
	}

	async getReferrals (page = 0, pageSize = 5, search = '') {
		try {
			const { data } = await AdminReferralService.getReferrals(pageSize, pageSize * page, search);
			runInAction(() => {
				this.referrals = data.referrals;
				this.pageCount = data.count;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async getWithdrawal (page: number = 0, pageSize: number = 100, search: string = '') {
		try {
			const { data }: any = await AdminReferralService.getWithdrawal(this.status.id, page, pageSize, search);
			runInAction(() => {
				/**
				 * TODO: монорепу не обновили(
				 */
				this.withdrawalReferrals = data.withdrawalReferrals
					.map(referral => ({ ...referral, updatedAt: replaceFormatDateForUsers(referral.updatedAt) }));
				this.withdrawalCount = data.count;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async getWithdrawalById (id: string) {
		try {
			const { data } = await AdminReferralService.getWithdrawalById(id);
			runInAction(() => {
				this.currentWithdrawal = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async editWithdrawalStatus (id:string, status: 'completed' | 'rejected') {
		try {
			await AdminReferralService.editWithdrawalStatus(id, status);
			runInAction(() => {
				this.withdrawalReferrals = this.withdrawalReferrals.filter((ref: IGetWithdrawalReferralsItemResponse) => ref.id !== id);
			});
		} catch (e) {
			console.log(e);
		}
	}
}

interface IStatusProps {
	id: WithdrawalStatus, name: string
}

export const adminReferralStore = new AdminReferralStore();
