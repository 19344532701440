import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from '@components';
import { adminEssaysStore } from '@stores';
import { EssayStatusCheck } from '@services';
import { DetailPanelCheckEssays } from './blocks';
import Select from '@components/select';

export default observer(() => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	useEffect(() => {
		if (!adminEssaysStore.checkEssays.items?.length) {
			adminEssaysStore.getCheckEssays(10, 0, 'verification');
		}
	}, []);

	const onChangeStatusEssay = (_e, value) => {
		adminEssaysStore.handleChangeStatusCheck(value.id);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		adminEssaysStore.getCheckEssays(rowsPerPage, newPage * rowsPerPage, adminEssaysStore.statusCheck);
	};

	const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	return (
		<>
			<Select
				options={checkEssayStatusOptions}
				value={checkEssayStatusOptions.find(status => status.id === adminEssaysStore.statusCheck)}
				onChange={onChangeStatusEssay}
				style={{ maxWidth: '320px', margin: '20px 0' }}
			/>
			<Table
				columns={[
					{ field: 'userName', name: 'Отправитель' },
					{ field: 'begins', name: 'Дата отправки', type: 'date' },
					{ field: 'type', name: 'Тип сочинения' },
					{ field: 'purchasesType', name: 'Тип покупки' }
				]}
				data={adminEssaysStore.checkEssays.items || []}
				search
				pagination={{
					count: adminEssaysStore.checkEssays.count,
					page: page,
					rowsPerPage: rowsPerPage,
					onPageChange: handleChangePage,
					onRowsPerPageChange: handleChangeRowsPerPage
				}}
				detailPanel={{
					Component: DetailPanelCheckEssays,
					autoClose: true
				}}
			/>
		</>
	);
});

const checkEssayStatusOptions: { id: EssayStatusCheck, name: string }[] = [
	{
		id: 'verification',
		name: 'Открытые'
	},
	{
		id: 'verified',
		name: 'Проверенные'
	}
];
