import React from 'react';
import { observer } from 'mobx-react-lite';
import { IGetTariffsComboResponse } from '@interfaces';
import { adminComboCoursesStore } from '@stores';
import { ComparisonCourse } from './ComparisonCourse';
import { TableComboPeriods } from '../table-combo-periods';

interface DetailTariffProps {
	dataL: IGetTariffsComboResponse;
}

export const DetailTariff = observer((props: DetailTariffProps) => {
	const { dataL } = props;
	// const [description, setDescription] = useState<string>(dataL.description || '');
	// const [isChanged, setIsChanged] = useState<boolean>(false);

	// const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	if (dataL.description !== e.target.value) {
	// 		setIsChanged(true);
	// 	} else {
	// 		setIsChanged(false);
	// 	}
	// 	setDescription(e.target.value);
	// };

	// const onSaveTariff = async () => {
	// 	await adminComboCoursesStore.updateTariffCombo(dataL.id, {
	// 		...dataL,
	// 		description: description || null
	// 	});
	// };

	return (
		<div className='detail-combo-tariff'>
			{/* <Input
				value={description}
				onChange={handleChangeDescription}
				multiline
				rows={4}
				label='Описание'
				name='description'
			/> */}
			<div className='detail-combo-tariff__comparisons'>
				{adminComboCoursesStore.comboCourses.map(course =>
					<ComparisonCourse
						key={course.id}
						course={course.course}
						tariffId={dataL.id}
					/>
				)}
			</div>
			{/* <div className='detail-combo-tariff__button'>
				<Button type='main_blue' onClick={onSaveTariff} disable={!isChanged}>
					Сохранить тариф
				</Button>
			</div> */}
			<TableComboPeriods combinationTariffId={dataL.id}/>
		</div>
	);
});
