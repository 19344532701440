import React from 'react';
import { observer } from 'mobx-react-lite';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

export default observer((props: any) => {
	const { referrals } = props;
	return (
		<TableContainer component={Paper} classes={{ root: 'theme-table-header' }}>
			<Table aria-label='custom pagination table'>
				<TableHead>
					<TableRow>
						<TableCell className='theme-table-header'>ФИО реферала</TableCell>
						<TableCell className='theme-table-header'>Статус</TableCell>
						<TableCell className='theme-table-header'>Сумма</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{referrals?.map(ref =>
						<TableRow key={ref.id}>
							<TableCell className='theme-block-background'>{`${ref.user.secondName} ${ref.user.firstName}`}</TableCell>
							<TableCell className='theme-block-background'>{referralStatus[ref.referralStatus]}</TableCell>
							<TableCell className='theme-block-background'>{ref.user.purchases.reduce((prev, cur) => prev.price + cur.price)}</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
});

const referralStatus = {
	completed: 'Выведено',
	ready: 'Готов к выводу',
	wait: 'Ждем покупку',
	rejected: 'Отклонено',
	pending: 'Ожидаем подтверждение',
	failed: 'Не выполнил условия'
};
