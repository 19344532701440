import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, CustomModal } from '@components';
import { constants } from '@helpers';
import '../style.css';

export default observer((props: any) => {
	const { isOpenModal, handleCloseModal, profession } = props;
	const theme = localStorage.getItem('theme');

	return (
		<CustomModal
			open={isOpenModal} handleClose={handleCloseModal}
			backdropColor='white' viewClose='cross'
			heightModal='auto' widthModal='auto'
		>
			<div className='gamification-modal'>
				<h3 className='gamification-modal__title'>Поздравляем!</h3>
				<img
					className={`gamification-modal__image ${theme === 'dark' && 'gamification-modal__image_dark'}`}
					src={`${constants.URL}/files/${profession?.preview?.[0]?.id}`}
					alt='head'
				/>
				<div className='gamification-modal__text'>
					Теперь ты {profession?.name}
				</div>
				<div className='gamification-modal__button'>
					<Button onClick={handleCloseModal} type='main_blue'>
            Круто!
					</Button>
				</div>
			</div>
		</CustomModal>
	);
});
