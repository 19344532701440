import React from 'react';
import CSS from 'csstype';
import { IconButton, Modal } from '@material-ui/core';
import { CloseButtonIcon, ArrowBackIcon } from 'lomonosov-icons';
import './style.css';
import classNames from 'classnames';

interface IModal {
	open: boolean,
	handleClose?: (event: React.MouseEvent<HTMLButtonElement>) => void,
	children: React.ReactNode,
	textTitle?: string | null,
	viewClose?: 'cross' | 'back',
	heightModal?: string,
	widthModal?: string,
	buttonColor?: string,
	backdropColor?: 'white' | 'black',
	className?: string,
	classNameTitle?: string,
	classes?: {
		container?: string;
	}
}

export default (props: IModal) => {
	const {
		open,
		handleClose,
		children,
		textTitle = null,
		viewClose,
		heightModal,
		widthModal,
		buttonColor = 'main',
		backdropColor = 'white',
		className = '',
		classNameTitle,
		classes
	} = props;

	const containerStyles: CSS.Properties = {
		height: heightModal,
		width: widthModal
	};

	const onClose = (event) => {
		handleClose?.(event);
	};

	const closeComponent = {
		cross:
			<IconButton className='modal__container-header-button modal__container-header-button-cross  theme-text' onClick={handleClose}>
				<CloseButtonIcon />
			</IconButton>,
		back:
			<IconButton className='modal__container-header-button modal__container-header-button-back theme-text' onClick={handleClose}>
				<ArrowBackIcon /><span>&nbsp;Назад</span>
			</IconButton>
	};

	const backdropColorValue = {
		white: 'rgba(155, 149, 171, 0.4)',
		black: 'rgba(52, 48, 63, 0.8)'
	};

	return (
		<Modal
			className='modal'
			open={open}
			onClose={onClose}
			BackdropProps={
				{
					style: {
						backgroundColor: backdropColorValue[backdropColor],
						backdropFilter: 'blur(2px)'
					}
				}
			}
		>
			<div style={containerStyles} className={`modal__container theme-background color-${buttonColor} ${className}`}>
				{
					(textTitle || viewClose) &&
					<div className='modal__container-header'>
						{viewClose === 'back' && closeComponent[viewClose]}
						{textTitle && <div className={classNames('modal__container-header-title', classNameTitle)}> {textTitle} </div>}
						{viewClose === 'cross' && closeComponent[viewClose]}
					</div>
				}
				<div className={classNames('modal__container-main', classes?.container)}>
					{children}
				</div>
			</div>
		</Modal >
	);
};
