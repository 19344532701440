import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
	
  .theme-background {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    //animation: snow 20s linear infinite;
    //-webkit-animation: snow 20s linear infinite;
	  //-moz-animation: snow 20s linear infinite;
	  //-ms-animation: snow 20s linear infinite;
  }
  
  //@keyframes snow {
  //  0% {background-position: 0 0, 0 0, 0 0;}
  //  100% {background-position: 500px 1000px, 400px 400px, 300px 300px;}
	//}
	
	.theme-blue-to-orange {
		color: ${({ theme }) => theme.blueToOrange} !important;
	}
	
	.theme-blue-to-green {
		color: ${({ theme }) => theme.blueToGreen} !important;
	}
	
	.theme-block-background {
		white-space: pre-line;
		background-color: ${({ theme }) => theme.blockBackground};
		color: ${({ theme }) => theme.text}
	}
	
	.theme-black-to-white {
		color: ${({ theme }) => theme.blackToWhite}
	}

	.theme-block-background-inverted {
		white-space: pre-line;
		background-color: ${({ theme }) => theme.text};
		color: ${({ theme }) => theme.background}
	}

	.theme-text {
		color: ${({ theme }) => theme.text} !important
	}
	
	.theme-icon {
		color: ${({ theme }) => theme.text} !important;
		background-color: ${({ theme }) => theme.background}
	}
	
	.theme-icon:hover {
		background-color: ${({ theme }) => theme.iconHover};
	}
	
	.theme-text-decoration {
		text-decoration: underline ${({ theme }) => theme.textDecoration} !important;
	}
	
	.theme-input {
		background-color: ${({ theme }) => theme.blockBackground};
		color: ${({ theme }) => theme.text};
	}
	
	.theme-outline {
		border: 4px solid ${({ theme }) => theme.blueToOrange};
	}
	
	.theme-table-header {
		overflow-x: initial;
		box-sizing: border-box;
		background-color: ${({ theme }) => theme.tableHeader};
    color: ${({ theme }) => theme.text};
    border-right: 1px solid ${({ theme }) => theme.border};
		
		&:last-child {
			border-right: 0;
		}
	}
	
	.theme-table-body {
		box-sizing: border-box;
		background-color: ${({ theme }) => theme.tableBody};
    color: ${({ theme }) => theme.text};
		border-right: 1px solid ${({ theme }) => theme.border};
		border-bottom: 1px solid ${({ theme }) => theme.border};
	}

	.theme-table-active {
		box-sizing: border-box;
		background-color: ${({ theme }) => theme.tableActiveField};
	}
	
	.theme-separator {
    background-color: ${({ theme }) => theme.separator};
	}
	
	.universal-button__color_orange {
		 background-color: ${({ theme }) => theme.buttonColor.orange};
		 border: none;
	}
	
	.universal-button__color_blue {
		background-color: ${({ theme }) => theme.buttonColor.blue};
		border: none;
	}
	
	.universal-button__color_green {
		background-color: ${({ theme }) => theme.buttonColor.green};
		border: none;
  }
  
  .universal-button__color_black {
		background-color: ${({ theme }) => theme.buttonColor.black};
		border: none;
  }
  
  .universal-button__color_red {
		background-color: ${({ theme }) => theme.buttonColor.red};
		border: none;
  }
  
  .universal-button__color_white {
		background-color: ${({ theme }) => theme.buttonColor.white};
		color: ${({ theme }) => theme.text};
		border: none;
  }
  
  .universal-button__color_orange-outline {
		 background-color: ${({ theme }) => theme.buttonColor.white};
		 border: 4px solid ${({ theme }) => theme.buttonColor.orange};
		 color: ${({ theme }) => theme.text};
	}

	.universal-button__color_blue-outline {
		background-color: ${({ theme }) => theme.buttonColor.white};
		border: 4px solid ${({ theme }) => theme.buttonColor.blue};
		color: ${({ theme }) => theme.text};
  }

  .universal-button__color_green-outline {
		background-color: ${({ theme }) => theme.buttonColor.white};
		border: 4px solid ${({ theme }) => theme.buttonColor.green};
		color: ${({ theme }) => theme.text};
  }
  
  .universal-button__color_black-outline {
		background-color: ${({ theme }) => theme.buttonColor.white};
		border: 4px solid ${({ theme }) => theme.buttonColor.black};
		color: ${({ theme }) => theme.text};
  }
  
  .universal-button__color_red-outline {
		background-color: ${({ theme }) => theme.buttonColor.white};
		border: 4px solid ${({ theme }) => theme.buttonColor.red};
		color: ${({ theme }) => theme.text};
  }
  
  .universal-button__color_white-outline {
		background-color: ${({ theme }) => theme.buttonColor.white};
		border: 4px solid ${({ theme }) => theme.buttonColor.white};
		color: ${({ theme }) => theme.text};
  }

  .ce-popover {
    --color-background-item-hover: ${({ theme }) => theme.blueToOrange};
    --color-background: ${({ theme }) => theme.blockBackground};
    --color-text-primary: ${({ theme }) => theme.text};
  }

  .ce-popover-item__icon {
    background: ${({ theme }) => theme.blockBackground};
  }

  .ce-toolbar__plus, .ce-toolbar__settings-btn {
    color: inherit;
  }

  .ce-toolbar__plus:hover, .ce-toolbar__settings-btn:hover, ce-inline-tool:hover, .ce-inline-toolbar__dropdown:hover {
    background: ${({ theme }) => theme.background};
  }

  .ce-block--selected .ce-block__content {
    background: ${({ theme }) => theme.background};
  }

  .ce-inline-toolbar, .ce-conversion-toolbar, .ce-conversion-tool__icon {
    background: ${({ theme }) => theme.blockBackground};
  }

	.tc-popover {
		--color-background: ${({ theme }) => theme.blockBackground};
	}
  
	.tc-wrap {
		--color-background: ${({ theme }) => theme.background};
	}

	`;
