import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Link from './components/link';
import UrlLink from './components/urlLink';
import CustomItem from './components/customItem';
import Avatar from './components/avatar';
import ThemeButton from './components/theme-button';
import Footer from './components/footer';
import './style.css';

type IMenu = DrawerProps & { ismobile: string };

function Menu (props: IMenu) {
	const { children, ismobile, ...otherProps } = props;

	return (
		<Drawer
			className={`DrawerMenu DrawerMenu__${otherProps.open ? 'open' : 'close'} ${ismobile === 'true' ? '' : 'DrawerMenu__pc'} theme-background`}
			{...otherProps}
		>
			{children}
		</Drawer>
	);
}

Menu.Link = memo(Link);
Menu.UrlLink = memo(UrlLink);
Menu.Avatar = memo(Avatar);
Menu.ThemeButton = memo(ThemeButton);
Menu.Divider = memo(() => (<Divider className='DrawerMenu__divider'/>));
Menu.CustomItem = memo(CustomItem);
Menu.Footer = memo(Footer);

export default observer(Menu);
