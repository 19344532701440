import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	AppBar,
	Button,
	ClickAwayListener,
	Paper,
	Popper
} from '@material-ui/core';

import { userStore } from '@stores';
import { MenuItem, MenuList } from '@mui/material';

interface AdminMainMenuProps {
	handleClick: (newValue: string) => void
}

export default observer((props: AdminMainMenuProps) => {
	const { handleClick } = props;

	const [studyMenu, setStudyMenu] = useState<boolean>(false);
	const [marketingMenu, setMarketingMenu] = useState<boolean>(false);
	const [adminMenu, setAdminMenu] = useState<boolean>(false);
	const [usersMenu, setUsersMenu] = useState<boolean>(false);
	const [analyticsMenu, setAnalyticsMenu] = useState<boolean>(false);

	const studyRef = useRef<any>(null);
	const marketingRef = useRef<any>(null);
	const adminRef = useRef<any>(null);
	const usersRef = useRef<any>(null);
	const analyticsRef = useRef<any>(null);

	const changeStudyMenuState = (event?) => {
		if (event && studyRef.current && studyRef.current.contains(event.target)) {
			return;
		}
		setStudyMenu(!studyMenu);
	};
	const changeMarketingMenuState = (event?) => {
		if (event && marketingRef.current && marketingRef.current.contains(event.target)) {
			return;
		}
		setMarketingMenu(!marketingMenu);
	};
	const changeAdminMenuState = (event?) => {
		if (event && adminRef.current && adminRef.current.contains(event.target)) {
			return;
		}
		setAdminMenu(!adminMenu);
	};
	const changeUsersMenuState = (event?) => {
		if (event && usersRef.current && usersRef.current.contains(event.target)) {
			return;
		}
		setUsersMenu(!usersMenu);
	};
	const changeAnalyticsMenuState = (event?) => {
		if (event && analyticsRef.current && analyticsRef.current.contains(event.target)) {
			return;
		}
		setAnalyticsMenu(!analyticsMenu);
	};

	const adminButtons = [
		{
			id: 'study-button',
			name: 'Учебный отдел',
			block: 'study',
			ref: studyRef,
			func: changeStudyMenuState,
			menu: studyMenu
		},
		{
			id: 'marketing-button',
			name: 'Маркетинг',
			block: 'marketing',
			ref: marketingRef,
			func: changeMarketingMenuState,
			menu: marketingMenu
		},
		{
			id: 'admin-button',
			name: 'Администрирование',
			block: 'admin',
			ref: adminRef,
			func: changeAdminMenuState,
			menu: adminMenu
		},
		{
			id: 'users-button',
			name: 'Пользователи',
			block: 'users',
			ref: usersRef,
			func: changeUsersMenuState,
			menu: usersMenu
		},
		{
			id: 'analytics-button',
			name: 'Аналитика',
			block: 'analytics',
			ref: analyticsRef,
			func: changeAnalyticsMenuState,
			menu: analyticsMenu
		}
	];
	const poppers = [
		{
			id: 'study',
			anchor: studyRef.current,
			open: studyMenu,
			close: changeStudyMenuState,
			items: [
				{ id: 'courses', name: 'Курсы', isForEmployee: false },
				{ id: 'homework-bank', name: 'Банк ДЗ', isForEmployee: false },
				{ id: 'homework-answers', name: 'Проверка заданий', isForEmployee: true },
				{ id: 'essays', name: 'Сочинения', isForEmployee: false },
				{ id: 'check-essays', name: 'Проверка сочинений', isForEmployee: true }
				// { id: 'student-analysis', name: 'Анализ активности студентов', isForEmployee: false }
			]
		},
		{
			id: 'marketing',
			anchor: marketingRef.current,
			open: marketingMenu,
			close: changeMarketingMenuState,
			items: [
				{ id: 'promo', name: 'Промокоды', isForEmployee: false },
				{ id: 'banners', name: 'Баннеры', isForEmployee: false },
				{ id: 'referral', name: 'Заявки на вывод', isForEmployee: false },
				{ id: 'invoice', name: 'Счета', isForEmployee: false },
				{ id: 'abandoned-carts', name: 'Брошенные корзины', isForEmployee: false },
				{ id: 'short-link', name: 'Короткие ссылки', isForEmployee: false },
				{ id: 'newsletters', name: 'Рассылки', isForEmployee: false }
			]
		},
		{
			id: 'admin',
			anchor: adminRef.current,
			open: adminMenu,
			close: changeAdminMenuState,
			items: [
				{ id: 'control-mentors', name: 'Контроль менторов', isForEmployee: false },
				{ id: 'categories', name: 'Категории', isForEmployee: false },
				{ id: 'directions', name: 'Направления', isForEmployee: false },
				{ id: 'disciplines', name: 'Дисциплины', isForEmployee: false },
				{ id: 'tariff-plan', name: 'Тарифный план', isForEmployee: false },
				{ id: 'shop', name: 'Магазин', isForEmployee: false },
				{ id: 'combo-courses', name: 'Комбо-курсы', isForEmployee: false },
				{ id: 'showcase-courses', name: 'Витрина-курсов', isForEmployee: false }
			]
		},
		{
			id: 'users',
			anchor: usersRef.current,
			open: usersMenu,
			close: changeUsersMenuState,
			items: [
				{ id: 'all-users', name: 'Все пользователи', isForEmployee: true },
				{ id: 'teachers', name: 'Учителя', isForEmployee: false },
				{ id: 'my-students', name: 'Мои ученики', isForEmployee: true },
				{ id: 'issuance-of-courses', name: 'Выдача курсов', isForEmployee: false },
				{ id: 'employees', name: 'Сотрудники', isForEmployee: false }
			]
		},
		{
			id: 'analytics',
			anchor: analyticsRef.current,
			open: analyticsMenu,
			close: changeAnalyticsMenuState,
			items: [
				{ id: 'analytics-courses', name: 'Аналитика курсов', isForEmployee: true },
				{ id: 'analytics-promo', name: 'Аналитика промокодов', isForEmployee: true },
				{ id: 'analytics-students', name: 'Аналитика активности студентов', isForEmployee: false }
			]
		}
	];

	return (
		<AppBar position='static' className='admin-main__menu'>
			<>
				{adminButtons.map(buttonInfo =>
					<Button
						id={buttonInfo.id} key={buttonInfo.id} color='primary' aria-haspopup='true'
						ref={buttonInfo.ref} onClick={() => buttonInfo.func()}
						aria-controls={buttonInfo.menu ? buttonInfo.block : undefined}
						aria-expanded={buttonInfo.menu ? 'true' : undefined}
					>
						<span className='theme-blue-to-orange'>{buttonInfo.name}</span>
					</Button>
				)}
				{poppers.map((popper) =>
					<Popper
						id={popper.id}
						key={popper.id}
						anchorEl={popper.anchor}
						open={popper.open}
						role={undefined}
						placement='bottom-start'
						transition
						disablePortal
						style={{ zIndex: 99 }}
					>
						<Paper>
							<ClickAwayListener onClickAway={popper.close}>
								<MenuList
									id={popper.id}
									aria-labelledby={`${popper.id}-button`}
									autoFocusItem={popper.open}
									className='theme-block-background'
								>
									{popper.items.map(item =>
										<MenuItem
											key={item.id}
											disabled={!(item.isForEmployee || userStore.isAdmin)}
											onClick={(e) => {
												handleClick(item.id);
												popper.close(e);
											}}
										>
											{item.name}
										</MenuItem>
									)}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Popper>
				)}
			</>
		</AppBar>
	);
});
