import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { adminHomeworkAnswersStore, adminMentorsStore } from '@stores';

import { Table } from '@components';
import { IColumn } from '@components/table';
import { HomeworkStatistics } from '../../admin-components';
import { FilteringMenuControlMentors, ControlMentorsDetails } from './control-mentors-blocks';
import { convertDateFormat } from '@helpers';

import './style.css';
import { HomeworkAnswerStatus } from '@lomonosov/types';

dayjs.extend(duration);

export default observer(() => {
	const [info, setInfo] = useState<any>([]);

	useEffect(() => {
		adminHomeworkAnswersStore.changeFilterField(null, 'mentor');
		adminHomeworkAnswersStore.changeFilterField({
			id: HomeworkAnswerStatus.VERIFIED,
			name: 'Провереные'
		}, 'status');
		if (!adminHomeworkAnswersStore.homeworkAnswers?.length) {
			adminHomeworkAnswersStore.getHomeworkAnswerAnalysis();
		}
		if (!adminMentorsStore.mentors?.length) {
			adminMentorsStore.getMentors(0, 10);
		}
		if (!adminHomeworkAnswersStore.directions?.length || !adminHomeworkAnswersStore.disciplines?.length) {
			adminHomeworkAnswersStore.getFilters();
		}
	}, []);

	useEffect(() => {
		const answers = adminHomeworkAnswersStore.homeworkAnswers.map(value => {
			const duration = dayjs.duration(dayjs(value.checkedAt || value.updatedAt).diff(value.createdAt));
			return {
				id: value.id,
				name: `${value.user.firstName} ${value.user.secondName}`,
				dateStart: convertDateFormat(value.createdAt),
				dateEnd: convertDateFormat(value.checkedAt),
				downtime: duration.format('D д. H ч. m м.')
			};
		});
		setInfo(answers || []);
	}, [adminHomeworkAnswersStore.homeworkAnswers]);

	const changeTablePage = async (e, newPage: number) => {
		await adminHomeworkAnswersStore.changeTablePage(newPage, adminHomeworkAnswersStore.paginationControl.rows);
	};

	const changeTableRows = async (e: React.ChangeEvent<HTMLInputElement>) => {
		await adminHomeworkAnswersStore.changeTableRows(+e.target.value, adminHomeworkAnswersStore.paginationControl.currentPage);
	};

	const openDetails = async (id) => {
		await adminHomeworkAnswersStore.getHomeworkAnswerById(id);
	};

	return (
		<div className='control-mentors'>
			<FilteringMenuControlMentors/>
			<HomeworkStatistics statistics={adminHomeworkAnswersStore.homeworkAnswersAnalysis}/>
			<Table
				data={info}
				columns={columns}
				pagination={{
					count: adminHomeworkAnswersStore.paginationControl.totalCount,
					page: adminHomeworkAnswersStore.paginationControl.currentPage,
					rowsPerPage: adminHomeworkAnswersStore.paginationControl.rows,
					onPageChange: changeTablePage,
					onRowsPerPageChange: changeTableRows,
					rowsPerPageOptions: [5, 10, 25, 50]
				}}
				detailPanel={{
					Component: ControlMentorsDetails,
					action: (id) => openDetails(id)
				}}
			/>
		</div>
	);
});

const columns: IColumn[] = [
	{ name: 'Ученик', field: 'name' },
	{ name: 'Дата подачи работы', field: 'dateStart' },
	{ name: 'Дата проверки', field: 'dateEnd' },
	{ name: 'Время простоя', field: 'downtime' }
];
