import { useState } from 'react';
import { authStore, generalStore } from '@stores';
import { IRegisterDto } from '../types/auth';

interface IUseRegisterResponse {
	error: string | null;
	isLoading: boolean;
	onRegister: (data: IRegisterDto, callback?: () => void) => Promise<void>;
}

export const useRegister = (): IUseRegisterResponse => {
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const onRegister = async (data: IRegisterDto, callback?: () => void) => {
		try {
			setIsLoading(true);
			setError(null);
			await authStore.checkLoginExists(data.login);

			if (authStore.statusUser === 'old') {
				setError('Аккаунта с таким email уже существует!');
				generalStore.changeAlertState('warning', 'Аккаунта с таким email уже существует!');
				return;
			}

			await authStore.registration(data);
			if (authStore.isAuth) {
				callback?.();
			}
		} catch (e) {
			setError(e.message);
		} finally {
			setIsLoading(false);
		}
	};

	return { error, isLoading, onRegister };
};
