import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { adminUserStore, generalStore } from '@stores';
import { Input, Button, Select } from '@components';
import { convertPhone, errorRules } from '@helpers';
import './style.css';

export default () => {
	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		mode: 'onBlur',
		defaultValues: defaultUser
	});

	const createUser = async (data) => {
		if (!data.email && !data.phone) {
			generalStore.changeAlertState('warning', 'Укажите телефон или email');
		} else {
			const phone = data.phone === '' ? null : convertPhone(data.phone);
			const email = data.email === '' ? null : data.email;
			await adminUserStore.createUser({ ...data, phone: phone, email: email, sex: data.sex.id });
		}
	};

	return (
		<form className='add-user-modal' onSubmit={handleSubmit(createUser)}>
			<p className='add-user-modal__title'>
				Создание пользователя
			</p>
			<div className='add-user-modal__item'>
				<Controller
					name='firstName'
					control={control}
					rules={errorRules.firstName}
					render={({ field }) =>
						<Input
							{...field}
							label='Имя'
							error={!!errors?.firstName?.message}
							helperText={errors?.firstName?.message}
						/>}
				/>
			</div>
			<div className='add-user-modal__item'>
				<Controller
					name='secondName'
					control={control}
					rules={errorRules.secondName}
					render={({ field }) =>
						<Input
							{...field}
							label='Фамилия'
							error={!!errors?.secondName?.message}
							helperText={errors?.secondName?.message}
						/>}
				/>
			</div>
			<div className='add-user-modal__item'>
				<Controller
					name='phone'
					control={control}
					rules={{ ...errorRules.phone, required: false }}
					render={({ field }) =>
						<Input
							{...field}
							label='Телефон'
							error={!!errors?.phone?.message}
							helperText={errors?.phone?.message}
						/>}
				/>
			</div>
			<div className='add-user-modal__item'>
				<Controller
					name='email'
					control={control}
					rules={{ ...errorRules.email, required: false }}
					render={({ field }) =>
						<Input
							{...field}
							label='Email'
							error={!!errors?.email?.message}
							helperText={errors?.email?.message}
						/>}
				/>
			</div>
			<div className='add-user-modal__item'>
				<Controller
					name='sex'
					control={control}
					rules={errorRules.firstName}
					render={({ field }) =>
						<Select
							{...field}
							onChange={(_e, value) => field.onChange(value)}
							options={sexOptions}
							input={{
								label: 'Выберите пол',
								error: !!errors?.sex?.message,
								helperText: errors?.sex?.message
							}}
						/>}
				/>
			</div>
			<div className='add-user-modal__item'>
				<Controller
					name='password'
					control={control}
					rules={errorRules.password}
					render={({ field }) =>
						<Input
							{...field}
							label='Пароль'
							error={!!errors?.password?.message}
							helperText={errors?.password?.message}
						/>}
				/>
			</div>
			<div className='add-user-modal__button'>
				<Button type='main_green' submit>
					Создать
				</Button>
			</div>
		</form>
	);
};

const defaultUser = {
	firstName: '',
	secondName: '',
	phone: null,
	password: '',
	email: null,
	sex: ''
};

const sexOptions = [
	{
		id: 'man',
		name: 'Парень'
	},
	{
		id: 'woman',
		name: 'Девушка'
	}
];
