/**
 * Splits an array into chunks of a specified size.
 * @param perChunk The number of elements per chunk.
 * @param arr The array to split.
 * @returns An array of arrays, where each inner array contains the elements of the original array grouped into chunks.
 */
export function splitIntoChunk<T> (perChunk: number, arr: T[]): T[][] {
	return arr.reduce((resultArray: T[][], item: T, index: number): T[][] => {
		const chunkIndex: number = Math.floor(index / perChunk);
		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = []; // start a new chunk
		}
		resultArray[chunkIndex].push(item);
		return resultArray;
	}, []);
}
