import React, { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput } from '@mui/material';
import './style.css';

type obj = {
    id: string,
    name: string
}

interface IProps {
    data: obj[]
    onChange: (newData: obj[]) => void
    value: obj[]
    label?: string
    key?: string
    className?: string
}

export default (props: IProps) => {
	const {
		data,
		onChange,
		value,
		label,
		key = 'MultiSelect-DefaultKey',
		className = ''
	} = props;
	const [val, setVal] = useState<string[]>([]);
	const [dataObj, setDataObj] = useState({});

	useEffect(() => {
		const tempData = {};
		data.forEach(d => {
			tempData[d.id] = d;
		});
		setDataObj(tempData);
	}, [data]);

	useEffect(() => {
		const temp = value.map(v => v.id);
		setVal(temp);
	}, [value]);

	const handleChange = (event: SelectChangeEvent<typeof val>) => {
		const { target: { value } } = event;

		let temp: string[] = [];
		if (typeof value === 'string') {
			temp = value.split(',');
		} else {
			temp = value;
		}
		const finalData = temp.map(t => (dataObj[t]));
		onChange(finalData);
	};

	return <FormControl className={`MultiSelect ${className}`}>
		{label && <InputLabel className='MultiSelect__label' id={`MultiSelect__label-${key}`}>{label}</InputLabel>}
		<Select
			labelId={`MultiSelect__select-${key}`}
			className='MultiSelect__select'
			multiple
			value={val}
			onChange={handleChange}
			input={<OutlinedInput label={label} />}
			renderValue={(selected) =>
				<Box className={'MultiSelect__select-box'} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
					{selected.map((value) =>
						<Chip
							className={'MultiSelect__select-box-chip'}
							key={value}
							label={dataObj[value]?.name}
						/>
					)}
				</Box>
			}
			MenuProps={{ PaperProps: { className: 'MultiSelect__select-menuProps-paper' } }}
		>
			{data.map((course) =>
				<MenuItem className={'MultiSelect__select-menuItem'} key={course.id} value={course.id}>
					<ListItemText className={'MultiSelect__select-menuItem-label'} primary={course.name} />
				</MenuItem>
			)}
		</Select>
	</FormControl >;
};
