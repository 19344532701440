import React, { useMemo } from 'react';
import {
	FormControl, IconButton, InputLabel, Select as MUISelect,
	MenuItem, SelectChangeEvent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type SelectFieldOptionKey = 'value' | 'name'
export interface SelectOptionWithValue <T extends string>{
	id: string | number;
  value: T;
}

export interface SelectOptionWithName <T extends string>{
	id: string | number;
  name: T;
}

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
type XOR<T, U> = (T | U) extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

export type SelectOption<T extends string> = XOR<SelectOptionWithName<T>, SelectOptionWithValue<T>>;

interface ISelectProps<T extends string> {
	className?: string;
	value?: SelectOption<T> | null;
	label?: string;
	options?: SelectOption<T>[];
	onChange?: (e: SelectChangeEvent<T>, value: T, option: SelectOption<T>) => void;
	readonly?: boolean;
	name?: string;
  fieldOptionKey?: SelectFieldOptionKey;
	error?: boolean;
	getOptionDisabled?: (option: SelectOption<T> | null) => boolean;
	onDelete?: (name?: string) => void;
}

export const DropdownList = <T extends string>(props: ISelectProps<T>) => {
	const {
		className, label, value, onChange,
		options = [], readonly, name, fieldOptionKey = 'value', error, getOptionDisabled, onDelete
	} = props;

	const onChangeHandler = (e: SelectChangeEvent<T>, child: any) => {
		onChange?.(
			e,
      e.target.value as T,
      {
      	...{ ...options.find(i => i[fieldOptionKey] === e.target.value) },
      	id: child?.props?.id,
      	[fieldOptionKey]: child?.props?.value
      } as SelectOption<T>
		);
	};

	const optionList = useMemo(() => {
		return options?.map(opt => (
			<MenuItem
				id={opt.id.toString()}
				key={opt.id}
				value={opt[fieldOptionKey]}
				disabled={getOptionDisabled?.(opt) || false}
			>{opt[fieldOptionKey]}
			</MenuItem>
		));
	}, [options, fieldOptionKey, getOptionDisabled]);

	return (
		<FormControl className={className}>
			<InputLabel >{label}</InputLabel>
			<MUISelect
				disabled={readonly}
				name={name}
				value={value?.name || value?.value || ''}
				label={label}
				variant='outlined'
				onChange={onChangeHandler}
				error={error}
				// MenuProps={{
				// 	disableScrollLock: true
				// }}
				endAdornment={
					onDelete && value && (
						<IconButton
							size='small'
							onClick={() => onDelete(name)}
							style={{ marginRight: '10px' }}
						>
							<CloseIcon fontSize='small'/>
						</IconButton>
					)
				}
			>
				{optionList}
			</MUISelect>
		</FormControl>
	);
};
