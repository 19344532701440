import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { QueryParams } from '../types';

function useQueryParamsToLocalStorage () {
	const navigate = useNavigate();
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		let hasQueryParams = false;

		// Сохраняем все параметры запроса в localStorage
		searchParams.forEach((value, key) => {
			if (Object.values(QueryParams).includes(key as QueryParams)) {
				localStorage.setItem(key, value);
				hasQueryParams = true;
			}
		});

		// Очищаем URL от параметров запроса если в нем есть параметры из QueryParams
		if (hasQueryParams) {
			navigate(window.location.pathname, { replace: true });
		}
	}, [localStorage.token]);
}

export default useQueryParamsToLocalStorage;
