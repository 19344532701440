import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@mui/material';
import { Button, Table } from '@components';
import { IUpdateReferral } from '@interfaces';
import { adminReferralStore, adminUserStore, userStore, generalStore } from '@stores';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import './mentors-panel/style.css';

export default observer(() => {
	const [isOpenPanel, setIsOpenPanel] = useState<boolean>(false);
	const [timer, setTimer] = useState<any>();
	const [search, setSearch] = useState<string>('');
	const [page, setPage] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(5);

	useEffect(() => {
		(async () => {
			await adminUserStore.getUserReferrals(adminUserStore.currentUser.id);
		})();
	}, []);

	const changeIsOpenPanel = useCallback(() => {
		adminReferralStore.getReferrals(page, pageSize, search);
		setIsOpenPanel(!isOpenPanel);
	}, [isOpenPanel]);

	const deleteReferral = async (referral) => {
		if (userStore.isManager) {
			const params: IUpdateReferral = {
				referralId: referral.id,
				body: {
					invitingReferralId: null,
					fundsPerUser: 0,
					referralStatus: 'wait'
				}
			};
			await adminUserStore.updateUserReferrals(params);
		} else {
			generalStore.changeAlertState('warning', 'Нет прав');
		}
	};

	const addReferral = async (referral) => {
		const params: IUpdateReferral = {
			referralId: referral.id,
			body: {
				invitingReferralId: adminUserStore.currentUser.referralInformation.id,
				fundsPerUser: 0,
				referralStatus: 'wait'
			}
		};
		await adminUserStore.updateUserReferrals(params);
	};

	const changePage = async (event, newPage) => {
		setPage(newPage);
		await adminReferralStore.getReferrals(newPage, pageSize, search);
	};

	const changePageSize = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(parseInt(event.target.value, 10));
		await adminReferralStore.getReferrals(page, parseInt(event.target.value, 10), search);
	};

	const onChangeSearch = useCallback(newSearch => {
		clearTimeout(timer);
		setTimer(setTimeout(() => adminReferralStore.getReferrals(0, pageSize, newSearch), 1000));
		setSearch(newSearch);
		setPage(0);
	}, [search]);

	return (
		<div className='mentors-panel'>
			<h2 className='mentors-panel__title'>Рефералы</h2>
			{adminUserStore.referrals.length > 0
				? <div className='mentors-panel__content'>
					{adminUserStore.referrals.map(referral =>
						<div key={referral.id} className='mentors-panel__content_item'>
							{referral.name}&nbsp;
							{referral.referralStatus}&nbsp;
							<IconButton onClick={() => deleteReferral(referral)}>
								<DeleteIcon className='mentors-panel__delete-button'/>
							</IconButton>
						</div>)
					}
				</div>
				: <span className='mentors-panel__no-records'>Рефералов нет</span>
			}
			{userStore.isManager &&
				<div className='mentors-panel__button'>
					<Button
						type='main_blue'
						onClick={changeIsOpenPanel}
					>
						{isOpenPanel ? 'Скрыть' : 'Добавить реферала'}
					</Button>
				</div>
			}
			{isOpenPanel &&
				<Table
					columns={columnsReferralsTable} data={adminReferralStore.referrals}
					search={{ prop: search, action: onChangeSearch }}
					pagination={{
						page: page,
						onPageChange: changePage,
						rowsPerPage: pageSize,
						onRowsPerPageChange: changePageSize,
						count: adminReferralStore.pageCount
					}}
					customCellsRender={[
						{
							name: 'Действие',
							align: 'center',
							width: 70,
							render: (state) => {
								return (
									adminUserStore.referrals.some(r => r.id === state.id)
										? <IconButton
											onClick={() => deleteReferral(state)}
										>
											<DeleteIcon className='mentors-panel__delete-button' />
										</IconButton>
										: <IconButton
											onClick={() => addReferral(state)}
										>
											<AddIcon className='mentors-panel__add-button' />
										</IconButton>
								);
							}
						}
					]}
				/>
			}
		</div>
	);
});

const columnsReferralsTable = [
	{ field: 'name', name: 'Имя' },
	{ field: 'referralStatus', name: 'Статус' }
];
