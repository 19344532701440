import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { constants, images, truncateText } from '@helpers';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import { PortalCoursesCardAdvantages, CardSubscriptionPeriod, CardTeacher } from '../../../../portal-components';
import { IGetUserCoursesItemResponse } from '@lomonosov/types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ICourseListItem } from '@interfaces';

import './style.css';

interface ICoursesCardProps {
	course: ICourseListItem;
	openTeacherModal: (teacher: IGetUserCoursesItemResponse['teacher']) => void;
	selectCourse: (course: any, type: 'delete' | 'setting') => void;
}

export default (props: ICoursesCardProps) => {
	const { course, openTeacherModal, selectCourse } = props;

	const [menuOpen, setMenuOpen] = useState(null);
	const handleOpen = (e): void => {
		setMenuOpen(e.currentTarget);
	};

	const handleClose = () => setMenuOpen(null);

	const openModal = useCallback((type: 'delete' | 'setting') => {
		selectCourse(course, type);
		setMenuOpen(null);
	}, [course]);

	const switchInDifficulties = (difficulty: string) => {
		switch (difficulty) {
		case 'easy':
			return 'Уровень: легкий';
		case 'medium':
			return 'Уровень: средний';
		case 'hard':
			return 'Уровень: сложный';
		default:
			return '';
		}
	};

	const advantages = [
		course.classroom,
		course.direction.name,
		course.discipline.name,
		switchInDifficulties(course?.setting?.difficult)
	];

	return (
		<div className='courses-card theme-block-background'>
			<img
				className='courses-card__preview'
				src={course.preview?.length !== 0
					? `${constants.URL}/files/${course.preview?.[0]?.id || ''}`
					: images.defaultCourse
				}
			/>
			<div className='courses-card__content'>
				<PortalCoursesCardAdvantages advantages={advantages} className='courses-card__advantages'/>
				<div className='courses-card__info'>
					<CardSubscriptionPeriod
						className='courses-card__subscription'
						classes={{ container: 'courses-card__subscription-container' }}
						periods={course.periods}
					/>
					{course.teacher &&
						<CardTeacher teacher={course.teacher} openTeacherModal={openTeacherModal} className='courses-card__teacher'/>
					}
				</div>
				<div className='courses-card__title-block'>
					<h1 className='courses-card__title'>{course.name}</h1>
					{course.description &&
						<p className='courses-card__description'>
							{truncateText(course.description, 300)}
						</p>
					}
				</div>
				<div className='courses-card__button-block'>
					<IconButton className='courses-card__icon-button' onClick={handleOpen}>
						<MoreHorizIcon fontSize='large' className='theme-text'/>
					</IconButton>
					<Link to={`/course/${course.shortId}`} className='courses-card__button'>
						<Button
							color='primary'
							variant='contained'
							className='courses-card__button'
						>
							Перейти&nbsp;&nbsp;<ArrowForwardIcon fontSize='medium'/>
						</Button>
					</Link>
				</div>
			</div>
			<Menu className='courses-card__menu' anchorEl={menuOpen} keepMounted open={Boolean(menuOpen)} onClose={handleClose}>
				<MenuItem onClick={() => openModal('setting')}>Настройки</MenuItem>
				<MenuItem onClick={() => openModal('delete')}>Удалить</MenuItem>
			</Menu>
		</div>
	);
};
