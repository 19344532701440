import React, { ChangeEvent, useCallback, useRef } from 'react';
import { Button } from '@components';
import { CircularProgress } from '@mui/material';
import './style.css';

interface UploadFileButtonProps {
	isLoading?: boolean;
	onSaveFile: (event: ChangeEvent<HTMLInputElement>) => void;
	type?: 'outline_orange' | 'main_blue' | 'main_orange' | 'outline_blue';
	className?: string;
}

export const UploadFileButton = (props: UploadFileButtonProps) => {
	const { onSaveFile, isLoading, type = 'main_blue', className = '' } = props;
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleClick = useCallback(() => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	}, [fileInputRef]);

	return (
		<>
			<div className={`upload-button ${className}`}>
				<Button
					type={type}
					className={'upload-button__button'}
					disable={isLoading}
					onClick={handleClick}
				>
					Добавить файл
				</Button>
				{isLoading &&
					<CircularProgress
						color='warning'
						className='upload-button__loader'
						thickness={8}
						size={28}
					/>
				}
			</div>
			<input
				ref={fileInputRef}
				className='upload-button__input'
				type='file'
				disabled={isLoading}
				onChange={onSaveFile}
			/>
		</>
	);
};
