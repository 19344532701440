import { makeAutoObservable, runInAction } from 'mobx';
import { AdminCategoryService } from '@services';
import { ICreateCategoryDto, IGetCategoriesResponse } from '@lomonosov/types';

class AdminCategoryStore {
	categories = [] as IGetCategoriesResponse;

	constructor () {
		makeAutoObservable(this);
	}

	async getCategories () {
		try {
			const { data } = await AdminCategoryService.getCategories();
			runInAction(() => {
				this.categories = data;
			});

			return data;
		} catch (e) {
			console.log(e);
		}
	}

	async getCategoryById (categoryId: string) {
		try {
			await AdminCategoryService.getCategoryById(categoryId);
		} catch (e) {
			console.log(e);
		}
	}

	async createCategory (newCategory: ICreateCategoryDto) {
		try {
			const { data } = await AdminCategoryService.createCategory(newCategory);
			runInAction(() => {
				this.categories = [data, ...this.categories];
			});
		} catch (e) {
			console.log(e);
		}
	}

	async updateCategory (categoryId: string, newCategory: ICreateCategoryDto) {
		try {
			const { data } = await AdminCategoryService.updateCategory(categoryId, newCategory);
			runInAction(() => {
				this.categories = this.categories.map(category => category.id === categoryId ? data : category);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async deleteCategory (id: string) {
		try {
			await AdminCategoryService.deleteCategory(id);
			runInAction(() => {
				this.categories = this.categories.filter(category => category.id !== id);
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const adminCategoryStore = new AdminCategoryStore();
