import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import { Button } from '@components';

import { BookIcon, GraduateCap } from 'lomonosov-icons/index';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import IconButton from '@mui/material/IconButton';

import './style.css';

export default ({ course, selectCourse }: any) => {
	return (
		<div className='courses-analysis-card theme-block-background'>
			<div className='courses-analysis-card__block'>
				<p className='courses-analysis-card__title'>
					{course.name}
					{course.isVisible &&
          	<span className='courses-analysis-card__archive'> (В магазине)</span>
					}
					{course.isArchive &&
          	<span className='courses-analysis-card__archive'> (Архивный)</span>
					}
				</p>
				<div className='courses-analysis-card__info'>
					<div className='courses-analysis-card__info-item'>
						<IconButton className='courses-analysis-card__icon portal-book'>
							<BookIcon/>
						</IconButton>
						<span>
							{`${course.category.name} ${course.direction.name} ${course.discipline.name}`}
						</span>
					</div>
					<div className='courses-analysis-card__info-item'>
						<div>
							<IconButton>
								<GraduateCap/>
							</IconButton>
							<span>
								{course.teacher?.name}
							</span>
						</div>
					</div>
				</div>
				<div className='courses-analysis-card__icons'>
					<div className='courses-analysis-card__tooltip-block'>
						<Tooltip title='Кол-во студентов на курсе'>
							<IconButton>
								<PeopleAltIcon/>
							</IconButton>
						</Tooltip>
						<span>
							{course.users}
						</span>
					</div>
					<div className='courses-analysis-card__tooltip-block'>
						<div>
							<Tooltip title='Кол-во разделов в курсе'>
								<IconButton>
									<AssignmentIcon/>
								</IconButton>
							</Tooltip>
						</div>
						<span>
							{course.sections}
						</span>
					</div>
					<div className='courses-analysis-card__tooltip-block'>
						<div>
							<Tooltip title='Кол-во уроков в курсе'>
								<IconButton>
									<ImportContactsIcon/>
								</IconButton>
							</Tooltip>
						</div>
						<span>
							{course.lessons}
						</span>
					</div>
					<div className='courses-analysis-card__tooltip-block'>
						<div>
							<Tooltip
								className='courses-analysis-card__tooltip'
								title={<span>
									Кол-во выполненных заданий: {course.tasks} <br/>
								</span>}
							>
								<IconButton>
									<HomeWorkIcon/>
								</IconButton>
							</Tooltip>
						</div>
						<span>
							{course.homeworks}
						</span>
					</div>
				</div>
			</div>
			<div className='courses-analysis-card__button'>
				<Button
					type='main_blue'
					onClick={() => selectCourse(course.id, course.tasks)}
					disable={course.lessons === 0}
				>
					Выбрать
				</Button>
			</div>
		</div>
	);
};
