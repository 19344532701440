export enum QueryParams {
	SSO_TOKEN = 'ssoToken',
	RET_PATH = 'retPath',
	REF = 'ref',
	TOKEN = 'token',
	THEME = 'theme',
	CODE = 'code',
	EMAIL = 'email',
	NAVIGATION_PATH = 'navigationPath',
}
