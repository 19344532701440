import React, { useEffect, useState } from 'react';
import { Button, List, ListItem } from '@mui/material';
import { Media, SocialButton } from '@components';
import { IStrapiTeacher } from '@interfaces';
import { QuoteColorType, TeacherCardQuote } from './ui/TeacherCardQuote';

import classNames from 'classnames';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './style.css';

interface ITeacherCardProps {
  className?: string;
  strapiTeachers?: IStrapiTeacher[];
	teacher: {
		name: string;
		photo: string;
	}
}

// const getRandomTeacherInfo = (teachers: IStrapiTeacher[]): IStrapiTeacher => {
// 	const randomTeacher = teachers[Math.floor(Math.random() * teachers.length)];
// 	return randomTeacher;
// };

export const TeacherCard = (props: ITeacherCardProps) => {
	const { className, strapiTeachers, teacher } = props;
	const [strapiTeacher, setStrapiTeacher] = useState<Partial<IStrapiTeacher> | null>(null);

	useEffect(() => {
		if (strapiTeachers?.length) {
			const currentTeacher = strapiTeachers.find(t => t.name.toLowerCase() === teacher.name.toLowerCase());
			if (currentTeacher) {
				setStrapiTeacher(currentTeacher);
			} else {
				setStrapiTeacher({
					name: teacher.name,
					poster: {
						data: {
							id: '1',
							attributes: {
								url: teacher.photo
							}
						}
					}
					// quote: getRandomTeacherInfo(strapiTeachers).quote
				});
			}
		}
	}, [strapiTeachers, teacher]);

	return (
		<div className={classNames('TeacherCard', {}, [className])}>
			<Media
				className={'TeacherCard__media'}
				posterUrl={strapiTeacher?.poster?.data?.attributes?.url}
				videoUrl={strapiTeacher?.video?.data?.attributes?.url}
				alt={strapiTeacher?.poster?.data?.attributes?.name || 'strapiTeacher'}
				timeLine='0:59'
				variant='small'
			/>
			<div className={'TeacherCard__info'}>
				<div className={'TeacherCard__mobileHeader'}>
					<p
						className={'TeacherCard__title'}
					>
						{strapiTeacher?.name}
					</p>
				</div>
				{strapiTeacher?.achievements?.length !== 0 &&
          <List className={
          	classNames('TeacherCard__resultsList', { displayNone: !!strapiTeacher?.achievements?.length })}
          >
          	{strapiTeacher?.achievements?.map(listItem =>
          		<ListItem
          			key={listItem?.id}
          			disablePadding
          			className={'TeacherCard__resultsItem'}
          		>
          			<span
          				className={'TeacherCard__resultsItemTitle'}
          			>
          				{listItem?.title}
          			</span>
          			<span
          				className={'TeacherCard__resultsItemText'}
          			>
          				{listItem?.subTitle}
          			</span>
          		</ListItem>
          	)}
          </List>
				}
				{strapiTeacher?.quote &&
          <TeacherCardQuote
          	color={(strapiTeacher.quote?.color || 'bright-mint') as QuoteColorType} className={'TeacherCard__quote'}
          >
          	{strapiTeacher.quote.text}
          </TeacherCardQuote>
				}
				<div className={'TeacherCard__socialLinks'}>
					{strapiTeacher?.socials?.map(social =>
						<SocialButton
							key={social?.id}
							href={social?.href || '#'} icon={social?.icon}
							className={'TeacherCard__socialLinksItem'}
							size='large'
							color='secondary'
							isTargetBlank
						/>
					)}
					{strapiTeacher?.moreInfoLink &&
            <Button
            	variant='contained'
            	color='primary'
            	className={'TeacherCard__button'}
            	href={strapiTeacher.moreInfoLink || ''}
            	target='_blank'
            >
              подробнее <br /> обо мне
            	<ArrowForwardIcon fontSize='large' />
            </Button>
					}
				</div>
			</div>
		</div >
	);
};
