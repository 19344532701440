import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export default (props: InputProps) => {
	const { className = '' } = props;
	const theme = localStorage.getItem('theme');
	return (
		<TextField
			{...props}
			className={className}
			// InputProps={{ endAdornment: icon, inputProps: { min: 0 } }}
			fullWidth
			sx={{
				'& .MuiInputBase-root': {
					height: '100%'
				}
			}}
			inputProps={{
				className: theme === 'dark' ? 'date-dark' : ''
			}}
		/>
	);
};

type InputProps = TextFieldProps & {
	icon?: React.ReactNode,
}
