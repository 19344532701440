import { IStrapiTeacher } from '@interfaces';
import { UserService } from '@services';
import { makeAutoObservable } from 'mobx';

class GeneralStore {
  alert: boolean = false;
  alertStatus: 'warning' | 'success' | undefined = 'success';
  alertMessage: string = 'Сохранено';
	theme: 'light' | 'dark' = 'light';
	currentTeacher: ICurrentTeacherModal = {} as ICurrentTeacherModal
	isOpenTeacherModal: boolean = false;
	teachers: IStrapiTeacher[] = [];
	isLoadingStrapiTeachers: boolean = false;

	constructor () {
  	makeAutoObservable(this);
	}

	async getStrapiTeachers () {
		try {
			this.isLoadingStrapiTeachers = true;
			const { data } = await UserService.getStrapiTeachers();
			this.teachers = data.data.attributes.teachers;
		} catch (error) {
			console.log(error);
		} finally {
			this.isLoadingStrapiTeachers = false;
		}
	}

	// Изменение состояния алёрта
	changeAlertState (status: 'warning' | 'success' = 'success', text?: string, bool?: boolean) {
  	this.alert = bool ?? !this.alert;
  	this.alertStatus = status;
  	if (!text) {
  		status === 'warning'
  			? this.alertMessage = 'Что-то пошло не так'
  			: this.alertMessage = 'Сохранено';
  	} else {
  		this.alertMessage = text;
  	}
	}

	changeTheme () {
		this.theme = themes[this.theme];
		localStorage.setItem('theme', this.theme);
	}

	setTheme () {
		this.theme = localStorage.theme ?? 'light';
	}

	setCurrentTeacher (teacher: ICurrentTeacherModal) {
		this.currentTeacher = teacher;
	}

	changeOpenTeacherModalState (bool: boolean) {
		this.isOpenTeacherModal = bool;
	}
}

const themes: any = {
	light: 'dark',
	dark: 'light'
};

interface ICurrentTeacherModal {
	name: string,
	photo: string
}

export const generalStore = new GeneralStore();
