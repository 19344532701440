import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	IGetAllUsersResponse,
	IUpdateUserResponse,
	ICreateAdminPurchaseDto,
	IGetUserPurchasesItemResponse,
	IGetUserPurchasesResponse,
	IGetUserMentorsResponse,
	IGetUserReferralsResponse,
	ICreateUserDto,
	IGetAllUsersItemResponse
} from '@lomonosov/types';
import { IUpdateReferral, IUser } from '@interfaces';

export default class {
	static async getUsers (page, pageSize, search): Promise<AxiosResponse<IGetAllUsersResponse>> {
		return $api.get<IGetAllUsersResponse>(`/users?skip=${(page * pageSize)}&search=${search}&take=${pageSize}`);
	}

	static async getAdmins (page, pageSize): Promise<AxiosResponse<IGetAllUsersResponse>> {
		return $api.get<IGetAllUsersResponse>(`/users/admins?skip=${(page * pageSize)}&take=${pageSize}`);
	}

	static async getUserById (userId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/users/${userId}`);
	}

	static async createUser (newUser: ICreateUserDto): Promise<AxiosResponse<IGetAllUsersItemResponse>> {
		return $api.post<any>('/users', newUser);
	}

	static async updateUser (userId: string, user: any): Promise<AxiosResponse<IUser>> {
		return $api.put<IUser>(`/users/${userId}`, user);
	}

	static async deleteUser (userId: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/users/${userId}`);
	}

	static async getUserPurchases (userId: string): Promise<AxiosResponse<IGetUserPurchasesResponse>> {
		return $api.get<IGetUserPurchasesResponse>(`users/${userId}/purchases`);
	}

	static async getMotivationCourses (userId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/users/${userId}/settings`);
	}

	static async createUserPurchase (purchase: ICreateAdminPurchaseDto): Promise<AxiosResponse<IGetUserPurchasesItemResponse>> {
		return $api.post<IGetUserPurchasesItemResponse>('/purchases', purchase);
	}

	static async updateUserPurchases (userId: string, purchases): Promise<AxiosResponse<any>> {
		return $api.put<any>(`users/${userId}/purchases`, purchases);
	}

	static async deleteUserPurchase (purchaseId: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/purchases/${purchaseId}`);
	}

	static async getUserMentors (userId: string): Promise<AxiosResponse<IGetUserMentorsResponse>> {
		return $api.get<IGetUserMentorsResponse>(`/users/${userId}/mentors`);
	}

	static async updateUserMentors (userId: string, mentors): Promise<AxiosResponse<any>> {
		return $api.put<any>(`users/${userId}`, { mentors });
	}

	static async getUserRoles (userId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/users/${userId}/roles`);
	}

	static async updateUserRoles (userId: string, roles: any): Promise<AxiosResponse<any>> {
		return $api.put<any>(`users/${userId}`, roles);
	}

	static async getUserReferrals (userId: string): Promise<AxiosResponse<IGetUserReferralsResponse>> {
		return $api.get<IGetUserReferralsResponse>(`/users/${userId}/referrals`);
	}

	static async updateUserReferrals ({ referralId, body }: IUpdateReferral): Promise<AxiosResponse<any>> {
		return $api.put<any>(`users/referrals/${referralId}`, body);
	}

	static async changeUserBanStatus (userId: string, banStatus: boolean) : Promise<AxiosResponse<any>> {
		return $api.put<any>(`users/${userId}`, { isBanned: banStatus });
	}

	static async changeUserHp (userId: string, hpId: string, healthCount: number) : Promise<AxiosResponse<any>> {
		return $api.put<any>(`users/${userId}/healths/${hpId}`, { healthCount });
	}

	static async getUserToken (userId: string): Promise<AxiosResponse<string>> {
		return $api.get<string>(`/users/${userId}/token`);
	}
}
