import React from 'react';
import { TextFieldProps, AutocompleteProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default (props: ISelectProps) => {
	return (
		<Autocomplete
			{...props}
			options={props.options || []}
			fullWidth
			getOptionLabel={(option) => option.name || ''}
			renderInput={params =>
				<TextField
					{...params}
					{...props.input}
				/>
			}
			isOptionEqualToValue={(option, value) =>
				option.id === value.id ? option : null
			}
		/>
	);
};

interface ISelectProps extends Omit<AutocompleteProps<any, any, any, any, 'div'>, 'renderInput'>{
	input?: TextFieldProps,
}
