import React from 'react';

import { adminUserStore } from '@stores';

import './style.css';

export default (props: { user }) =>
	<div className='referral-purchases-modal'>
		<h1>{props.user?.firstName} {props.user?.secondName}</h1>
		<h3>Курсы пользователя</h3>
		{adminUserStore.purchases.map(purchase =>
			<div className='referral-purchases-modal__item' key={purchase.id}>
				<div className='referral-purchases-modal__field'>
					<b>Курс:</b> <br/>
					{purchase.course.name}
				</div>
				<div className='referral-purchases-modal__field'>
					<b>Тариф:</b> {purchase.tariff.name}
				</div>
				<div className='referral-purchases-modal__field'>
					<b>Продолжительность:</b> <br/>
					{purchase.startPeriod.toString().slice(0, 10)} - {purchase.endPeriod.toString().slice(0, 10)}
				</div>
			</div>
		)}
	</div>;
