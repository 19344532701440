import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Select } from '@components';
import { adminDisciplineStore, adminCategoryStore, adminDirectionStore, adminAnalyticsStore } from '@stores';

export default observer(() => {
	useEffect(() => {
		if (!adminDisciplineStore.disciplines?.length) {
			adminDisciplineStore.getDisciplines();
		}
		if (!adminCategoryStore.categories?.length) {
			adminCategoryStore.getCategories();
		}
		if (!adminDirectionStore.directions?.length) {
			adminDirectionStore.getDirections();
		}
	}, []);

	const changeFilter = (value, field) => {
		adminAnalyticsStore.changeFilterField(value, field);
	};

	return (
		<div className='student-analysis__filtering'>
			<div className='student-analysis__field'>
				<Select
					input={{
						label: 'Выберите предмет'
					}}
					value={adminAnalyticsStore.filtersCourses.discipline || null}
					options={adminDisciplineStore.disciplines}
					onChange={(e, value) => changeFilter(value, 'discipline') }
				/>
			</div>
			<div className='student-analysis__field'>
				<Select
					input={{
						label: 'Выберите категорию'
					}}
					value={adminAnalyticsStore.filtersCourses.category || null}
					options={adminCategoryStore.categories}
					onChange={(e, value) => changeFilter(value, 'category') }
				/>
			</div>
			<div className='student-analysis__field'>
				<Select
					input={{
						label: 'Выберите направление'
					}}
					value={adminAnalyticsStore.filtersCourses.direction || null}
					options={adminDirectionStore.directions}
					onChange={(e, value) => changeFilter(value, 'direction') }
				/>
			</div>
			{adminAnalyticsStore.filtersCourses.isFiltered &&
        <div className='student-analysis__field'>
        	<Button type='main_orange' onClick={() => adminAnalyticsStore.resetFilterCourse()}>
						Сбросить
        	</Button>
        </div>
			}
		</div>
	);
});
