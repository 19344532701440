export const themes = {
	light: {
		background: '#F9F9F9',
		text: '#191919',
		border: 'rgba(0, 0, 0, 0.2)',
		iconHover: '#bebbbb',
		textDecoration: '#191919',
		blockBackground: '#FFF',
		blackToWhite: '#FFF',
		blueToOrange: '#8417FF',
		blueToGreen: '#8417FF',
		tableHeader: '#E7E7E7',
		tableBody: '#FFF',
		tableActiveField: 'rgba(173, 243, 239, 0.2)',
		separator: '#E6EBF7',
		buttonColor: {
			orange: '#FFC800',
			blue: '#7800FF',
			green: '#ADF000',
			black: '#191919',
			red: '#FF3364',
			white: '#FFFFFF'
		}
	},
	dark: {
		background: '#1E1E1E',
		text: '#FFF',
		border: 'rgba(100,100,100,0.2)',
		iconHover: '#30293E',
		textDecoration: '#FFF',
		blockBackground: '#191919',
		blackToWhite: '#191919',
		blueToOrange: '#FFC800',
		blueToGreen: '#ADF000',
		tableHeader: '#191919',
		tableBody: '#191919',
		tableActiveField: '#1E1E1E',
		separator: '#514961',
		buttonColor: {
			orange: '#FFC800',
			blue: '#8417FF',
			green: '#BBFF0C',
			black: '#fff',
			red: '#FF3364',
			white: '#191919'
		}
	}
};
