import { makeAutoObservable, runInAction } from 'mobx';
import {
	ICreateShopButtonDto
} from '@lomonosov/types';
import { ShopAdminService } from '@services';
import { generalStore } from '@stores';
import { ICreateAdminShopButtonResponse, IGetAdminShopButtonsItemResponse, IUpdateAdminShopButtonDto } from '@interfaces';

class AdminShopStore {
	constructor () {
		makeAutoObservable(this);
	}

	async getButtonsShop (id?: string): Promise<IGetAdminShopButtonsItemResponse[] | null> {
		try {
			const { data } = await ShopAdminService.getButtonsShop(id);
			return data;
		} catch (error) {
			return null;
		}
	}

	async getButtonShop (idButton: string): Promise<IGetAdminShopButtonsItemResponse[] | null> {
		try {
			const { data } = await ShopAdminService.getButtonShopById(idButton);
			return data;
		} catch (e) {
			return null;
		}
	}

	async addButton (newState: ICreateShopButtonDto): Promise<ICreateAdminShopButtonResponse | null> {
		try {
			const { data } = await ShopAdminService.createShopButton(newState);
			return data;
		} catch (e) {
			return null;
		}
	}

	async deleteButton (id: string): Promise<boolean> {
		try {
			const { data } = await ShopAdminService.deleteShopButton(id);
			return data;
		} catch (error) {
			return false;
		}
	}

	async editButton (id: string, newState: IUpdateAdminShopButtonDto): Promise<ICreateAdminShopButtonResponse | null> {
		try {
			const { data } = await ShopAdminService.editShopButton(newState, id);
			runInAction(() => {
				generalStore.changeAlertState('success');
			});
			return data;
		} catch (error) {
			return null;
		}
	}

	async editOrderButtons (buttons: { id: string, order: number }[]): Promise<boolean | null> {
		try {
			const { data } = await ShopAdminService.editShopButtons({ entities: buttons });
			if (data) {
				generalStore.changeAlertState('success', 'Сортировка сохранена');
			}
			return data;
		} catch (e) {
			return null;
		}
	}
}

export const adminShopStore = new AdminShopStore();
