import React, { useState, useEffect } from 'react';
import { v4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import { Input, Button } from '@components';
import Table, { IColumn } from '@components/table';
import {
	ICreateTariffDto
} from '@lomonosov/types';
import { adminTariffPlansStore, generalStore } from '@stores';
import { DetailTablePeriod } from '../DetailTablePeriod/DetailTablePeriod';

interface IProps {
	actions: {
		addPeriod: (idTariff: string, state: any) => void
		editPeriod: (idTariff: string, id: string, state: any) => void
		deletePeriod: (idTariff: string, id: string) => void
		changeDescription: (idTariff: string, str: string) => void
		changeSubTitle: (idTariff: string, str: string) => void,
		saveTariff: () => void,
		setCurrentTariff: (tariff: ICreateTariffDto | null) => void,
	}
	dataL: any
}

export default observer((props: IProps) => {
	const {
		actions,
		dataL
	} = props;

	const [description, setDescription] = useState(dataL.description ? dataL.description : '');
	const [subTitle, setSubTitle] = useState(dataL.subtitle ? dataL.subtitle : '');
	const [dataPeriod, setDataPeriod] = useState<any[]>(dataL.periods ? dataL.periods : []);
	const [isChanged, setIsChanged] = useState<boolean>(false);

	useEffect(() => {
		adminTariffPlansStore.changeCurrentTariff(dataL);
	}, []);

	const addPeriod = (state: any) => {
		if (state.begins < state.ends) {
			const id = v4();
			setDataPeriod([...dataPeriod, { ...state, id, description: state.description || '', consist: [] }]);
			setIsChanged(true);
			actions.addPeriod(dataL.id, { ...state, id, description: state.description || '', consist: [] });
		} else {
			generalStore.changeAlertState('warning', 'Некорректные даты');
		}
	};

	const editPeriod = (id: string, state: any) => {
		if (state.begins < state.ends) {
			const i = dataPeriod.findIndex((t) => t.id === id);
			dataPeriod[i] = state;
			setDataPeriod([...dataPeriod]);
			setIsChanged(true);
			actions.editPeriod(dataL.id, id, state);
		} else {
			generalStore.changeAlertState('warning', 'Некорректные даты');
		}
	};

	const deletePeriod = (id: string) => {
		setDataPeriod(dataPeriod.filter((t) => t.id !== id));
		setIsChanged(true);
		actions.deletePeriod(dataL.id, id);
	};

	const handleClick = () => {
		actions.saveTariff();
		setIsChanged(false);
	};

	const changeDetailPeriod = (id: string, state: any) => {
		const i = dataPeriod.findIndex((t) => t.id === id);
		dataPeriod[i] = { ...dataPeriod[i], ...state };
		setDataPeriod([...dataPeriod]);
		actions.saveTariff();
		setIsChanged(false);
	};

	return (
		<>
			<Input
				label='Подзаголовок'
				disabled={!actions}
				value={subTitle}
				onChange={(e) => {
					actions.changeSubTitle(dataL.id, e.target.value);
					setSubTitle(e.target.value);
					setIsChanged(true);
				}}
			/>
			<Input
				style={{ marginTop: '10px' }}
				label='Описание'
				disabled={!actions}
				multiline
				maxRows={10}
				rows={5}
				value={description}
				onChange={(e) => {
					actions.changeDescription(dataL.id, e.target.value);
					setDescription(e.target.value);
					setIsChanged(true);
				}}
			/>
			{actions
				? <Table
					title='Периоды'
					columns={columnsPeriods}
					actionRow={{
						add: addPeriod,
						edit: editPeriod,
						delete: deletePeriod
					}}
					data={dataPeriod}
					styleOption={{
						size: 'small'
					}}
					detailPanel={{
						Component: (props) =>
							<DetailTablePeriod
								{...props}
								actions={{
									changeDetailPeriod
								}}
							/>,
						autoClose: true
					}}
					className='tarifPlan__table-detailPanel-tariffs'
				/>
				: <Table
					title='Периоды'
					columns={columnsPeriods}
					data={dataPeriod}
					styleOption={{
						size: 'small'
					}}
					className='tarifPlan__table-detailPanel-tariffs'
				/>
			}
			<div className='tarifPlan__modal-button'>
				<Button
					onClick={handleClick}
					type='main_green'
					disable={!isChanged}
				>
					Сохранить
				</Button>
			</div>
		</>
	);
});

const columnsPeriods: IColumn[] = [
	{ field: 'isActive', name: 'Акт.', type: 'boolean', width: 30, defaultValue: true },
	{ field: 'isRecommended', name: 'Ркм.', type: 'boolean', width: 30, defaultValue: false },
	{ field: 'name', name: 'Продолжительность' },
	{ field: 'price', name: 'Цена', typeInput: 'number', width: 100 },
	{ field: 'begins', name: 'Дата начала', type: 'date', width: 100 },
	{ field: 'ends', name: 'Дата конца', type: 'date', width: 100 }
];
