import { AxiosResponse } from 'axios';
import { IDeleteMentorResponse, ICreateMentorResponse, IGetAllMentorsResponse } from '@lomonosov/types';
import { $api } from '@helpers';

export default class {
	static async getMentors (take: number, skip: number, search: string): Promise<AxiosResponse<IGetAllMentorsResponse>> {
		return $api.get<IGetAllMentorsResponse>(`/mentors?take=${take}&skip=${skip}&search=${search}`);
	}

	static async getMentorStudents (take: number, skip: number, mentorId: string, search: string): Promise<AxiosResponse<any>> {
		return $api.get<any>('/mentors/students', {
			params: { take, skip, mentorId, search: search || undefined }
		});
	}

	static async createMentor (userId: string): Promise<AxiosResponse<ICreateMentorResponse>> {
		return $api.post<ICreateMentorResponse>('/mentors', { originalUserId: userId });
	}

	static async deleteMentor (mentorId: string): Promise<AxiosResponse<IDeleteMentorResponse>> {
		return $api.delete<IDeleteMentorResponse>(`/mentors/${mentorId}`);
	}
}
