import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, DeleteModal } from '@components';
import { adminCategoryStore } from '@stores';

export default observer(() => {
	const [deletableCategoryId, setDeletableCategoryId] = useState<string>('');

	useEffect(() => {
		if (!adminCategoryStore.categories.length) {
			adminCategoryStore.getCategories();
		}
	}, []);

	const deleteCategory = () => {
		adminCategoryStore.deleteCategory(deletableCategoryId).finally(() => setDeletableCategoryId(''));
	};

	return (
		<>
			<Table
				columns={[
					{ field: 'id', name: 'id', copy: true, editable: false },
					{ field: 'name', name: 'Название' }
				]}
				data={adminCategoryStore.categories}
				search pagination
				actionRow={{
					edit: (id, newState) => adminCategoryStore.updateCategory(id, newState),
					add: (newState) => adminCategoryStore.createCategory(newState),
					delete: (id) => setDeletableCategoryId(id)
				}}
			/>
			<DeleteModal
				deleteName={adminCategoryStore.categories.find(category => category.id === deletableCategoryId)?.name}
				open={!!deletableCategoryId}
				action={deleteCategory}
				handleClose={() => setDeletableCategoryId('')}
			/>
		</>
	);
});
