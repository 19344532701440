import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { HomeworkCardDeadline } from './homework-card-components';
import { PortalCoursesCardAdvantages } from '../../../../portal-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { constants, images, truncateText } from '@helpers';
import { getTextTypeHomework } from '@pages/portal/portal-utils';
import { IUserHomework } from '@interfaces';

import './style.css';

interface IHomeworkCardProps {
	homework: IUserHomework;
}

export default (props: IHomeworkCardProps) => {
	const { homework } = props;

	const advantages = [
		homework.template.discipline.name,
		getTextTypeHomework(homework.template.type)
	];

	return (
		<div className='homework-card theme-block-background'>
			<img
				className='homework-card__preview'
				src={
					homework.lesson.section.course.preview?.length
						? `${constants.URL}/files/${homework.lesson.section.course.preview[0].id}`
						: images.defaultCourse
				}
				alt='teacher photo'
			/>
			<div className='homework-card__title-block'>
				<PortalCoursesCardAdvantages advantages={advantages} className='homework-card__advantages'/>
				<h2 className='homework-card__title'>{truncateText(homework.template.name, 60)}</h2>
			</div>
			<HomeworkCardDeadline ends={homework.ends}/>
			<Link
				className='homework-card__button'
				to={`/homework/${homework?.shortId}`}
			>
				<Button
					color='primary'
					variant='contained'
				>
					Решать&nbsp;&nbsp;<ArrowForwardIcon fontSize='medium'/>
				</Button>
			</Link>
		</div>
	);
};
