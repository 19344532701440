import { AxiosResponse } from 'axios';
import { $api } from '@helpers';

export default class {
	static async getLinks (): Promise<AxiosResponse<any>> {
		return $api.get<any>('/links');
	}

	static async createLink (newLink: {
		link: string;
		description: string;
	}): Promise<AxiosResponse<any>> {
		return $api.post<any>('/links', newLink);
	}

	static async updateLink (
		id: string,
		newData: { description: string }
	): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/links/${id}`, newData);
	}

	static async deleteLink (id: string): Promise<AxiosResponse<any>> {
		return $api.delete<any>(`/links/${id}`);
	}
}
