import { makeAutoObservable } from 'mobx';
import {
	AdminEssaysService, EssayStatusCheck, ICheckEssayDto, ICreateEssayDto,
	IGetAllEssayResponse, IGetEssayResponse
} from '@services';
import { generalStore } from '../general-stores';

class AdminEssaysStore {
	essays: IGetAllEssayResponse = { items: [], count: 0 };
	essay: IGetEssayResponse | null | { file: null } = { file: null };
	checkEssays: INormalizeDataCheckEssay = { items: [], count: 0 };
	statusCheck: EssayStatusCheck = 'verification'

	constructor () {
		makeAutoObservable(this);
	}

	handleChangeFieldEssay (value, field: keyof IGetEssayResponse) {
		if (this.essay) {
			this.essay[field] = value;
		}
	}

	handleChangeStatusCheck (status: EssayStatusCheck) {
		this.statusCheck = status;
		this.getCheckEssays(5, 0, status);
	}

	clearCurrentEssay () {
		this.essay = { file: null };
	}

	async getEssays (take: number, skip: number) {
		try {
			const { data } = await AdminEssaysService.getEssays(take, skip);
			this.essays = data;
		} catch (e) {
			console.log(e);
		}
	}

	async getEssaysById (essayId: string) {
		try {
			const { data } = await AdminEssaysService.getEssaysById(essayId);
			if (data) {
				this.essay = data;
			}
		} catch (e) {
			console.log(e);
		}
	}

	async createEssay (essayDto: ICreateEssayDto) {
		try {
			const { data } = await AdminEssaysService.createEssay(essayDto);
			this.essays.items.push(data);
			generalStore.changeAlertState('success', 'Сочинение создано');
		} catch (e) {
			console.log(e);
		}
	}

	async updateEssay (essayId: string, updatedEssay: Partial<ICreateEssayDto>) {
		try {
			const { data } = await AdminEssaysService.updateEssay(essayId, updatedEssay);
			const index = this.essays.items.findIndex(essay => essay.id === data.id);
			this.essays[index] = data;
			generalStore.changeAlertState('success', 'Сочинение обновлено');
		} catch (e) {
			console.log(e);
		}
	}

	async deleteEssay (essayId) {
		try {
			await AdminEssaysService.deleteEssay(essayId);
			this.essays.items = this.essays.items.filter(essay => essay.id !== essayId);
		} catch (e) {
			console.log(e);
		}
	}

	async getCheckEssays (take: number, skip: number, status?: EssayStatusCheck) {
		try {
			const { data } = await AdminEssaysService.getCheckEssays(take, skip, status);
			this.checkEssays = {
				items: data.items.map(essay => ({
					id: essay.id,
					begins: essay.createdAt,
					ends: essay.literaryText.ends,
					file: essay.literaryText.file,
					text: essay.literaryText.text,
					mentorFile: essay.mentorFile,
					type: essay.literaryText.type === 'final' ? 'Итоговое' : 'Обычное',
					userFile: essay.userFile,
					userName: `${essay.user.firstName} ${essay.user.secondName}`,
					purchasesType: essay.purchaseType === 'extended' ? 'Подробная проверка' : 'Проверка без комментариев'
				} as INormalizeDataCheckEssayItem)),
				count: data.count
			};
		} catch (error) {
			console.log(error);
		}
	}

	async updateCheckEssay (checkEssayDto: ICheckEssayDto, essayId: string) {
		try {
			await AdminEssaysService.checkEssay(checkEssayDto, essayId);
			await this.getCheckEssays(5, 0, 'verification');
			generalStore.changeAlertState('success', 'Сочинение проверенно');
		} catch (error) {
			console.log(error);
		}
	}

	changeFileCheckEssay (file: { id: string } | null, essayId) {
		const index = this.checkEssays.items.findIndex(essay => essay.id === essayId);
		this.checkEssays.items[index].mentorFile = file;
	}
}

export interface INormalizeDataCheckEssayItem {
	id: string;
	text: string;
	type: string;
	file: {
		id: string;
	};
	begins: Date;
	ends: string;
	userName: string;
	purchasesType: string;
	userFile: {
		id: string;
	};
	mentorFile: {
		id: string;
	} | null;
}

interface INormalizeDataCheckEssay {
	items: INormalizeDataCheckEssayItem[];
	count: number;
}

export const adminEssaysStore = new AdminEssaysStore();
