import React, { useEffect, useState } from 'react';
import { debounce } from '@mui/material/utils';
import { observer } from 'mobx-react-lite';

import { adminShopStore, adminDirectionStore, adminCategoryStore, generalStore } from '@stores';
import Table, { IColumn } from '@components/table';
import { DetailMainButtons } from './components';
import { IGetAdminShopButtonsItemResponse, IUpdateAdminShopButtonDto } from '@interfaces';

import './style.css';
import { Avatar } from '@mui/material';
import { constants } from '@helpers';

export default observer(() => {
	const [data, setData] = useState<IGetAdminShopButtonsItemResponse[]>([]);

	useEffect(() => {
		if (!adminDirectionStore.directions.length) {
			adminDirectionStore.getDirections();
		}
		getButton();
	}, []);

	const getButton = async () => {
		const res = await adminShopStore.getButtonsShop();
		if (res) { setData(res); }
	};

	const addButton = async (newState: any) => {
		const res = await adminShopStore.addButton({ ...newState, order: data.length });
		if (res) { setData([...data, res]); }
	};

	const deleteButton = async (id: string) => {
		const res = await adminShopStore.deleteButton(id);
		if (res) {
			const index = data.findIndex((d) => d.id === id);
			data.splice(index, 1);
			setData([...data]);
		}
	};

	const editButton = async (id: string, newState: IUpdateAdminShopButtonDto) => {
		const res = await adminShopStore.editButton(id, newState);
		if (res) {
			const index = data.findIndex((d) => d.id === id);
			data[index] = res;
			setData([...data]);
		}
	};

	const shopButtonsOptions: IColumn[] = [
		{ field: 'isActive', name: 'Активен', type: 'boolean', defaultValue: false, width: 80, align: 'center' },
		{ field: 'trial', name: 'Бесплатный', type: 'boolean', defaultValue: false, width: 80, align: 'center' },
		{
			field: 'previewId',
			name: 'Изображение',
			type: 'element',
			element: (data) => (
				data.id &&
				<Avatar alt={data.name} src={`${constants.URL}/files/${data.previewId}`} className='admin-main-shop__image'/>
			),
			align: 'center'
		},
		{ field: 'name', name: 'Надпись на кнопке' },
		{ field: 'direction', name: 'Направление', options: adminDirectionStore.directions, type: 'select' }
	];

	const openDetailRow = async (id: string) => {
		let categories;
		const infoRow = await adminShopStore.getButtonsShop(id);
		if (!adminCategoryStore.categories.length) { categories = await adminCategoryStore.getCategories(); } else { categories = adminCategoryStore.categories; }

		const onUpdatePreview = async (fileId: string) => {
			const res = await adminShopStore.editButton(id, { previewId: fileId });
			if (res) {
				generalStore.changeAlertState('success', 'Изображение обновлено');
				const index = data.findIndex((d) => d.id === id);
				data[index] = { ...data[index], previewId: fileId };
				setData([...data]);
			}
		};

		return {
			categories: categories || [],
			buttons: infoRow,
			onUploadPreview: onUpdatePreview
		};
	};

	const swapRow = debounce(async (swapItems: IGetAdminShopButtonsItemResponse[]) => {
		const orderingButtons = swapItems.map((button, i) => ({ id: button.id, order: i }));
		adminShopStore.editOrderButtons(orderingButtons);
	}, 1000);

	return (
		<>
			<Table
				swap={swapRow}
				title='Кнопки магазина'
				columns={shopButtonsOptions}
				data={data}
				actionRow={{
					add: addButton,
					edit: editButton,
					delete: deleteButton
				}}
				detailPanel={{
					Component: DetailMainButtons,
					action: openDetailRow
				}}
			/>
		</>
	);
});
