import React from 'react';
import { observer } from 'mobx-react-lite';

import { CustomModal } from '..';
import { Button } from '@mui/material';

import './style.css';

interface deleteModalProps {
	open: boolean,
	handleClose?: (event: React.MouseEvent<HTMLButtonElement>) => void,
	children?: React.ReactNode,
	deleteName?: string,
	action: () => void
}

export default observer((props: deleteModalProps) => {
	const { open, handleClose = () => null, action, deleteName, children = null } = props;

	return (
		<CustomModal
			open={open}
			handleClose={handleClose} heightModal='auto'
			className='delete-modal'
			textTitle={`Вы точно хотите удалить ${deleteName || ''}?`}
			classes={{
				container: 'delete-modal__container'
			}}
		>
			{children &&
			<div className='delete-modal__text'>
				{children}
			</div>
			}
			<div className='delete-modal__buttons'>
				<Button variant='contained' onClick={handleClose} className='delete-modal__button'>Вернуться</Button>
				<Button variant='contained' color='error' onClick={action} className='delete-modal__button'>Удалить</Button>
			</div>
		</CustomModal>
	);
});
