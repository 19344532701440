import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { userGamificationStore } from '@stores';

import { Car, Clothes, Face, Home } from 'lomonosov-icons';

import './style.css';

export default observer((props: any) => {
	const { onLoad } = props;
	const data = {
		head: { title: 'Сменить внешность', type: 'head' },
		body: { title: 'Купить одежду', type: 'body' },
		car: { title: 'Купить машину', type: 'car' },
		house: { title: 'Обустроить дом', type: 'house' },
		default: { title: '', type: '' }
	};

	const handleClickItem = (item) => {
		if (item.type === 'car' || userGamificationStore.shopItem.type === 'car') {
			onLoad(item.type, true);
		}
		if (userGamificationStore.shopItem.type !== item.type) {
			userGamificationStore.changeShopItem(item);
			userGamificationStore.changeIsShowShopMenu(!(item.type === 'house' || item.type === 'car'));
			userGamificationStore.changePlayerPosition('left');
		} else {
			userGamificationStore.changeShopItem(data.default);
			userGamificationStore.changeIsShowShopMenu(false);
			userGamificationStore.changePlayerPosition();
		}
	};

	const checkItemType = useCallback((type: string) => {
		return userGamificationStore.shopItem.type === type || userGamificationStore.shopItem.type === '';
	}, [userGamificationStore.shopItem.type]);

	const menu = useMemo(() => [
		{
			id: 2,
			smile: <Face/>,
			colorHomepage: '#8417FF',
			color: '#FFF',
			colorAvatar: userGamificationStore.shopItem.type === 'head' ? '#8417FF' : '#000000',
			opacity: userGamificationStore.shopItem.type === 'head' ? 1 : 0.4,
			onClick: () => handleClickItem(data.head),
			data: data.head
		},
		{
			id: 3,
			smile: <Clothes/>,
			colorHomepage: '#FFC800',
			color: checkItemType('body') ? '#000' : '#FFF',
			colorAvatar: userGamificationStore.shopItem.type === 'body' ? '#FFC800' : '#000000',
			opacity: userGamificationStore.shopItem.type === 'body' ? 1 : 0.4,
			onClick: () => handleClickItem(data.body),
			data: data.body
		},
		{
			id: 4,
			smile: <Car/>,
			colorHomepage: '#FF3364',
			color: '#FFF',
			colorAvatar: userGamificationStore.shopItem.type === 'car' ? '#FF3364' : '#000000',
			opacity: userGamificationStore.shopItem.type === 'car' ? 1 : 0.4,
			onClick: () => handleClickItem(data.car),
			data: data.car
		},
		{
			id: 5,
			smile: <Home/>,
			colorHomepage: '#342B48',
			color: '#FFF',
			colorAvatar: userGamificationStore.shopItem.type === 'house' ? '#342B48' : '#000000',
			opacity: userGamificationStore.shopItem.type === 'house' ? 1 : 0.4,
			onClick: () => handleClickItem(data.house),
			data: data.house
		}
	], [userGamificationStore.shopItem.type]);

	return (
		<div className='gamification-menu'>
			{menu.map((block, index) =>
				<div
					key={index}
					style={{
						backgroundColor:
							checkItemType(block.data.type)
								? block.colorHomepage
								: block.colorAvatar,
						opacity:
							checkItemType(block.data.type)
								? 1
								: block.opacity,
						color: block.color
					}}
					className='gamification-menu__block'
					onClick={block.onClick}
				>
					{block.smile}
				</div>
			)}
		</div>
	);
});
