import dayjs from 'dayjs';

export const replaceFormatDate = (date: Date) => {
	return dayjs(date).format('YYYY-MM-DD');
};

export const replaceFormatDateTime = (date: Date) => {
	return dayjs(date).format('YYYY-MM-DDTHH:mm');
};

export const replaceFormatDateForUsers = (date: Date) => {
	return dayjs(date).format('YYYY.MM.DD HH:mm');
};

export function convertDateFormat (dateString) {
	// Create a new Date object from the input string
	const date = new Date(dateString);

	// Extract the date and time components from the Date object
	const day = String(date.getUTCDate()).padStart(2, '0');
	const month = String(date.getUTCMonth() + 1).padStart(2, '0');
	const year = date.getUTCFullYear();
	const hours = String(date.getUTCHours()).padStart(2, '0');
	const minutes = String(date.getUTCMinutes()).padStart(2, '0');

	// Format the date and time components into the desired format
	const formattedDate = `${day}.${month}.${year}`;
	const formattedTime = `${hours}:${minutes}`;

	// Return the formatted date and time
	return `${formattedDate} ${formattedTime}`;
}

/*
	Выводит дату
	Если сегодня hh:mm
	Если в этом году DD:MM
	Если не в этом году DD.MM.YY
 */
export function displayDate (date: Date) {
	const dateNow = new Date();
	const currentDate = new Date(date);
	const convertedDate = convertDateFormat(currentDate).split(' ');
	const splittedCurrentDate = convertedDate[0].split('.');
	if (dateNow.getTime() - currentDate.getTime() < 86400000) {
		return convertedDate[1];
	}
	if (dateNow.getFullYear() === currentDate.getFullYear()) {
		return splittedCurrentDate[0] + '.' + splittedCurrentDate[1];
	} else {
		return splittedCurrentDate[0] + '.' + splittedCurrentDate[1] + '.' + splittedCurrentDate[2].substr(2, 2);
	}
}
