import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, useNavigate } from 'react-router-dom';

import { userStore } from '@stores';

import '../admin-layout/style.css';

const mentorRoutes = [
	'/admin/all-users',
	'/admin/homework-answers',
	'/admin/analytics-courses',
	'/admin/homework-answer/'
];

export default observer(() => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!userStore.isEmployee && !userStore.isAdmin) {
			navigate('/');
		} else if ((userStore.isEmployee && !userStore.isAdmin) &&
			!mentorRoutes.some(route => window.location.pathname.includes(route))) {
			navigate('/admin/homework-answers');
		}
	}, []);

	return (
		<Outlet />
	);
});
