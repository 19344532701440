import React from 'react';
import classNames from 'classnames';
import { Chip } from '@mui/material';

import './style.css';

interface ICoursesCardAdvantagesProps {
	advantages: string[];
	className?: string;
}

// TODO: изменить нейминг компонента
export default (props: ICoursesCardAdvantagesProps) => {
	const { advantages, className } = props;

	return (
		<div className={classNames('portal-courses-card-advantages', className)}>
			{advantages.filter(Boolean).map((advantage) =>
				<Chip
					key={advantage}
					label={advantage}
					variant='outlined'
				/>
			)}
		</div>
	);
};
