import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	IGetWithdrawalReferralsResponse,
	IGetWithdrawalReferralByIdResponse,
	WithdrawalStatus
} from '@lomonosov/types';

export default class {
	static async getReferrals (take: number, skip: number, search: string) {
		return $api.get(`/users/referrals?take=${take}&skip=${skip}&search=${search}`);
	}

	static async getWithdrawal (status: WithdrawalStatus, page: number = 0, pageSize: number = 100, search: string = '')
		: Promise<AxiosResponse<IGetWithdrawalReferralsResponse>> {
		return $api.get<IGetWithdrawalReferralsResponse>(`/withdrawal-referrals?status=${status}&skip=${(page * pageSize)}&search=${search}&take=${pageSize}`);
	}

	static async getWithdrawalById (withdrawalId: string): Promise<AxiosResponse<IGetWithdrawalReferralByIdResponse>> {
		return $api.get<IGetWithdrawalReferralByIdResponse>(`/withdrawal-referrals/${withdrawalId}`);
	}

	static async editWithdrawalStatus (
		withdrawalId: string,
		status: 'completed' | 'rejected'
	): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/withdrawal-referrals/${withdrawalId}`, { status });
	}
}
