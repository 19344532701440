import { regPhone, regEmail } from '../functions';

export const errorRules = {
	phone: {
		required: 'Заполните поле',
		pattern: {
			value: regPhone,
			message: 'Некорректный номер'
		},
		maxLength: {
			value: 12,
			message: 'Некорректный номер'
		},
		minLength: {
			value: 11,
			message: 'Некорректный номер'
		}
	},
	email: {
		required: 'Заполните поле',
		pattern: {
			value: regEmail,
			message: 'Некорректный Email'
		}
	},
	password: {
		required: 'Поле обязательно к заполнению',
		minLength: { value: 6, message: 'Минимальная длина пароля: 6 символов' },
		pattern: {
			value: /^[a-zA-Z0-9!@#$%^&*]{6,16}$/,
			message: 'Недопустимые символы в пароле'
		}
	},
	code: {
		trim: true,
		required: 'Введите код',
		pattern: {
			value: /[0-9]/,
			message: 'Пожалуйста введи число'
		},
		maxLength: {
			value: 4,
			message: 'Код состоит из 4 цифр'
		},
		minLength: {
			value: 4,
			message: 'Код состоит из 4 цифр'
		}
	},
	firstName: {
		required: 'Поле обязательно к заполнению',
		minLength: { value: 2, message: 'Минимум 2 символа' },
		maxLength: { value: 15, message: 'Максимум 15 символов' }
	},
	secondName: {
		required: 'Поле обязательно к заполнению',
		minLength: { value: 3, message: 'Минимум 3 символова' },
		maxLength: { value: 20, message: 'Максимум 20 символов' }
	},
	class: {
		required: 'Поле обязательно к заполнению'
	},
	login: {
		required: 'Заполните поле',
		validate: (value) => {
			return (
				[regPhone].every((pattern) => pattern.test(value))
			) || [regEmail].every((pattern) => pattern.test(value)) || 'Некорректный номер телефона или Email';
		}
	}
};
