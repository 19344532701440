import React from 'react';

const LevelIcon = () => {
	return (
		<svg width='24' height='24' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect width='48' height='48' fill='url(#pattern0)'/>
			<defs>
				<pattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'>
					<use xlinkHref='#image0_504_902' transform='scale(0.0208333)'/>
				</pattern>
				<image id='image0_504_902' width='48' height='48' xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC0UlEQVR4nO3WS08TYRgF4G/jHYXg/Yb3UP6XuDNuXBg3ggZUggreQAUN5Z/QVG6lpS29TYdpCwpiW0DaxAQXx9RYcxIz6Uu/mRBNT/LsejJ9J/O9M0o10kgj/3cMK+NPW1lUGFbGJ+3FkoY/lkzjl4Qh7plWzm9aOVSkray4ZxtjMQMm7YVjSTD59bJgSjfJtAUm7QUjMTBpL2VmwJRu4oYJJu3NBCNgbt8w2/x5jn9TwnycDYFJewljEUzpJhpPgUl7vqkAmLQXT5lgSjc7PYyj2ygOfQcGykDfJtCdB+6sALdywPU0CrX6C8k0mPYAoWgcrOYAPwC7ATrTqNmPJgww7QF2uk10B4jEU2DaAwTmF8DcHiBc5/vDNjOhCFit349so6BzBuajCTDtAabmwmDS3p57Jpi0F4zEwZRuJmdDYNLe3q5FMGlvLhwDU7rxzwTBpL399y0wt85czfim58CUMAceZMCkvdlQFEzVm8Eyiv1bQO860JUH7q4Ct5eBm1mg06x9GA/2ZMGk150ORsB0BoDdADfM2uuwqTcH5vbScHyAww+XwJQwk4F5sF0boPnxEpjbX7F/ZaCMgs4ZaOlbBlMubz3HX0it/Z/A3N56ttnXbYEpYY4++Qwm7U1MBcCUburd58eeroC5vbZt09SbBVPCnBhYBXN7adjmyKMlMCXMycEvYLs2QEud2+T0izWwXRugtc5tcublVzC317Zjh7Gas6/yYG5/xTp2GKs5P5QHk/YO9WTBlG5OPV8Dk/bahgtg0l5znZ8gjj3L1Vx8WwRz+8zZ5tzrPJgS5tK7dTBp7/izVTClm7bhIpi0d3lkA8ztR9bxO3n1/SaY29vLNldGN8CUMNc+fAOT9i68KYJJe47/kfaxLTC3Hz3H/4jHWwJz+4bZxjNe8nWMl1Dh8ZYm5L2yr2O8jAqPtyzveUu+6tDtY/LrNdJII+rfzE9KjCX50G+85AAAAABJRU5ErkJggg=='/>
			</defs>
		</svg>

	);
};

export default LevelIcon;
