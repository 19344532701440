import { makeAutoObservable, runInAction } from 'mobx';
import { generalStore } from '@stores';
import { FileService } from '@services';
import axios from 'axios';

class FileStore {
	files: any = [];
	temp: any = { key: '', url: '' };
	loading: boolean = false;

	constructor () {
		makeAutoObservable(this);
	}

	async saveFile (file, func) {
		try {
			await axios.put(this.temp.url, file, { headers: { 'Content-Type': file.type } });
			runInAction(() => {
				this.confirmFile(this.temp, func);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async generateUrlFile (file, func?) {
		try {
			this.setLoading(true);
			generalStore.changeAlertState('success', 'Идет загрузка...');
			const typeFile = otherTypesFile[file.name.split('.').slice(-1).join()] || '';
			const { data } = await FileService.generateUrlFile({ originalname: file.name, mimetype: file.type || typeFile, size: file.size });
			await runInAction(async () => {
				this.temp = data;
				await this.saveFile(file, func);
			});
		} catch (e) {
			console.log(e);
			this.setLoading(false);
		}
	}

	async confirmFile (file, func) {
		try {
			const { data } = await FileService.confirmFile(file);
			runInAction(() => {
				func(data);
			});
			generalStore.changeAlertState('success', 'Файл загружен', true);
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async uploadFile (e, func) {
		const file = e.files[0];
		await this.generateUrlFile(file, func);
	}

	async deleteFile (fileId, func) {
		try {
			await FileService.deleteFile(fileId);
			func();
		} catch (e) {
			console.log(e.response?.data?.message);
		}
	}

	/**
	 * Вставка файла из буфера обмена
	 * @param e событие из которого берем файл
	 * @param func функция которая обрабатывает добавление файла
	 */
	pasteFile (e, func) {
		if (e.clipboardData.files.length > 0) {
			e.files = e.clipboardData.files;
			func(e);
		}
	}

	setLoading (state: boolean) {
		this.loading = state;
	}
}

const otherTypesFile = {
	pages: 'application/vnd.apple.pages',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	jpg: 'image/jpeg',
	png: 'image/png',
	pdf: 'application/pdf'
};

export const fileStore = new FileStore();
