import React, { useState } from 'react';
import { AutocompleteChangeDetails, AutocompleteChangeReason, Checkbox, IconButton, Tooltip } from '@mui/material';
import { DateInput, Input, Select } from '@components';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { ICopyField } from '../../type';

interface IProps {
	isEdit: boolean
	size?: 'small' | 'medium'
}

interface IDateField {
	value: string
	onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

interface IStringField {
	value: string
	maxCharacters?: number
	copy?: ICopyField | boolean
	typeInput?: React.HTMLInputTypeAttribute
	onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

interface ISelectField {
	value: {
		id: string,
		name: string
	};
	onChangeInput?: (value: string) => void;
	onChange?: ((event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => void)
	maxCharacters?: number
	options?: any[];
	onFocus?: () => void;
	onBlur?: () => void;
	required?: boolean
}

interface IBooleanFiled {
	value: boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export const StringField = (props: IProps & IStringField) => {
	const {
		isEdit,
		size,
		value,
		maxCharacters,
		typeInput,
		onChange,
		copy
	} = props;

	const [isCopy, setCopy] = useState(false);

	const copyClick = (str: string) => {
		setCopy(true);
		navigator.clipboard.writeText(str);
		setTimeout(() => {
			setCopy(false);
		}, 1000);
	};

	return <>{
		copy
			? <Tooltip title='Скопировать'>
				<IconButton
					disabled={isEdit}
					className='theme-blue-to-orange'
					size={size}
					onClick={() => {
						copyClick(
							typeof copy === 'boolean'
								? value
								: `${copy.prefix ? copy.prefix : ''}${value}${copy.postfix ? copy.postfix : ''}`
						);
					}}
				>
					{isCopy
						? <CheckIcon style={{ marginLeft: '5px' }} />
						: <ContentCopyIcon style={{ marginLeft: '5px' }} />}
				</IconButton>
			</Tooltip>
			: isEdit
				? <Input
					type={typeInput}
					size={size}
					variant='standard'
					value={value}
					onChange={onChange}
					placeholder='Отсутствует'
				/>
				: maxCharacters
					? value && value?.length > maxCharacters ? value.substring(0, maxCharacters) + '...' : value ?? 'Отсутствует'
					: value ?? 'Отсутствует'
	}</>;
};

export const SelectField = (props: IProps & ISelectField) => {
	const {
		isEdit,
		size,
		value,
		maxCharacters,
		onChange,
		onChangeInput,
		options,
		onBlur,
		onFocus
	} = props;

	return <>{
		isEdit
			? options &&
			<Select
				onBlur={onBlur}
				onFocus={onFocus}
				value={value}
				onChange={onChange}
				options={options}
				input={{
					variant: 'standard',
					size: size,
					onChange: (e) => onChangeInput?.(e.target.value)
				}}
			/>
			: value?.name &&
				maxCharacters
				? value?.name && value?.name?.length > maxCharacters ? value?.name.substring(0, maxCharacters) + '...' : value?.name
				: value?.name
	}</>;
};

export const BooleanFiled = (props: IProps & IBooleanFiled) => {
	const {
		isEdit,
		size,
		value,
		onChange
	} = props;
	return <>{
		isEdit
			? <Checkbox
				value={value}
				checked={value}
				size={size}
				onChange={onChange}
			/>
			: value ? <CheckCircleOutlineOutlinedIcon /> : <CancelOutlinedIcon />
	}</>;
};

export const DateField = (props: IProps & IDateField) => {
	const {
		isEdit,
		size,
		value,
		onChange
	} = props;

	return <>{
		isEdit
			? <DateInput
				variant='standard'
				size={size}
				value={value}
				onChange={onChange}
				type='date'
			/>
			: value
	}</>;
};
