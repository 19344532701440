import { EditorConfig } from '@editorjs/editorjs';

export const INTERNATIONAL_CONFIG: EditorConfig['i18n'] = {
	messages: {
		/**
     * Other below: translation of different UI components of the editor.js core
     */
		ui: {
			blockTunes: {
				toggler: {
					'Click to tune': 'Нажмите, чтобы настроить',
					'or drag to move': 'или перетащите'
				}
			},
			inlineToolbar: {
				converter: {
					'Convert to': 'Конвертировать в'
				}
			},
			toolbar: {
				toolbox: {
					Add: 'Добавить'
				}
			}
		},

		/**
     * Section for translation Tool Names: both block and inline tools
     */
		toolNames: {
			Text: 'Параграф',
			Heading: 'Заголовок',
			List: 'Список',
			Warning: 'Примечание',
			Checklist: 'Чеклист',
			Quote: 'Цитата',
			Code: 'Код',
			Delimiter: 'Разделитель',
			'Raw HTML': 'HTML-фрагмент',
			Table: 'Таблица',
			Link: 'Ссылка',
			Marker: 'Маркер',
			Bold: 'Полужирный',
			Italic: 'Курсив',
			InlineCode: 'Моноширинный',
			CodeFlask: 'Вставить код',
			Hyperlink: 'Гиперссылка'
		},
		tools: {
			list: {
				Unordered: 'Маркированный',
				Ordered: 'Нумерованный'
			},
			header: {
				'Heading 1': 'Заголовок 1',
				'Heading 2': 'Заголовок 2',
				'Heading 3': 'Заголовок 3',
				'Heading 4': 'Заголовок 4',
				'Heading 5': 'Заголовок 5',
				'Heading 6': 'Заголовок 6'
			},
			table: {
				'Add column to left': 'Добавить столбец слева',
				'Add column to right': 'Добавить столбец справа',
				'Delete column': 'Удалить столбец',
				'Add row above': 'Добавьте строку выше',
				'Add row below': 'Добавьте строку ниже',
				'Delete row': 'Удалить строку',
				Heading: 'Заголовок',
				'With headings': 'С заголовками',
				'Without headings': 'Без заголовков'
			},
			link: {
				'Add a link': 'Добавить ссылку'
			},
			hyperlink: {
				Save: 'Создать гиперссылку'
			}
		},

		blockTunes: {
			delete: {
				Delete: 'Удалить'
			},
			moveUp: {
				'Move up': 'Переместить вверх'
			},
			moveDown: {
				'Move down': 'Переместить вниз'
			}
		}
	}
};
