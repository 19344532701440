import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Table, DeleteModal } from '@components';
import { adminTeacherStore } from '@stores';
import { TeacherInfo } from './teachers-components';

export default observer(() => {
	const [deletableTeacherId, setDeletableTeacherId] = useState<string>('');
	const [page, setPage] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(5);
	const [search, setSearch] = useState<string>('');
	const [timer, setTimer] = useState<any>();

	useEffect(() => {
		if (!adminTeacherStore.teachers.length) {
			adminTeacherStore.getTeachers(page, pageSize, search);
		}
	}, []);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		adminTeacherStore.getTeachers(newPage, pageSize, search);
	};

	const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(parseInt(event.target.value, 10));
	};

	const onSearchChange = (newSearch: string) => {
		setPage(0);
		setSearch(newSearch);
		clearTimeout(timer);
		setTimer(setTimeout(() => adminTeacherStore.getTeachers(page, pageSize, newSearch), 1000));
	};

	const deleteTeacher = () => {
		adminTeacherStore.deleteTeacher(deletableTeacherId).finally(() => setDeletableTeacherId(''));
	};

	return (
		<>
			<Table
				columns={[{ field: 'name', name: 'ФИО' }]}
				data={adminTeacherStore.teachers}
				search={{
					prop: search,
					action: onSearchChange
				}}
				pagination={{
					page: page,
					onPageChange: handleChangePage,
					rowsPerPage: pageSize,
					onRowsPerPageChange: handleChangePageSize,
					count: adminTeacherStore.totalCount
				}}
				detailPanel={{
					Component: TeacherInfo
				}}
				actionRow={{
					delete: id => setDeletableTeacherId(id)
				}}
			/>
			<DeleteModal
				deleteName={adminTeacherStore.teachers.find(teacher => teacher.id === deletableTeacherId)?.name}
				open={!!deletableTeacherId}
				handleClose={() => setDeletableTeacherId('')}
				action={deleteTeacher}
			/>
		</>
	);
});
