import React, { ReactNode } from 'react';
import { IconButton, Popper } from '@material-ui/core';
import './SelectIcon.css';

interface SelectIconProps {
	anchorEl: Element | null;
	onSelect: (key: string) => void;
	icons: Record<string, ReactNode>;
}

export const SelectIcon = (props: SelectIconProps) => {
	const { anchorEl, onSelect, icons } = props;

	return (
		<Popper
			id={'simple-popover'}
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			style={{ zIndex: 100 }}
		>
			<div className='selectIcon theme-block-background'>
				{Object.keys(icons).map((key) => (
					<IconButton
						key={key}
						onClick={() => onSelect(key)}
						className='theme-text'
					>
						{icons[key]}
					</IconButton>
				))}
			</div>
		</Popper>
	);
};
