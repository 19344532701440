import React, { useEffect, useState } from 'react';
import { SortableHandle, SortableElement } from 'react-sortable-hoc';
import {
	TableRow,
	TableCell,
	IconButton,
	Tooltip,
	Collapse,
	Box,
	ButtonGroup,
	Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import {
	IColumn,
	IDetailPanel,
	IActionRow,
	ICustomCellRender,
	styleOption,
	ISwapRow
} from '../../type';
/// ///////////////////////////////////////////////
import { BooleanFiled, DateField, SelectField, StringField } from '../inputs';

interface IProps {
	detailPanel?: IDetailPanel
	activeDetailPanel: [string | null, (s: string | null) => void]
	columns: IColumn[];
	colSpan: number,
	data?: any
	actionRow?: IActionRow
	isCreate?: boolean,
	customCellsRender?: ICustomCellRender[]
	styleOption: styleOption
	swap: ISwapRow | boolean
	swapRow?: (a: number, b: number) => void
	count?: number
	order: number
	draggable?: boolean
}

export default SortableElement((props: IProps) => {
	const {
		detailPanel,
		columns,
		actionRow,
		data,
		draggable,
		colSpan,
		isCreate = false,
		customCellsRender,
		activeDetailPanel,
		styleOption,
		swap,
		swapRow,
		count,
		order
	} = props;

	const id = data?.id;

	const [open, setOpen] = useState<boolean>(false);
	const [load, setLoad] = useState<boolean>(false);
	const [isEdit, setEdit] = useState(false);
	const [values, setValues] = useState({});
	const [dataForDetailPanel, setDataForDetailPanel] = useState<any | null>();
	const [widthActions, setWidth] = useState(48);

	useEffect(() => {
		if (activeDetailPanel[0] !== id && detailPanel?.autoClose) { setOpen(false); }
	}, [activeDetailPanel[0]]);

	useEffect(() => {
		if (data) { setValues(JSON.parse(JSON.stringify(data))); }
		if (isCreate) {
			setEdit(true);
		}

		const w = styleOption.size === 'small' ? 34 : 40;

		let width = 0;
		if (actionRow?.delete) width = w;
		if (actionRow?.edit) width = w * 2;
		if (actionRow?.custom) width += w;
		setWidth(width);
	}, [data]);

	const handleChangeCollapse = async (v: boolean) => {
		if (v && detailPanel?.action) {
			setLoad(true);
			setDataForDetailPanel(await detailPanel?.action(id, data));
			activeDetailPanel[1](id);
			setLoad(false);
		} else {
			activeDetailPanel[1](null);
		}
		setOpen(v);
	};

	const onDelete = () => {
		if (actionRow?.delete) actionRow.delete(id, data);
	};

	const onEdit = () => {
		setEdit(true);
	};

	const onSave = () => {
		setEdit(false);

		if (actionRow?.edit) {
			actionRow.edit(id, values);
		}
	};

	const onCancel = () => {
		if (isCreate) {
			if (actionRow?.delete) actionRow.delete(id);
		} else {
			setValues(JSON.parse(JSON.stringify(data)));
		}

		setEdit(false);
	};

	const onCustom = () => {
		if (actionRow?.custom) actionRow.custom.action(id, data);
	};

	return (
		<>
			<TableRow className={styleOption.classNameRow}>
				{draggable &&
					<TableCell width={styleOption.size === 'small' ? 34 : 40}>
						<DragHandle />
					</TableCell>
				}
				{detailPanel && !isCreate &&
					<TableCell width={styleOption.size === 'small' ? 34 : 40}>
						<IconButton size={styleOption.size} className='theme-blue-to-orange' onClick={() => handleChangeCollapse(!open)} disabled={load}>
							{
								load
									? <LoopSharpIcon />
									: open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
				}
				{
					swap && !isCreate &&
					<TableCell width={styleOption.size === 'small' ? 34 : 40}>
						<ButtonGroup
							variant='text'
							orientation='vertical'
							size='small'
							className='table__row-swap-buttons'
						>
							<Button
								disabled={order === 0}
								onClick={() => {
									if (swapRow && order >= 0) { swapRow(order, order - 1); }
								}}
								className='table__row-swap-buttons-button'
							>
								&and;
							</Button>
							<Button
								disabled={count === order + 1}
								onClick={() => {
									if (swapRow && order >= 0) { swapRow(order, order + 1); }
								}}
								className='table__row-swap-buttons-button'
							>
								&or;
							</Button>
						</ButtonGroup>
					</TableCell>
				}
				{detailPanel && isCreate && <TableCell></TableCell>}
				{swap && isCreate && <TableCell></TableCell>}
				{columns.map(({
					field,
					editable = true,
					options,
					type = 'string',
					copy = false,
					maxCharacters,
					align = 'left',
					className = '',
					onChangeSelect,
					onChangeInput,
					onBlur,
					onFocus,
					typeInput,
					element
				}, columnIndex) => {
					return (<TableCell className={className} key={columnIndex} align={align}>
						{
							type === 'string' &&
							<StringField
								typeInput={typeInput}
								isEdit={isEdit && editable}
								size={styleOption.size}
								value={values[field]}
								copy={copy}
								maxCharacters={maxCharacters}
								onChange={(e) => {
									values[field] = e.target.value;
									setValues({ ...values });
									onChangeInput?.(e.target.value);
								}}
							/>
						}
						{
							type === 'select' &&
							<SelectField
								isEdit={isEdit && editable}
								value={values[field]}
								options={options}
								maxCharacters={maxCharacters}
								onChange={(e, value) => {
									values[field] = value;
									setValues({ ...values });
									onChangeSelect?.(value);
								}}
								onChangeInput={(value) => onChangeInput?.(value)}
								onBlur={onBlur}
								onFocus={onFocus}
							/>
						}
						{
							type === 'boolean' &&
							<BooleanFiled
								isEdit={isEdit && editable}
								value={values[field]}
								size={styleOption.size}
								onChange={(e) => {
									values[field] = e.target.checked;
									setValues({ ...values });
								}}
							/>
						}
						{
							type === 'date' &&
							<DateField
								isEdit={isEdit && editable}
								size={styleOption.size}
								value={values[field]}
								onChange={(e) => {
									values[field] = e.target.value;
									setValues({ ...values });
								}}
							/>
						}
						{
							type === 'element' &&
							<div className={values[field]}>{element ? element(values, (value) => setValues({ ...values, [field]: value })) : null}</div>
						}
					</TableCell>);
				}
				)}
				{
					customCellsRender?.map((cell, index) =>
						<TableCell key={`customCell:${index}`} width={cell.width} align={cell.align}>
							{cell.render(data)}
						</TableCell>
					)
				}
				{(actionRow?.delete || actionRow?.edit || actionRow?.custom) &&
					<TableCell width={widthActions}>
						{
							actionRow?.custom &&
							<Tooltip title={actionRow?.custom?.tooltip ? actionRow?.custom?.tooltip : 'action'}>
								<IconButton
									onClick={onCustom}
									size={styleOption.size}
								>
									{actionRow.custom.icon}
								</IconButton>
							</Tooltip>
						}
						{
							isEdit
								? <>
									<Tooltip title='Сохранить'>
										<IconButton
											onClick={onSave}
											size={styleOption.size}
										>
											<CheckIcon className='invoice-modal__table-row-button-save' />
										</IconButton>
									</Tooltip>
									<Tooltip title='Отменить'>
										<IconButton
											onClick={onCancel}
											size={styleOption.size}
										>
											<ClearIcon className='table__delete-icon' />
										</IconButton>
									</Tooltip>
								</>
								: <>
									{
										actionRow?.edit &&
										<Tooltip title='Редактировать'>
											<IconButton
												className='theme-blue-to-orange'
												size={styleOption.size}
												onClick={onEdit}
											>
												<EditIcon className='invoice-modal__table-row-button-edit' />
											</IconButton>
										</Tooltip>
									}
									{
										actionRow?.delete &&
										<Tooltip title='Удалить'>
											<IconButton
												size={styleOption.size}
												onClick={onDelete}
											>
												<DeleteIcon className='table__delete-icon' />
											</IconButton>
										</Tooltip>
									}
								</>
						}
					</TableCell>
				}
			</TableRow>
			{
				detailPanel?.Component && dataForDetailPanel !== null &&
				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
						<Collapse style={{ paddingBottom: 10, paddingTop: 10 }} in={open} timeout='auto' unmountOnExit>
							<Box sx={{ margin: 1 }}>
								<detailPanel.Component dataL={data} {...dataForDetailPanel} />
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			}
		</>
	);
});

const DragHandle = SortableHandle(({ style }: { style?: React.CSSProperties }) => (
	<span style={{ ...style, ...{ cursor: 'move' } }}> { '::::' } </span>)
);
