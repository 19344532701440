import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';
import { AddCourse } from '../../components';
import { adminCoursesStore, adminUserStore, userStore } from '@stores';
import { Button, Table, Select, Input } from '@components';
import { IColumn } from '@components/table';
import '../detail-panel-invoice/style.css';
import { getArrMonthsName } from '@helpers';

interface IProps {
	createInvoice: (data: any) => void
	duplicateInvoiceData?: {
		courses: any[]
	}
}

export default observer((props: IProps) => {
	const { createInvoice, duplicateInvoiceData } = props;

	const [courses, setCourses] = useState<Array<any>>([]);
	const [totlaPrice, setTotalPrice] = useState(0);
	const [currentUser, setCurrentUser] = useState<any>(null);
	const [description, setDescription] = useState<string | undefined>();

	useEffect(() => {
		if (adminCoursesStore.allCourses?.length === 0) {
			adminCoursesStore.getAllCourses(20, undefined, true, true);
		}
		adminUserStore.getUsers(0, 8);

		if (duplicateInvoiceData) {
			const temp: any[] = [];
			duplicateInvoiceData.courses.forEach(item => {
				temp.push({
					id: item.tariff.id,
					course: item.tariff.course,
					tariff: item.tariff,
					period: null,
					tariffId: item.tariff.id,
					begins: item.begins,
					ends: item.ends,
					price: item.price,
					duration: getArrMonthsName([{ begins: item.begins, ends: item.ends }]).monthsName
				});
			});
			setCourses(temp);
		}
	}, []);

	useEffect(() => {
		if (courses.length > 0) {
			changePrice();
		}
	}, [courses.length]);

	const onCreate = () => {
		createInvoice({
			total: totlaPrice,
			items: courses.map(course => {
				let price = course.price;
				if (price < 10) { price = 10; }

				return ({
					tariffId: course.tariffId,
					ends: course.ends,
					begins: course.begins,
					price: price
				});
			}),
			managerId: userStore.user.id,
			userId: currentUser?.id || undefined,
			description: description !== '' ? description : undefined
		});
	};

	const addCourse = (course) => {
		setCourses([...courses, {
			...course, id: course.course.id
		}]);
	};

	const deleteCourse = (id) => {
		const updatedCourses = courses.filter(course => course?.id !== id);
		setCourses(updatedCourses);
	};

	const onChangeUser = (e, user) => {
		setCurrentUser(user);
	};

	const changeDescription = (e) => {
		setDescription(e.target.value);
	};

	const searchUser = debounce(async (e) => {
		await adminUserStore.getUsers(1, 10, e.target.value);
	}, 1000);

	const changePrice = () => {
		const price = courses.reduce((prev, curr) => prev + curr.price, 0);
		setTotalPrice(price);
	};

	const getCourses = (text: string) => {
		adminCoursesStore.getAllCourses(20, text, true, true);
	};

	return (
		<div className='add-panel-invoice'>
			<p>Основная информация:</p>
			<div className='add-panel-invoice__info'>
				<Select
					options={adminUserStore.users}
					value={currentUser}
					input={{
						label: 'Пользователь',
						onChange: searchUser
					}}
					onChange={onChangeUser}
				/>
				<Input
					label='Заметки'
					value={description}
					onChange={changeDescription}
				/>
				<p>Общая сумма: {totlaPrice} ₽</p>
			</div>
			<Table
				columns={columns}
				data={courses}
				title='Курсы'
				pagination
				actionRow={{
					delete: deleteCourse
				}}
			/>
			<AddCourse
				courses={adminCoursesStore.allCourses}
				addCourse={addCourse}
				countCourses={courses.length}
				onSearchCourses={getCourses}
			/>
			<div className='add-panel-invoice__button'>
				<Button type='main_green' disable={courses.length === 0} onClick={onCreate}>
					Создать счет
				</Button>
			</div>
		</div>
	);
});

const columns: IColumn[] = [
	{
		field: 'course',
		name: 'Курс',
		type: 'select',
		options: []
	},
	{
		field: 'tariff',
		name: 'Тариф',
		type: 'select',
		options: []
	},
	{
		field: 'duration',
		name: 'Продолжительность'
	},
	{
		field: 'price',
		name: 'Цена'
	}
];
