import React from 'react';
import { IGetCourseByIdResponse } from '@lomonosov/types';

import { Button } from '@components';
import { images } from '@helpers';

import './style.css';

export default (props: { banner: IGetCourseByIdResponse['banner'] }) => {
	const { banner } = props;

	return (
		banner?.isActive
			? <div className={`course-banner course-banner__${banner?.color}`}>
				<span className='course-banner__title'>
					{banner?.name}
				</span>
				<span className='course-banner__text'>
					{banner?.description}
				</span>
				<a
					className='course-banner__button'
					href={banner?.link}
					target='_blank'
					rel='noreferrer'
				>
					<Button type={banner?.color === 'green' ? 'main_blue' : 'main_green'}>
						{banner?.textButton}
					</Button>
				</a>
				<img
					className='course-banner__image'
					src={banner?.image}
					alt='bannerImage'
				/>
				<img
					className='course-banner__hat'
					src={images.hat}
					alt='hat'
				/>
				<img
					className='course-banner__five'
					src={images.five}
					alt='five'
				/>
				<img
					className='course-banner__bell'
					src={images.bell}
					alt='bell'
				/>
			</div>
			: null
	);
};
