import React from 'react';
import { INormalizeDataCheckEssayItem, adminEssaysStore, fileStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { FileList } from '@blocks';
import { Button, UploadFileButton } from '@components';
import './style.css';

interface DetailPanelCheckEssaysProps {
	dataL: INormalizeDataCheckEssayItem;
}

export const DetailPanelCheckEssays = observer((props: DetailPanelCheckEssaysProps) => {
	const { dataL } = props;

	const onSaveFile = async (e) => {
		const handleChangeFile = (file) => {
			adminEssaysStore.changeFileCheckEssay(file, dataL.id);
		};
		await fileStore.uploadFile(e.target, handleChangeFile);
	};

	const onDeleteFile = async (fileId: string) => {
		const deleteFile = () => {
			adminEssaysStore.changeFileCheckEssay(null, dataL.id);
		};
		await fileStore.deleteFile(fileId, deleteFile);
	};

	const saveCheckEssay = async () => {
		if (dataL.mentorFile) {
			await adminEssaysStore.updateCheckEssay({
				mentorFile: dataL.mentorFile
			}, dataL.id);
		}
	};

	return (
		<div className='detail-panel-check-essays'>
			<div className='detail-panel-check-essays__block'>
				<p className='detail-panel-check-essays__text'>
					Отправитель: { dataL.userName }
				</p>
				<p className='detail-panel-check-essays__text'>
					Тип сочинения: { dataL.type }
				</p>
			</div>
			<div className='detail-panel-check-essays__block'>
				<div className='detail-panel-check-essays__fileBlock'>
					<p className='detail-panel-check-essays__textFile'>
						Файл сочинения
					</p>
					<FileList files={[dataL.file]} buttonVisible={false}/>
				</div>
				<div className='detail-panel-check-essays__fileBlock'>
					<p className='detail-panel-check-essays__textFile'>
						Файл отправителя
					</p>
					<FileList files={[dataL.userFile]} buttonVisible={false}/>
				</div>
			</div>
			<div className='detail-panel-check-essays__fileBlock'>
				<p className='detail-panel-check-essays__textFile'>
					Файл ментора
				</p>
				{dataL.mentorFile
					? <FileList files={[dataL.mentorFile]} buttonVisible={true} action={onDeleteFile}/>
					: <UploadFileButton onSaveFile={onSaveFile} isLoading={fileStore.loading} className='detail-panel-check-essays__fileBlock'/>
				}
			</div>
			{adminEssaysStore.statusCheck === 'verification' &&
				<Button
					type='main_green'
					disable={!dataL.mentorFile}
					onClick={saveCheckEssay}
					className='detail-panel-check-essays__saveButton'
				>
					Завершить проверку
				</Button>
			}
		</div>
	);
});
