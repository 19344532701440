import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import { IUpdateTeacherDto } from '@lomonosov/types';

export default class {
	static async getTeachers (page: number = 0, pageSize: number = 5, search: string = ''): Promise<AxiosResponse<any>> {
		return $api.get<any>(
			'/teachers',
			{
				params: {
					take: pageSize,
					skip: page * pageSize,
					search: search
				}
			}
		);
	}

	static async createTeacher (userId): Promise<AxiosResponse<any>> {
		return $api.post<any>('/teachers', { originalUserId: userId });
	}

	static async updateTeacher (teacher: IUpdateTeacherDto, teacherId: string): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/teachers/${teacherId}`, teacher);
	}

	static async deleteTeacher (teacherId: string): Promise<AxiosResponse<any>> {
		return $api.delete<any>(`/teachers/${teacherId}`);
	}
}
