import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SocialButton, Loader } from '@components';
import { QueryParams, constants } from '@helpers';
import Header from '@blocks/header';
import Menu from '@blocks/menu';
import Footer from '@blocks/footer';
import { authStore, userNewslettersStore, userStore } from '@stores';

import {
	GamificationIcon,
	GraduateCap,
	HeadPhones,
	Warning
} from 'lomonosov-icons';

import './style.css';
import classNames from 'classnames';

const MainLayout = observer(() => {
	const [open, setOpen] = useState<boolean>(false);
	const [isMobile, setIsMobile] = useState<boolean>(true);

	const alertSound = new Audio('/public/message-pop-alert.mp3');
	const navigate = useNavigate();

	const changeMenuState = (menuOpen?: boolean) => {
		setOpen(menuOpen || !open);
	};

	const logOut = async () => {
		await authStore.logout();
		navigate('/login');
	};

	useEffect(() => {
		if (!localStorage.token) {
			localStorage.setItem(QueryParams.NAVIGATION_PATH, window.location.pathname);
			navigate('/login');
		}
	}, [localStorage.token]);

	useEffect(() => {
		setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
		if (authStore.isAuth) {
			(async () => {
				if (!localStorage.newslettersCount) {
					await userNewslettersStore.getAllNewsletters(userStore.user.id);
				}
				const source = new EventSource(`${constants.URL}/users/${userStore.user.id}/newsletters/stream`, { withCredentials: true });
				source.onmessage = () => {
					alertSound.play();
					userNewslettersStore.changeCount(userNewslettersStore.count + 1);
					localStorage.newslettersCount = userNewslettersStore.count;
				};
				return () => source.close();
			})();
		}
	}, []);

	return (
		<>
			<Header changeMenuState={changeMenuState} />
			<Menu
				open={open}
				ismobile={isMobile.toString()}
				variant={isMobile ? 'temporary' : 'permanent'}
				anchor='left'
				onClose={() => setOpen(false)}
			>
				<div className={classNames({ 'mainLayout__menu-block-opened': open }, { 'mainLayout__menu-block-closed': !open }, 'theme-block-background')}>
					<Menu.Avatar
						tooltip='Личный кабинет'
						onClick={() => setOpen(false)}
					/>
				</div>
				<Menu.Divider />
				<div className={classNames({ 'mainLayout__menu-block-opened': open }, { 'mainLayout__menu-block-closed': !open }, 'theme-block-background')}>
					<Menu.Link
						tooltip='Программы обучения'
						to='/study-program'
						customicon={<GraduateCap />}
						onClick={() => setOpen(false)}
					>
						программы обучения
					</Menu.Link>
					<Menu.Link
						tooltip='Расписание'
						to='/calendar'
						iconname='today'
						onClick={() => setOpen(false)}
					>
						расписание
					</Menu.Link>
					<Menu.Link
						tooltip='Домашние задания'
						to='/homeworks'
						iconname='menu_book'
						onClick={() => setOpen(false)}
					>
						домашние задания
					</Menu.Link>
					<Menu.Link
						tooltip='Дневник'
						to='/journal'
						iconname='assessment'
						onClick={() => setOpen(false)}
					>
						дневник
					</Menu.Link>
				</div>
				{/* <Menu.Link
					tooltip='Мои подписки'
					to='/'
					className='mainLayout__link_opacity'
				>
					Мои подписки
				</Menu.Link> */}
				<Menu.Divider />
				<div className={classNames({ 'mainLayout__menu-block-opened': open }, { 'mainLayout__menu-block-closed': !open }, 'theme-block-background')}>
					<Menu.UrlLink
						tooltip='Магазин'
						href='/shop'
						iconname='shopping_cart'
						onClick={() => setOpen(false)}
					>
						магазин
					</Menu.UrlLink>
					<Menu.Link
						tooltip='Игра'
						to='/gamification'
						customicon={<GamificationIcon />}
						onClick={() => setOpen(false)}
					>
						игра
					</Menu.Link>
				</div>
				<Menu.Divider />
				<div className={classNames({ 'mainLayout__menu-block-opened': open }, { 'mainLayout__menu-block-closed': !open }, 'theme-block-background')}>
					<Menu.UrlLink
						tooltip='Учебный отдел'
						href='https://vk.me/lomonosov_school'
						target='_blank'
						rel='noreferrer noopener'
						customicon={<HeadPhones />}
					>
						учебный отдел
					</Menu.UrlLink>
					<Menu.UrlLink
						tooltip='Тех. поддержка'
						href='https://vk.me/lomonosov_school'
						target='_blank'
						rel='noreferrer noopener'
						customicon={<Warning />}
					>
						тех. поддержка
					</Menu.UrlLink>
				</div>
				<Menu.Divider />
				<div className={classNames({ 'mainLayout__menu-block-opened': open }, { 'mainLayout__menu-block-closed': !open }, 'theme-block-background')}>
					{(userStore.isAdmin || userStore.isEmployee) &&
						<>
							<Menu.Link
								tooltip='Админка'
								to='/admin'
								iconname='supervisor_account'
								onClick={() => setOpen(false)}
							>
								админка
							</Menu.Link>
						</>
					}
					<Menu.CustomItem
						onClick={logOut}
						tooltip='Выход'
						iconName='exit_to_app'>
							выход
					</Menu.CustomItem>
				</div>
				<Menu.Footer>
					<Menu.ThemeButton
						tooltip='Тема'
					/>
					<Menu.Divider/>
					<div className={classNames('DrawerMenu__footer__socials-block', { 'DrawerMenu__footer__socials-block_closed': !open })}>
						<span className='DrawerMenu__footer__socials-title'>
							Связаться с нами
						</span>
						<div className='DrawerMenu__footer__socials'>
							<SocialButton
								href='https://t.me/go_lomonosov'
								icon='telegram'
								color='secondary'
								size='large'
								variant='clear'
								isTargetBlank
							/>
							<SocialButton
								href='https://vk.me/lomonosov_school'
								icon='vk'
								color='secondary'
								size='large'
								variant='clear'
								isTargetBlank
							/>
							<SocialButton
								href='https://api.whatsapp.com/send/?phone=79104353535&text&type=phone_number&app_absent=0'
								icon='whatsapp'
								color='secondary'
								size='large'
								variant='clear'
								isTargetBlank
							/>
						</div>
					</div>
					<Menu.UrlLink
						href='tel:+79104353535'
						iconname='smartphone'
						tooltip='8 (910) 435-35-35'
					>
						8 (910) 435-35-35
					</Menu.UrlLink>
					<Menu.Divider/>
					<div className='DrawerMenu__footer__all-socials'>
						<SocialButton
							href='https://t.me/go_lomonosov'
							icon='telegram'
							isTargetBlank
							size='small'
						/>
						<SocialButton
							href='https://vk.me/lomonosov_school'
							icon='vk'
							isTargetBlank
							size='small'
						/>
						<SocialButton
							href='https://www.youtube.com/@LomonosovSchool'
							icon='youtube'
							isTargetBlank
							size='small'
						/>
						<SocialButton
							href='https://www.instagram.com/lmnsv.online/'
							icon='instagram'
							isTargetBlank
							size='small'
						/>
						<SocialButton
							href='https://www.tiktok.com/@lomonosov_school_house'
							icon='tiktok'
							isTargetBlank
							size='small'
						/>
					</div>
				</Menu.Footer>
			</Menu>
			{authStore.isAuth && userStore.user.id
				? <main className={`mainLayout mainLayout__margin-left-${isMobile ? 'static' : 'dynamic'}-${open ? 'open' : 'close'} mainLayout__margin-top theme-background`}>
					<Outlet />
				</main>
				: <Loader />
			}
			<Footer />
		</>
	);
});

export default MainLayout;
