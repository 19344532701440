import { AxiosResponse } from 'axios';
import { $api, $apiSSO, getQueries } from '@helpers';
import {
	ILoginResponse,
	ICheckPhoneResponse,
	ISMSPushResponse,
	IVerifyCodeResponse,
	IPasswordRecoveryParams,
	PasswordChangeDto
} from './types';
import { IUser } from '@interfaces';
import { IRegisterDto } from '@features/auth/utils';

export default class {
	static async logIn (login: string, password: string, type): Promise<AxiosResponse<ILoginResponse>> {
		return $apiSSO.post<ILoginResponse>('/auth/login', { [type]: login, password });
	}

	static async logOut (): Promise<AxiosResponse<{ status: boolean }>> {
		return $apiSSO.get<{ status: boolean }>('/auth/portal-logout');
	}

	static async sendMessage (value: string, actionType: 'confirmation' | 'recovery'): Promise<AxiosResponse<ISMSPushResponse>> {
		return $apiSSO.post<ISMSPushResponse>('/auth/email/send', { email: value, templateType: 'link', actionType });
	}

	static async checkCode (login, userCode): Promise<AxiosResponse<IVerifyCodeResponse>> {
		return $apiSSO.put<IVerifyCodeResponse>('/auth/email/verify', { ...login, code: Number.parseInt(userCode, 10) });
	}

	static async checkSession (): Promise<AxiosResponse<string | null>> {
		return $apiSSO.get<string | null>('/auth/check-session');
	}

	static async installSSOSession (ssoToken: string): Promise<AxiosResponse<any>> {
		return $apiSSO.get<string | null>(`/auth/session/install?ssoToken=${ssoToken}`);
	}

	static async checkLoginExists (value, type): Promise<AxiosResponse<ICheckPhoneResponse>> {
		return $api.get<ICheckPhoneResponse>(`/auth/check-exist?${type}=${value}`);
	}

	static async registration (userData: IRegisterDto): Promise<AxiosResponse<ILoginResponse>> {
		return $apiSSO.post<ILoginResponse>(`/auth/registration?${getQueries()}`, { ...userData, email: userData.login });
	}

	static async resetPassword (recoveryInfo: IPasswordRecoveryParams): Promise<AxiosResponse<{status: boolean}>> {
		return $apiSSO.put<{status: boolean}>('/auth/password/recovery', recoveryInfo);
	}

	/**
	 * Changes the user's password.
	 *
	 * @param {PasswordChangeDto} newDataPassword - The new password data.
	 * @return {Promise<AxiosResponse<{status: boolean}>>} A promise that resolves to the response from the API.
	 */
	static async changePassword (newDataPassword: PasswordChangeDto): Promise<AxiosResponse<{status: boolean, msg: string}>> {
		return $apiSSO.put<{status: boolean, msg: string}>('/auth/password/change', newDataPassword);
	}

	static async confirmVK (code: string, userId: string): Promise<AxiosResponse<IUser>> {
		return $api.get<IUser>(`/users/confirm-vk?code=${code}&state=${userId}`);
	}
}
