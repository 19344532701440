export const images = {
	// Essay
	coverEssay: 'https://storage.yandexcloud.net/lomonosov-frontend/essay/essay_cover.png',
	// Referrals
	oneThousand: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/oneThousand.png',
	partnersTv: 'https://cdn1.lomonosov.tech/partners/partners-tv.png',
	partnersTv1000: 'https://cdn1.lomonosov.tech/partners/partners-tv1000.png',
	refTv: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/ref-tv.png',
	// Admin
	defaultKinescope: 'https://cdn1.lomonosov.tech/admin/default-kinescope.png',
	// File-list
	defaultDocument: 'https://cdn1.lomonosov.tech/public-images/defaultDocument.png',
	// MaterialsLanding
	hand1: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/hand1.png',
	hand2: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/hand2.png',
	hand3: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/hand3.png',
	hand4: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/hand4.png',
	hand5: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/hand5.png',
	materialsLandingCoverArrow: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/materials-landing-cover-arrow.png',
	coverCastleBackground: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/cover-castle-background.png',
	emojiBook: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/emoj-book.png',
	emojiBooks: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/emoj-books.png',
	emojiHat: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/emoj-hat.png',
	emojiIphone: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/emoj-iphone.png',
	emojiPaper: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/emoj-paper.png',
	emojiPc: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/emoj-pc.png',
	// course-header
	defaultCourse: 'https://cdn1.lomonosov.tech/admin/defaultCourse.jpg',
	// Landing
	// LearningSteps (Этот компонент не используется)
	card1: 'https://cdn1.lomonosov.tech/src-images/card1.png',
	card2: 'https://cdn1.lomonosov.tech/src-images/card2.png',
	card3: 'https://cdn1.lomonosov.tech/src-images/card3.png',
	card4: 'https://cdn1.lomonosov.tech/src-images/card4.png',
	smile1: 'https://cdn1.lomonosov.tech/src-images/smile1.png',
	smile2: 'https://cdn1.lomonosov.tech/src-images/smile2.png',
	// StudyDirectionCard
	card1logo: 'https://cdn1.lomonosov.tech/src-images/card1logo.png',
	card2logo: 'https://cdn1.lomonosov.tech/src-images/card2logo.png',
	card3logo: 'https://cdn1.lomonosov.tech/src-images/card3logo.png',
	card4logo: 'https://cdn1.lomonosov.tech/src-images/card4logo.png',
	// Introduce
	clock: 'https://cdn1.lomonosov.tech/src-images/clock.png',
	desktop: 'https://cdn1.lomonosov.tech/src-images/desktop.png',
	friendship: 'https://cdn1.lomonosov.tech/src-images/friendship.png',
	introduceDuration: 'https://cdn1.lomonosov.tech/src-images/introduceDuration.png',
	introduceHomework: 'https://cdn1.lomonosov.tech/src-images/introduceHomework.png',
	introduceSocial: 'https://cdn1.lomonosov.tech/src-images/introduceSocial.png',
	trailerPreview: 'https://cdn1.lomonosov.tech/src-images/trailer-preview.png',
	notepad: 'https://cdn1.lomonosov.tech/src-images/notepad.png',
	// CourseInfoNew, CourseInfo
	hatSmile: 'https://cdn1.lomonosov.tech/src-images/hatSmile.png',
	medalSmile: 'https://cdn1.lomonosov.tech/src-images/medalSmile.png',
	// Free courses
	headerImg: 'https://cdn1.lomonosov.tech/src-images/headerImg.png',
	// Teacher
	lomonosov__block3: 'https://cdn1.lomonosov.tech/src-images/lomonosov__block-3.png',
	// Separator
	lomonosov_blue: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/lomonosov_blue.png',
	lomonosov_green: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/lomonosov_green.png',
	// StudentFeedback (видео отзывы)
	block8img1: 'https://cdn1.lomonosov.tech/src-images/block8img1.png',
	block8img10: 'https://cdn1.lomonosov.tech/src-images/block8img10.jpg',
	block8img12: 'https://cdn1.lomonosov.tech/src-images/block8img12.png',
	block8img13: 'https://cdn1.lomonosov.tech/src-images/block8img13.png',
	block8img14: 'https://cdn1.lomonosov.tech/src-images/block8img14.png',
	block8img15: 'https://cdn1.lomonosov.tech/src-images/block8img15.png',
	block8img16: 'https://cdn1.lomonosov.tech/src-images/block8img16.png',
	block8img3: 'https://cdn1.lomonosov.tech/src-images/block8img3.png',
	block8img4: 'https://cdn1.lomonosov.tech/src-images/block8img4.png',
	block8img5: 'https://cdn1.lomonosov.tech/src-images/block8img5.png',
	block8img6: 'https://cdn1.lomonosov.tech/src-images/block8img6.png',
	block8img7: 'https://cdn1.lomonosov.tech/src-images/block8img7.png',
	block8img8: 'https://cdn1.lomonosov.tech/src-images/block8img8.png',
	block8img9: 'https://cdn1.lomonosov.tech/src-images/block8img9.png',
	// Free materials
	planeImg: 'https://cdn1.lomonosov.tech/src-images/plane1.png',
	// banner
	seasonSaleBannerBackground: 'https://cdn1.lomonosov.tech/src-images/season-sale-banner-background.png',

	// teachers и teachers-modal
	// germanovich: 'https://cdn1.lomonosov.tech/popular-courses/germanovich.png',
	andrey: 'https://cdn1.lomonosov.tech/teachers/andrey.jpg',
	kulikova: 'https://cdn1.lomonosov.tech/teachers/1-1.webp',
	hachaturyan: 'https://cdn1.lomonosov.tech/teachers/hachaturyan.jpg',
	pettay: 'https://cdn1.lomonosov.tech/teachers/pettay.jpg',
	kluchnikova: 'https://cdn1.lomonosov.tech/teachers/kluchinikova.jpg',
	kokonov: 'https://cdn1.lomonosov.tech/teachers/kokonov.jpg',
	leonova: 'https://cdn1.lomonosov.tech/teachers/leonova.jpg',
	pankova: 'https://cdn1.lomonosov.tech/teachers/pankova.jpg',
	harchenko: 'https://cdn1.lomonosov.tech/teachers/harchenko.jpg',
	pavlikov: 'https://cdn1.lomonosov.tech/teachers/pavlikov.jpg',
	kosovskaya: 'https://cdn1.lomonosov.tech/teachers/kosovskaya.jpg',
	jickaya: 'https://cdn1.lomonosov.tech/teachers/jickaya.jpg',
	efimova: 'https://cdn1.lomonosov.tech/teachers/efimova.jpg',
	petrov: 'https://cdn1.lomonosov.tech/teachers/petrov.jpg',
	novikov: 'https://cdn1.lomonosov.tech/teachers/novikov.png',
	chernikova: 'https://cdn1.lomonosov.tech/teachers/chernikova.png',
	smirnova: 'https://cdn1.lomonosov.tech/teachers/smirnova.png',
	// ////////////////////////////////////////////////////////////////////////////////////////////////////
	castle: 'https://cdn1.lomonosov.tech/src-images/castle.png',

	// Mini-landing
	miniLandingEmoji1: 'https://cdn1.lomonosov.tech/src-images/mini-landing-emoji1.png',
	miniLandingEmoji2: 'https://cdn1.lomonosov.tech/src-images/mini-landing-emoji2.png',
	miniLandingEmoji3: 'https://cdn1.lomonosov.tech/src-images/mini-landing-emoji3.png',
	miniLandingEmoji4: 'https://cdn1.lomonosov.tech/src-images/mini-landing-emoji4.png',
	notebookSmile: 'https://cdn1.lomonosov.tech/src-images/notebookSmile.png',
	olymp: 'https://cdn1.lomonosov.tech/src-images/olymp.png',
	// ////////////////////////////////////////////////////////////////////////////////////////////////////

	// landing-dvi
	child: 'https://cdn1.lomonosov.tech/learning-formats/child.png',
	coolguy: 'https://cdn1.lomonosov.tech/learning-formats/coolguy.png',
	coverDVI: 'https://cdn1.lomonosov.tech/src-images/coverDVI.png',
	bookSmile: 'https://cdn1.lomonosov.tech/src-images/bookSmile.png',
	cloud: 'https://cdn1.lomonosov.tech/src-images/cloud.png',
	mgu: 'https://cdn1.lomonosov.tech/src-images/mgu.png',
	msu1: 'https://cdn1.lomonosov.tech/src-images/msu1.png',
	rectangle: 'https://cdn1.lomonosov.tech/src-images/rectangle.png',
	safeSmile: 'https://cdn1.lomonosov.tech/src-images/safeSmile.png',
	// ////////////////////////////////////////////////////////////////////////////////////////////////////

	// landing-summer
	advantage1: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/advantage1.png',
	advantage2: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/advantage2.png',
	advantage3: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/advantage3.png',
	advantage4: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/adventage4.png',
	coverSpring: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/cover-spring.png',
	springBooking: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/spring-booking.png',
	springArrow1: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/spring-arrow1.png',
	springArrow2: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/spring-arrow2.png',
	springArrow3: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/spring-arrow3.png',
	springArrow4: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/spring-arrow4.png',
	springLine: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/spring-line.png',
	gameGirl: 'https://cdn1.lomonosov.tech/src-images/gameGirl.png',
	gameMan: 'https://cdn1.lomonosov.tech/src-images/gameMan.png',
	gameMan1: 'https://cdn1.lomonosov.tech/src-images/gameMan1.png',
	// ////////////////////////////////////////////////////////////////////////////////////////////////////

	// Portal
	bannerEnglish: 'https://cdn1.lomonosov.tech/src-images/banner-english.png',
	bannerFreeCourse: 'https://cdn1.lomonosov.tech/src-images/banner-free-course.png',
	bannerHistory: 'https://cdn1.lomonosov.tech/src-images/banner-history.png',
	bannerLiterature: 'https://cdn1.lomonosov.tech/src-images/banner-literature.png',
	bannerMathematic: 'https://cdn1.lomonosov.tech/src-images/banner-mathematic.png',
	bannerRussian: 'https://cdn1.lomonosov.tech/src-images/banner-russian.png',
	bannerSocial: 'https://cdn1.lomonosov.tech/src-images/banner-social.png',
	bell: 'https://cdn1.lomonosov.tech/src-images/bell.png',
	five: 'https://cdn1.lomonosov.tech/src-images/five.png',
	lkoin: 'https://cdn1.lomonosov.tech/src-images/lkoin.png', // в компоненте который не используется
	lvlSmile: 'https://cdn1.lomonosov.tech/src-images/lvl-smile.png', // в компоненте который не используется
	memeModalImage: 'https://cdn1.lomonosov.tech/src-images/meme-modal-image.png',
	memeModalStar: 'https://cdn1.lomonosov.tech/src-images/meme-modal-star.png',
	memeModalZebra: 'https://cdn1.lomonosov.tech/src-images/meme-modal-zebra.png',
	procent: 'https://cdn1.lomonosov.tech/src-images/procent.png',
	secretModalCircle: 'https://cdn1.lomonosov.tech/src-images/secret-modal-circle.png',
	tasksImage: 'https://cdn1.lomonosov.tech/src-images/tasks-image.png',
	textLogoDark: 'https://cdn1.lomonosov.tech/src-images/text_logo_dark.png',
	thinkingLomonosov: 'https://cdn1.lomonosov.tech/src-images/thinking-lomonosov.png',
	personalInfoAvatar: 'https://cdn1.lomonosov.tech/src-images/personal-info-avatar.png',
	techSupportImage: 'https://cdn1.lomonosov.tech/src-images/banner-notification.png',
	personalBonusHeader: 'https://cdn1.lomonosov.tech/src-images/personal-bonus-header.png',
	defaultBannerImg: 'https://cdn1.lomonosov.tech/src-images/defaultBannerImg.png',
	ticket: 'https://cdn1.lomonosov.tech/src-images/ticket.png',
	// ////////////////////////////////////////////////////////////////////////////////////////////////////

	// Universal-image
	block7Smile1: 'https://cdn1.lomonosov.tech/src-images/block7Smile1.png',
	block7Smile2: 'https://cdn1.lomonosov.tech/src-images/block7Smile2.png',
	block7Smile3: 'https://cdn1.lomonosov.tech/src-images/block7Smile3.png',
	block7Smile4: 'https://cdn1.lomonosov.tech/src-images/block7Smile4.png',
	card1smile: 'https://cdn1.lomonosov.tech/src-images/card1smile.png',
	card2smile: 'https://cdn1.lomonosov.tech/src-images/card2smile.png',
	card3smile: 'https://cdn1.lomonosov.tech/src-images/card3smile.png',
	card4smile: 'https://cdn1.lomonosov.tech/src-images/card4smile.png',
	circle: 'https://cdn1.lomonosov.tech/src-images/circle.png',
	circleCover: 'https://cdn1.lomonosov.tech/src-images/circleCover.png',
	cross: 'https://cdn1.lomonosov.tech/src-images/cross.png',
	hat: 'https://cdn1.lomonosov.tech/src-images/hat.png',
	lightLogo: 'https://cdn1.lomonosov.tech/src-images/logo.png',
	darkLogo: 'https://storage.yandexcloud.net/lomonosov-frontend/src-images/logo-white.png',
	coverSummer: 'https://cdn1.lomonosov.tech/src-images/web_illustration%201.png',
	writeSmile: 'https://cdn1.lomonosov.tech/src-images/writeSmile.png',
	// используются в sass
	miniLandingHeaderBgBlack: 'https://cdn1.lomonosov.tech/src-images/mini-landing-header-bg-black.png',
	miniLandingHeaderBgBlue: 'https://cdn1.lomonosov.tech/src-images/mini-landing-header-bg-blue.png',
	miniLandingHeaderBgGreen: 'https://cdn1.lomonosov.tech/src-images/mini-landing-header-bg-green.png',
	miniLandingHeaderBgOrange: 'https://cdn1.lomonosov.tech/src-images/mini-landing-header-bg-orange.png',
	block7bg: 'https://cdn1.lomonosov.tech/src-images/block7bg.png',
	block9bg: 'https://cdn1.lomonosov.tech/src-images/block9bg.png',
	card1bg: 'https://cdn1.lomonosov.tech/src-images/card1bg.png',
	card2bg: 'https://cdn1.lomonosov.tech/src-images/card2bg.png',
	card3bg: 'https://cdn1.lomonosov.tech/src-images/card3bg.png',
	card4bg: 'https://cdn1.lomonosov.tech/src-images/card4bg.png',
	miniLandingLearningFormatsBg: 'https://cdn1.lomonosov.tech/src-images/mini-landing-learning-formats-bg.png',
	miniLandingRequestBgBlack: 'https://cdn1.lomonosov.tech/src-images/mini-landing-request-bg-black.png',
	miniLandingRequestBgBlue: 'https://cdn1.lomonosov.tech/src-images/mini-landing-request-bg-blue.png',
	miniLandingRequestBgGreen: 'https://cdn1.lomonosov.tech/src-images/mini-landing-request-bg-green.png',
	miniLandingRequestBgOrange: 'https://cdn1.lomonosov.tech/src-images/mini-landing-request-bg-orange.png',
	assessmentBlockGreen: 'https://cdn1.lomonosov.tech/public-images/assessment-block-green.png',
	assessmentBlockOrange: 'https://cdn1.lomonosov.tech/public-images/assessment-block-orange.png',
	// ////////////////////////////////////////////////////////////////////////////////////////////////////

	// Login
	done: 'https://cdn1.lomonosov.tech/src-images/done.png',
	telegramImage96px: 'https://cdn1.lomonosov.tech/src-images/telegram-image96px.png',
	paintedHeart: 'https://cdn1.lomonosov.tech/src-images/painted-heart.png',
	accentImg: 'https://cdn1.lomonosov.tech/new-landing/accentImg.png',
	underline: 'https://cdn1.lomonosov.tech/new-landing/underline.png',
	// ////////////////////////////////////////////////////////////////////////////////////////////////////

	// Gamification
	defaultBoy: 'https://cdn1.lomonosov.tech/src-images/defaultBoy.png',
	defaultGirl: 'https://cdn1.lomonosov.tech/src-images/defaultGirl.png',
	head3: 'https://cdn1.lomonosov.tech/src-images/head3.png',
	notificationHead1: 'https://cdn1.lomonosov.tech/src-images/notificationHead1.png',
	notificationHead2: 'https://cdn1.lomonosov.tech/src-images/notificationHead2.png',
	garage: 'https://cdn1.lomonosov.tech/src-images/garage.jpg',
	// ////////////////////////////////////////////////////////////////////////////////////////////////////

	// Public-images
	act: 'https://cdn1.lomonosov.tech/public-images/act.jpg',
	schetLite: 'https://cdn1.lomonosov.tech/public-images/schet-lite.jpg',
	schetUltima: 'https://cdn1.lomonosov.tech/public-images/schet-ultima.jpg',
	schet1_1: 'https://cdn1.lomonosov.tech/public-images/schet1.1.jpg',
	schet1_2: 'https://cdn1.lomonosov.tech/public-images/schet1.2.jpg',
	schet1_3: 'https://cdn1.lomonosov.tech/public-images/schet1.3.jpg',
	schet1_4: 'https://cdn1.lomonosov.tech/public-images/schet1.4.jpg',
	schet1_5: 'https://cdn1.lomonosov.tech/public-images/schet1.5.jpg',
	schet1_6: 'https://cdn1.lomonosov.tech/public-images/schet1.6.jpg',
	schet1_7: 'https://cdn1.lomonosov.tech/public-images/schet1.7.jpg',
	plug: 'https://cdn1.lomonosov.tech/public-images/plug.png',
	newPlug: 'https://cdn1.lomonosov.tech/public-images/portal_plug.png',
	profileNotification: 'https://cdn1.lomonosov.tech/public-images/profile_notification.png',
	profileTile1: 'https://cdn1.lomonosov.tech/public-images/profile_tile_1.png',
	profileTile2: 'https://cdn1.lomonosov.tech/public-images/profile_tile_2.png',
	profileTile3: 'https://cdn1.lomonosov.tech/public-images/profile_tile_3.png',
	profileTile4: 'https://cdn1.lomonosov.tech/public-images/profile_tile_4.png',
	profileTile5: 'https://cdn1.lomonosov.tech/public-images/profile_tile_5.png',
	profileTile6: 'https://cdn1.lomonosov.tech/public-images/profile_tile_6.png',

	// сироп
	heartWhite: 'https://cdn1.lomonosov.tech/sirop/heart-white.png',
	heartBlack: 'https://cdn1.lomonosov.tech/sirop/heart-black.png',
	crown: 'https://cdn1.lomonosov.tech/sirop/crown.png',
	crown_blue: 'https://cdn1.lomonosov.tech/new-landing/crown-blue.png',
	gear: 'https://cdn1.lomonosov.tech/sirop/gear.png',
	// career-guidance
	lock: 'https://cdn1.lomonosov.tech/career-guidance/career-guidance-lock.png',
	blueStar: 'https://cdn1.lomonosov.tech/career-guidance/star.png',
	careerGuidanceCard1: 'https://cdn1.lomonosov.tech/career-guidance/career-guidance-card1.png',
	careerGuidanceCard2: 'https://cdn1.lomonosov.tech/career-guidance/career-guidance-card2.png',
	// Баннер "купи 4 предмета"
	buy4BannerArrows: 'https://cdn1.lomonosov.tech/buy-4-banner/banner-arrows.png',
	buy4BannerArrowsMobile: 'https://cdn1.lomonosov.tech/buy-4-banner/banner-arrows-mobile.png',
	// остальное в json
	// ////////////////////////////////////////////////////////////////////////////////////////////////////
	// не используется
	biology: 'https://cdn1.lomonosov.tech/lesson-images/biology.png',
	chemistry: 'https://cdn1.lomonosov.tech/lesson-images/chemistry.png',
	englishEge: 'https://cdn1.lomonosov.tech/lesson-images/english-ege.jpeg',
	englishOge: 'https://cdn1.lomonosov.tech/lesson-images/english-oge.jpeg',
	english: 'https://cdn1.lomonosov.tech/lesson-images/english.png',
	historyEge: 'https://cdn1.lomonosov.tech/lesson-images/history-ege.jpeg',
	historyOge: 'https://cdn1.lomonosov.tech/lesson-images/history-oge.jpeg',
	history: 'https://cdn1.lomonosov.tech/lesson-images/history.png',
	literatureEge: 'https://cdn1.lomonosov.tech/lesson-images/literature-ege.jpeg',
	literatureOge: 'https://cdn1.lomonosov.tech/lesson-images/literature-oge.jpeg',
	literature: 'https://cdn1.lomonosov.tech/lesson-images/literature.png',
	other: 'https://cdn1.lomonosov.tech/lesson-images/other.jpg',
	russianEge: 'https://cdn1.lomonosov.tech/lesson-images/russian-ege.jpeg',
	russianOge: 'https://cdn1.lomonosov.tech/lesson-images/russian-oge.jpeg',
	russian: 'https://cdn1.lomonosov.tech/lesson-images/russian.png',
	socialEge: 'https://cdn1.lomonosov.tech/lesson-images/social-ege.jpeg',
	socialOge: 'https://cdn1.lomonosov.tech/lesson-images/social-oge.jpeg',
	social: 'https://cdn1.lomonosov.tech/lesson-images/social.png',
	head: 'https://cdn1.lomonosov.tech/src-images/head.png',
	gamificationHome: 'https://cdn1.lomonosov.tech/src-images/gamificationHome.png',
	bodiesBlocked: 'https://cdn1.lomonosov.tech/public-images/bodies-blocked.png',
	carsBlocked: 'https://cdn1.lomonosov.tech/public-images/cars-blocked.png',
	faviconPng: 'https://cdn1.lomonosov.tech/public-images/favicon.png',
	headsBlocked: 'https://cdn1.lomonosov.tech/public-images/heads-blocked.png',
	housesBlocked: 'https://cdn1.lomonosov.tech/public-images/houses-blocked.png',
	default: 'https://cdn1.lomonosov.tech/feedback-students/default.jpg',

	// Auth
	uMathIcon: 'https://cdn1.lomonosov.tech/auth/Unilogy-80px-math.png',
	uEnglishIcon: 'https://cdn1.lomonosov.tech/auth/Unilogy-80px-englisn.png',
	uHistoryIcon: 'https://cdn1.lomonosov.tech/auth/Unilogy-80px-history.png',
	uInformaticsIcon: 'https://cdn1.lomonosov.tech/auth/Unilogy-80px-informatics.png',
	uRussianIcon: 'https://cdn1.lomonosov.tech/auth/Unilogy-80px-russian.png',
	uChemistryIcon: 'https://cdn1.lomonosov.tech/auth/Unilogy-80px-chemistry.png',
	uTeacherChemistry: 'https://cdn1.lomonosov.tech/auth/unilogy-teacher-chemistry2.png',
	uTeacherRussian: 'https://cdn1.lomonosov.tech/auth/unilogy-teacher-russian3.png',
	uTeacherMath: 'https://cdn1.lomonosov.tech/auth/unilogy-teacher-math2.png',
	uTeacherHistory: 'https://cdn1.lomonosov.tech/auth/unilogy-teacher-history3.png',
	uTeacherInformatics: 'https://cdn1.lomonosov.tech/auth/unilogy-teacher-informatics2.png',
	uTeacherEnglish: 'https://cdn1.lomonosov.tech/auth/unilogy-teacher-english2.png'
};
