import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Input, Button, Select, Editor } from '@components';
import { adminCoursesStore, adminNewslettersStore, generalStore } from '@stores';
import './style.css';

export default observer(() => {
	useEffect(() => {
		if (!adminCoursesStore.allCourses.length) {
			(async () => {
				await adminCoursesStore.getAllCourses();
			})();
		}
	}, []);

	const onButtonClick = async () => {
		adminNewslettersStore.currentNewsletter?.id
			? await adminNewslettersStore.updateNewsletter(adminNewslettersStore.currentNewsletter)
			: await adminNewslettersStore.createNewsletter(adminNewslettersStore.currentNewsletter);
	};

	const handleChangeTypeSelect = (e, value, reason) => {
		if (reason !== 'clear') {
			adminNewslettersStore.changeCurrentNewsletter(value.id, 'type');
		}
	};

	const handleChangeCourseSelect = (e, value, reason) => {
		if (reason !== 'clear') {
			adminNewslettersStore
				.changeCurrentNewsletter(value.map(course => ({ id: course.id, name: course.name })), 'courses');
		}
	};

	return (
		<div className='current-newsletter'>
			<div className='current-newsletter__input'>
				<Input
					label='Тема'
					value={adminNewslettersStore.currentNewsletter?.topic}
					onChange={(e) => adminNewslettersStore.changeCurrentNewsletter(e.target.value, 'topic')}
				/>
			</div>
			<div className='current-newsletter__input'>
				<Input
					label='Отправитель'
					value={adminNewslettersStore.currentNewsletter?.sender}
					onChange={(e) => adminNewslettersStore.changeCurrentNewsletter(e.target.value, 'sender')}
				/>
			</div>
			<div className='current-newsletter__input'>
				<Select
					input={{ label: 'Тип' }}
					value={selectTypeOptions.find(option => option.id === adminNewslettersStore.currentNewsletter?.type)}
					options={selectTypeOptions}
					onChange={handleChangeTypeSelect}
				/>
			</div>
			{adminNewslettersStore.currentNewsletter.type === 'Courses' &&
				<div className='current-newsletter__input'>
					<Select
						value={adminNewslettersStore.currentNewsletter.courses}
						input={{ label: 'Курс' }}
						options={adminCoursesStore.allCourses}
						onChange={handleChangeCourseSelect}
						multiple
					/>
				</div>
			}
			<span className='current-newsletter__text_editor'>Редактор:</span>
			<Editor
				className={`current-newsletter__editor_${generalStore.theme} theme-block-background`}
				blocks={adminNewslettersStore.currentNewsletter.text}
				onChange={(e) => adminNewslettersStore.changeCurrentNewsletter(e, 'text')}
			/>
			<div className='current-newsletter__button'>
				<Button
					type='main_green'
					onClick={() => onButtonClick()}
				>
					{adminNewslettersStore.currentNewsletter.id ? 'Переотправить' : 'Отправить'}
				</Button>
			</div>
		</div>
	);
});

const selectTypeOptions = [
	{ id: 'All', name: 'Все' },
	{ id: 'Courses', name: 'Выбрать курсы' }
];
