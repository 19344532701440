import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import { getArrMonthsName } from '@helpers';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { InfoCard } from '@components';

import './style.css';

interface ICoursesCardSubscriptionProps {
	periods: { begins: Date | string; ends: Date | string }[];
	className?: string;
	classes?: {
		container?: string;
	}
}

export const CardSubscriptionPeriod = memo((props: ICoursesCardSubscriptionProps) => {
	const { periods, className, classes } = props;

	const namesMonths = useMemo(() => (
		getArrMonthsName(periods).monthsName
	), [periods]);

	return (
		<InfoCard
			icon={<CalendarMonthOutlinedIcon fontSize='small'/>}
			title='Моя подписка'
			theme='light-lemon'
			className={classNames(className, 'card-subscription-period')}
			classes={classes}
		>
			<span className='card-subscription-period__text'>
				{namesMonths[namesMonths.length - 1]}
			</span>
		</InfoCard>
	);
});
