import React from 'react';

export default (props: { children: React.ReactNode }) => {
	const { children } = props;

	return (
		<div className='DrawerMenu__footer'>
			{children}
		</div>
	);
};
