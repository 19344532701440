import { makeAutoObservable, runInAction } from 'mobx';
import {
	ICreatePromoCodeDto,
	IGetPromoCodesResponse,
	IUpdatePromoCodeDto
} from '@lomonosov/types';
import { generalStore } from '@stores';
import { AdminPromoCodeService } from '@services';
import { replaceFormatDate, replaceFormatDateForUsers } from '@helpers';

class AdminPromoCodeStore {
	promoCodes: IGetPromoCodesResponse = [];
	promoCodesStatistics: any = [];
	currentPromoCodeStatistics: any;
	currentUser: any;
  addPromoModal: boolean = false;

  constructor () {
  	makeAutoObservable(this);
  }

  // Получить промокоды
  async getPromoCodes () {
  	try {
  		const { data } = await AdminPromoCodeService.getPromoCodes();
  		runInAction(() => {
  			this.promoCodes = data.map(promoCode => {
  				return {
  					...promoCode,
  					dateEnd: replaceFormatDate(promoCode.dateEnd) as unknown as Date,
  					createdAt: replaceFormatDate(promoCode.createdAt) as unknown as Date
  				};
  			});
  		});
  	} catch (e) {
  		console.log(e);
  	}
  }

  // Получить статистику промокодов
  async getPromoCodesStatistics () {
  	try {
  		const { data } = await AdminPromoCodeService.getPromoCodesStatistic();
  		runInAction(() => {
  			this.promoCodesStatistics = data;
  		});
  	} catch (e) {
  		console.log(e);
  	}
  }

  // Получить статистику по конкретному промокоду
  async getPromoCodeStatisticsById (id: string) {
  	try {
  		const { data } = await AdminPromoCodeService.getPromoCodeStatisticById(id);
  		runInAction(() => {
  			this.currentPromoCodeStatistics = data.map(promo => ({ ...promo, purchases: promo.purchases.map(purchase => ({ ...purchase, createdAt: replaceFormatDateForUsers(purchase.createdAt) })) }));
  		});
  	} catch (e) {
  		console.log(e);
  	}
  }

  // Получить конкретный промокод по ID
  async getCurrentPromoCode (promoCodeId: string) {
  	try {
  		await AdminPromoCodeService.getCurrentPromoCode(promoCodeId);
  	} catch (e) {
  		console.log(e);
  	}
  }

  // Добавить новый промокод
  async addPromoCode (promoCode: ICreatePromoCodeDto) {
  	try {
  		const { data } = await AdminPromoCodeService.addPromoCode(promoCode);
  		runInAction(() => {
  			this.promoCodes = [...this.promoCodes, { ...data, dateEnd: replaceFormatDate(data.dateEnd) as unknown as Date, createdAt: replaceFormatDate(data.createdAt) as unknown as Date }];
  			generalStore.changeAlertState('success', 'Промокод создан');
  		});
  	} catch (e) {
  		console.log(e);
  	}
  }

  // Изменить конкретный промокод
  async updatePromoCode (promoCodeId: string, newInfoRole: IUpdatePromoCodeDto) {
  	try {
  	  const { data } = await AdminPromoCodeService.updatePromoCode(promoCodeId, newInfoRole);
  		runInAction(() => {
  			this.promoCodes = this.promoCodes.map(promo =>
				  promo.id === data.id ? data : promo
			  );
  			generalStore.changeAlertState('success', 'Промокод изменён');
  		});
  	} catch (e) {
  		console.log(e);
  	}
  }

  // Удалить конкретный промокод
  async deletePromoCode (id: string) {
  	try {
  		await AdminPromoCodeService.deletePromoCode(id);
  		runInAction(() => {
  			generalStore.changeAlertState('success', 'Промокод удален');
  			this.promoCodes = this.promoCodes.filter(promoCode => promoCode.id !== id);
  		});
  	} catch (e) {
  		console.log(e);
  	}
  }

  changeAddModalState () {
  	this.addPromoModal = !this.addPromoModal;
  }

  changeCurrentUser (data) {
  	this.currentUser = data;
  }
}

export const adminPromoCodeStore = new AdminPromoCodeStore();
