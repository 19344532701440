import React from 'react';
import { Button, Card } from '@mui/material';

import './style.css';

export const BuyFourCoursesBanner = () => {
	return (
		<div className='buy-four-courses-banner-wrapper'>
			<Card className='buy-four-courses-banner'>
				<div className='buy-four-courses-banner__text-container'>
					<div className='buy-four-courses-banner__title-container'>
						<span>
							<b>4 предмета</b>
						</span>
						<span>
							по цене одного
						</span>
					</div>
					<span className='buy-four-courses-banner__description'>
						<b>Собери комбо</b> из нескольких предметов и получи максимальную выгоду:<br/>
						первые 2 предмета со <b>скидкой 50%</b>, а 3 и 4 <b>в подарок</b>
					</span>
				</div>
				<div className='buy-four-courses-banner__button-block'>
					<Button
						className='buy-four-courses-banner__button'
						variant='contained'
						color='primary'
						href='https://portal.lomonosov.school/shop'
						target='_blank'
					>
						Выбрать курсы
					</Button>
					<span>
						Готовься с нами ко всем экзаменам
					</span>
				</div>
			</Card>
		</div>
	);
};
