import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import { Link } from 'react-router-dom';
import { Button } from '@components';
import { checkLink, getNoun, images } from '@helpers';
import { IGetAllBannersItemResponse } from '@lomonosov/types';
import './style.css';
import 'swiper/css';

interface IPriceIncreaseBannerProps {
	banners: IGetAllBannersItemResponse[],
	userName: string,
	disciplineAng: string
}

export default (props: IPriceIncreaseBannerProps) => {
	const { disciplineAng = 'math', banners, userName } = props;

	const subject = useMemo(() => ({
		math: {
			name: 'математике',
			image: images.bannerMathematic
		},
		russian: {
			name: 'русскому языку',
			image: images.bannerRussian
		},
		social: {
			name: 'обществознанию',
			image: images.bannerSocial
		},
		history: {
			name: 'истории',
			image: images.bannerHistory
		},
		literature: {
			name: 'литературе',
			image: images.bannerLiterature
		},
		english: {
			name: 'английскому языку',
			image: images.bannerEnglish
		},
		physic: {
			name: 'физике',
			image: images.defaultBannerImg
		},
		biology: {
			name: 'биологии',
			image: images.defaultBannerImg
		},
		informatics: {
			name: 'биологии',
			image: images.defaultBannerImg
		}
	}), []);

	const replaceKeyWords = (str: string, deadline?) => {
		const bannerDay = Number(new Date(deadline)) - Number(new Date());
		let text = str.replace(/name/i, userName)
			.replace(/month/i, `${getMonthByNumber(new Date().getMonth())}`)
			.replace(/discipline/i, `${subject[disciplineAng]?.name}`);
		if (deadline) {
			text = text.replace(/day/i, `${String(new Date(bannerDay).getDate())} ${getNoun(new Date(bannerDay).getDate(), 'день', 'дня', 'дней')}`);
		}
		return text;
	};

	return (
		<div className='price-increase-banner'>
			<div className='price-increase-banner__container'>
				<div className='container__slider'>
					<Swiper
						slidesPerView={1}
						autoplay={{ delay: 5000, disableOnInteraction: false }} pagination
						spaceBetween={10}
						speed={1000}
						modules={[Pagination, Autoplay]}
					>
						{banners.map((banner:IGetAllBannersItemResponse, index: number) =>
							<SwiperSlide key={index}>
								<div className='container__slider__item' >
									<div className='item__block-content'>
										<span className='block-content__title'>
											{ replaceKeyWords(banner.name, banner.deadline) }
										</span>
										<div className='block-content__description'>
											{ replaceKeyWords(banner.description, banner.deadline) }
										</div>
										<div className='block-content__buttons'>
											{banner.link
												? checkLink(banner.link)
													? <a
														className='block-content__button' href={banner.link}
														target='_blank' rel='noreferrer'
													>
														<Button type='main_green'>
															<span>{banner.textButton ? banner.textButton : 'Написать' }</span>
														</Button>
													</a>
													: <Link className='block-content__button' to={banner.link}>
														<Button type='main_green'>
															<span>{banner.textButton ? banner.textButton : 'Перейти' }</span>
														</Button>
													</Link>
												: null
											}
										</div>
									</div>
								</div>
							</SwiperSlide>
						)}
					</Swiper>
				</div>
				<div className='container__block-images'>
					<img className='block-images__body' src={subject[disciplineAng]?.image ?? images.defaultBannerImg} alt='body' />
					<img className='block-images__hat' src={images.hat} alt='hat' />
					<img className='block-images__logo' src={images.textLogoDark} alt='logo'/>
					<img className='block-images__smile1' src={images.procent} alt='smile1'/>
					<img className='block-images__smile2' src={images.procent} alt='smile2'/>
				</div>
			</div>
		</div>
	);
};

const getMonthByNumber = (number: number) => {
	switch (number) {
	case 0: return 'январь';
	case 1: return 'февраль';
	case 2: return 'март';
	case 3: return 'апрель';
	case 4: return 'май';
	case 5: return 'июнь';
	case 6: return 'июль';
	case 7: return 'август';
	case 8: return 'сентябрь';
	case 9: return 'октябрь';
	case 10: return 'ноябрь';
	case 11: return 'декабрь';
	}
};
