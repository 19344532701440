import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { CustomModal, Table, DeleteModal } from '@components';
import { adminPromoCodeStore } from '@stores';
import { AddPromo } from '../../admin-blocks';

import '../admin-main-block.globalStyle.css';
import './style.css';

export default observer(() => {
	const [deletablePromoId, setDeletablePromoId] = useState<string>('');

	useEffect(() => {
		if (!adminPromoCodeStore.promoCodes?.length) {
			adminPromoCodeStore.getPromoCodes().then();
		}
	}, []);

	const deletePromo = () => {
		adminPromoCodeStore.deletePromoCode(deletablePromoId).finally(() => setDeletablePromoId(''));
	};

	return (
		<>
			<Table
				columns={columnsPromo}
				data={adminPromoCodeStore.promoCodes}
				search pagination
				actionHeader={{
					add: () => adminPromoCodeStore.changeAddModalState()
				}}
				actionRow={{
					delete: id => setDeletablePromoId(id)
				}}
				detailPanel={{
					Component: AddPromo
				}}
			/>
			<CustomModal
				textTitle='Добавить промокод' open={adminPromoCodeStore.addPromoModal}
				handleClose={() => adminPromoCodeStore.changeAddModalState()}
				viewClose='cross'
			>
				<AddPromo/>
			</CustomModal>
			<DeleteModal
				deleteName={adminPromoCodeStore.promoCodes.find(promo => promo.id === deletablePromoId)?.promo}
				open={!!deletablePromoId}
				handleClose={() => setDeletablePromoId('')}
				action={deletePromo}
			/>
		</>
	);
});

const columnsPromo = [
	{ field: 'id', name: 'id', copy: true, editable: false },
	{ field: 'promo', name: 'Промокод' },
	{ field: 'createdAt', name: 'Дата создания' },
	{ field: 'dateEnd', name: 'Дата окончания' }
];
