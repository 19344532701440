/* eslint-disable no-unused-vars */

import { IGetTariffPlanByIdResponse, IUpdateTariffPlanDto } from '@lomonosov/types';

export enum ShopNavigationStep {
	DIRECTION = 0,
	CATEGORY = 1,
	COURSES = 2,
	TARIFF = 3,
	RESULT = 4,
	AUTH = 5,
	PAYMENT = 6,
}

export enum TrialNavigationStep {
	DIRECTION = 0,
	CATEGORY = 1,
	COURSES = 2,
	AUTH = 3,
}

export enum DiscountPerCount {
	FIRST = 'first',
	SECOND = 'second',
	THIRD = 'third',
	FOURTH = 'fourth',
}

export interface IOptionItem {
	id: string;
	name: string;
}

export interface IGetShopButtonsItemResponse {
	id: string;
	category: IOptionItem | null;
	direction: IOptionItem | null;
	name: string;
	order: number;
	previewId: string | null;
	trial: boolean;
}

export interface IShopPeriod {
	id: string;
	name: string;
	description?: string;
	begins: string;
	ends: string;
	order?: number;
	price: number;
	marketingDiscount?: number;
	isRecommended?: boolean;
}

export interface IShopTariff {
	id: string;
	name: string;
	order?: number;
	description?: string;
	isRecommended?: boolean;
	parentTariffId?: string;
	subtitle?: string;
	periods: IShopPeriod[];
}

export interface IShopCourse {
	id: string;
	discipline: IOptionItem;
	name: string;
	order: number;
	preview: Array<{id: string}>;
	tariffs: IShopTariff[];
	teacher: {
		id: string;
		originalUser: {
			firstName: string;
			secondName: string;
			photo: string;
		}
	}
	tariff?: IShopTariff | null;
	period?: IShopPeriod | null;
}

export interface IShopTariffPlanPeriod extends IShopPeriod {
	isActive: boolean;
	consist: IPeriodConsist[];
}
export interface IShopTariffPlanTariff extends Omit<IShopTariff, 'parentTariffId' | 'periods'> {
	isActive: boolean;
	periods: IShopTariffPlanPeriod[];
}

export interface IShopTariffPlan {
	id: string;
	comparisonTable: string;
	tariffs: IShopTariffPlanTariff[];
	discountPerCount: Record<DiscountPerCount, number> | null;
}

export interface IGetShopCategoryResponse {
	category: IOptionItem | null;
	direction: IOptionItem | null;
	courses: IShopCourse[];
	previewId: string | null;
	trial: boolean;
	order: number;
	way: IOptionItem;
	tariffPlan: IShopTariffPlan;
}

export interface PurchaseCourse {
	courseId: string;
	tariffId: string;
	periodId: string;
	discountPerCount?: DiscountPerCount;
}
export interface PurchasePaymentDto {
	courses?: PurchaseCourse[];
	combinationPeriodIds?: string[];
	email?: string | null;
	phone?: string | null;
	promoCode?: string;
}

export interface PurchasePaymentResponse {
	OrderId: string;
	PaymentURL: string;
	Status: string;
	Success: boolean;
}

export interface IPeriodConsist {
	disabled: boolean;
	iconName: string;
	id: string;
	order: number;
	title: string;
}

export interface IShopInvoice {
	id: string;
	description: string | null;
	isClose: boolean;
	orderId: string | null;
	shortId: string;
	userId: string | null;
	items: Array<{
		begins: string;
		ends: string;
		id: string;
		price: number;
		tariff: {
			id: string;
			description: string;
			name: string;
			course: IShopCourse;
		}
		tariffId: string;
	}>
}

// /////////////// ADMIN INTERFACES ///////////////////////////////////////////////////

export interface IGetAdminShopButtonsItemResponse extends IGetShopButtonsItemResponse {
	isActive: boolean;
}

export interface ICreateAdminShopButtonResponse extends IGetAdminShopButtonsItemResponse {
	way: any;
	parentButtonId: string | null;
}

export interface IUpdateAdminShopButtonDto extends Partial<IGetShopButtonsItemResponse> {
	way?: IOptionItem;
	courses?: IOptionItem[];
	tariffPlan?: IOptionItem;
}

export interface UpdateTariffPlanDto extends IUpdateTariffPlanDto{
	discountPerCount?: Record<DiscountPerCount, number> | null;
}

export interface GetTariffPlanByIdResponse extends IGetTariffPlanByIdResponse {
	discountPerCount: Record<DiscountPerCount, number> | null;
}
