import { useState } from 'react';
import { authStore, generalStore } from '@stores';
import { ILoginEmailData } from '../types/auth';

interface IUseLoginResponse {
	error: string | null;
	isLoading: boolean;
	onLogin: (data: ILoginEmailData, callback?: () => void) => Promise<void>;
}

export const useLogin = (): IUseLoginResponse => {
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const onLogin = async (data: ILoginEmailData, callback?: () => void) => {
		try {
			setIsLoading(true);
			setError(null);
			await authStore.checkLoginExists(data.login);

			if (authStore.statusUser === 'new') {
				setError('Аккаунта с таким email не существует!');
				generalStore.changeAlertState('warning', 'Аккаунта с таким email не существует!');
				return;
			}

			await authStore.logIn(data.login, data.password);
			if (authStore.isAuth) {
				callback?.();
			}
		} catch (e) {
			setError(e.message);
		} finally {
			setIsLoading(false);
		}
	};

	return { error, isLoading, onLogin };
};
