import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import { IIssueCourseByFile, IIssueCourseResponse } from '@interfaces';

export default class {
	static async issueCoursesByFile (data: IIssueCourseByFile): Promise<AxiosResponse<IIssueCourseResponse>> {
		const body = {
			...data,
			courseId: data.course.id,
			tariffId: data.tariff.id
		};
		return $api.post<IIssueCourseResponse>('/purchases/senler', body);
	}

	static async issueCourseByCourses (data: IIssueCourseByFile, courseIds: { id: string, name: string }[]): Promise<AxiosResponse<any>> {
		const body = {
			courseIds: courseIds.map(id => id.id),
			givingCourse: { ...data, courseId: data.course.id, tariffId: data.tariff.id }
		};
		return $api.post<any>('/purchases/paid-or-all', body);
	}
}
