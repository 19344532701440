import { IGetPrivateUserResponse, Difficult } from '@lomonosov/types';

export enum Claim {
	getUsers = 'GET_USERS'
}

export interface IParentItem {
	firstName: string;
	secondName: string;
	phone: string;
}

export interface IParents {
	father: IParentItem;
	mother: IParentItem;
}

export interface IUserNotificationsItem {
	course: OptionSelect & { disciplineId: string };
	courseId: string;
	id: string;
	userId: string;
}

export interface IUser extends Omit<IGetPrivateUserResponse, 'phone' | 'email'>{
	telegramId: string;
	discordId: string;
	appleId: string;
	googleId: string;
	classroom: string | null;
	difficult: Difficult;
	difficultUpdated: Date | string;
	isVerifiedEmail: boolean;
	hasPassword: boolean;
	parents: IParents | null;
	phone: string | null;
	email: string | null;
	notifications: IUserNotificationsItem[];
}
