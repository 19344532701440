import React, { useEffect, useState, useCallback } from 'react';
import { IconButton } from '@mui/material';
import { Button, Table } from '@components';
import { observer } from 'mobx-react-lite';
import { adminUserStore, adminMentorsStore, userStore } from '@stores';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import './style.css';

export default observer(() => {
	const [isOpenPanel, setIsOpenPanel] = useState<boolean>(false);
	const [timer, setTimer] = useState<any>();
	const [isMeMentor, setIsMeMentor] = useState<boolean>();
	const [search, setSearch] = useState<string>('');
	const [page, setPage] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(5);

	const changeIsOpenPanel = useCallback(() => {
		adminMentorsStore.getMentors();
		adminUserStore.getUserMentors(adminUserStore.currentUser.id);
		setIsOpenPanel(!isOpenPanel);
	}, [isOpenPanel]);

	useEffect(() => {
		adminUserStore.getUserMentors(adminUserStore.currentUser.id)
			.then(() => setIsMeMentor(checkIsMeMentor()));
	}, []);

	const checkIsMeMentor = () => {
		return adminUserStore.mentors.some(mentor => userStore.user.id === mentor.originalUser.id);
	};

	const changeIsMeMentorState = useCallback(() => {
		const updatedMentors = isMeMentor
			? adminUserStore.mentors.filter(mentor => mentor.originalUser.id !== userStore.user.id)
			: [...adminUserStore.mentors, { id: userStore.user.mentor?.id, name: userStore.user.mentor?.name }];
		adminUserStore.updateUserMentors(adminUserStore.currentUser.id, updatedMentors).then(() => setIsMeMentor(!isMeMentor));
	}, [isMeMentor]);

	const deleteMentor = (mentor) => {
		const updatedMentors = adminUserStore.mentors.filter(m => m.id !== mentor.id);
		adminUserStore.updateUserMentors(adminUserStore.currentUser.id, updatedMentors)
			.then(() => setIsMeMentor(checkIsMeMentor()));
	};

	const addMentor = (mentor) => {
		adminUserStore.updateUserMentors(adminUserStore.currentUser.id, adminUserStore.mentors.concat(mentor)).then(() => {
			if (checkIsMeMentor()) setIsMeMentor(true);
		});
	};

	const changePage = async (event, newPage) => {
		setPage(newPage);
		await adminMentorsStore.getMentors(newPage, pageSize, search);
	};

	const changePageSize = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(parseInt(event.target.value, 10));
		await adminMentorsStore.getMentors(page, parseInt(event.target.value, 10), search);
	};

	const onChangeSearch = newSearch => {
		clearTimeout(timer);
		setTimer(setTimeout(() => adminMentorsStore.getMentors(0, pageSize, newSearch), 1000));
		setSearch(newSearch);
		setPage(0);
	};

	return (
		<div className='mentors-panel'>
			{userStore.isAdmin &&
				<>
					<h2 className='mentors-panel__title'>Менторы</h2>
					{adminUserStore.mentors.length
						? <div className='mentors-panel__content'>
							{adminUserStore.mentors.map(mentor =>
								<div key={mentor.id} className='mentors-panel__content_item'>
									{mentor.originalUser.firstName}&nbsp;
									{mentor.originalUser.secondName}&nbsp;
									{(userStore.user?.mentor?.id === mentor.id || userStore.isManager) &&
										<IconButton
											onClick={() => deleteMentor(mentor)}
										>
											<DeleteIcon className='mentors-panel__delete-button' />
										</IconButton>
									}
								</div>)
							}
						</div>
						: <span className='mentors-panel__no-records'>Менторов нет</span>
					}
				</>
			}
			{userStore.user.mentor &&
				<div className='mentors-panel__button'>
					<Button
						type={isMeMentor ? 'main_red' : 'main_green'}
						onClick={changeIsMeMentorState}
					>
						{isMeMentor ? 'Отказаться от менторства' : 'Стать ментором'}
					</Button>
				</div>
			}
			{userStore.isManager &&
				<div className='mentors-panel__button'>
					<Button
						disable={!userStore.isAdmin}
						type='main_blue'
						onClick={changeIsOpenPanel}
					>
						{isOpenPanel ? 'Скрыть' : 'Добавить ментора'}
					</Button>
				</div>
			}
			{isOpenPanel &&
				<Table
					columns={columnsMentorsTable}
					data={adminMentorsStore.mentors}
					search={{ prop: search, action: onChangeSearch }}
					pagination={{
						page: page,
						onPageChange: changePage,
						rowsPerPage: pageSize,
						onRowsPerPageChange: changePageSize,
						count: adminMentorsStore.pageCount
					}}
					customCellsRender={[
						{
							name: 'Действие',
							align: 'center',
							width: 70,
							render: (state) => {
								return (
									adminUserStore.mentors.some(m => m.id === state.id)
										? <IconButton
											onClick={() => deleteMentor(state)}
										>
											<DeleteIcon className='mentors-panel__delete-button' />
										</IconButton>
										: <IconButton
											onClick={() => addMentor(state)}
										>
											<AddIcon className='mentors-panel__add-button' />
										</IconButton>
								);
							}
						}
					]}
				/>
			}
		</div>
	);
});

const columnsMentorsTable = [
	{ field: 'name', name: 'Имя' },
	{ field: 'description', name: 'Описание' }
];
