import React from 'react';

interface DocumentIconProps {
	className?: string;
	classes?: {
		text?: string;
	}
	text?: string;
}

export const DocumentIcon = (props: DocumentIconProps) => {
	const { className, classes, text } = props;
	return (
		<svg
			id='eNbCdXw0e1f1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 62 74' shapeRendering='geometricPrecision'
			textRendering='geometricPrecision'
			className={className}
		>
			<path
				d='M42.4153,0h-29.7214C8.35189,0,4.81796,3.53639,4.81796,7.87595v29.12385h-.76925c-1.75246,0-3.17371,1.4199-3.17371,3.1739v19.2473c0,1.7539,1.42109,3.1736,3.17371,3.1736h.76925v3.5293c0,4.3443,3.53393,7.8759,7.87594,7.8759h40.5536c4.3392,0,7.8736-3.5317,7.8736-7.8759v-47.4836L42.4153,0ZM9.20096,43.6472c.93014-.1572,2.23744-.2757,4.07944-.2757c1.8614,0,3.1881.3553,4.0795,1.069.8516.6726,1.4262,1.7827,1.4262,3.0892c0,1.3061-.4354,2.4165-1.2277,3.1688-1.0305.97-2.5544,1.4054-4.3371,1.4054-.3968,0-.7524-.0196-1.0305-.0582v4.7727h-2.98984v-13.1712ZM53.2475,69.1765h-40.5536c-1.6812,0-3.0503-1.3691-3.0503-3.0526v-3.5293h37.8043c1.7527,0,3.1738-1.4197,3.1738-3.1736v-19.2473c0-1.754-1.4211-3.1739-3.1738-3.1739h-37.8043v-29.12385c0-1.67879,1.3692-3.04785,3.0503-3.04785l27.9169-.02917v10.31907c0,3.0141,2.4456,5.462,5.462,5.462l10.1087-.029.1137,45.5727c0,1.6837-1.3666,3.0528-3.0477,3.0528ZM20.714,56.7579v-13.1107c1.1089-.1765,2.5542-.2757,4.0795-.2757c2.535,0,4.1788.4548,5.4668,1.4246c1.3861,1.0305,2.2568,2.6731,2.2568,5.0313c0,2.5545-.9301,4.318-2.2183,5.4063-1.4054,1.1683-3.5448,1.7224-6.1585,1.7224-1.5652,0-2.6741-.0992-3.4263-.1982Zm21.6144-7.7623v2.4551h-4.7932v5.3677h-3.0298v-13.3477h8.1592v2.4744h-5.1294v3.0505h4.7932Z'
				transform='translate(.00195 0.0002)'
			/>
			<rect
				width='49' height='25' rx='3' ry='3'
				transform='translate(1.104799 37.286058)' strokeWidth='0'
			/>
			{text &&
				<text
					className={classes?.text}
					dx='0' dy='0'
					fontSize='16' fontWeight='700' transform='translate(12 55)'
					fill='#fff' strokeWidth='0'
				>
					<tspan y='0' fontWeight='700' strokeWidth='0'>
						{text}
					</tspan>
				</text>
			}
		</svg>
	);
};
