import React, { useMemo } from 'react';
import { Button, styled } from '@mui/material';

import { buttonTypes } from './button-types';

import './style.css';

export default (props: ButtonProps) => {
	const {
		children,
		icon,
		onClick,
		disable = false,
		type,
		glare = false,
		href = '',
		submit,
		className = '',
		style = null,
		variant = 'contained'
	} = props;

	const customStyle = useMemo(() => ({
		outlined: {
			transition: '0.25s',
			border: `4px solid ${style?.color}`,
			'&:hover': {
				boxShadow: `inset 0 0 0 2em ${style?.color}`,
				// backgroundColor: style?.color,
				color: style?.textColor
			}
		},
		contained: {
			backgroundColor: style?.color,
			color: style?.textColor,
			'&:hover': {
				backgroundColor: style?.color,
				// boxShadow: `0 4px 10px 0 ${style?.color}`,
				filter: 'brightness(0.8)',
				color: style?.textColor
			}
		}
	}), [style?.color, style?.textColor]);

	const styles = type === 'custom' ? customStyle[variant] : undefined;

	const StyledButton = styled(Button)(({ theme }) => (
		{
			...buttonTypes[theme.palette.mode][type],
			fontFamily: 'Gotham Pro, sans-serif',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '14px',
			textTransform: 'none',
			borderRadius: '7px',
			'&:disabled': {
				backgroundColor: '#C1C1C1',
				color: '#EEEEEE'
			},
			...styles
		}
	));

	return (
		<StyledButton
			className={`button ${glare && 'glare'} ${className}`}
			startIcon={icon}
			onClick={onClick}
			disabled={disable}
			href={href}
			type={submit ? 'submit' : 'button'}
			formTarget='_blank'
			target='_blank'
			rel='noreferrer'
		>
			{children}
		</StyledButton>
	);
};

export interface ButtonProps {
	className?: string
	children?: React.ReactNode,
	icon?: React.ReactFragment,
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
	disable?: boolean,
	glare?: boolean,
	href?: string,
	submit?: boolean,
	style?: {
		color?: string,
		textColor?: string
	},
	variant?: 'text' | 'contained' | 'outlined' | undefined | string
	type:
		'main_blue' |
		'main_blue_light' |
		'main_green' |
		'main_orange' |
		'main_red' |
		'main' |
		'outline' |
		'outline_blue' |
		'outline_green' |
		'outline_orange' |
		'outline_red' |
		'disabled' |
		'white' |
		'custom'
}
