import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import { IUserRankings } from 'src/interfaces/IUserRankings.interface';

export default class {
	/* Рейтинг участников */
	static async getRankings (courseId: string): Promise<AxiosResponse<IUserRankings>> {
		return $api.get<IUserRankings>(`/answers/rate/courses/${courseId}`);
	}
}
