import React, { useEffect, useState } from 'react';
import { IGetCategoriesResponse } from '@lomonosov/types';
import { debounce } from '@mui/material/utils';

import { adminCategoryStore, adminCoursesStore, adminShopStore, adminTariffPlansStore, generalStore } from '@stores';
import Table, { IColumn } from '@components/table';
import { DetailChildButtons } from '../';
import { IGetAdminShopButtonsItemResponse, IUpdateAdminShopButtonDto } from '@interfaces';
import { UploadPreview } from '../../../../admin-components/upload-preview';
import { observer } from 'mobx-react-lite';
import { Avatar } from '@mui/material';
import { constants } from '@helpers';

interface IProps {
	dataL: IGetAdminShopButtonsItemResponse;
	categories: IGetCategoriesResponse;
	buttons: IGetAdminShopButtonsItemResponse[];
	onUploadPreview: (fileId: string) => void;
}

export default observer((props: IProps) => {
	const { dataL, onUploadPreview } = props;
	const [data, setData] = useState<IGetAdminShopButtonsItemResponse[]>([]);

	useEffect(() => {
		if (!adminCategoryStore.categories.length) { adminCategoryStore.getCategories(); }
		setData(props.buttons);
	}, []);

	const addButton = async (newState: any) => {
		newState.parentButtonId = dataL.id;
		const res = await adminShopStore.addButton(newState);
		if (res) { setData([...data, res]); }
	};

	const deleteButton = async (id: string) => {
		const res = await adminShopStore.deleteButton(id);
		if (res) {
			const index = data.findIndex((d) => d.id === id);
			data.splice(index, 1);
			setData([...data]);
		}
	};

	const editButton = async (id: string, newState: IUpdateAdminShopButtonDto) => {
		const res = await adminShopStore.editButton(id, newState);
		if (res) {
			const index = data.findIndex((d) => d.id === id);
			data[index] = res;
			setData([...data]);
		}
	};

	const shopButtonsOptions: IColumn[] = [
		{ field: 'trial', name: 'trial', type: 'boolean', defaultValue: false, width: 80, align: 'center' },
		{ field: 'isActive', name: 'isActive', type: 'boolean', defaultValue: false, width: 80, align: 'center' },
		{
			field: 'previewId',
			name: 'Изображение',
			type: 'element',
			element: (data) => (
				data.id &&
				<Avatar alt={data.name} src={`${constants.URL}/files/${data.previewId}`} className='admin-main-shop__image'/>
			),
			align: 'center'
		},
		{ field: 'name', name: 'name' },
		{ field: 'category', name: 'category', options: props.categories, type: 'select' }
	];
	const openDetailRow = async (id: string) => {
		const idCategory = data.find((button) => button.id === id)?.category?.id;
		const idDirection = dataL?.direction?.id;

		const onUpdatePreview = async (fileId: string) => {
			const res = await adminShopStore.editButton(id, { previewId: fileId });
			if (res) {
				generalStore.changeAlertState('success', 'Изображение обновлено');
				const index = data.findIndex((d) => d.id === id);
				data[index] = { ...data[index], previewId: fileId };
				setData([...data]);
			}
		};

		adminCoursesStore.isArchive = true;
		adminCoursesStore.changeFilterField([{ id: idCategory }, { id: idDirection }], ['category', 'direction'], 0);
		const courses = await adminCoursesStore.getCourses(100, 0);
		const tariffPlan = await adminTariffPlansStore.getPlans();
		const info = await adminShopStore.getButtonShop(id);
		return {
			courses,
			tariffPlan,
			info,
			onUpdatePreview
		};
	};

	const swapRow = debounce(async (swapItems: IGetAdminShopButtonsItemResponse[]) => {
		const orderingButtons = swapItems.map((button, i) => ({ id: button.id, order: i }));
		adminShopStore.editOrderButtons(orderingButtons);
	}, 1000);

	// const onUpdatePreview = async (fileId: string) => {
	// 	const res = await adminShopStore.editButton(dataL.id, { previewId: fileId });
	// 	if (res) {
	// 		generalStore.changeAlertState('success', 'Изображение обновлено');
	// 	}
	// };

	return (
		<>
			<UploadPreview
				className='admin-main-shop__upload'
				fileId={dataL.previewId}
				isLocalDelete
				onChangeImage={onUploadPreview}
			/>
			<Table
				swap={swapRow}
				styleOption={{
					size: 'small'
				}}
				columns={shopButtonsOptions}
				data={data}
				actionRow={{
					add: addButton,
					edit: editButton,
					delete: deleteButton
				}}
				detailPanel={{
					Component: DetailChildButtons,
					action: openDetailRow
				}}
			/>
		</>
	);
});
