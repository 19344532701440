import React, { useState } from 'react';
import Input from '@components/input';
import { ICombo } from '@interfaces';
import { UploadPreview } from '../../../../admin-components/upload-preview';
import Button from '@components/button';
import { observer } from 'mobx-react-lite';
import { adminComboCoursesStore } from '@stores';
import { TableComboCourses } from '../table-combo-courses';
import { TableComboTariffs } from '../table-combo-tariffs';

interface DetailComboProps {
	dataL: ICombo;
}

export const DetailCombo = observer((props: DetailComboProps) => {
	const { dataL } = props;
	const [description, setDescription] = useState<string>(dataL.description || '');
	const [imageId, setImageId] = useState<string | null>(dataL.previewId || null);

	const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDescription(e.target.value);
	};

	const onSaveCombo = async () => {
		await adminComboCoursesStore.updateCombo(
			dataL.id,
			{
				...dataL,
				previewId: imageId,
				description: description || null,
				addingCourses: adminComboCoursesStore.addingCourses,
				deletingCourses: adminComboCoursesStore.deletingCourses
			}
		);
	};

	const onChangePreview = async (newImageId: string | null = null) => {
		setImageId(newImageId);
		await adminComboCoursesStore.updateCombo(
			dataL.id,
			{
				...dataL,
				previewId: newImageId,
				addingCourses: [],
				deletingCourses: []
			}
		);
	};

	return (
		<div className='detail-combo'>
			<div className='detail-combo__block'>
				<UploadPreview
					fileId={imageId}
					onChangeImage={onChangePreview}
					onDelete={onChangePreview}
				/>
				<div className='detail-combo__form'>
					<Input
						value={description}
						onChange={handleChangeDescription}
						multiline
						rows={4}
						label='Описание'
						name='description'
					/>
					<Button type='main_blue' className='detail-combo__button' onClick={onSaveCombo}>
						Сохранить
					</Button>
				</div>
			</div>
			<TableComboCourses onSaveCombo={onSaveCombo} />
			{/* <div className='detail-combo__table-btn'>
				<Button type='main_blue' onClick={onSaveCombo}>
					Сохранить курсы
				</Button>
			</div> */}
			<TableComboTariffs comboId={dataL.id} />
		</div>
	);
});
