import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { Button, Input } from '@components';
import { adminUserStore, userStore } from '@stores';

import './style.css';
import { convertPhone } from '@helpers';

export default observer(() => {
	const [user, setUser] = useState<any>(adminUserStore.currentUser);

	useEffect(() => {
		setUser(adminUserStore.currentUser);
	}, [adminUserStore.currentUser]);

	const updateUserInfo = () => {
		const newUser = {
			...user,
			dateOfBirth: user.dateOfBirth,
			phone: user.phone ? convertPhone(user.phone) : null,
			email: user.email ? user.email : null
		};

		adminUserStore.updateUser(newUser);
	};

	const loginUser = useCallback(() => {
		adminUserStore.getUserToken(user.id);
	}, [user.id]);

	return (
		<div className='user-info'>
			<div className='user-info__item'>
				<Input
					disabled={true}
					label='vkId'
					value={user.vkId ?? ''}
					onChange={e => setUser({ ...user, vkId: e.target.value })}
				/>
			</div>
			<div className='user-info__item'>
				<Input
					disabled={!userStore.isAdmin}
					label='Имя'
					value={user.firstName}
					onChange={e => setUser({ ...user, firstName: e.target.value })}
				/>
			</div>
			<div className='user-info__item'>
				<Input
					disabled={!userStore.isAdmin}
					label='Фамилия'
					value={user.secondName}
					onChange={e => setUser({ ...user, secondName: e.target.value })}
				/>
			</div>
			<div className='user-info__item'>
				<Input
					disabled={!userStore.isAdmin}
					label='Дата рождения'
					value={user.dateOfBirth ?? ''}
					onChange={e => setUser({ ...user, dateOfBirth: e.target.value })}
				/>
			</div>
			<div className='user-info__item'>
				<Input
					disabled={!userStore.isAdmin}
					label='Телефон'
					value={user.phone ?? ''}
					onChange={e => setUser({ ...user, phone: e.target.value })}
				/>
			</div>
			<div className='user-info__item'>
				<Input
					disabled={!userStore.isAdmin}
					label='Email'
					value={user.email ?? ''}
					onChange={e => setUser({ ...user, email: e.target.value })}
				/>
			</div>
			<div className='user-info__item'>
				<Input
					disabled={!userStore.isAdmin}
					label='Пароль'
					value={user.password ?? ''}
					onChange={e => setUser({ ...user, password: e.target.value })}
				/>
			</div>
			<div className='user-info__item'>
				<Button
					disable={!userStore.isAdmin}
					type={user.isBanned ? 'main_blue' : 'main_red'}
					onClick={() =>
						adminUserStore.changeUserBanStatus(user.id, !user.isBanned)
							.then(() => setUser(adminUserStore.currentUser))
					}
				>
					{user.isBanned ? 'Разбанить' : 'Забанить'}
				</Button>
			</div>
			<div className='user-info__item'>
				<Button type='outline_green' onClick={loginUser}>
					Зайти под этим аккаунтом
				</Button>
			</div>
			<div className='user-info__item'>
				<Button
					disable={!userStore.isAdmin}
					type='main_green'
					onClick={() => updateUserInfo()}
				>
					Сохранить
				</Button>
			</div>
		</div>
	);
});
