import { makeAutoObservable, runInAction } from 'mobx';
import {
	ICreateTemplateDto,
	IGetAllHomeworkTemplatesItemResponse,
	// ICreateTaskTemplateDto,
	IUpdateTemplateDto,
	IGetAllHomeworkAnswersItemResponse,
	IGetHomeworkTemplateByIdResponse,
	HomeworkTemplateType, TaskTemplateType
} from '@lomonosov/types';

import { AdminHomeworkService, IGetTemplatesHomeworkQueryParams } from '@services';
import { adminLessonsStore } from './admin-lessons-store';
import { generalStore } from '../general-stores';

type DeepPartial<T> = T extends object ? {
	[P in keyof T]?: DeepPartial<T[P]>;
} : T;

class AdminHomeworkStore {
	templates: IGetAllHomeworkTemplatesItemResponse[] = [];
	currentType: TaskTemplateType | null = null;
	currentTemplate: DeepPartial<IGetHomeworkTemplateByIdResponse> = { taskTemplates: [defaultQuestion] };
	pagination = {
		pages: 1,
		currentPage: 1
	};

	filterFields: Record<keyof IGetTemplatesHomeworkQueryParams, any> = defaultFilterFields;
	isLoadingAddHomework: boolean = false;

	isLoading: boolean = false;

	answers: IGetAllHomeworkAnswersItemResponse[];
	queryParams: IGetTemplatesHomeworkQueryParams | null = null;

	currentHomeworkForLesson;

	constructor () {
		makeAutoObservable(this);
	}

	// Шаблоны ДЗ
	/**
	 * Получение всех шаблонов ДЗ
	 * @param take количество получаемых записей
	 * @param skip количество записей пропустить
	 * @param queryParams фильтры (discipline, type)
	 **/
	async getTemplates (take: number = 5, skip: number = 0) {
		this.setLoading(true);
		try {
			const { data } = await AdminHomeworkService.getTemplates(take, skip, this.queryParams);
			runInAction(() => {
				this.templates = data.templates;
				this.pagination.pages = Math.ceil(data.count / take);
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async getTemplateById (templateId: string) {
		this.setLoading(true);
		try {
			const { data } = await AdminHomeworkService.getTemplateById(templateId);
			runInAction(() => {
				this.currentTemplate = data;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async updateTemplate (templateId: string, template: IUpdateTemplateDto) {
		try {
			const { data } = await AdminHomeworkService.updateTemplate(templateId, template);
			runInAction(() => {
				this.currentTemplate = data;
				this.getTemplates();
			});
			generalStore.changeAlertState('success', 'ДЗ обновлено');
		} catch (e) {
			console.log(e);
		}
	}

	async createTemplate (template: ICreateTemplateDto) {
		try {
			const { data } = await AdminHomeworkService.createTemplate(template);
			runInAction(() => {
				this.currentTemplate = data;
				this.getTemplates();
			});
			generalStore.changeAlertState('success', 'ДЗ создано');
		} catch (e) {
			console.log(e);
		}
	}

	async deleteTemplate (templateId: string) {
		this.setLoading(true);
		try {
			await AdminHomeworkService.deleteTemplate(templateId);
			await this.getTemplates();
			generalStore.changeAlertState('success', 'ДЗ удалено');
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async changePage (page: number, take: number) {
		await this.getTemplates(take, (page - 1) * take);
		this.pagination.currentPage = page;
	}

	clearCurrentTemplate () {
		this.currentTemplate = { taskTemplates: [defaultQuestion] };
	}

	setTypeTemplate (type: HomeworkTemplateType) {
		this.currentTemplate.type = type;
	}

	changeFilterField (newValue, field: keyof IGetTemplatesHomeworkQueryParams) {
		this.filterFields[field] = newValue;
		this.queryParams = {
			...this.queryParams,
			[field]: newValue?.id || newValue
		};
	}

	resetFilterFields () {
		this.pagination.currentPage = 1;
		this.filterFields = defaultFilterFields;
		this.getTemplates(5, 0).then();
	}

	async createHomeworkForLesson (homework) {
		this.isLoadingAddHomework = true;
		try {
			const { data } = await AdminHomeworkService.createHomework(homework);
			runInAction(() => {
				this.currentHomeworkForLesson = data;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoadingAddHomework = false;
		}
	}

	async updateHomeworkForLesson (homeworkId, homework) {
		this.isLoadingAddHomework = true;
		try {
			const { data } = await AdminHomeworkService.updateHomework(homeworkId, homework);
			runInAction(() => {
				this.currentHomeworkForLesson = data;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoadingAddHomework = false;
		}
	}

	async deleteHomeworkForLesson (homeworkId) {
		try {
			await AdminHomeworkService.deleteHomework(homeworkId);
			runInAction(() => {
				adminLessonsStore.currentLesson.homeworks = adminLessonsStore.currentLesson.homeworks
					.filter(homework => homework.id !== homeworkId);
			});
		} catch (e) {
			console.log(e);
		}
	}

	setLoading (state) {
		this.isLoading = state;
	}
}

const defaultQuestion = {
	order: 0,
	explanation: '',
	rate: 1,
	text: 'Новый вопрос',
	answers: ['Ответ']
};

const defaultFilterFields: Record<keyof IGetTemplatesHomeworkQueryParams, any> = {
	discipline: null,
	type: null,
	search: ''
};

export const adminHomeworkStore = new AdminHomeworkStore();
