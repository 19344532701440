import React, { useState } from 'react';
import './style.css';

interface DragAndDropProps {
	children: React.ReactNode;
	handleDropFiles: (e: React.DragEvent<HTMLDivElement>) => void;
	className?: string;
}

export default (props:DragAndDropProps) => {
	const { children, handleDropFiles, className = '' } = props;

	const [dragging, setDragging] = useState(false);
	const [dragCounter, setDragCounter] = useState(0);

	const onDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDragIn = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragCounter(dragCounter + 1);
		if (e.dataTransfer.items.length > 0) {
			setDragging(true);
		}
	};

	const handleDragOut = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragCounter(dragCounter - 1);
		if (dragCounter - 1 === 0) {
			setDragging(false);
		}
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragging(false);
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			handleDropFiles(e);
			e.dataTransfer.clearData();
			setDragCounter(0);
		}
	};

	return (
		<div
			onDragEnter={e => handleDragIn(e)} onDragLeave={e => handleDragOut(e)}
			onDragOver={e => onDragOver(e)} onDrop={e => handleDrop(e)}
			className={className}
		>
			{ dragging &&
			(<div className='drag-and-drop__container'>
				<div className='drag-and-drop__container_item' >
					<div>drop here :)</div>
				</div>
			</div>)
			}
			{ children }
		</div>
	);
};
