import { makeAutoObservable, runInAction } from 'mobx';
import { AdminAnalyticsService } from '@services';
import { replaceFormatDateForUsers } from '@helpers';

class AdminRoleStore {
	courses: any = [];
	lessons = [];
	tasks: any = [];
	answers = [];
	taskActivity = [];
	currentTaskActivity: any = [];
	usersActivity = [];
	currentUserActivity = [];
	isLoading: boolean = false;
	pagination = {
		courses: defaultPagePagination,
		lessons: defaultPagePagination,
		tasks: defaultTablePagination,
		answers: defaultTablePagination
	};

	paginationStudentActivity = {
		users: defaultTablePagination,
		tasks: defaultTablePagination,
		currentTask: defaultTablePagination,
		currentUser: defaultTablePagination
	};

	paramsFile: string = ''

	filtersCourses = defaultFiltersCourses;
	filtersStudentActivity = defaultFiltersStudents;

	currentCourseId: string = '';
	currentUserHomeworkId: string = '';
	taskType: { id: string, name: string } | null = null;

	activeScreen: number = 0;

	constructor () {
		makeAutoObservable(this);
	}

	setCurrentCourseId (id: string) {
		this.currentCourseId = id;
	}

	setCurrentUserHomeworkId (id: string) {
		this.currentUserHomeworkId = id;
	}

	setTaskType (type) {
		this.taskType = type;
		this.getTasksAnalysis(8, 0);
	}

	async getCoursesAnalysis (take: number = 8, skip: number = 0) {
		this.isLoading = true;
		const params = ['discipline', 'direction', 'category']
			.map((value) => this.filtersCourses[value] !== null
				? `&${value}=${this.filtersCourses[value].id}`
				: ''
			).join('');
		try {
			const { data } = await AdminAnalyticsService.getCoursesAnalysis(take, skip, params);
			runInAction(() => {
				this.courses = data.courses;
				this.pagination.courses.pages = Math.ceil(data.count / take);
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	async getLessonsAnalysis (take: number = 8, skip: number = 0, courseId: string = this.currentCourseId) {
		this.isLoading = true;
		try {
			const { data } = await AdminAnalyticsService.getLessonsAnalysis(take, skip, courseId);
			runInAction(() => {
				this.lessons = data.lessons;
				this.pagination.lessons.pages = Math.ceil(data.count / take);
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	async getTasksAnalysis (take: number = 8, skip: number = 0, courseId: string = this.currentCourseId, type = this.taskType?.id || null) {
		this.isLoading = true;
		try {
			const { data } = await AdminAnalyticsService.getTasksAnalysis(take, skip, courseId, type);
			runInAction(() => {
				this.tasks = data.homeworks || [];
				this.pagination.tasks.totalCount = data.count;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	downloadFileUserAnswers (): string {
		let newParams = this.paginationStudentActivity.tasks.queryParams;
		newParams = newParams.replace(`&type=${this.filtersStudentActivity.type?.id}`, '');
		try {
			return AdminAnalyticsService.getUserAnswersFileActivity(newParams);
		} catch (e) {
			console.log(e);
			throw e;
		}
	}

	downloadFileCurrentTaskAnswers (taskId, type: 'rate' | 'text'): string {
		try {
			return AdminAnalyticsService.getCurrentTaskAnswersFile(taskId, type);
		} catch (e) {
			console.log(e);
			throw e;
		}
	}

	async getTaskUsersAnalysis (take: number = 8, skip: number = 0) {
		this.isLoading = true;
		try {
			const { data } = await AdminAnalyticsService.getTaskUsersAnalysis(take, skip, this.currentUserHomeworkId);
			runInAction(() => {
				this.answers = data.answers.map(answer => {
					return {
						name: answer.name,
						scores: this.checkVerifiedAnswer(answer.status, answer.scores).scores,
						comment: this.checkVerifiedAnswer(answer.status, answer.scores, answer.comment).comment
					};
				});
				this.pagination.answers.totalCount = data.count;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	async getTasksActivity (take = 8, skip = 0, queryParams = this.paginationStudentActivity.tasks.queryParams) {
		this.isLoading = true;
		try {
			const { data } = await AdminAnalyticsService.getTasksActivity(take, skip, queryParams);
			runInAction(() => {
				this.taskActivity = data.homeworks.map(task => {
					const maxScores = task.avg.split('/')[1];
					return {
						...task,
						date: replaceFormatDateForUsers(task.date),
						avg: +maxScores > 0 ? task.avg.replace('/', ' из ') : 'Отсутствует',
						type: typesTask[task.type]
					};
				});
				this.paginationStudentActivity.tasks.totalCount = data.count;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	async getCurrentTaskActivity (take, skip, taskId) {
		this.isLoading = true;
		try {
			const { data } = await AdminAnalyticsService.getCurrentTaskActivity(take, skip, taskId);
			runInAction(() => {
				this.currentTaskActivity = data.answers || [];
				this.paginationStudentActivity.currentTask.totalCount = data.count;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	async getUsersActivity (take = 8, skip = 0, search = this.paginationStudentActivity.users.queryParams) {
		this.isLoading = true;
		try {
			const { data } = await AdminAnalyticsService.getUsersActivity(take, skip, search);
			runInAction(() => {
				this.usersActivity = data.users;
				this.paginationStudentActivity.users.totalCount = data.count;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	async getCurrentUserActivity (take = 8, skip = 0, userId: string = '') {
		this.isLoading = true;
		try {
			const { data } = await AdminAnalyticsService.getCurrentUserActivity(take, skip, userId);
			runInAction(() => {
				this.currentUserActivity = data.answers.map(answer => {
					return (
						{
							...answer,
							name: answer.homework.template.name
						}
					);
				});
				this.paginationStudentActivity.currentUser.totalCount = data.count;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	changeActiveScreen (screen: number) {
		this.activeScreen = screen;
	}

	async changePageCourses (page, take, table: 'courses' | 'answers' | 'lessons' | 'tasks') {
		this.pagination[table].currentPage = page;
		if (table !== 'courses' && table !== 'lessons') {
			this.pagination[table].rows = take;
		}
		switch (table) {
		case 'courses': await this.getCoursesAnalysis(take, (page - 1) * take); break;
		case 'lessons': await this.getLessonsAnalysis(take, (page - 1) * take, this.currentCourseId); break;
		case 'tasks': await this.getTasksAnalysis(take, take * page, this.currentCourseId); break;
		case 'answers': await this.getTaskUsersAnalysis(take, take * page); break;
		}
	}

	async changePageStudentActivity (page, take, table: 'tasks' | 'users' | 'currentTask' | 'currentUser', id?: string) {
		this.paginationStudentActivity[table].currentPage = page;
		this.paginationStudentActivity[table].rows = take;
		switch (table) {
		case 'tasks': await this.getTasksActivity(take, take * page); break;
		case 'currentTask': await this.getCurrentTaskActivity(take, take * page, id); break;
		case 'users': await this.getUsersActivity(take, take * page); break;
		case 'currentUser': await this.getCurrentUserActivity(take, take * page, id); break;
		}
	}

	changeFilterField (value, field) {
		this.filtersCourses[field] = value;
		this.filtersCourses.isFiltered = true;
		this.getCoursesAnalysis();
	}

	changeFilterFieldStudentActivity (value, field) {
		this.filtersStudentActivity[field] = value;
		this.filtersStudentActivity.isFiltered = true;
	}

	async getFiltersStudentActivity (table: 'tasks' | 'users') {
		const params = filterParams.map((value) => (this.filtersStudentActivity[value] !== null && this.filtersStudentActivity[value] !== '')
			? `&${value}=${this.filtersStudentActivity[value].id ?? this.filtersStudentActivity[value]}`
			: ''
		).join('');
		this.paginationStudentActivity[table].currentPage = 0;
		this.paginationStudentActivity[table].queryParams = params.replace('&', '?');
		switch (table) {
		case 'tasks': await this.getTasksActivity(this.paginationStudentActivity.tasks.rows, 0, params.replace('&', '?')); break;
		case 'users': await this.getUsersActivity(this.paginationStudentActivity.users.rows, 0, params.replace('&', '?')); break;
		}
	}

	resetFilterCourse () {
		this.filtersCourses = defaultFiltersCourses;
		this.getCoursesAnalysis();
	}

	resetFiltersStudentActivity () {
		this.filtersStudentActivity = defaultFiltersStudents;
		this.getTasksActivity(this.paginationStudentActivity.tasks.rows, 0);
	}

	resetCurrentTaskPagination () {
		this.paginationStudentActivity.currentTask.currentPage = 0;
	}

	checkVerifiedAnswer (status: 'varification' | 'verified', scores: string, comment: string = '') {
		const userScores = scores.split('/')[0];
		const maxScores = scores.split('/')[1];
		if (status === 'verified') {
			return {
				scores: `${userScores} из ${maxScores}`,
				comment: comment
			};
		} else if (+userScores > 0) {
			return {
				scores: `${userScores} — Авт. проверка`,
				comment: '[Вторая часть не проверена]'
			};
		} else {
			return {
				scores: 'Не проверено',
				comment: '[Вторая часть не проверена]'
			};
		}
	}
}

const defaultFiltersCourses = {
	discipline: null,
	category: null,
	direction: null,
	isFiltered: false
};

const defaultFiltersStudents: FiltersProps = {
	discipline: null,
	type: null,
	isFiltered: false,
	start: '',
	end: '',
	search: ''
};

const filterParams = ['discipline', 'start', 'end', 'type', 'search'];

const defaultTablePagination = {
	currentPage: 0,
	totalCount: 1,
	rows: 8,
	queryParams: ''
};

const defaultPagePagination = {
	currentPage: 1,
	pages: 1
};

const typesTask = {
	probe: 'Пробник',
	automatic: 'Автоматическая проверка',
	manual: 'Ручная проверка'
};

interface FiltersProps {
	discipline: { id: string, name: string } | null
	type: { id: string, name: string } | null
	isFiltered: boolean
	start: string
	end: string
	search: string
}

export const adminAnalyticsStore = new AdminRoleStore();
