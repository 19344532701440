import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Table } from '@components';
import { adminEmployeesStore } from '@stores';

export default observer(() => {
	const [page, setPage] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(5);

	useEffect(() => {
		if (!adminEmployeesStore.employees.length) {
			adminEmployeesStore.getAdmins(page, pageSize);
		}
	}, []);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		adminEmployeesStore.getAdmins(newPage, pageSize);
	};

	const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(parseInt(event.target.value, 10));
	};

	return (
		<>
			<Table
				columns={[
					{ field: 'shortId', name: 'ID', copy: true },
					{ field: 'firstName', name: 'Имя' },
					{ field: 'secondName', name: 'Фамилия' },
					{ field: 'roles', name: 'Роли' }
				]}
				data={adminEmployeesStore.employees}
				pagination={{
					page: page,
					onPageChange: handleChangePage,
					rowsPerPage: pageSize,
					onRowsPerPageChange: handleChangePageSize,
					count: adminEmployeesStore.totalCount
				}}
			/>
		</>
	);
});
