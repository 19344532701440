import React from 'react';
import { observer } from 'mobx-react-lite';

import { DropdownList } from '@components';
import { userCourseStore } from '@stores';
import { SelectOption } from '@components/dropdown-list';

export default observer(() => {
	const onSelectDiscipline = (_e, value, course) => {
		if (value === 'Все предметы') {
			userCourseStore.setSelectedCourse(null);
		} else {
			userCourseStore.setSelectedCourse(course);
		}
	};

	return (
		<div className='courses-menu'>
			<div className='courses-menu__field'>
				<DropdownList
					className='courses-menu__dropdown-list'
					name={userCourseStore.selectedCourse?.name}
					options={[{ name: 'Все предметы', id: 'all' }, ...userCourseStore.disciplines] as SelectOption<string>[]}
					value={userCourseStore.selectedCourse}
					fieldOptionKey='name'
					onChange={onSelectDiscipline}
				/>
			</div>
		</div>
	);
});
