import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { MainLayout, AdminLayout, AdminMainLayout } from '../../layouts';
import {
	Banners,
	Categories,
	HomeworkAnswers,
	Courses,
	Directions,
	Disciplines,
	HomeworkBank,
	Promo,
	AdminReferral,
	Students,
	Teachers,
	TariffPlan,
	ControlMentors,
	CoursesAnalytics,
	StudentAnalysis,
	Shop,
	MyStudents,
	PromoStatistics,
	Invoice,
	AbandonedCarts,
	ShortLink,
	AdminNewsletters,
	AdminEssays,
	AdminCheckEssays,
	IssuanceOfCourses,
	ComboCourses,
	ShowcaseCourses,
	Employees
} from '../../../pages/admin/admin-main';

const AdminHomeworkAnswer = lazy(() => import('../../../pages/admin/admin-pages/homework-answer'));
const HomeworkTemplate = lazy(() => import('../../../pages/admin/admin-pages/homework-template'));
const AdminCourse = lazy(() => import('../../../pages/admin/admin-pages/course'));

export default {
	path: '/',
	element: <MainLayout />,
	children: [
		{
			path: 'admin',
			element: <AdminMainLayout />,
			children: [
				{ path: '', element: <Navigate to='courses' /> },
				{
					path: '',
					element: <AdminLayout />,
					children: [
						{ path: 'courses', element: <Courses /> },
						{ path: 'homework-bank', element: <HomeworkBank /> },
						{ path: 'control-mentors', element: <ControlMentors /> },
						{ path: 'banners', element: <Banners /> },
						{ path: 'categories', element: <Categories /> },
						{ path: 'promo', element: <Promo /> },
						{ path: 'directions', element: <Directions /> },
						{ path: 'disciplines', element: <Disciplines /> },
						{ path: 'referral', element: <AdminReferral /> },
						{ path: 'all-users', element: <Students /> },
						{ path: 'my-students', element: <MyStudents /> },
						{ path: 'employees', element: <Employees /> },
						{ path: 'homework-answers', element: <HomeworkAnswers /> },
						{ path: 'teachers', element: <Teachers /> },
						{ path: 'issuance-of-courses', element: <IssuanceOfCourses/> },
						{ path: 'tariff-plan', element: <TariffPlan /> },
						{ path: 'analytics-courses', element: <CoursesAnalytics /> },
						{ path: 'analytics-students', element: <StudentAnalysis /> },
						{ path: 'analytics-promo', element: <PromoStatistics /> },
						{ path: 'shop', element: <Shop /> },
						{ path: 'invoice', element: <Invoice /> },
						{ path: 'abandoned-carts', element: <AbandonedCarts /> },
						{ path: 'short-link', element: <ShortLink /> },
						{ path: 'newsletters', element: <AdminNewsletters/> },
						{ path: 'essays', element: <AdminEssays/> },
						{ path: 'check-essays', element: <AdminCheckEssays/> },
						{ path: 'combo-courses', element: <ComboCourses/> },
						{ path: 'showcase-courses', element: <ShowcaseCourses/> }
					]
				},
				{ path: 'course', element: <AdminCourse /> },
				{ path: 'course/:courseId', element: <AdminCourse /> },
				{ path: 'homework', element: <HomeworkTemplate /> },
				{ path: 'homework/:homeworkId', element: <HomeworkTemplate /> },
				{ path: 'homework-answer/:answerId', element: <AdminHomeworkAnswer /> }
			]
		}
	]
};
