import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, DeleteModal, CustomModal } from '@components';
import { adminEssaysStore } from '@stores';
import { DetailPanelEssay } from './essay-blocks';
import { IGetEssayResponse } from '@services';

export default observer(() => {
	const [currentEssay, setCurrentEssay] = useState<IGetEssayResponse | null>(null);
	const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	useEffect(() => {
		if (!adminEssaysStore.essays.items?.length) {
			adminEssaysStore.getEssays(10, 0);
		}
	}, []);

	const onDeleteEssay = async () => {
		if (currentEssay?.id) {
			await adminEssaysStore.deleteEssay(currentEssay.id);
			setCurrentEssay(null);
		}
	};

	const addEssay = () => {
		adminEssaysStore.clearCurrentEssay();
		setIsOpenAddModal(true);
	};

	const getEssayById = async (id: string, data: any) => {
		await adminEssaysStore.getEssaysById(id);
	};

	const handleOpenDeleteModal = (id: string) => {
		const essay = adminEssaysStore.essays.items.find(essay => essay.id === id);
		setCurrentEssay(essay || null);
	};

	const handleCloseDeleteModal = () => {
		setCurrentEssay(null);
	};

	const handleCloseAddModal = () => {
		setIsOpenAddModal(false);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		adminEssaysStore.getEssays(rowsPerPage, newPage * rowsPerPage);
	};

	const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	return (
		<>
			<Table
				columns={[
					{ field: 'id', name: 'id', copy: true, editable: false },
					{ field: 'text', name: 'Текст', maxCharacters: 60 },
					{ field: 'begins', name: 'Дата начала', type: 'date' },
					{ field: 'ends', name: 'Дата конца', type: 'date' },
					{ field: 'type', name: 'Тип' }
				]}
				data={adminEssaysStore.essays.items || []}
				search
				pagination={{
					count: adminEssaysStore.essays.count,
					page: page,
					rowsPerPage: rowsPerPage,
					onPageChange: handleChangePage,
					onRowsPerPageChange: handleChangeRowsPerPage
				}}
				actionRow={{
					delete: handleOpenDeleteModal
				}}
				actionHeader={{
					add: addEssay
				}}
				detailPanel={{
					Component: DetailPanelEssay,
					action: getEssayById,
					autoClose: true
				}}
			/>
			<DeleteModal
				deleteName={`${currentEssay?.begins} - ${currentEssay?.ends}`}
				open={!!currentEssay}
				handleClose={handleCloseDeleteModal}
				action={onDeleteEssay}
			/>
			<CustomModal
				open={isOpenAddModal}
				handleClose={handleCloseAddModal}
				viewClose='cross'
				widthModal='600px'
			>
				<DetailPanelEssay isNew handleCloseModal={handleCloseAddModal}/>
			</CustomModal>
		</>
	);
});
