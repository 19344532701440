import React from 'react';
import { KeyBanner, OtherProducts } from './proforientation-blocks';
import './style.css';

export default () => {
	return (
		<div className='proforientation-page'>
			<KeyBanner/>
			{/*<OtherProducts/>*/}
		</div>
	);
};
