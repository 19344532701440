import { Difficult } from '@lomonosov/types';

export const getDifficultName = (difficult: Difficult): string | null => {
	switch (difficult) {
	case 'easy':
		return 'Лёгкий';
	case 'medium':
		return 'Средний';
	case 'hard':
		return 'Сложный';
	default: return null;
	}
};
