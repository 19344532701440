import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Table, { IColumn } from '@components/table';
import { adminShortLinkStore } from '@stores';

import '../admin-main-block.globalStyle.css';
import './style.css';

export default observer(() => {
	useEffect(() => {
		if (!adminShortLinkStore.links.length) {
			adminShortLinkStore.getLinks();
		}
	}, []);

	return (
		<>
			<Table
				title='Короткие ссылки'
				columns={linksColumns}
				data={adminShortLinkStore.links}
				actionRow={{
					add: adminShortLinkStore.addLink,
					edit: adminShortLinkStore.editLink,
					delete: adminShortLinkStore.deleteLink
				}}
				pagination
			/>
		</>
	);
});

const linksColumns: IColumn[] = [
	{ field: 'hash', name: 'Short Link', editable: false, copy: { prefix: `${process.env.API_SHORT_ROOT}/l/` } },
	{ field: 'hash', name: 'hash', editable: false },
	{ field: 'link', name: 'URL' },
	{ field: 'description', name: 'Описание' },
	{ field: 'count', name: 'Переходы', editable: false },
	{ field: 'date', name: 'Дата создания' }
];
