import { makeAutoObservable, runInAction } from 'mobx';
import {
	ICreateDisciplineDto,
	IGetDisciplineByIdResponse,
	IGetDisciplinesResponse,
	IUpdateDisciplineDto
} from '@lomonosov/types';

import { AdminDisciplineService } from '@services';

class AdminDisciplineStore {
	disciplines = [] as IGetDisciplinesResponse;
	currentDiscipline: IGetDisciplineByIdResponse;

	constructor () {
		makeAutoObservable(this);
	}

	async getDisciplines () {
		try {
			const { data } = await AdminDisciplineService.getDisciplines();
			runInAction(() => {
				this.disciplines = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async getDisciplineById (disciplineId: string) {
		try {
			const { data } = await AdminDisciplineService.getDisciplineById(disciplineId);
			runInAction(() => {
				this.currentDiscipline = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async createDiscipline (discipline: ICreateDisciplineDto) {
		try {
			const { data } = await AdminDisciplineService.createDiscipline(discipline);
			runInAction(() => {
				this.disciplines = [data, ...this.disciplines];
			});
		} catch (e) {
			console.log(e);
		}
	}

	async updateDiscipline (disciplineId: string, disciplineNew: IUpdateDisciplineDto) {
		try {
			const { data } = await AdminDisciplineService.updateDiscipline(disciplineId, disciplineNew);
			runInAction(() => {
				this.disciplines = this.disciplines.map(discipline => discipline.id === disciplineId ? data : discipline);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async deleteDiscipline (id: string) {
		try {
			await AdminDisciplineService.deleteDiscipline(id);
			runInAction(() => {
				this.disciplines = this.disciplines.filter(discipline => discipline.id !== id);
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const adminDisciplineStore = new AdminDisciplineStore();
