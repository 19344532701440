import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	ICreateDisciplineDto,
	ICreateDisciplineResponse,
	IGetDisciplineByIdResponse,
	IGetDisciplinesResponse,
	IUpdateDisciplineDto,
	IUpdateDisciplineResponse
} from '@lomonosov/types';

export default class {
	/* Дисциплины */
	static async createDiscipline (
		newDiscipline: ICreateDisciplineDto
	): Promise<AxiosResponse<ICreateDisciplineResponse>> {
		return $api.post<ICreateDisciplineResponse>('/disciplines', newDiscipline);
	}

	static async getDisciplines (): Promise<AxiosResponse<IGetDisciplinesResponse>> {
		return $api.get<IGetDisciplinesResponse>('/disciplines');
	}

	static async getDisciplineById (disciplineId: string): Promise<AxiosResponse<IGetDisciplineByIdResponse>> {
		return $api.get<IGetDisciplineByIdResponse>(`/disciplines/${disciplineId}`);
	}

	static async updateDiscipline (
		disciplineId: string,
		newInfoDiscipline: IUpdateDisciplineDto
	): Promise<AxiosResponse<IUpdateDisciplineResponse>> {
		return $api.put<IUpdateDisciplineResponse>(`/disciplines/${disciplineId}`, newInfoDiscipline);
	}

	static async deleteDiscipline (disciplineId: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/disciplines/${disciplineId}`);
	}
}
