import { makeAutoObservable } from 'mobx';
import { AdminIssuanceOfCoursesService } from '@services';
import { IIssueCourseByFile } from '@interfaces';

class AdminIssuanceOfCoursesStore {
  currentIssue: IIssueCourseByFile = {} as IIssueCourseByFile;
	type: IssueType = { id: '', name: '' };
	courseIds: { id: string, name: string }[] = [];
	loader: boolean = false;

	async issueCoursesByFile (data: IIssueCourseByFile) {
		this.changeLoaderState(true);
  	try {
  		await AdminIssuanceOfCoursesService.issueCoursesByFile(data);
  	} catch (e) {
  		console.log(e);
  	}
		this.changeLoaderState(false);
	}

	async issueCoursesByCourses (data: IIssueCourseByFile, givingCourse: { id: string, name: string }[]) {
		this.changeLoaderState(true);
		try {
			await AdminIssuanceOfCoursesService.issueCourseByCourses(data, givingCourse);
		} catch (e) {
			console.log(e);
		}
		this.changeLoaderState(false);
	}

	changeCurrentIssue (value: any, field: string = '') {
		field
			? this.currentIssue[field] = value
			: this.currentIssue = value;
	}

	changeCourseIds (value: { id: string, name: string }[]) {
		this.courseIds = value;
	}

	changeType (newType: IssueType) {
		this.type = newType;
	}

	resetCurrentIssue () {
		this.currentIssue = defaultCurrentIssue;
	}

	changeLoaderState (value: boolean) {
		this.loader = value;
	}

	constructor () { makeAutoObservable(this); }
}

const defaultCurrentIssue: IIssueCourseByFile = {
	course: { id: '', name: '' },
	tariff: { id: '', name: '' },
	endPeriod: '',
	startPeriod: '',
	fileId: ''
};

type IssueType = {
	id: 'course' | 'file' | '',
	name: 'По купленным курсам' | 'По файлу' | ''
};

export const adminIssuanceOfCoursesStore = new AdminIssuanceOfCoursesStore();
