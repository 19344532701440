import React from 'react';
import Icon from '@mui/material/Icon';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

interface ILink extends ListItemButtonProps {
	iconName?: string
	tooltip?: string
	children: any
}

export default (props: ILink) => {
	const {
		children,
		iconName,
		tooltip,
		...otherProps
	} = props;

	return (
		<Tooltip
			enterDelay={500}
			leaveDelay={300}
			placement='right'
			title={tooltip}
		>
			<ListItemButton className='DrawerMenuListItem theme-text' {...otherProps}>
				{iconName &&
					<ListItemIcon className='DrawerMenuListItem__icon theme-text'>
						<Icon>{iconName}</Icon>
					</ListItemIcon>
				}
				{children && <ListItemText className='DrawerMenuListItem__text theme-text' primary={children} />}
			</ListItemButton>
		</Tooltip>
	);
};
