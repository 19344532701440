import { Components, Theme } from '@mui/material';

export const MuiStyledComponents = (theme: 'light' | 'dark'): Components<Omit<Theme, 'components'>> => ({
	MuiTextField: {
		styleOverrides: {
			root: {
				fontFamily: 'Geologica, sans-serif',
				fontWeight: 200
			}
		}
	},
	MuiButton: {
		styleOverrides: {
			root: {
				textTransform: 'none',
				color: `${theme === 'light' ? '#191919' : '#ffffff'}`
			}
		},
		variants: [
			{
				props: { variant: 'outlined', color: 'primary' },
				style: {
					border: `1px solid ${theme === 'light' ? '#191919' : '#ffffff'}`,
					color: `${theme === 'light' ? '#191919' : '#ffffff'}`,
					'&:hover': {
						border: `1px solid ${theme === 'light' ? '#191919' : '#ffffff'}`
					}
				}
			},
			{
				props: { variant: 'contained', color: 'secondary' },
				style: {
					backgroundColor: '#ffffff',
					color: '#191919',
					'&:hover': {
						backgroundColor: '#ffffff'
					}
				}
			},
			{
				props: { variant: 'contained', color: 'info' },
				style: {
					backgroundColor: '#7F6EC1',
					color: '#ffffff',
					'&:hover': {
						backgroundColor: '#7F6EC1'
					}
				}
			},
			{
				props: { size: 'large' },
				style: {
					fontSize: '24px',
					textTransform: 'lowercase'
				}
			},
			{
				props: { variant: 'contained', color: 'primary' },
				style: {
					backgroundColor: `${theme === 'light' ? '#191919' : '#ffffff'}`,
					color: `${theme === 'light' ? '#ffffff' : '#191919'}`,
					'&:hover': {
						backgroundColor: `${theme === 'light' ? '#191919' : '#ffffff'}`
					},
					lineHeight: '100%'
				}
			},
			{
				props: { variant: 'contained', color: 'error' },
				style: {
					backgroundColor: '#FF6189',
					color: '#191919'
				}
			}
		]
	},
	MuiChip: {
		variants: [
			{
				props: { variant: 'outlined' },
				style: {
					border: `1px solid ${theme === 'light' ? '#191919' : '#ffffff'}`,
					color: '1px solid #191919',
					fontWeight: 200
				}
			}
		]
	},
	MuiTabs: {
		variants: [
			{
				props: { indicatorColor: 'primary' },
				style: {
					'& .MuiTabs-indicator': {
						display: 'none'
					},
					backgroundColor: theme === 'light' ? '#E7E7E7' : '#6B6B6B',
					borderRadius: '10px'
				}
			},
			{
				props: { color: 'success' },
				style: {
					'& .MuiTabs-flexContainer': {
						gap: '6px'
					}
				}
			}
		]
	},
	MuiTab: {
		variants: [
			{
				props: { color: 'primary' },
				style: {
					background: theme === 'light' ? '#E7E7E7' : '#6B6B6B',
					border: `1px solid ${theme === 'light' ? '#E7E7E7' : '#6B6B6B'}`,
					position: 'relative',
					padding: '11px 50px',
					'&.Mui-selected': {
						borderRadius: '10px',
						color: theme === 'light' ? '#191919' : '#ffffff',
						background: theme === 'light' ? '#FFFFFF' : '#191919'
					}
				}
			},
			{
				props: { color: 'default' },
				style: {
					color: theme === 'light' ? '#191919' : '#ffffff',
					background: 'transparent',
					fontWeight: 'normal',
					'&.Mui-selected': {
						color: theme === 'light' ? '#191919' : '#ffffff',
						background: theme === 'light' ? '#FFFFFF' : '#191919',
						fontWeight: 'bold'
					}
				}
			},
			{
				props: { color: 'success' },
				style: {
					background: theme === 'light' ? '#ffffff' : '#191919',
					textTransform: 'none',
					fontFamily: 'UA-brand, sans-serif',
					fontWeight: '400',
					fontSize: '16px',
					borderRadius: '5px',
					'&.Mui-selected': {
						background: '#70EBE7'
					}
				}
			}
		]
	},
	MuiCheckbox: {
		variants: [
			{
				props: { color: 'primary' },
				style: {
					color: theme === 'light' ? '#191919' : '#ffffff',
					'&.Mui-checked': {
						color: theme === 'light' ? '#191919' : '#ffffff'
					}
				}
			}
		]
	},
	MuiMenu: {
		styleOverrides: {
			paper: {
				width: 'fit-content',
				maxWidth: 'max-content'
			}
		}
	},
	MuiSwitch: {
		styleOverrides: {
			thumb: {
				color: theme === 'light' ? '#191919' : '#ffffff'
			}
		}
	},
	MuiCard: {
		styleOverrides: {
			root: {
				backgroundColor: theme === 'light' ? '#FFFFFF' : '#191919',
				boxShadow: 'none'
			}
		},
		variants: [
			{
				props: { color: 'secondary' },
				style: {
					backgroundColor: theme === 'light' ? '#F9F9F9' : '#191919',
					color: theme === 'light' ? '#191919' : '#ffffff',
					borderRadius: '20px'
				}
			},
			{
				props: { color: 'white' },
				style: {
					backgroundColor: theme === 'light' ? '#FFFFFF' : '#191919',
					color: theme === 'light' ? '#191919' : '#ffffff',
					borderRadius: '20px'
				}
			}
		]
	},
	MuiIconButton: {
		variants: [
			{
				props: { color: 'primary' },
				style: {
					color: theme === 'light' ? '#FFFFFF' : '#191919',
					background: theme === 'light' ? '#191919' : '#FFFFFF',
					'&:hover': {
						background: theme === 'light' ? '#474747' : '#b3b3b3'
					}
				}
			}
		]
	}
});
