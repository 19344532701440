import React from 'react';
import { observer } from 'mobx-react-lite';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { generalStore } from '@stores';

import './style.css';

export default observer(() => {
	return (
		<Snackbar
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			open={generalStore.alert}
			autoHideDuration={5000}
			onClose={() => generalStore.changeAlertState(generalStore.alertStatus, generalStore.alertMessage)}
		>
			<MuiAlert
				className={generalStore.alertStatus === 'success' ? 'alert' : 'warning'}
				elevation={6} variant='filled'
				severity={generalStore.alertStatus}
			>
				{generalStore.alertMessage}
			</MuiAlert>
		</Snackbar>
	);
});
