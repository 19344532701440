import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import { INewsletterInterface } from '@interfaces';

export default class {
	static async getAllNewsletters (userId: string): Promise<AxiosResponse<INewsletterInterface[]>> {
		return $api.get<INewsletterInterface[]>(`/users/${userId}/newsletters`);
	}

	static async readNewsLetter (userId: string, newsLetterId: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/users/${userId}/newsletters/${newsLetterId}`);
	}
}
