import React, { useEffect, useState } from 'react';
import './style.css';
import classNames from 'classnames';

interface LoaderProps {
	className?: string;
}

export default (props: LoaderProps) => {
	const { className } = props;
	const [progress, setProgress] = useState(0);

	const incrementProgress = () => {
		if (progress < 99) {
			setProgress(prevProgress => prevProgress + 1);
		}
	};

	useEffect(() => {
		const interval = setInterval(incrementProgress, 300);
		return () => clearInterval(interval);
	}, []);

	return (
		<div className={classNames('loader theme-background', className)}>
			<svg className='loader__icon' viewBox='0 0 24 24'>
				<circle className='loader__icon-value' cx='12' cy='12' r='10'/>
				<circle className='loader__icon-value' cx='12' cy='12' r='10'/>
				<circle className='loader__icon-value' cx='12' cy='12' r='10'/>
				<circle className='loader__icon-value' cx='12' cy='12' r='10'/>
				<circle className='loader__icon-value' cx='12' cy='12' r='10'/>
				<circle className='loader__icon-value' cx='12' cy='12' r='10'/>
			</svg>
		</div>
	);
};
