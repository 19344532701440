import { $api } from '@helpers';
import {
	IGetComboResponse, IGetComboFiltersDto,
	ICreateComboDto, ICreateComboResponse, IUpdateComboDto, IGetTariffsComboResponse,
	ICreateTariffComboDto, IUpdateTariffComboDto, IGetComparisonTariffsComboResponse, ICreateComparisonTariffsComboDto, IUpdateComparisonTariffComboDto, IUpdateComboResponse, IComboCourse, IComboTariffPeriod, IUpdateOrderingDto
} from '@interfaces';
import { AxiosResponse } from 'axios';

export default class {
	static async getCombinations (take: number, skip: number, filters?: IGetComboFiltersDto): Promise<AxiosResponse<IGetComboResponse>> {
		return $api.get<IGetComboResponse>('/combinations', { params: { take, skip } });
	}

	static async getComboCourses (comboId: string): Promise<AxiosResponse<IComboCourse[]>> {
		return $api.get<IComboCourse[]>(`/combinations/${comboId}/courses`);
	}

	static async createCombo (data: ICreateComboDto): Promise<AxiosResponse<ICreateComboResponse>> {
		return $api.post<ICreateComboResponse>('/combinations', data);
	}

	static async updateCombo (id: string, data: IUpdateComboDto): Promise<AxiosResponse<IUpdateComboResponse>> {
		return $api.put<IUpdateComboResponse>(`/combinations/${id}`, data);
	}

	static async activateComboCourse (courseId: string, isActive: boolean): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/combinations/to-courses/${courseId}/activate`, { isActive });
	}

	static async activeCombo (id: string, isActive: boolean): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/combinations/${id}/activate`, { isActive });
	}

	static async deleteCombo (id: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/combinations/${id}`);
	}

	// ////////////// TARIFFS COMBO ////////////////////////////////

	static async getTariffsCombo (comboId: string): Promise<AxiosResponse<IGetTariffsComboResponse[]>> {
		return $api.get<IGetTariffsComboResponse[]>(`/combination-tariffs/combination/${comboId}`);
	}

	static async createTariffCombo (data: ICreateTariffComboDto): Promise<AxiosResponse<IGetTariffsComboResponse>> {
		return $api.post<IGetTariffsComboResponse>('/combination-tariffs', data);
	}

	static async updateTariffCombo (id: string, data: IUpdateTariffComboDto): Promise<AxiosResponse<IGetTariffsComboResponse>> {
		return $api.put<IGetTariffsComboResponse>(`/combination-tariffs/${id}`, data);
	}

	static async activateTariffCombo (id: string, isActive: boolean): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/combination-tariffs/${id}/activate`, { isActive });
	}

	static async deleteTariffCombo (id: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/combination-tariffs/${id}`);
	}

	static async orderingTariffsCombo (tariffs: IUpdateOrderingDto[]): Promise<AxiosResponse<void>> {
		return $api.put<void>('/combination-tariffs/ordering', { tariffs: tariffs });
	}

	// ////////////// COMPARISON TARIFFS COMBO ////////////////////////////////

	static async getComparisonTariffsCombo (tariffId: string): Promise<AxiosResponse<IGetComparisonTariffsComboResponse[]>> {
		return $api.get<IGetComparisonTariffsComboResponse[]>(`/comparison-tariffs/combo-tariffs/${tariffId}`);
	}

	static async createComparisonTariffCombo (data: ICreateComparisonTariffsComboDto): Promise<AxiosResponse<IGetComparisonTariffsComboResponse>> {
		return $api.post<IGetComparisonTariffsComboResponse>('/comparison-tariffs', data);
	}

	static async updateComparisonTariffCombo (id: string, data: IUpdateComparisonTariffComboDto): Promise<AxiosResponse<IGetTariffsComboResponse>> {
		return $api.put<IGetTariffsComboResponse>(`/comparison-tariffs/${id}`, data);
	}

	static async deleteComparisonTariffCombo (id: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/comparison-tariffs/${id}`);
	}

	// ////////////// PERIODS COMBO ////////////////////////////////

	static async getComboTariffPeriods (comboTariffId: string): Promise<AxiosResponse<IComboTariffPeriod[]>> {
		return $api.get<IComboTariffPeriod[]>(`/combination-periods/combo-tariff/${comboTariffId}`);
	}

	static async createComboTariffPeriod (data: Omit<IComboTariffPeriod, 'consist' | 'id'>): Promise<AxiosResponse<IComboTariffPeriod>> {
		return $api.post<IComboTariffPeriod>('/combination-periods', data);
	}

	static async updateComboTariffPeriod (id: string, data: IComboTariffPeriod): Promise<AxiosResponse<IComboTariffPeriod>> {
		return $api.put<IComboTariffPeriod>(`/combination-periods/${id}`, data);
	}

	static async activateComboTariffPeriod (id: string, isActive: boolean): Promise<AxiosResponse<void>> {
		return $api.put<void>(`/combination-periods/${id}/activate`, { isActive });
	}

	static async deleteComboTariffPeriod (id: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/combination-periods/${id}`);
	}

	static async orderingComboTariffPeriods (periods: IUpdateOrderingDto[]): Promise<AxiosResponse<void>> {
		return $api.put<void>('/combination-periods/ordering', { periods: periods });
	}
}
