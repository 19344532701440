import React, { useRef, useState } from 'react';
import { ViewportList } from 'react-viewport-list';
import { generalStore, userCourseStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Button, CustomModal, PortalPlug, SettingCourseModal } from '@components';
import { CoursesCardLoader } from '../../study-program-components';
import CoursesCard from '../courses-card';
import { SettingCourseModalType } from '@components/setting-course-modal';
import { TeacherCardModal } from '@pages/portal/portal-blocks';
import { ICourseListItem } from '@interfaces';

export const CoursesList = observer(() => {
	const [currentCourse, setCurrentCourse] = useState<ICourseListItem | null>(null);
	const [typeModal, setTypeModal] = useState<SettingCourseModalType>('setting');
	const listRef = useRef(null);

	if (userCourseStore.loading.isLoading) {
		return (
			<div className='study-program__list'>
				<CoursesCardLoader/>
				<CoursesCardLoader/>
				<CoursesCardLoader/>
				<CoursesCardLoader/>
				<CoursesCardLoader/>
			</div>
		);
	}

	if (!userCourseStore.filteredCourses?.length) {
		return (
			<div className='study-program__list'>
				<PortalPlug buttons={plugButtons}>
					Пока у тебя нет ни одного курса
				</PortalPlug>
			</div>
		);
	}

	const openTeacherModal = async (teacherInfo: ICourseListItem['teacher']) => {
		generalStore.setCurrentTeacher({
			name: teacherInfo.name,
			photo: teacherInfo?.originalUser?.photo
		});
		generalStore.changeOpenTeacherModalState(true);
		if (!generalStore.teachers?.length) {
			await generalStore.getStrapiTeachers();
		}
	};

	const handleCloseModal = () => {
		setCurrentCourse(null);
	};

	const openSettingModal = (course: ICourseListItem, type) => {
		setCurrentCourse(course);
		setTypeModal(type);
	};

	const handleSubmitActionMenu = (settings) => {
		if (typeModal === 'delete') {
			userCourseStore.deleteCurrentCourse(currentCourse?.id || '');
		} else {
			userCourseStore.updateSettingCourse(currentCourse?.id || '', settings.id, settings);
		}
		setCurrentCourse(null);
	};

	return (
		<div className='study-program__list' ref={listRef}>
			<ViewportList
				items={userCourseStore.filteredCourses}
			>
				{(course) => (
					<CoursesCard key={course.id} course={course} openTeacherModal={openTeacherModal} selectCourse={openSettingModal}/>
				)}
			</ViewportList>
			<div className='study-program__footer'>
				<Link className='study-program__button' to='/shop'>
					<Button type='outline_green'>+ Добавить другие курсы</Button>
				</Link>
			</div>
			<CustomModal
				viewClose='cross'
				open={currentCourse !== null}
				handleClose={handleCloseModal}
				heightModal='auto'
				textTitle={'Настройки курса'}
				classNameTitle='study-program__modalTitle'
				className='study-program__modal theme-block-background'
				widthModal='400px'
			>
				<SettingCourseModal
					type={typeModal}
					course={currentCourse}
					action={handleSubmitActionMenu}
					handleClose={handleCloseModal}
					changeType={setTypeModal}
				/>
			</CustomModal>
			<TeacherCardModal
				open={generalStore.isOpenTeacherModal}
				handleClose={() => generalStore.changeOpenTeacherModalState(false)}
				isLoading={generalStore.isLoadingStrapiTeachers}
				teacher={generalStore.currentTeacher}
				strapiTeachers={generalStore.teachers}
			/>
		</div>
	);
});

const plugButtons = [
	{ title: 'Купить мастер группу', color: 'main_green', href: '/shop' },
	{ title: 'Активировать бесплатный курс', color: 'outline_green', href: '/trial-activate' }
];
