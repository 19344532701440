import { useState } from 'react';

export default function useTimer () {
	const [id, setId] = useState<NodeJS.Timeout | null>(null);
	const [status, setStatus] = useState<'OFF' | 'ON'>('OFF');
	const [countdown, setCountdown] = useState({
		hrs: '00',
		mins: '00',
		secs: '00'
	});

	const start = (durationInSecs: number) => {
		if (status === 'ON' || id !== null) {
			throw new Error('Another timer already running.');
		}

		if (!durationInSecs) {
			clear();
			return;
		}

		try {
			let timer = durationInSecs;

			const thisId = setInterval(() => {
				let hrs = parseInt((timer / 3600 % 3600).toString(), 10).toString();
				let mins = parseInt((timer / 60 % 60).toString(), 10).toString();
				let secs = parseInt((timer % 60).toString(), 10).toString();

				if (hrs.length === 1) hrs = '0' + hrs;
				if (mins.length === 1) mins = '0' + mins;
				if (secs.length === 1) secs = '0' + secs;

				setCountdown({ hrs, mins, secs });

				if (--timer < 0) {
					clear();
				}
			}, 1000);

			setId(thisId);
			setStatus('ON');
			return { ok: true };
		} catch (e) {
			return { ok: false };
		}
	};

	const clear = () => {
		if (id === null || status === 'OFF') return;
		clearInterval(id);
		setId(null);
		setStatus('OFF');
		return { ok: true };
	};

	const getSeconds: () => number | null = () => {
		if (!id) return null;
		return Number(countdown.hrs) * 3600 + Number(countdown.mins) * 60 + Number(countdown.secs);
	};

	return { start, countdown, getSeconds, clear, status };
}
