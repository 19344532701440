const timezones = [
	{
		name: 'UTC+02:00 Калининград',
		timezone: 'Europe/Kaliningrad'
	},
	{
		name: 'UTC+03:00 Москва',
		timezone: 'Europe/Moscow'
	},
	{
		name: 'UTC+03:00 Санкт-Петербург',
		timezone: 'Europe/Moscow'
	},
	{
		name: 'UTC+03:00 Волгоград',
		timezone: 'Europe/Volgograd'
	},
	{
		name: 'UTC+04:00 Самара',
		timezone: 'Europe/Samara'
	},
	{
		name: 'UTC+05:00 Екатеринбург',
		timezone: 'Asia/Yekaterinburg'
	},
	{
		name: 'UTC+06:00 Омск',
		timezone: 'Asia/Omsk'
	},
	{
		name: 'UTC+07:00 Новосибирск',
		timezone: 'Asia/Novosibirsk'
	},
	{
		name: 'UTC+07:00 Красноярск',
		timezone: 'Asia/Krasnoyarsk'
	},
	{
		name: 'UTC+08:00 Иркутск',
		timezone: 'Asia/Irkutsk'
	},
	{
		name: 'UTC+09:00 Якутск',
		timezone: 'Asia/Yakutsk'
	},
	{
		name: 'UTC+10:00 Владивосток',
		timezone: 'Asia/Vladivostok'
	},
	{
		name: 'UTC+11:00 Магадан',
		timezone: 'Asia/Magadan'
	},
	{
		name: 'UTC+12:00 Камчатка',
		timezone: 'Asia/Kamchatka'
	},
	{
		name: 'UTC+12:00 Анадырь',
		timezone: 'Asia/Anadyr'
	}
];

export default timezones;
