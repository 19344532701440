import React from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from '@components';
import { constants, images } from '@helpers';

import './style.css';

export default observer((props: MentorFeedbackProps) => {
	const { chatLink, chatImage } = props;

	return (
		<div className='mentor-feedback theme-block-background'>
			<img
				className='mentor-feedback__image'
				src={chatImage[0]?.id
					? `${constants.URL}/files/${chatImage[0]?.id}`
					: images.personalInfoAvatar
				}
				alt='chatImage'
			/>
			<a
				className='mentor-feedback__link'
				href={chatLink}
				target='_blank' rel='noreferrer'
			>
				<Button type='main_green'>
					Написать ментору
				</Button>
			</a>
		</div>
	);
});

interface MentorFeedbackProps {
	chatLink: string;
	chatImage: { id: string }[]
}
