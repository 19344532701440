import { ICreateCourseDto, ICreateCourseResponse, IGetCourseByIdResponse, IUpdateCourseDto, IUpdateCourseResponse } from '@lomonosov/types';

export enum ClassEnum {
  '11th'= '11 класс',
  '10th'= '10 класс',
  '9th'= '9 класс',
  '8th'= '8 класс'
}

export interface IClassroomOption {
	id: ClassEnum;
	name: ClassEnum;
	anglicism?: string;
}

export interface ICurrentCourse extends IGetCourseByIdResponse {
	classroom: IClassroomOption;
	conversionTable: any[];
	start: Date | string;
	end: Date | string;
}

export interface ICurrentCourseResponse extends IGetCourseByIdResponse {
	classroom: ClassEnum;
	conversionTable: any[];
	start: Date | string;
	end: Date | string;
}

export interface CreateCourseResponse extends ICreateCourseResponse{
	classroom: ClassEnum;
	conversionTable: any[];
	start: Date | string;
	end: Date | string;
}

export interface UpdateCourseResponse extends IUpdateCourseResponse {
	classroom: ClassEnum;
	conversionTable: any[];
	start: Date | string;
	end: Date | string;
}

export interface UpdateCourseDto extends IUpdateCourseDto {
	classroom: ClassEnum;
	conversionTable: any[];
	start: Date | string;
	end: Date | string;
}

export interface CreateCourseDto extends ICreateCourseDto {
	classroom: ClassEnum;
	conversionTable: any[];
	start: Date | string;
	end: Date | string;
}

export type CourseType = 'course' | 'combination';

export interface IGetCoursesNewShopItem {
	id: string;
	name: string;
	order: number;
	type: CourseType;
	directions: string;
	disciplines: string;
}

export interface IOrderingNewShopCourseDto {
	id: string;
	order: number;
	type: CourseType;
}
