import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { PortalCoursesCardAdvantages } from 'src/pages/portal/portal-components';
import { JournalCardGradeBlock } from './journal-card-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IUserHomework } from '@interfaces';
import { constants, images, truncateText } from '@helpers';

import './style.css';

interface IJournalCardProps {
	homework: IUserHomework
}

export default (props: IJournalCardProps) => {
	const { homework } = props;

	const getHomeworkType = () => {
		switch (homework.template.type) {
		case 'probe': return 'пробный экзамен';
		case 'manual': return 'дз с проверкой от менторов';
		default: return 'дз с автопроверкой';
		}
	};

	const advantages = [
		homework.template.discipline.name,
		getHomeworkType()
	];

	return (
		<div className='journal-card theme-block-background'>
			<img
				className='journal-card__preview'
				src={
					homework.lesson.section.course.preview?.length
						? `${constants.URL}/files/${homework.lesson.section.course.preview[0].id}`
						: images.defaultCourse
				}
			/>
			<div className='journal-card__title-block'>
				<PortalCoursesCardAdvantages advantages={advantages}/>
				<h2 className='journal-card__title'>
					{truncateText(homework.template.name, 60)}
				</h2>
			</div>
			<JournalCardGradeBlock
				{...homework.answers}
			/>
			<Link
				className='journal-card__button'
				to={`/tasks/${homework.shortId}`}
			>
				<Button
					color='primary'
					variant='contained'
				>
					Смотреть&nbsp;&nbsp;<ArrowForwardIcon/>
				</Button>
			</Link>
		</div>
	);
};
