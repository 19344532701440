import React, { useEffect, useState, memo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CloseOrOpenIcon = memo((props: any) => {
	const { data, updateStatus } = props;
	const [isClose, setIsClose] = useState(data.isClose);

	useEffect(() => {
		setIsClose(data.isClose);
	}, [data.id, data.isClose]);

	const onToggleIcon = async () => {
		if (data.status !== 'green') {
			setIsClose(prev => !prev);
		}
		updateStatus({ ...data, isClose: !isClose });
	};

	return (
		<Tooltip title={isClose ? 'Открыть' : 'Закрыть'}>
			<IconButton onClick={onToggleIcon}>
				{isClose ? <RestoreIcon color='error' /> : <CheckCircleOutlineIcon color='success' />}
			</IconButton>
		</Tooltip>
	);
});

export default CloseOrOpenIcon;
