import React from 'react';
import { Button } from '@components';
import { BookIcon, DoneIcon } from 'lomonosov-icons';
import { images } from '@helpers';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HeadphonesSharpIcon from '@mui/icons-material/HeadphonesSharp';
import './style.css';

export default () => {
	return (
		<div className='other-products'>
			<div className='other-products__title theme-text'>
				<span>Другие продукты Work Way</span>
				<a
					className='theme-text'
					href='https://work-way.ru'
					target='_blank'
					rel='noreferrer'
				>
					Перейти на сайт
				</a>
			</div>
			<div className='other-products__list'>
				{data.map((item, index) =>
					<div key={index} className='other-products__list-item theme-block-background'>
						<div className='other-products__image-container'>
							<img src={item.img} alt='other product image'/>
							{item.feature}
						</div>
						<div className='other-products__list-item-title'>
							<div style={{ color: item.color }}>
								{item.icon}
							</div>
							<span>{item.name}</span>
						</div>
						<div className='other-products__advantages'>
							{item.advantages.map((advantage, index) =>
								<div key={index}>
									<DoneIcon/>
									<span>{advantage}</span>
								</div>
							)}
						</div>
						<a
							className='other-products__button'
							href='https://work-way.ru/#tarifs'
							target='_blank'
							rel='noreferrer'
						>
							<Button type='main_blue'>
								Перейти
							</Button>
						</a>
					</div>
				)}
			</div>
		</div>
	);
};

const data = [
	{
		img: images.careerGuidanceCard1,
		icon: <HeadphonesSharpIcon/>,
		color: '#FF3364',
		name: 'Консультация',
		feature: <div className='other-products__image-container__time'>
			<AccessTimeIcon/>
			<span>60 мин</span>
		</div>,
		advantages: [
			'Индивидуально помогаем выбрать профессию',
			'Анализируем приоритеты, интересы и способности'
		]
	},
	{
		img: images.careerGuidanceCard2,
		icon: <BookIcon/>,
		color: '#FFC800',
		name: 'Курс по профориентации',
		feature: <>
			<div className='other-products__image-container__time'>
				<AccessTimeIcon/>
				<span>1 месяц</span>
			</div>
			<div className='other-products__image-container__star'>
				хит
			</div>
		</>,
		advantages: [
			'Помогаем выбрать профессию и вуз',
			'Анализируем рынок труда',
			'Готовим рекомендации по карьерному развитию'
		]
	}
];
