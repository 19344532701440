import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, CustomModal } from '@components';
import { userGamificationStore, userStore } from '@stores';
import { constants } from '@helpers';
import '../style.css';

export default observer(() => {
	const accrualSalary = async () => {
		await userGamificationStore.getSalary(userStore.user.id);
	};

	return (
		<CustomModal
			open={userGamificationStore.isShowSalaryModal && userGamificationStore.gamificationInfo?.profession !== null}
			handleClose={async () =>
				await userGamificationStore.getSalary(userStore.user.id)
			}
			heightModal='auto'
			widthModal='auto'
		>
			<div className='gamification-modal'>
				<h3 className='gamification-modal__title'>
          Отлично, вот твоя зарплата
				</h3>
				<div className='gamification-modal__center-block'>
					<img
						className='gamification-modal__image'
						src={`${constants.URL}/files/${userGamificationStore.gamificationInfo?.profession
							? userGamificationStore.gamificationInfo?.profession?.preview?.[0]?.id
							: ''}`
						}
						alt='error'
					/>
					<div className='gamification-modal__salary'>
            +{userGamificationStore.gamificationInfo?.profession
							? userGamificationStore.gamificationInfo?.profession?.salary
							: 0}
						<span className='dollars'>$</span>
					</div>
				</div>
				<div className='gamification-modal__text'>
          Ты заработал &nbsp;
					{userGamificationStore.gamificationInfo?.profession
						? userGamificationStore.gamificationInfo?.profession.salary
						: 0
					}
					<span className='dollars'>$</span>
          &nbsp; на работе &quot;{userGamificationStore.gamificationInfo?.profession
						? userGamificationStore.gamificationInfo?.profession.name
						: ''
					}&quot;
				</div>
				<div className='gamification-modal__description'>
          Заходи на платформу каждый день, чтобы забирать зарплату
				</div>
				<div className='gamification-modal__button'>
					<Button onClick={() => accrualSalary()} type='main_blue'>
            Забрать
					</Button>
				</div>
			</div>
		</CustomModal>
	);
});
