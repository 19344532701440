import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';

const customLocale: Partial<LocaleObject> = {
	relativeTime: {
		future: '%s',
		past: '%s',
		s: (number) => `${number} ${getNoun(number, 'секунду', 'секунды', 'секунд')}`,
		m: (number) => `${number} ${getNoun(number, 'секунду', 'секунды', 'секунд')}`,
		mm: (number) => `${number} ${getNoun(number, 'минуту', 'минуты', 'минут')}`,
		h: (number) => `${number} ${getNoun(number, 'минуту', 'минуты', 'минут')}`,
		hh: (number) => `${number} ${getNoun(number, 'час', 'часа', 'часов')}`,
		d: (number) => `${number} ${getNoun(number, 'час', 'часа', 'часов')}`,
		dd: (number) => `${number} ${getNoun(number, 'день', 'дня', 'дней')}`,
		M: (number) => `${number} ${getNoun(number, 'день', 'дня', 'дней')}`,
		MM: (number) => `${number} ${getNoun(number, 'месяц', 'месяца', 'месяцев')}`,
		y: (number) => `${number} ${getNoun(number, 'месяц', 'месяца', 'месяцев')}`,
		yy: (number) => `${number} ${getNoun(number, 'год', 'года', 'лет')}`
	}
};

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', customLocale);
dayjs.updateLocale('ru', customLocale);

export function convertMoscowToLocal (moscowDateString: string | Date = new Date()): dayjs.Dayjs {
	// Создаем объект dayjs с датой по московскому времени
	const moscowDate = dayjs.tz(moscowDateString, 'Europe/Moscow');

	// Конвертируем в локальное время пользователя
	return moscowDate.local();
}

export const getDaysUntilDeadline = (firstDate, secondDate) => {
	return Math.ceil((firstDate - secondDate) / (60 * 60 * 24 * 1000)) > 0
		? Math.ceil((firstDate - secondDate) / (60 * 60 * 24 * 1000))
		: 0;
};

export const getNoun = (number, one, two, three) => {
	let n = Math.abs(number);
	n %= 100;
	if (n >= 5 && n <= 20) {
		return three;
	}
	n %= 10;
	if (n === 1) {
		return one;
	}
	if (n >= 2 && n <= 4) {
		return two;
	}
	return three;
};

export const getArrMonthsName = (dates: { begins?: Date | string, ends?: Date | string }[] = []) => {
	const monthsName: any = [];
	let countMonths: number = 0;
	const periods: string[] = [];
	if (dates?.length !== 0) {
		for (let i = 0; i < dates.length; i++) {
			const diffMonths = dayjs(dates[i].ends).diff(dates[i].begins, 'month');
			if (diffMonths > 0) {
				for (let m = 0; m <= diffMonths; m++) {
					periods.push(`${dayjs(dates[i].begins).add(m, 'month').format('MM-YYYY')}`);
				}
			} else {
				periods.push(`${dayjs(dates[i].begins).format('MM-YYYY')}`);
			}
			if (dayjs(dates[i].ends).diff(dates[i].begins, 'month') === 0) {
				monthsName.push(`${months[new Date(dates[i].begins || 0).getUTCMonth()]}-${new Date(dates[i].begins || 0).getUTCFullYear()}`);
			} else {
				monthsName.push(`
				${months[new Date(dates[i].begins || 0).getUTCMonth()]}-${new Date(dates[i].begins || 0).getUTCFullYear()}\u00A0—
				${months[new Date(dates[i].ends || 0).getUTCMonth()]}-${new Date(dates[i].ends || 0).getUTCFullYear()}
			`);
			}
		}
		countMonths = Array.from(new Set(periods)).length;
	}

	return {
		countMonths,
		monthsName
	};
};

export const getPeriodNames = (begins: string, ends: string) => {
	return `${months[new Date(begins).getUTCMonth()]} ${new Date(begins).getUTCFullYear()} - ${months[new Date(ends).getUTCMonth()]} ${new Date(ends).getUTCFullYear()}`;
};

export const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
export const parentMoths = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

interface LocaleObject {
	name: string;
  relativeTime: RelativeTimeOptions;
}

interface RelativeTimeOptions {
  future: string;
  past: string;
  s: string | ((n: number) => string);
  m: string | ((n: number) => string);
  mm: string | ((n: number) => string);
  h: string | ((n: number) => string);
  hh: string | ((n: number) => string);
  d: string | ((n: number) => string);
  dd: string | ((n: number) => string);
  M: string | ((n: number) => string);
  MM: string | ((n: number) => string);
  y: string | ((n: number) => string);
  yy: string | ((n: number) => string);
}
