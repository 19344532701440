import React, { useCallback, useEffect, memo } from 'react';
import { observer } from 'mobx-react-lite';
import Pagination from '@mui/material/Pagination';
import { PortalPlug } from '@components';
import { userHomeworkStore } from '@stores';
import { HomeworkCard } from './homeworks-blocks';
import { HomeworkCardLoader } from './homework-components';
import { HomeworksFilteringMenu } from '../../portal-blocks';
import './style.css';

export default observer(() => {
	useEffect(() => {
		if (!userHomeworkStore.tasks.homework?.length) {
			userHomeworkStore.getHomeworks('homework', 5, 0);
		}
	}, []);

	const changePage = useCallback(async (e, page) => {
		await userHomeworkStore.changePage('homework', page, 5);
	}, []);

	return (
		<div className='homeworks-page'>
			<div className='homeworks-page__header'>
				<h1>
					Домашние задания
				</h1>
				<HomeworksFilteringMenu activeScreen='homework'/>
			</div>
			{userHomeworkStore.isLoading.homework
				? <Loader/>
				: userHomeworkStore.tasks.homework.length !== 0
					? userHomeworkStore.tasks.homework.map(homework =>
						<HomeworkCard key={homework.id} homework={homework}/>
					)
					: <PortalPlug>Доступных домашних заданий еще нет</PortalPlug>
			}
			{userHomeworkStore.pagination.homework.pages > 1 &&
	      <div className='homeworks-page__pagination'>
	        <Pagination
	          count={userHomeworkStore.pagination.homework.pages}
	          page={userHomeworkStore.pagination.homework.currentPage}
	          siblingCount={0} boundaryCount={1}
	          onChange={changePage}
	          defaultPage={1} size='large' showFirstButton showLastButton
	          classes={{ ul: 'pagination' }}
	        />
	      </div>
			}
		</div>
	);
});

const Loader = memo(() =>
	<>
		<HomeworkCardLoader/>
		<HomeworkCardLoader/>
		<HomeworkCardLoader/>
		<HomeworkCardLoader/>
		<HomeworkCardLoader/>
	</>
);
