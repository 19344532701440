import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import {
	ICreateBannerDto,
	ICreateBannerResponse,
	IGetAllBannersResponse,
	IGetBannerByIdResponse,
	IUpdateBannerDto,
	IUpdateBannerResponse
} from '@lomonosov/types';

export default class {
	/* Баннеры  */
	static async getBanners (): Promise<AxiosResponse<IGetAllBannersResponse>> {
		return $api.get<IGetAllBannersResponse>('/banners', { params: { isAdmin: true } });
	}

	static async createBanner (newBanner: ICreateBannerDto): Promise<AxiosResponse<ICreateBannerResponse['portal']>> {
		return $api.post<ICreateBannerResponse['portal']>('/banners', newBanner);
	}

	static async getCurrentBanner (bannerId: string): Promise<AxiosResponse<IGetBannerByIdResponse>> {
		return $api.get<IGetBannerByIdResponse>(`/banners/${bannerId}`);
	}

	static async updateBanner (
		bannerId: string,
		newInfoBanner: IUpdateBannerDto
	): Promise<AxiosResponse<IUpdateBannerResponse['portal']>> {
		return $api.put<IUpdateBannerResponse['portal']>(`/banners/${bannerId}`, newInfoBanner);
	}

	static async deleteBanner (bannerId: string): Promise<AxiosResponse<void>> {
		return $api.delete<void>(`/banners/${bannerId}`);
	}
}
